import Avatar from 'components/shared/Avatar'
import { squadNameTooLong } from 'components/shared/shared2'

const FixtureTeam = ({ squad, name, photo, size = 40, opponent = false }) => {
    return (
        <div className="fixture-team">
            <h6 className="text-center m-b-5">{squadNameTooLong(squad)}</h6>
            {opponent ? (
                <Avatar width={size} height={size} boxed />
            ) : (
                <Avatar width={size} height={size} boxed src={photo} alt={name} />
            )}
        </div>
    )
}
export default FixtureTeam
