import { logout } from 'components/store/actions'
import { Button } from 'react-bootstrap'
import { Power } from 'react-feather'
import { useDispatch } from 'react-redux'

const Logout = () => {
    const dispatch = useDispatch()
    const handelLogout = () => dispatch(logout())
    return (
        <Button onClick={handelLogout} className="floating-logout bg-transparent">
            <Power size={20} className="me-1" />
            Logout
        </Button>
    )
}
export default Logout
