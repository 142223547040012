import Avatar from 'components/shared/Avatar'
import { inbox } from 'components/shared/svg/svgs'
import { toggleCalendar } from 'components/store'
import { pinSidebar, toggleChat } from 'components/store/actions'
import { useWindowSize } from 'hooks'
import { useEffect, useState } from 'react'
import { Calendar, Menu, MessageCircle, Shield } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toggleSidebar } from 'utils'

const MobileMenu = () => {
    const dispatch = useDispatch()
    const { user, chatPin: chatPinned } = useSelector(state => state)
    const { width } = useWindowSize()

    useEffect(() => {
        if (chatPinned) {
            document.body.classList.add('no-overflow')
        } else {
            document.body.classList.remove('no-overflow')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatPinned])

    if (width >= 992) return null

    const onChatClick = () => dispatch(toggleChat())

    const handleCalendarClick = () => dispatch(toggleCalendar())

    return (
        <div className="mobile-menu">
            {/* <div className="button-container">
                <Link onClick={toggleSidebar}>
                    <Menu size={20} />
                    <span className="title">Menu</span>
                </Link>
            </div> */}
            <div className="button-container">
                <Link to="/dashboard/search/squads">
                    <Shield size={20} />
                    <span className="title">Squads</span>
                </Link>
            </div>
            <div className="button-container">
                <Link onClick={handleCalendarClick}>
                    <Calendar size={20} />
                    <span className="title">Calendar</span>
                </Link>
            </div>
            <div className="button-container">
                <Link to="/dashboard/inbox">
                    {user.total_unread > 0 && (
                        <span className="badge text-white badge-important unread">
                            {user.total_unread > 99 ? '99+' : user.total_unread}
                        </span>
                    )}
                    <div className="custom-con md" dangerouslySetInnerHTML={{ __html: inbox }} />
                    <span className="title">Inbox</span>
                </Link>
            </div>
            <div className="button-container">
                <Link onClick={onChatClick}>
                    <MessageCircle size={20} />
                    <span className="title">Chat</span>
                </Link>
            </div>
            <div className="button-container">
                <Link to="/dashboard">
                    {/* <MessageCircle size={20} /> */}
                    <Avatar size={20} src={user.photo_url_xs} alt={user.username} />
                    <span className="title">Me</span>
                </Link>
            </div>
        </div>
    )
}
export default MobileMenu
