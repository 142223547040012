import { getSubstitutionEvents } from 'components/store'
import { useSelector } from 'react-redux'

const LineUpPlayerSubstitution = ({ player, x, y }) => {
    const matchEvents = useSelector(getSubstitutionEvents)

    const playerIsSubbed = matchEvents?.find(event => event.player.id === player.id)

    if (!playerIsSubbed) return null

    return (
        <g
            style={{
                transform: 'translate(3px, 1.3px) scale(0.5)',
                transformBox: 'fill-box',
                transformOrigin: 'center',
            }}>
            <svg x={x} y={y}>
                <path fill="#4fbe30" d="M4 2.16L2 0 0 2.16l.018.05H1.02V7h1.958V2.21H3.98" />
                <path d="M8.02 4.837l-2 2.16-2-2.16.017-.05H5.04v-4.79h1.957v4.79H8" fill="#e43b3b" />
            </svg>
        </g>
    )
}

export default LineUpPlayerSubstitution
