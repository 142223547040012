// import { Chat } from 'components/Dashboard/Chat'
import QuickView from 'components/Dashboard/QuickView'
import Sidebar from 'components/Dashboard/Sidebar'
import Calendar from 'components/Dashboard/pages/Calendar'
import OnBoarding from 'components/Dashboard/pages/OnBoarding'
import OnBoardingSquad from 'components/Dashboard/pages/OnBoardingSquad'
import Player from 'components/Dashboard/pages/Squad/Fixtures/FixtureBody/Tabs/LineUp/Player'
import FixtureModal from 'components/Dashboard/pages/Squad/Fixtures/FixtureModal'
import TrainingModal from 'components/Dashboard/pages/Squad/Training/TrainingModal'
import PlayerModal from 'components/Dashboard/pages/User/PlayerModal'
import { adminRoutesPath } from 'components/routes/AdminRoutes'
import GlobalPopup from 'components/shared/GlobalPopup'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import userMainPusher from 'utils/userMainPusher'
import Logout from './Logout'
import MobileMenu from './MobileMenu'
import VerifyEmail from './VerifyEmail'

const OnBoardingLayout = ({ children }) => (
    <>
        <Logout />
        {children}
    </>
)

const DashboardLayout = ({ children }) => {
    const { pathname } = useLocation()
    const {
        email_unverified: emailUnverified,
        not_onboarded: isNotOnBoarded,
        squad_not_onboarded: notOnBoardedSquads,
    } = useSelector(state => state.user)

    useEffect(() => {
        userMainPusher()
    }, [])

    const hasNotOnBoardedSquads = notOnBoardedSquads?.length > 0

    if (emailUnverified) {
        return (
            <OnBoardingLayout>
                <VerifyEmail />
            </OnBoardingLayout>
        )
    }

    if (isNotOnBoarded) {
        return (
            <OnBoardingLayout>
                <OnBoarding />
            </OnBoardingLayout>
        )
    }

    if (
        hasNotOnBoardedSquads &&
        // not in admin
        !adminRoutesPath.includes(pathname)
    ) {
        return (
            <OnBoardingLayout>
                <OnBoardingSquad squadSlug={notOnBoardedSquads[0]?.squad_slug} />
            </OnBoardingLayout>
        )
    }

    return (
        <>
            <Sidebar />
            <div className="page-container">
                {/* <Header /> */}
                <div className="page-content-wrapper">
                    <div className="content scrollbar-theme" style={{ height: '100%', display: 'block' }}>
                        {children}
                        <TrainingModal />
                        <FixtureModal />
                        <PlayerModal />
                    </div>
                </div>
                <Calendar />
                <QuickView />
                <MobileMenu />
            </div>
            <GlobalPopup />
        </>
    )
}
export default DashboardLayout
