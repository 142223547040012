import { BottomSideDrawer } from 'components/ui'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { ChevronLeft } from 'react-feather'
import Event from '../UserPage/Event'

const DayDrawer = ({ selectedDay, handleDayClose, events }) => {
    return (
        <BottomSideDrawer open={selectedDay} onClose={handleDayClose} className="z-1010 h-100 h-md-unset">
            <div className="h-100 flex-col">
                <div className="px-5c py-3 d-flex justify-content-between align-items-center">
                    <h5 className="ms-6">
                        <strong>{moment(selectedDay).format('dddd, Do MMM YYYY')}</strong>
                    </h5>
                    {events === null ? <p className="my-0"></p> : <p className="my-0">{events?.length} events</p>}
                </div>
                <div className="flex-1 px-5c scrollbar-hidden scrollbar-y d-flex flex-column gap-2">
                    {events === null ? (
                        <div className="h-100 loader-scouty">
                            <div className="loader-container">
                                <div className="loader-ball"> </div>
                            </div>
                        </div>
                    ) : events?.length === 0 ? (
                        <p className="my-auto text-center">You have no events on this day.</p>
                    ) : (
                        events?.map((event, index) => <Event event={event} key={index} />)
                    )}
                </div>
                {/* <div className="flex-center py-2"> */}
                <Button variant="link" size="lg" onClick={handleDayClose} className="day-drawer-close">
                    <ChevronLeft />
                </Button>
                {/* </div> */}
            </div>
        </BottomSideDrawer>
    )
}
export default DayDrawer
