import { EventSquadLayout } from 'components/Layout'
import {
    loadingSelectedTraining,
    resetLoadingSelectedTraining,
    resetSelectedTraining,
    resetTrainingSquad,
    setSelectedTraining,
    setTrainingSquad,
} from 'components/store'
import { BottomSideDrawer } from 'components/ui'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getTraining } from 'services'
import { getAllSearchParams } from 'utils'
import TrainingBody from './TrainingBody'

const TrainingModal = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)
    const { training } = params
    const { isSelectedLoading: isLoading, selected: selectedTraining } = useSelector(state => state.training)
    const [isRequesting, setIsRequesting] = useState(false)

    const reset = () => {
        const { training, ...rest } = params
        setSearchParams(rest)
    }

    const getData = async (squadSlug, id, date) => {
        if (isRequesting) return
        if (!squadSlug || !id || !date) return reset()
        dispatch(loadingSelectedTraining())
        setIsRequesting(true)
        const response = await getTraining(squadSlug, id, date)
        if (response && response.status === 200 && response.data && response.data.status === 'success') {
            const { data: trainingData } = response.data
            dispatch(setTrainingSquad(squadSlug))
            dispatch(setSelectedTraining(trainingData))
        } else {
            reset()
        }
        dispatch(resetLoadingSelectedTraining())
        setIsRequesting(false)
    }

    if (!isLoading && isEmpty(selectedTraining) && training) {
        const [squadSlug, id, date] = training.split('|')
        getData(squadSlug, id, date)
    }

    if (!training) return null

    const handleClose = () => {
        dispatch(resetSelectedTraining())
        dispatch(resetTrainingSquad())
        reset()
    }

    return (
        <BottomSideDrawer open={true} onClose={handleClose} className="z-1010 h-100 h-md-unset">
            <EventSquadLayout event="training">
                {isLoading || isEmpty(selectedTraining) ? (
                    <div className="h-100 loader-scouty">
                        <div className="loader-container">
                            <div className="loader-ball"> </div>
                        </div>
                    </div>
                ) : (
                    <TrainingBody handleClose={handleClose} />
                )}
            </EventSquadLayout>
            <Button variant="link" size="lg" onClick={handleClose} className="training-modal-close">
                <X />
            </Button>
        </BottomSideDrawer>
    )
}

export default TrainingModal
