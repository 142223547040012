import React from 'react'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

const Toast = Component => {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return (
            <Wrapper>
                <Component {...props} {...toastFuncs} />
            </Wrapper>
        )
    }
}

export default Toast

const Wrapper = styled.div`
    .react-toast-notifications__container {
        background: green;
    }
`
