import classNames from 'classnames'
import config from 'config'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const GooglePlacesComponent = props => {
    const selectProps = props?.selectProps || {}
    const selectPropsClasses = selectProps?.className || ''

    const componentsProps = {
        ...props,
        selectProps: {
            placeholder: 'Search for a location',
            ...selectProps,
            classNamePrefix: 'react-select',
            className: classNames('react-select-wrapper', selectPropsClasses),
        },
        apiKey: config.GOOGLE_API_KEY,
    }

    return <GooglePlacesAutocomplete {...componentsProps} />
}

export default GooglePlacesComponent
