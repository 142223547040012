import { matchHasStarted } from 'components/shared/shared2'
import { useWindowSize } from 'hooks'
import { isEmpty } from 'lodash'
import { Suspense, lazy, useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getSquadRoles, getSquadSettings, isLive, playersRsvpNoFixture } from 'utils'
import FixtureHeader from './FixtureHeader'

const MatchDetails = lazy(() => import('./Tabs/MatchDetails'))
const Lineup = lazy(() => import('./Tabs/Lineup'))
const MatchRSVP = lazy(() => import('./Tabs/RSVP'))
const FixtureMap = lazy(() => import('./Tabs/FixtureMap'))
const FixtureLive = lazy(() => import('./Tabs/FixtureLive'))

const TABS = [
    {
        label: 'Match Info',
        key: 'details',
        content: MatchDetails,
        hidden: ({ width }) => width >= 768,
    },
    {
        label: 'Lineup',
        key: 'lineup',
        content: Lineup,
    },
    {
        label: 'Players',
        key: 'players',
        content: MatchRSVP,
    },
    {
        label: 'Map',
        key: 'map',
        content: FixtureMap,
    },
    {
        label: 'Live',
        key: 'live',
        content: FixtureLive,
        hidden: ({ live }) => !live,
    },
]

const FixtureRight = () => {
    const { width } = useWindowSize()
    // const filteredTabs = width >= 768 ? TABS.filter(tab => !tab?.hidden) : TABS
    const squad = useSelector(state => state.clubSquadDetail)
    const { streamType } = getSquadSettings(squad)
    const { isLoading, data: fixture } = useSelector(state => state.fixture)
    const live = streamType !== 'none' && isLive(fixture)
    const filteredTabs = TABS.filter(tab => !tab.hidden?.({ width, live }))

    const [key, setKey] = useState(filteredTabs[0].key)

    const { isManager } = getSquadRoles(squad)

    useEffect(() => {
        if (filteredTabs.length && width >= 768 && key === TABS[0].key) setKey(filteredTabs[0].key)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    if (isEmpty(fixture) || isLoading || !squad) return null

    return (
        <div className="fixture-right">
            {width < 768 && <FixtureHeader />}
            {/* <hr className="mx-5 mt-0 mb-3" /> */}
            <Tab.Container
                defaultActiveKey={filteredTabs[0].key}
                activeKey={key}
                onSelect={k => setKey(k)}
                id="fixture-tab">
                <Nav variant="border">
                    {filteredTabs.map(({ label, key }) => (
                        <Nav.Item key={key}>
                            <Nav.Link eventKey={key}>
                                <span className="d-flex align-items-center justify-content-center">
                                    {isManager &&
                                        playersRsvpNoFixture(fixture).length > 0 &&
                                        key === 'players' &&
                                        !matchHasStarted(fixture) && <div className="blink m-r-5" />}
                                    {label}
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                <Tab.Content>
                    {filteredTabs
                        .filter(({ key: k }) => k === key)
                        .map(({ key, content: Component }) => (
                            <Tab.Pane key={key} eventKey={key}>
                                <Suspense fallback={<div />}>
                                    <Component />
                                </Suspense>
                            </Tab.Pane>
                        ))}
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default FixtureRight
