import classNames from 'classnames'
import lineUpOutlineDark from 'img/line-up-outline-dark.png'
import { Fragment } from 'react'

export const Skeleton = ({ children, isLoading, loader, className }) => {
    const Component = className ? 'div' : Fragment
    const ComponentProps = className ? { className } : {}
    return <Component {...ComponentProps}>{isLoading ? loader : children}</Component>
}

export const LoadingAvatar = ({ className, size = 60, boxed = false, pulse = false }) => (
    <div
        className={classNames(
            'avatar bg-loader',
            boxed ? 'rounded' : 'rounded-circle',
            pulse ? 'animate-pulse' : '',
            className
        )}
        style={{ width: size, height: size }}></div>
)

export const LoadingCircle = ({ className }) => (
    <div className={classNames('spinner-border spinner-border-sm', className)} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
)

export const LoadingBar = ({ className }) => (
    <div className={classNames('bg-loader animate-pulse w-100 h-6 rounded', className)}></div>
)

export const LoadingTableRow = ({ className, columns = 3 }) => (
    <div
        className={classNames('d-grid animate-pulse gap-1', className)}
        style={{
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
        }}>
        {Array.from(Array(columns).keys()).map((_, index) => (
            <div key={index} className={classNames('bg-loader rounded h-10')}></div>
        ))}
    </div>
)

export const LoadingTable = ({ loading, className, rows = 10 }) => (
    <div
        className={classNames(
            '-loading',
            {
                '-active': loading,
            },
            className
        )}>
        <div className="pt-1">
            {Array.from(Array(rows).keys()).map((_, index) => (
                <LoadingBar key={index} className="h-10 mb-2" />
            ))}
        </div>
    </div>
)

export const LoadingWord = ({ className, big = false, height = 'md', style, ...props }) => (
    <div
        className={classNames(
            big ? 'w-20' : 'w-15',
            'bg-loader',
            {
                'h-2.5': height === 'sm',
                'h-4': height === 'md',
                'h-5': height === 'lg',
                'h-6': height === 'xl',
                'h-7': height === '2xl',
                '': height === 'unset',
            },
            className
        )}
        style={{
            borderRadius: '0.15rem',
            ...style,
        }}
        {...props}
    />
)

export const LoadingWords = ({ className, number = 3 }) => (
    <div className={classNames('d-flex h-6 gap-3', className)}>
        {Array.from(Array(number).keys()).map((item, index) => (
            <LoadingWord key={index} big={item % 2 === 0} className="h-100" />
        ))}
    </div>
)

export const LoadingParagraph = ({ className, line = 3 }) => (
    <div className={classNames('d-flex flex-wrap gap-3', className)}>
        {Array.from(Array(line).keys()).map((item, index) => (
            <LoadingWords key={index} number={index % 2 === 0 ? 4 : 3} />
        ))}
    </div>
)

export const LoadingButton = ({ className, width = 100, height = 40, style }) => (
    <div className={classNames('bg-loader rounded', className)} style={{ width, height, ...style }}></div>
)

export const LoadingFormationPlayer = () => (
    <div className="d-flex flex-column align-items-center">
        <div className="w-16 h-16 bg-main-dark animate-pulse rounded-circle mb-2"></div>
        <div className="h-11 w-22.5 bg-main-dark animate-pulse rounded"></div>
    </div>
)

export const LoadingFormation = ({ className, width = 'auto', height = 839 }) => (
    <div className={classNames('bg-loader-gray relative animate-pulse p-3', className)} style={{ width, height }}>
        <img src={lineUpOutlineDark} alt="line-up-outline" className="line-up-outline" />
        <div className="d-flex justify-content-around py-16">
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
        </div>
        <div className="d-flex justify-content-around py-16">
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
        </div>
        <div className="d-flex justify-content-around pt-16">
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
            <LoadingFormationPlayer />
        </div>
        <div className="d-flex justify-content-around ">
            <LoadingFormationPlayer />
        </div>
    </div>
)
// <div className="w-8 h-8 bg-main-dark animate-pulse rounded-circle"></div>
export const LoadingFixtureCard = ({ className, width = 'auto', height = 54.8 }) => (
    <div className={classNames('bg-main-light animate-pulse p-2 d-flex', className)} style={{ width, height }}>
        <div className="w-15 d-flex flex-column justify-content-around">
            <div className="bg-main-dark w-15 h-3 animate-pulse rounded-1"></div>
            <div className="bg-main-dark w-15 h-3 animate-pulse rounded-1"></div>
        </div>
        <div className="bg-main-light-plus animate-pulse rounded w-px mx-2"></div>
        <div className="flex-grow-1 d-flex flex-column justify-content-between">
            <div className="d-flex h-3.5 gap-1.25">
                <div className="w-3.5 h-3.5 bg-main-dark animate-pulse rounded-circle"></div>
                <div className="w-10 h-3.5 bg-main-dark animate-pulse rounded-1"></div>
                <div className="w-14 h-3.5 bg-main-dark animate-pulse rounded-1"></div>
            </div>
            <div className="d-flex h-3.5 gap-1.25">
                <div className="w-3.5 h-3.5 bg-main-dark animate-pulse rounded-circle"></div>
                <div className="w-10 h-3.5 bg-main-dark animate-pulse rounded-1"></div>
                <div className="w-14 h-3.5 bg-main-dark animate-pulse rounded-1"></div>
            </div>
        </div>
        <div className="w-3 d-flex flex-column justify-content-between me-1">
            <div className="bg-main-dark w-3 h-3 animate-pulse rounded-1"></div>
            <div className="bg-main-dark w-3 h-3 animate-pulse rounded-1"></div>
        </div>
    </div>
)

export const LoadingFixtureCards = ({ className, number = 8 }) => (
    <div className={classNames('flex flex-column gap-2 my-2', className)}>
        {Array.from(Array(number).keys()).map((item, index) => (
            <LoadingFixtureCard key={index} />
        ))}
    </div>
)

export const LoadingSearchResult = ({ className, number = 5 }) => (
    <div className={classNames(className, 'px-3')}>
        <LoadingWords number={2} className="mb-3" />
        {Array.from(Array(number).keys()).map((item, index) => (
            <div
                className="d-flex justify-content-between bg-main-light rounded animate-pulse p-5c h-42.25 mb-5c"
                key={index}>
                <div className="d-flex gap-3">
                    <LoadingAvatar className="" />
                    <div className="">
                        <LoadingWords number={3} className="mb-2" />
                        <LoadingWords number={2} className="mb-2" />
                        <LoadingWords number={4} className="mb-2" />
                        <LoadingWords number={2} className="mb-2" />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <LoadingButton width={116} className="mx-3" />
                </div>
            </div>
        ))}
    </div>
)

export const LoadingHome = () => (
    <div className="pt-2.5 px-1.25 flex-grow-1 d-flex flex-column">
        <div className="d-flex gap-2 h-10 w-100">
            <div className="flex-grow-1 d-flex gap-1.25 bg-main-dark animate-pulse rounded-pill p-1.25">
                {Array.from(Array(9).keys()).map((_, index) => (
                    <div
                        key={index}
                        className={classNames('bg-loader h-100 rounded-pill', index % 2 === 0 ? 'w-24' : 'w-20')}
                    />
                ))}
            </div>
            <LoadingAvatar height={40} width={40} />
        </div>
        <div className="d-grid grid-cols-1 grid-cols-md-3 gap-2 py-2 px-0.75 flex-grow-1">
            <div className="bg-main-light animate-pulse rounded"></div>
            <div className="bg-main-light animate-pulse rounded"></div>
            <div className="bg-main-light animate-pulse rounded"></div>
            <div className="bg-main-light animate-pulse rounded"></div>
            <div className="bg-main-light animate-pulse rounded"></div>
            <div className="bg-main-light animate-pulse rounded"></div>
        </div>
    </div>
)

export const LoadingNotification = ({ className }) => (
    <div className={classNames('bg-main-light animate-pulse rounded p-3', className)}>
        <div className="d-flex gap-3">
            <LoadingAvatar className="w-10 h-10" />
            <div className="flex-grow-1">
                <div className="d-flex justify-content-between mb-2">
                    <div className="w-20 h-5 bg-main-dark rounded"></div>
                    <div className="w-20 h-5 bg-main-dark rounded"></div>
                </div>
                <div className="w-100 h-5 bg-main-dark rounded"></div>
            </div>
        </div>
    </div>
)

export const LoadingNotifications = ({ className, number = 4 }) => (
    <div className={classNames('flex flex-column gap-2', className)}>
        {Array.from(Array(number).keys()).map((item, index) => (
            <LoadingNotification key={index} />
        ))}
    </div>
)

export const LoadingSquadCard = ({ className, row = false, country = false, ...otherProps }) => (
    <div className={classNames(row ? 'd-flex flex-row' : 'flex-col flex-center', className)} {...otherProps}>
        <LoadingAvatar width={row ? 30 : 110} height={row ? 30 : 110} boxed className={row ? 'me-2' : 'mb-2'} />
        <div className={classNames('flex-col', row ? '' : 'flex-center')}>
            <LoadingWord big className="mb-1" />
            <LoadingWord className={country ? 'mb-1' : ''} />
            {country && <LoadingWord />}
        </div>
    </div>
)

export const LoadingSquadCardsNoWrap = ({ className, number = 6, row = false, country = false, ...otherProps }) =>
    Array.from(Array(number).keys()).map((item, index) => (
        <LoadingSquadCard key={index} className={className} row={row} country={country} {...otherProps} />
    ))

export const LoadingSquadCards = ({
    className,
    wrapperClassName,
    number = 6,
    row = false,
    country = false,
    ...otherProps
}) => (
    <div className={classNames('flex flex-wrap gap-2', wrapperClassName)}>
        <LoadingSquadCardsNoWrap className={className} number={number} row={row} country={country} {...otherProps} />
    </div>
)

export const LoadingUserCard = ({ className }) => (
    <div className={classNames('w-50c flex-col flex-center', className)}>
        <LoadingAvatar width={110} height={110} className="mb-2" />
        <LoadingWord big className="mb-1" />
        <LoadingWord />
    </div>
)

export const LoadingUserCardsNoWrap = ({ className, number = 6 }) =>
    Array.from(Array(number).keys()).map((item, index) => <LoadingUserCard key={index} className={className} />)

export const LoadingUserCards = ({ className, wrapperClassName, number = 6 }) => (
    <div className={classNames('flex flex-wrap gap-2', wrapperClassName)}>
        <LoadingUserCardsNoWrap className={className} number={number} />
    </div>
)
