import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

const calculateDuration = eventTime => {
    const start = moment()
    let end = eventTime // some random moment after start (in ms)
    let diff = end.diff(start)

    return moment(diff).format('mm:ss')
}

const Countdown = ({ onChange, eventTime, interval = 1000 }) => {
    const [duration, setDuration] = useState(calculateDuration(eventTime))
    const timerRef = useRef(0)
    const timerCallback = useCallback(() => {
        setDuration(calculateDuration(eventTime))
    }, [eventTime])

    useEffect(() => {
        timerRef.current = setInterval(timerCallback, interval)

        return () => {
            clearInterval(timerRef.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventTime])

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if(duration === '00:00') {
            onChange()
        }
    }, [duration])

    return <div>{duration}</div>
}

export default Countdown
