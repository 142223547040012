import { Suspense, lazy, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'

const Attributes = lazy(() => import('./Tabs/Attributes'))
const Info = lazy(() => import('./Tabs/Info'))
const Stats = lazy(() => import('./Tabs/Stats'))
const Games = lazy(() => import('./Tabs/Games'))

const TABS = [
    {
        label: 'Info',
        key: 'info',
        content: Info,
    },
    {
        label: 'Attributes',
        key: 'attributes',
        content: Attributes,
    },
    {
        label: 'Stats',
        key: 'stats',
        content: Stats,
    },
    {
        label: 'Games',
        key: 'games',
        content: Games,
    },
]

const UserTabs = () => {
    const filteredTabs = TABS
    const [key, setKey] = useState(filteredTabs[0].key)

    return (
        <div className="flex-1 flex-col">
            <Tab.Container
                defaultActiveKey={filteredTabs[0].key}
                activeKey={key}
                onSelect={k => setKey(k)}
                id="user-profile-tab">
                <Nav variant="border" className="space-evenly">
                    {filteredTabs.map(({ label, key }) => (
                        <Nav.Item key={key}>
                            <Nav.Link eventKey={key}>{label}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                <Tab.Content className="flex-1">
                    {filteredTabs
                        .filter(({ key: k }) => k === key)
                        .map(({ key, content: Component }) => (
                            <Tab.Pane key={key} eventKey={key} className="h-100">
                                <Suspense fallback={<div />}>
                                    <Component />
                                </Suspense>
                            </Tab.Pane>
                        ))}
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}
export default UserTabs
