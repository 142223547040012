import FinalFormError from 'components/shared/FinalFormError'
import GridButtonSelector from 'components/shared/GridButtonSelector'
import { closeAddEvent, openAddEvent } from 'components/store'
import { setLoader } from 'components/store/actions'
import { BottomDrawer, CircularSlider } from 'components/ui'
import { MATCH_HALF_OPTIONS } from 'constants'
import { cloneDeep } from 'lodash'
import { Field } from 'react-final-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { saveSquadMatchEvent } from 'services'
import {
    getEventsFirstPersonLabel,
    getEventsSecondPersonLabel,
    getGoalkeeper,
    getHalfNumber,
    getPlayerName,
    getPlayersFilteredById,
    getPlayersWithoutGoalkeeper,
    getStartingEleven,
    getSubstitutes,
    isExtraTimeThisHalf,
    isGoalConceded,
    isGoalScored,
    isBlunder,
    isHeader,
    isInterception,
    isRedCard,
    isShot,
    isShotBlocked,
    isShotSaved,
    isSubstitution,
    isTackle,
    isTechnical,
    isYellowCard,
    setMinMaxMinutes,
    sortMatchEvents,
} from 'utils'
import AddEvenPlayer from './AddEvenPlayer2'
import Wizard from './Wizard2'
import validation from './validation'

const isEventInPast = (eventHalf, eventMinute, matchHalf, matchMinute) => {
    const eventHalfNumber = getHalfNumber(eventHalf)
    const matchHalfNumber = getHalfNumber(matchHalf)

    return eventHalfNumber < matchHalfNumber || (eventHalfNumber === matchHalfNumber && eventMinute <= matchMinute)
}

const countYellowCards = (playerId, matchEvents) => {
    return matchEvents.filter(e => isYellowCard(e.event_type) && e.player.id === playerId).length
}

const playerGotDoubleYellowCard = (playerId, matchEvents) => countYellowCards(playerId, matchEvents) >= 2

const isEventsContradictory = (eventType, event, matchEvents) => {
    return (
        isRedCard(eventType) ||
        isSubstitution(eventType) ||
        (isYellowCard(eventType) && playerGotDoubleYellowCard(event.player.id, matchEvents))
    )
}

const removePlayerWithRedCard = (matchPlayers, event, minute, matchHalf, matchEvents, eventType) => {
    if (
        isRedCard(event.event_type) &&
        (isEventsContradictory(eventType, event, matchEvents) ||
            isEventInPast(event.half, event.minute, matchHalf, minute))
    ) {
        return matchPlayers.filter(player => player.player.id !== event.player.id)
    }
    return matchPlayers
}

const removePlayerWithTwoYellowCards = (matchPlayers, event, minute, matchHalf, matchEvents, eventType) => {
    if (
        isYellowCard(event.event_type) &&
        (isEventsContradictory(eventType, event, matchEvents) ||
            isEventInPast(event.half, event.minute, matchHalf, minute))
    ) {
        if (playerGotDoubleYellowCard(event.player.id, matchEvents)) {
            return matchPlayers.filter(player => player.player.id !== event.player.id)
        }
    }

    return matchPlayers
}

const substitutePlayer = (matchPlayers, event, minute, matchHalf, matchEvents, eventType) => {
    let newMatchPlayers = cloneDeep(matchPlayers)
    if (isSubstitution(event.event_type) && isEventInPast(event.half, event.minute, matchHalf, minute)) {
        newMatchPlayers = matchPlayers.map(player => {
            if (player.player.id === event.player2.id) {
                const newPlayer = matchPlayers.find(p => p.player.id === event.player.id)
                return {
                    ...newPlayer,
                    position: player.position,
                    x: player.x,
                    y: player.y,
                }
            }
            if (player.player.id === event.player.id) {
                const newPlayer = matchPlayers.find(p => p.player.id === event.player2.id)
                return {
                    ...newPlayer,
                    position: player.position,
                    x: player.x,
                    y: player.y,
                }
            }
            return player
        })
        // remove the player from the list
        // newMatchPlayers = matchPlayers.filter(
        //     player => player.player.id !== event.player2.id || !player.position.includes('SUB')
        // )
    }
    if (isSubstitution(event.event_type) && isEventsContradictory(eventType, event, matchEvents)) {
        newMatchPlayers = matchPlayers.filter(player => player.player.id !== event.player.id)
    }
    return newMatchPlayers
}

const getPlayersAtMinute = (players, minute, matchHalf, matchEvents, eventType) => {
    let matchPlayers = cloneDeep(players)

    sortMatchEvents(matchEvents, true).forEach(event => {
        matchPlayers = removePlayerWithRedCard(matchPlayers, event, minute, matchHalf, matchEvents, eventType)
        matchPlayers = removePlayerWithTwoYellowCards(matchPlayers, event, minute, matchHalf, matchEvents, eventType)
        matchPlayers = substitutePlayer(matchPlayers, event, minute, matchHalf, matchEvents, eventType)
    })

    return matchPlayers
}

const getPlayerLabel = player => getPlayerName(player.player)

const getPlayerOption = player => ({
    value: player.player.id,
    label: getPlayerLabel(player),
    data: player,
})

const getFirstPlayers = (players, eventType) =>
    isShotSaved(eventType)
        ? getGoalkeeper(players)
        : isShotBlocked(eventType)
        ? getPlayersWithoutGoalkeeper(players)
        : getStartingEleven(players)

const getSecondPlayers = (players, firstId, eventType) =>
    eventType === 'substitution' ? getSubstitutes(players) : getPlayersFilteredById(players, firstId)

const getFirstPlayerOptions = (players, minute, matchHalf, eventType, matchEvents) => {
    const playersAtMinute = getPlayersAtMinute(players, minute, matchHalf, matchEvents, eventType)
    const firstPlayers = getFirstPlayers(playersAtMinute, eventType)
    const firstPlayerOptions = firstPlayers.map(player => getPlayerOption(player))
    return firstPlayerOptions
}

const getSecondPlayerOptions = (players, minute, matchHalf, firstId, eventType, matchEvents) => {
    const playersAtMinute = getPlayersAtMinute(players, minute, matchHalf, matchEvents)
    const secondPlayers = getSecondPlayers(playersAtMinute, firstId, eventType)
    const secondPlayerOptions = secondPlayers.map(player => getPlayerOption(player))
    return secondPlayerOptions
}

const isGoalType = eventType => isGoalScored(eventType) || isGoalConceded(eventType)

const isShowSecondPlayer = (event, values) =>
    event === 'substitution' || (isGoalScored(event) && values?.goal_type?.value === 'open-play')

const showExtraStep = (event, values) => isGoalType(event) && values?.goal_type?.value === 'open-play'
const showExtraStepBlunder = (event, values) => isBlunder(event)

const isTrue = value => value === 'yes'

const EventDrawer = () => {
    const dispatch = useDispatch()
    const { selectedEvent: event, data: fixture } = useSelector(state => state.fixture)
    const { id: squad_id } = useSelector(state => state.clubSquadDetail)
    const { list: matchEvents } = useSelector(state => state.matchEvents)
    const { id: match_id, players, timer, ground, squad } = fixture

    const handleClose = () => dispatch(closeAddEvent())

    const isValidateEvent = (match_half, event_type, minute, first_id, second_id) => {
        // if a player have a event in the future
        // const playerEvents = matchEvents.filter(
        //     event =>
        //         event.player.id === first_id &&
        //         event.event_type !== 'substitution' &&
        //         event.minute > minute &&
        //         event.match_half === match_half
        // )
        const playerEvents = matchEvents.filter(mEvent => mEvent?.player?.id === first_id)
        const playerEventsInFeature = playerEvents.filter(mEvent => mEvent.minute > minute)

        if (playerEventsInFeature.length) {
            const errorMsg = 'Sorry, you cannot add an event with this player since he has an event in the future.'
            const isEventNotAllowed = event_type === 'substitution' || event_type === 'red_card'
            if (isEventNotAllowed) {
                toast.error(errorMsg)
                dispatch(setLoader(false))
                return false
            }
            if (event_type === 'yellow_card') {
                const playerEventsInPast = playerEvents.filter(event => event.minute <= minute)
                const isYellowCardNotAllowed = playerEventsInPast.some(event => event.event_type === 'yellow_card')
                if (isYellowCardNotAllowed) {
                    toast.error(errorMsg)
                    dispatch(setLoader(false))
                    return false
                }
            }
        }
        return true
    }

    const onSubmit = async values => {
        dispatch(setLoader(true))
        const {
            match_half,
            minute,
            first_id,
            second_id,
            notes,
            shot_on_target,
            header_made,
            tackle_made,
            interception_made,
        } = values

        const event_type = event
        const goal_type = values?.goal_type?.value
        const goal_scored_from = values?.goal_scored_from?.value
        const goal_location = values?.goal_location?.value
        const goal_scored_with = values?.goal_scored_with?.value

        const blunder_type = values?.blunder_type?.value
        const possible_blunder = values?.possible_blunder?.value

        if (!isValidateEvent(match_half, event_type, minute, first_id, second_id)) return false

        const time = {
            half: match_half,
            value: minute,
        }

        const payload = {
            match_id,
            event_type,
            squad_id,
            first_id,
            minute,
            time,
            notes,
        }

        if (second_id) payload.second_id = second_id

        if (isGoalScored(event_type)) {
            payload.goal_location = goal_location
            payload.goal_scored_from = goal_scored_from
            payload.goal_scored_with = goal_scored_with
            payload.goal_type = goal_type
        }

        if (isBlunder(event_type)) {
            payload.blunder_type = blunder_type
            payload.possible_blunder = possible_blunder
        }

        if (isGoalConceded(event_type)) {
            payload.goal_scored_from = goal_scored_from
            payload.goal_type = goal_type
        }

        if (isShot(event_type)) {
            payload.shot_on_target = isTrue(shot_on_target)
        }

        if (isHeader(event_type)) {
            payload.header_made = isTrue(header_made)
        }

        if (isTackle(event_type)) {
            payload.tackle_made = isTrue(tackle_made)
        }

        if (isInterception(event_type)) {
            payload.interception_made = isTrue(interception_made)
        }

        const response = await saveSquadMatchEvent(fixture.squad.squad_slug, payload)
        if (response.status === 202 && response.data && response.data.status === 'success') {
            toast.success('Event created successfully')
            // const { data: eventData } = responseData
            // dispatch(setMatchEventsData(eventData))
            // dispatch(handelGoal(match_id, event_type))
            dispatch(openAddEvent())
            handleClose()
        } else {
            toast.error('Something went wrong')
        }

        dispatch(setLoader(false))
    }

    // const timerMinute = getTimerMinute(timer, addEvent)
    // const timerHalfValue = getTimerHalfValue(timer)
    const minMaxMinutes = setMinMaxMinutes('1st')

    const defaultValues = {
        match_half: '1st',
        min_max_mins: minMaxMinutes,
        minute: 0,
        tackle_made: 'yes',
        shot_on_target: 'yes',
        shot_type: 'non-header',
        interception_made: 'yes',
        header_made: 'yes',
        event_type: event,
    }

    return (
        <BottomDrawer open={!!event} onClose={handleClose}>
            <Wizard onSubmit={onSubmit} initialValues={defaultValues} event={event}>
                <Wizard.Page hide={() => isTechnical(event)}>
                    {(mutators, values) => (
                        <div className="pt-3 px-3">
                            <Field name="match_half">
                                {({ input, meta }) => (
                                    <>
                                        <GridButtonSelector
                                            valueOnly
                                            horizontal
                                            rounded
                                            size="xxs"
                                            array={MATCH_HALF_OPTIONS}
                                            {...input}
                                            onChange={value => {
                                                input.onChange(value)
                                                const minMax = mutators.setMinMaxMinutes(value)
                                                const { min } = minMax
                                                mutators.setMinute(min)
                                            }}
                                        />
                                        <FinalFormError meta={meta} />
                                    </>
                                )}
                            </Field>
                            <Field name="minute">
                                {({ input }) => (
                                    <div className="flex-center m-2 mt-4 minute-picker">
                                        <CircularSlider
                                            min={values.min_max_mins.min}
                                            max={values.min_max_mins.max}
                                            value={values.minute}
                                            onChange={value => input.onChange(value)}
                                            labelColor="#fff"
                                            label="Minute"
                                            appendToValue="'"
                                            labelBottom={true}
                                            knobColor="#444"
                                            knobSize={90}
                                            progressColorFrom={isExtraTimeThisHalf(values) ? '#f44336' : '#40991c'}
                                            progressColorTo={isExtraTimeThisHalf(values) ? '#f44336' : '#40991c'}
                                            progressSize={30}
                                            trackSize={30}
                                            trackColor="#222"
                                            useMouseAdditionalToTouch={true}
                                            key={values.min_max_mins.min}
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                    )}
                </Wizard.Page>
                <Wizard.Page hide={() => !isGoalType(event)} validate={validation.typeOfGoal}>
                    {() => (
                        <div>
                            <p className="px-5c mt-0 mb-3 text-white bright">Type of goal</p>
                            <Field name="goal_type">
                                {({ input, meta }) => (
                                    <>
                                        <GridButtonSelector
                                            horizontal
                                            size="sm"
                                            array={[
                                                { label: 'Penalty', value: 'penalty' },
                                                { label: 'Open Play', value: 'open-play' },
                                            ]}
                                            {...input}
                                            className="mx-4"
                                        />
                                        <FinalFormError meta={meta} />
                                    </>
                                )}
                            </Field>
                        </div>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.first_id(event)}>
                    {(mutators, values) => (
                        <div>
                            <p className="px-5c mt-0 mb-3">{getEventsFirstPersonLabel(event)}</p>
                            <div className="players-input">
                                {getFirstPlayerOptions(
                                    players,
                                    values.minute,
                                    values.match_half,
                                    values.event_type,
                                    matchEvents
                                ).map((option, index) => (
                                    <Field name="first_id" type="radio" key={index} value={option.value}>
                                        {({ input }) => (
                                            <AddEvenPlayer
                                                input={input}
                                                option={option}
                                                values={values}
                                                event={event}
                                            />
                                        )}
                                    </Field>
                                ))}
                            </div>
                        </div>
                    )}
                </Wizard.Page>
                <Wizard.Page hide={values => !isShowSecondPlayer(event, values)} validate={validation.second_id(event)}>
                    {(mutators, values) => (
                        <div>
                            <p className="px-5c mt-0 mb-3">{getEventsSecondPersonLabel(event)}</p>
                            <div className="players-input">
                                {getSecondPlayerOptions(
                                    players,
                                    values.minute,
                                    values.match_half,
                                    values.first_id,
                                    values.event_type,
                                    matchEvents
                                ).map((option, index) => (
                                    <Field name="second_id" type="radio" key={index} value={option.value}>
                                        {({ input }) => (
                                            <AddEvenPlayer
                                                input={input}
                                                option={option}
                                                values={values}
                                                event={event}
                                            />
                                        )}
                                    </Field>
                                ))}
                            </div>
                        </div>
                    )}
                </Wizard.Page>
                <Wizard.Page hide={values => !showExtraStep(event, values)} validate={validation.extraStep(event)}>
                    {() => (
                        <div className="px-5c">
                            <p className="mt-0 mb-3">Goal Resulting From</p>
                            <Field name="goal_scored_from">
                                {({ input, meta }) => (
                                    <>
                                        <GridButtonSelector
                                            horizontal
                                            scroll
                                            size="xs"
                                            array={[
                                                { label: 'Crossed', value: 'cross' },
                                                { label: 'Open Play', value: 'open-play' },
                                                { label: 'Set Piece', value: 'set-piece' },
                                                { label: 'Corner', value: 'corner' },
                                                { label: 'Direct Free-kick', value: 'direct-free-kick' },
                                            ]}
                                            {...input}
                                        />
                                        <FinalFormError meta={meta} />
                                    </>
                                )}
                            </Field>
                            {isGoalScored(event) && (
                                <>
                                    <p className="my-3">Goal Scored From</p>
                                    <Field name="goal_location">
                                        {({ input, meta }) => (
                                            <>
                                                <GridButtonSelector
                                                    horizontal
                                                    scroll
                                                    rounded
                                                    size="xs"
                                                    array={[
                                                        { label: 'Inside Box', value: 'inside-box' },
                                                        { label: 'Outside Box', value: 'outside-box' },
                                                    ]}
                                                    {...input}
                                                />
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                    <p className="my-3">Goal Scored With</p>
                                    <Field name="goal_scored_with">
                                        {({ input, meta }) => (
                                            <>
                                                <GridButtonSelector
                                                    horizontal
                                                    scroll
                                                    rounded
                                                    size="xs"
                                                    array={[
                                                        { label: 'Left Foot', value: 'left-foot' },
                                                        { label: 'Right Foot', value: 'right-foot' },
                                                        { label: 'Header', value: 'header' },
                                                        { label: 'Other', value: 'other' },
                                                    ]}
                                                    {...input}
                                                />
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                </>
                            )}
                        </div>
                    )}
                </Wizard.Page>
                <Wizard.Page hide={values => !showExtraStepBlunder(event, values)}>
                    {(mutators, values) => (
                        <div className="px-5c">
                            <p className="mt-0 mb-3">Mistake in Area</p>
                            <Field name="blunder_type">
                                {({ input, meta }) => (
                                    <>
                                        <GridButtonSelector
                                            horizontal
                                            scroll
                                            dark
                                            size="xs"
                                            array={[
                                                { label: 'Attacking', value: 'attacking-blunder' },
                                                { label: 'Defending', value: 'defensive-blunder' },
                                                { label: 'Goalkeeping', value: 'gk-blunder' },
                                            ]}
                                            {...input}
                                        />
                                        <FinalFormError meta={meta} />
                                    </>
                                )}
                            </Field>
                            {values?.blunder_type?.value === 'attacking-blunder' && (
                                <>
                                    <p className="my-3">Possible mistakes</p>
                                    <Field name="possible_blunder">
                                        {({ input, meta }) => (
                                            <>
                                                <GridButtonSelector
                                                    horizontal
                                                    scroll
                                                    size="xs"
                                                    array={[
                                                        { label: 'Missed Open Goal', value: 'miss-open-goal' },
                                                        { label: 'Missed PK', value: 'miss-pk' },
                                                        { label: 'Missed 1v1', value: 'miss-1v1' },
                                                        { label: 'Terrible Pass', value: 'terrible-pass' },
                                                        {
                                                            label: 'Heavy 1st touch',
                                                            value: 'heavy-first-touch',
                                                        },
                                                    ]}
                                                    {...input}
                                                />
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                </>
                            )}
                            {values?.blunder_type?.value === 'defensive-blunder' && (
                                <>
                                    <p className="my-3">Possible mistakes</p>
                                    <Field name="possible_blunder">
                                        {({ input, meta }) => (
                                            <>
                                                <GridButtonSelector
                                                    horizontal
                                                    scroll
                                                    size="xs"
                                                    array={[
                                                        { label: 'Own Goal', value: 'own-goal' },
                                                        { label: 'Missed Clearance', value: 'missed-clearance' },
                                                        {
                                                            label: 'Poor Marking leading to goal',
                                                            value: 'poor-marking',
                                                        },
                                                        { label: 'Foul Leading to Penalty', value: 'foul-penalty' },
                                                    ]}
                                                    {...input}
                                                />
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                </>
                            )}
                            {values?.blunder_type?.value === 'gk-blunder' && (
                                <>
                                    <p className="my-3">Possible mistakes</p>
                                    <Field name="possible_blunder">
                                        {({ input, meta }) => (
                                            <>
                                                <GridButtonSelector
                                                    horizontal
                                                    scroll
                                                    size="xs"
                                                    array={[
                                                        {
                                                            label: 'Dropped Catch Leading to Goal',
                                                            value: 'dropped-catch-goal',
                                                        },
                                                        {
                                                            label: 'Rushing Out and Missing Ball',
                                                            value: 'rushing-out-miss',
                                                        },
                                                        {
                                                            label: 'Passing Directly to Opponent',
                                                            value: 'passing-to-opponent',
                                                        },
                                                        {
                                                            label: 'Fumbling a Simple Save',
                                                            value: 'fumbling-save-goal',
                                                        },
                                                    ]}
                                                    {...input}
                                                />
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                </>
                            )}
                        </div>
                    )}
                </Wizard.Page>
            </Wizard>
        </BottomDrawer>
    )
}
export default EventDrawer
