import { getUserConversation, loadConversationUsers } from 'components/store'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConversationUsers, useFirestoreDocument } from 'services'
import Message from './Message'

const Messages = ({ selectedConversation }) => {
    const dispatch = useDispatch()
    const lastDivRef = useRef(null)
    const { squadId, members } = selectedConversation
    const { data: chats, loading } = useFirestoreDocument('chats', squadId.toString())
    const { id: currentUserId } = useSelector(state => state.user)
    const squad = useSelector(state => getUserConversation(state, squadId))

    const getData = async () => {
        const response = await getConversationUsers(squad?.squad_slug, members)
        const { data: responseData } = response
        if (responseData.status === 'success') {
            const { data } = responseData
            dispatch(loadConversationUsers(data))
        }
    }

    useEffect(() => {
        getData(squadId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [squadId])

    useEffect(() => {
        if (lastDivRef.current && chats?.messages?.length > 0) lastDivRef.current.scrollIntoView()
    }, [chats])

    return (
        <div className="flex-grow-1 d-flex flex-column gap-1 py-3 px-4 h-px scrollbar-hidden scrollbar-y">
            {!loading && (
                <>
                    {chats?.messages?.length === 0 && (
                        <p className="text-center text-secondary">You have joined the conversation</p>
                    )}
                    {chats?.messages?.map((message, index) => (
                        <Message key={index} message={message} currentUserId={currentUserId} />
                    ))}
                </>
            )}
            <div ref={lastDivRef} />
        </div>
    )
}

export default Messages
