import { getMinute } from 'utils'

export const MatchEventTemplate = ({ title = null, time, half, secondary = null, small = null, image }) => {
    return (
        <div className="fixture-event--container">
            <div className="fixture-event--first">
                <div className="fixture-event--image" dangerouslySetInnerHTML={{ __html: image }} />
                <div className="fixture-event--time">{getMinute(time, half)}</div>
            </div>
            <div className="fixture-event--divider">
                <div className="divider"></div>
            </div>
            <div className="fixture-event--detail">
                <div>
                    {title && <span>{title}</span>}
                    {secondary && <span>{secondary}</span>}
                </div>
                {small && <small className="block hint-text">{small}</small>}
            </div>
        </div>
    )
}
