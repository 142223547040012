const SET_SCOUTING_DATA = 'SET_SCOUTING_DATA'
const RESET_SCOUTING_DATA = 'RESET_SCOUTING_DATA'
const SET_SCOUTING_LOADING = 'SET_SCOUTING_LOADING'
const RESET_SCOUTING_LOADING = 'RESET_SCOUTING_LOADING'
const SET_SCOUTING_PAGINATION = 'SET_SCOUTING_PAGINATION'
const RESET_SCOUTING_PAGINATION = 'RESET_SCOUTING_PAGINATION'

const initialState = {
    list: [],
    isLoading: false,
    pagination: {},
}

const scoutPlayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCOUTING_DATA:
            return {
                ...state,
                list: action.payload,
            }
        case RESET_SCOUTING_DATA:
            localStorage.removeItem('isLoading')
            localStorage.removeItem('currentPage')
            localStorage.removeItem('totalPages')
            return {
                ...state,
                list: [],
            }

        case SET_SCOUTING_LOADING:
            localStorage.setItem('isLoading', true)
            return {
                ...state,
                isLoading: true,
            }
        case RESET_SCOUTING_LOADING:
            localStorage.setItem('isLoading', false)
            return {
                ...state,
                isLoading: false,
            }
        case SET_SCOUTING_PAGINATION: {
            const { current_page: currentPage, total_pages: totalPages } = action.payload
            localStorage.setItem('currentPage', currentPage)
            localStorage.setItem('totalPages', totalPages)

            return {
                ...state,
                pagination: action.payload,
            }
        }
        case RESET_SCOUTING_PAGINATION:
            localStorage.removeItem('currentPage')
            localStorage.removeItem('totalPages')
            return {
                ...state,
                pagination: {},
            }
        default:
            return state
    }
}

export default scoutPlayersReducer

export const addScoutPlayers = data => ({
    type: SET_SCOUTING_DATA,
    payload: data,
})

export const addMoreScoutPlayers = data => (dispatch, getState) => {
    const { scoutPlayers } = getState()
    const newList = [...scoutPlayers.list, ...data]
    dispatch(addScoutPlayers(newList))
}

export const resetScoutPlayers = () => ({
    type: RESET_SCOUTING_DATA,
})

export const loadingScoutPlayers = () => ({
    type: SET_SCOUTING_LOADING,
})

export const resetLoadingScoutPlayers = () => ({
    type: RESET_SCOUTING_LOADING,
})

export const setScoutPlayersPagination = data => ({
    type: SET_SCOUTING_PAGINATION,
    payload: data,
})

export const resetScoutPlayersPagination = () => ({
    type: RESET_SCOUTING_PAGINATION,
})

export const updateScoutPlayer = user => (dispatch, getState) => {
    const { list } = getState().scoutPlayers
    const newList = list.map(item => (item.id === user.id ? user : item))
    dispatch(addScoutPlayers(newList))
}

export const updateScoutPlayerInTalks = (id, inTalks) => (dispatch, getState) => {
    const { list } = getState().scoutPlayers
    const newList = list.map(item =>
        item.id === id
            ? {
                  ...item,
                  in_talks: inTalks,
              }
            : item
    )
    dispatch(addScoutPlayers(newList))
}
