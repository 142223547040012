import { ConfirmModal } from 'components/shared'
import FinalFormError from 'components/shared/FinalFormError'
import FormationPicker from 'components/shared/formationPicker'
import { closeChangeLineup, setFormationPlayers, updateFixture } from 'components/store'
import { resetLineup, setLineup, setNewFormation, setSubstitutions } from 'components/store/actions'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Form as BsForm, Button, Container, Modal } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPlayersSquad, putRequest } from 'services'
import { getStartingEleven, getSubstitutes } from 'utils'

const ChangeLineup = () => {
    const dispatch = useDispatch()
    const { squadSlug, changeLineup: showModal, data: fixture } = useSelector(state => state.fixture)
    const squad = useSelector(state => state.clubSquadDetail)
    const { players } = useSelector(store => store.formationPicker)

    const { shirts: jerseys } = useSelector(st => st.masterData)
    const [isLoading, setIsLoading] = useState(true)

    const handleClose = () => dispatch(closeChangeLineup())

    useEffect(() => {
        getPlayers()
        setLineups()

        return () => {
            dispatch(resetLineup())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setLineups = () => {
        if (!fixture) return

        const lineUpToRedux = {
            formation: fixture.formation,
            lineup: getStartingEleven(fixture.players),
            substitutions: getSubstitutes(fixture.players),
        }

        dispatch(setLineup(lineUpToRedux.lineup, false))
        dispatch(setSubstitutions(lineUpToRedux.substitutions))
        dispatch(setNewFormation({ formation: lineUpToRedux.formation, history: false }))
        setIsLoading(false)
    }

    const onSubmit = async data => {
        const values = {
            match_id: fixture.id,
            ...data,
        }
        const { data: matchResponse } = await putRequest(`/squads/update-formation/${fixture.squad.squad_slug}`, {
            values,
        })
        if (matchResponse && matchResponse.status === 'success') {
            const matchData = matchResponse.data
            dispatch(updateFixture(matchData.id, matchData))
            handleClose()
            toast.success('Lineup updated successfully')
        }
    }

    const getPlayers = async () => {
        const { data: responseData } = await getAllPlayersSquad(squadSlug)
        if (responseData.status === 'success') {
            const { players } = responseData
            dispatch(setFormationPlayers(players))
        }
    }

    if (isLoading) return null

    if (isLoading || !showModal || !fixture || !players) return null

    if (!jerseys) return null

    const { players: playerShirts, keepers: keeperShirts } = jerseys

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            centered
            className="change-lineup-modal fill-in colorized-darkest"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4 className="my-0">Change Lineup</h4>
                    <p>
                        {fixture.squad.name} vs {fixture.opponent.name} - {moment(fixture.date).format('DD MMM YYYY')}  
                    </p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={onSubmit}
                    validate={values => {
                        const errors = {}
                        if (!values['formation-picker']?.lineup) {
                            errors['formation-picker'] = 'Your line-up is required'
                        } else if (values['formation-picker']?.lineup) {
                            if (
                                values['formation-picker'].lineup.filter(
                                    e => e.player !== undefined && e.player !== null
                                ).length < squad.max_players
                            ) {
                                errors['formation-picker'] = 'You need 11 players to proceed. Subs are optional.'
                            }
                        }

                        return errors
                    }}>
                    {({ handleSubmit, submitting, valid }) => (
                        <BsForm onSubmit={handleSubmit} className="flex-col h-100">
                            <Field name="formation-picker">
                                {({ input, meta }) => (
                                    <>
                                        <FormationPicker
                                            autoAssign={false}
                                            shirts={{
                                                players: playerShirts,
                                                keepers: keeperShirts,
                                            }}
                                            shirtPlayer={fixture.shirts.player}
                                            shirtGK={fixture.shirts.gk}
                                            ground={fixture.ground}
                                            onChange={input.onChange}
                                            title={null}
                                            titleClass="text-center"
                                            meta={meta}
                                        />
                                        <FinalFormError meta={meta} />
                                    </>
                                )}
                            </Field>
                            <Container>
                                <Modal.Footer>
                                    <div className="d-flex space-between w-100 gap-2">
                                        <Button
                                            size="md"
                                            disabled={submitting}
                                            variant="transparent"
                                            className="bg-transparent"
                                            onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <ConfirmModal
                                            title="Update Lineup"
                                            body={<p>Are you sure you want to update the lineup?</p>}
                                            onConfirm={handleSubmit}
                                            disabled={submitting || !valid}>
                                            <Button
                                                type="button"
                                                disabled={submitting || !valid}
                                                variant="success"
                                                size="md">
                                                Update
                                            </Button>
                                        </ConfirmModal>
                                    </div>
                                </Modal.Footer>
                            </Container>
                        </BsForm>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default ChangeLineup
