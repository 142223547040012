import RecentForm from 'components/Dashboard/pages/Squad/CurrentSquad/columns/RecentForm'
import ReactSelectAdapter from 'components/shared/ReactSelectAdapter'
import { sortPlayers } from 'components/shared/shared2'
import store from 'components/store'
import { getGroupName, getPlayerName } from 'utils'

const total = last_five => {
    if (last_five && last_five.length > 0) {
        const total = last_five.map(i => i.rating).reduce((a, b) => a + b)
        return total / last_five.length
    }

    return null
}

const columns = (players, handleChange) => [
    {
        Header: 'Pos',
        accessor: 'position',
        sortable: true,
        width: 75,
        resizable: false,
        sortMethod: (pos1, pos2) => {
            const { positions } = store.getState().masterData
            const types = Object.keys(positions)

            return sortPlayers(types, pos1, pos2)
        },
        Cell: ({ value, original }) => (
            <span className={`block bold ${getGroupName(original.position)} text-center w-100`}>{value}</span>
        ),
    },
    {
        Header: 'Player',
        accessor: 'player',
        sortable: false,
        width: 220,
        resizable: false,
        Cell: ({ original, value: player }) => (
            <>
                <ReactSelectAdapter
                    value={
                        player
                            ? {
                                  value: `${getPlayerName(player)} (${player.pos_group})`,
                                  label: `${getPlayerName(player)} (${player.pos_group})`,
                              }
                            : null
                    }
                    // styles={{
                    //     control: base => ({
                    //         ...base,
                    //         height: 20,
                    //         minHeight: 20,
                    //     }),
                    // }}
                    placeholder={`choose ${original.position}`}
                    onChange={e => handleChange(e.value, original.position, original.node)}
                    formatOptionLabel={player => (
                        <div className="player-option">
                            <span>
                                {/* {player.inLineup && <strong>{`${player.inLineup} - `}</strong>} */}
                                {player.label}
                            </span>
                        </div>
                    )}
                    menuPosition="fixed"
                    menuPlacement="auto"
                    minMenuHeight={290}
                    options={players}
                />
            </>
        ),
    },
    {
        Header: 'Form',
        accessor: 'player.last_five',
        sortable: true,
        width: 150,
        resizable: false,
        sortMethod: (a, b) => total(b) - total(a),
        Cell: ({ original, value }) => {
            const { player } = original
            return <RecentForm lastFive={player?.last_five} />
        },
    },
    {
        Header: 'Apps',
        accessor: 'player.total_appearances',
        sortable: true,
        width: 50,
        resizable: false,
        Cell: ({ original }) => (original ? <span className="stat">{original.player?.total_appearances}</span> : '-'),
    },
    {
        Header: 'Goals',
        accessor: 'player.goals_scored',
        sortable: true,
        width: 50,
        resizable: false,
        Cell: ({ original }) => (original ? <span className="stat">{original.player?.goals_scored}</span> : '-'),
    },
    {
        Header: 'Assists',
        accessor: 'player.goals_assisted',
        sortable: true,
        width: 50,
        resizable: false,
        Cell: ({ original }) => (original ? <span className="stat">{original.player?.goals_assisted}</span> : '-'),
    },
]

export default columns
