import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { fetchTwitchStream, fetchYoutubeLiveVideo } from 'services'
import { getSquadSettings } from 'utils'

const FixtureLive = () => {
    const [videoId, setVideoId] = useState(null)
    const squad = useSelector(state => state.clubSquadDetail)
    const { streamType, twitchChannel, youtubeChannel } = getSquadSettings(squad)

    const getData = async () => {
        try {
            if (streamType === 'youtube') {
                const videoId = await fetchYoutubeLiveVideo(youtubeChannel)
                if (videoId) {
                    setVideoId(videoId)
                } else {
                    toast('Waiting for stream to start')
                }
            } 
            // else if (streamType === 'twitch') {
            //     const videoId = await fetchTwitchStream(twitchChannel)
            //     // if (videoId) {
            //     //     setVideoId(videoId)
            //     //     // setCount(-1)
            //     // } else {
            //     //     toast('Waiting for stream to start')
            //     // }
            // }
        } catch (err) {
            toast.error(err.message)
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [streamType])

    // useEffect(() => {
    //     if (count >= 0) {
    //         const timeoutId = setTimeout(() => {
    //             getData()
    //             setCount(count + 1)
    //         }, 30 * 1000)
    //         // Cleanup function to clear the timeout
    //         return () => clearTimeout(timeoutId)
    //     }
    // }, [count])

    return (
        <div className="h-100">
            {streamType === 'youtube' && (
                <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
            {streamType === 'twitch' && (
                <iframe
                    src={`https://player.twitch.tv/?channel=${twitchChannel}&parent=scouty.io`}
                    title="Twitch"
                    height="315"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    allowFullScreen="true"
                />
            )}
        </div>
    )
}
export default FixtureLive
