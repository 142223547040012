import classNames from 'classnames'
import { addSubstitution, closePlayersPositionModal, resetPlayerPosition, selectPlayer } from 'components/store'
import { NUMBER_OF_SUBSTITUTES } from 'constants'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import { Badge } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayerName, getPosition, getPositionPlayer, isSubPosition } from 'utils'

const DropdownPosition = () => {
    const dispatch = useDispatch()
    const {
        selectedPlayerPosition: node,
        lineup,
        substitutions,
        playersPositionModal: open,
    } = useSelector(store => store.formationPicker)
    const position = getPosition(node, lineup)
    const positionPlayer = getPositionPlayer(node, lineup, substitutions)

    const onClose = () => dispatch(closePlayersPositionModal())

    // const onPlayerDeselect = () => {
    //     dispatch(resetPlayerPosition())
    //     return onClose()
    // }
    const onAddSubstitute = () => {
        if (!position) return
        if (isSubPosition(position)) return
        const subPosition = `SUB-${substitutions.length + 1}`
        const player = isObject(position) ? position : lineup[node].player
        dispatch(addSubstitution({ position: subPosition, player }))
        dispatch(resetPlayerPosition())
        return onClose()
    }

    const onPlayerReserve = () => {
        if (!position) return
        if (isObject(node)) return
        const payload = {
            node,
            position,
        }
        if (isSubPosition(position)) dispatch(addSubstitution(payload))
        if (isNumber(node)) dispatch(selectPlayer(payload))
        dispatch(resetPlayerPosition())
        return onClose()
    }

    const getShowAddSubstitute = position => {
        if (substitutions.length >= 10) return false
        if (!position) return false
        if (isObject(position)) return true
        if (isSubPosition(position)) return false
        const hasPlayer = lineup[node].player
        if (hasPlayer) return true
        return false
    }

    const getShowMakeReserve = position => {
        if (!position) return false
        if (isObject(position)) return false
        if (isSubPosition(position)) {
            const hasPlayer = substitutions.find(s => s.position === position)?.player
            if (hasPlayer) return true
            return false
        }
        const hasPlayer = lineup[node].player
        if (hasPlayer) return true
        return false
    }

    if (!open) return null

    return (
        <div
            className={classNames('dropdown-wrapper', {
                show: open,
            })}>
            {open && (
                <div className="dropdown dropdown-zone">
                    <p className="dropdown-title">
                        Switching {positionPlayer ? getPlayerName(positionPlayer) : position}
                    </p>
                    <div className="badge-group">
                        {/* <Badge onClick={onClose}>Swap With Player</Badge> */}
                        {getShowAddSubstitute(position) && (
                            <Badge
                                onClick={onAddSubstitute}
                                className={classNames({
                                    'cursor-not-allowed hint-text': substitutions.length === NUMBER_OF_SUBSTITUTES,
                                })}>
                                Move to Subs
                                {substitutions.length === NUMBER_OF_SUBSTITUTES ? ' (FULL)' : ''}
                            </Badge>
                        )}
                        {getShowMakeReserve(position) && <Badge onClick={onPlayerReserve}>Move to Reserves</Badge>}
                        <Badge bg="danger" onClick={onClose}>
                            Close
                        </Badge>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DropdownPosition
