import { Link, useSearchParams } from 'react-router-dom'
import { getAllSearchParams, objToUrlParamString } from 'utils'

export const LinkParam = ({ to, params, children, ...rest }) => {
    const [searchParams] = useSearchParams()
    const queryParams = getAllSearchParams(searchParams)
    const newParams = { ...queryParams, ...params }
    const search = objToUrlParamString(newParams)

    const linkTo = to ? { pathname: to, search } : { search }

    return (
        <Link to={linkTo} {...rest}>
            {children}
        </Link>
    )
}
