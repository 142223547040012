import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

const Dropdown = ({ children, options, className, align = 'auto', shadow = false }) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleDropdown = () => setIsOpen(!isOpen)
    const dropdownRef = useRef(null)

    const dropdownClasses = `dropdown-container relative ${className}`
    const dropdownOptionsContainerClasses = classNames(
        'dropdown-options-container position-absolute rounded overflow-hidden z-50',
        shadow && 'shadow',
        align === 'auto' && 'top-100 start-0 mt-2',
        align === 'top' && 'bottom-100 start-50 translate-middle-x mb-2',
        align === 'right' && 'top-50 start-100 translate-middle-y ms-2',
        align === 'bottom' && 'top-100 start-50 translate-middle-x mt-2',
        align === 'left' && 'top-50 end-100 translate-middle-y me-2',
        align === 'top-start' && 'bottom-100 start-0 mb-2',
        align === 'top-end' && 'bottom-100 end-0 mb-2',
        align === 'right-start' && 'top-0 start-100 ms-2',
        align === 'right-end' && 'bottom-0 start-100 ms-2',
        align === 'bottom-start' && 'top-100 start-0 mt-2',
        align === 'bottom-end' && 'top-100 end-0 mt-2',
        align === 'left-start' && 'top-0 end-100 me-2',
        align === 'left-end' && 'bottom-0 end-100 me-2'
    )

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    })

    return (
        <div className={dropdownClasses} ref={dropdownRef}>
            <div
                className="dropdown-toggler select-none"
                role="button"
                onClick={toggleDropdown}
                tabIndex={0}
                onKeyDown={e => e.key === 'Enter' && toggleDropdown}>
                {children}
            </div>
            {isOpen && <div className={dropdownOptionsContainerClasses}>{options}</div>}
        </div>
    )
}
export default Dropdown
