const PLAYER_MODAL_SHOW = 'PLAYER_MODAL_SHOW'
const PLAYER_MODAL_HIDE = 'PLAYER_MODAL_HIDE'
const PLAYER_MODAL_SET_USER = 'PLAYER_MODAL_SET_USER'

const initialState = {
    show: false,
    user: {},
    isLoading: true,
}

const squadPlayerModal = (state = initialState, action) => {
    switch (action.type) {
        case PLAYER_MODAL_SHOW:
            console.log(action)
            return {
                show: true,
                user: action.payload,
            }
        case PLAYER_MODAL_HIDE:
            return initialState
        case PLAYER_MODAL_SET_USER:
            return {
                user: action.payload,
                isLoading: false,
            }
        default:
            return state
    }
}

export default squadPlayerModal

export const showPlayerModal = data => ({
    type: PLAYER_MODAL_SHOW,
    payload: data
})

export const hidePlayerModal = () => ({
    type: PLAYER_MODAL_HIDE
})

export const setPlayerModalUser = data => ({
    type: PLAYER_MODAL_SET_USER,
    payload: data,
})