import Tooltip from '@mui/material/Tooltip'
import { ConfirmModal } from 'components/shared'
import { matchHasStarted } from 'components/shared/shared2'
import {
    getMatchEvents,
    getNonTacticalEvents,
    getTacticalEvents,
    handelGoalRemove,
    setMatchEventsData,
} from 'components/store'
import { setLoader } from 'components/store/actions'
import { useMemo, useState } from 'react'
import { Button, Nav, Tab } from 'react-bootstrap'
import { Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSquadMatchEvent } from 'services'
import { getHalf, groupByKey, sortMatchEvents } from 'utils'
import { MatchEvent } from '../Tabs/MatchEvents/MatchEvent'

const MatchEventWithDelete = ({ event, deleteable = false }) => {
    const dispatch = useDispatch()
    const [deleteLoading, setDeleteLoading] = useState(null)
    const { data: fixture } = useSelector(state => state.fixture)

    const handelEventDelete = async (event_id, event_type) => {
        // dispatch(setLoader(true))
        setDeleteLoading(true)
        const match_id = fixture.id
        const squad_id = fixture.squad.id
        const values = { event_id, match_id, squad_id }
        const { data: responseData } = await deleteSquadMatchEvent(fixture.squad.squad_slug, values)
        if (responseData.status === 'success') {
            const {
                data: { match_events },
            } = responseData
            
            dispatch(setMatchEventsData(match_events))
            dispatch(handelGoalRemove(match_id, event_type))
        }
        setDeleteLoading(null)
        // dispatch(setLoader(false))
    }

    return (
        <div className="fixture--events-modal-single">
            <MatchEvent event={event} />
            {deleteable && (
                <Tooltip title={<div className="fs-20">Delete this event</div>}>
                    <ConfirmModal
                        title="Delete Event"
                        isLoading={deleteLoading}
                        isLoadingConfirmText={'Deleting'}
                        body={<p>Are you sure you want to delete this event?</p>}
                        confirmVariant="danger"
                        confirmText="Yes delete"
                        onConfirm={() => handelEventDelete(event.id, event.event_type)}
                    >
                        <Button style={{ marginLeft: 'auto' }} className="btn-clear" type="button">
                            <Trash2 size={20} strokeWidth={1.5} stroke="red" />
                        </Button>
                    </ConfirmModal>
                </Tooltip>
            )}
        </div>
    )
}

const MatchEventsOfHalf = ({ halfEvents, deleteable = false }) => {
    return (
        <>
            <div className="bg-main-dark py-1 rounded-pill mx-2 my-3">
                <p className="text-center m-0 fw-bold">{getHalf(halfEvents[0].half)}</p>
            </div>
            {halfEvents.map(event => (
                <MatchEventWithDelete event={event} deleteable={deleteable} key={event.id} />
            ))}
        </>
    )
}

const MatchEventsList = ({ events, deleteable = false }) => {
    const groupedByHalf = useMemo(() => Object.values(groupByKey(events, 'half')), [events])

    return groupedByHalf.map((halfEvents, index) => (
        <MatchEventsOfHalf halfEvents={halfEvents} deleteable={deleteable} key={index} />
    ))
}

export const MatchEvents = ({ tactical = false, deleteable = false }) => {
    const { data: fixture } = useSelector(state => state.fixture)
    const keyMoments = useSelector(getNonTacticalEvents)
    const sortedKeyMoments = sortMatchEvents(keyMoments)
    const basicEvents = useSelector(getTacticalEvents)
    const sortedBasicEvents = sortMatchEvents(basicEvents)

    if (tactical)
        return (
            <div className="fixture--events-modal">
                <div className="fixture--events">
                    <Tab.Container defaultActiveKey="keyMoments">
                        <Nav variant="border" className="events-nav justify-content-center">
                            <Nav.Item>
                                <Nav.Link eventKey="keyMoments">Key Moments</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="basicEvents">Basic Events</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="events-tab-content">
                            <Tab.Pane eventKey="keyMoments">
                                {sortedKeyMoments?.length ? (
                                    <MatchEventsList events={sortedKeyMoments} deleteable={deleteable} />
                                ) : (
                                    <span className="no-results my-auto py-10">No events recorded</span>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="basicEvents">
                                {sortedBasicEvents?.length ? (
                                    sortedBasicEvents.map(event => (
                                        <MatchEventWithDelete
                                            event={event}
                                            events={basicEvents}
                                            deleteable={deleteable}
                                            key={event.id}
                                        />
                                    ))
                                ) : (
                                    <span className="no-results">No events recorded yet</span>
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        )

    return (
        <>
            <h5 className="text-center mt-3 mb-0">Match Events</h5>
            <div className="fixture--events-modal">
                <div className="fixture--events">
                    {!matchHasStarted(fixture) ? (
                        <p className="px-3 hint-text text-center">Match not started yet</p>
                    ) : sortedKeyMoments?.length ? (
                        <MatchEventsList events={sortedKeyMoments} deleteable={deleteable} />
                    ) : (
                        <span className="no-results my-3">No events recorded yet</span>
                    )}
                </div>
            </div>
        </>
    )
}
