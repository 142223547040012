import { 
    SET_MATCH_DETAIL_DATA
} from '../actions/actionTypes'

const initialState = {}

const matchDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCH_DETAIL_DATA:
            return { 
                // ...state, 
                ...action.payload 
            }
        default:
            return state
    }
}
export default matchDetailReducer