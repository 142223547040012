import { combineReducers } from 'redux'
import adminSquad from './adminSquadReducer'
import calender from './calenderReducer'
import chatPin from './chatPinReducer'
import chat from './chatReducer'
import clubDetail from './clubDetailReducer'
import clubSquadDetail from './clubSquadDetailReducer'
import competitionType from './competitionTypeReducer'
import conversation from './conversationReducer'
import eventDetail from './eventDetailReducer'
import fixture from './fixtureReducer'
import fixtures from './fixturesReducer'
import formationPicker from './formationPickerReducer'
import groupDetail from './groupDetailReducer'
import home from './homeReducer'
import loader from './loaderReducer'
import locale from './localeReducer'
import loginModal from './loginModalReducer'
import masterData from './masterDataReducer'
import matchDetail from './matchDetailReducer'
import matchEvents from './matchEventsReducer'
import matchGround from './matchGroundReducer'
import matchType from './matchTypeReducer'
import multiStepForm from './multiStepFormReducer'
import notificationBell from './notificationBellReducer'
import notificationData from './notificationDataReducer'
import popup from './popupReducer'
import ratePlayers from './ratePlayersReducer'
import scoutPlayers from './scoutPlayersReducer'
import searchClubs from './searchClubsReducer'
import searchGames from './searchGamesReducer'
import searchGroups from './searchGroupsReducer'
import searchPlayers from './searchPlayersReducer'
import sidebarPin from './sidebarPinReducer'
import sidebar from './sidebarReducer'
import squadPlayerModal from './squadPlayerModalReducer'
import staff from './staffReducer'
import times from './timesReducer'
import training from './trainingReducer'
import transfer from './transferReducer'
import userProfile from './userProfileReducer'
import user from './userReducer'
import verifyEmail from './verifyEmailReducer'
import weights from './weightReducer'

export default combineReducers({
    loader,
    user,
    masterData,
    home,
    locale,
    staff,
    sidebar,
    sidebarPin,
    chatPin,
    clubDetail,
    groupDetail,
    clubSquadDetail,
    conversation,
    matchDetail,
    scoutPlayers,
    searchGroups,
    searchGames,
    searchClubs,
    searchPlayers,
    eventDetail,
    times,
    competitionType,
    matchType,
    matchGround,
    matchEvents,
    notificationBell,
    notificationData,
    loginModal,
    weights,
    formationPicker,
    fixtures,
    fixture,
    transfer,
    calender,
    popup,
    training,
    verifyEmail,
    userProfile,
    ratePlayers,
    multiStepForm,
    chat,
    adminSquad,
    squadPlayerModal,
})

export * from './adminSquadReducer'
export * from './calenderReducer'
export * from './chatReducer'
export * from './clubSquadDetailReducer'
export * from './conversationReducer'
export * from './fixtureReducer'
export * from './fixturesReducer'
export * from './formationPickerReducer'
export * from './homeReducer'
export * from './matchEventsReducer'
export * from './multiStepFormReducer'
export * from './notificationDataReducer'
export * from './popupReducer'
export * from './ratePlayersReducer'
export * from './scoutPlayersReducer'
export * from './squadPlayerModalReducer'
export * from './trainingReducer'
export * from './transferReducer'
export * from './userProfileReducer'
export * from './userReducer'
export * from './verifyEmailReducer'
