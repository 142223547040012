import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Loading = () => {
    const loader = useSelector(state => state.loader)

    if (!loader) return null

    return (
        <Wrapper>
            <div className="d-flex justify-content-center flex-column full-height loader-scouty">
                <div className="loader-container">
                    <div className="loader-ball"> </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Loading

const Wrapper = styled.div`
    backdrop-filter: blur(3px);
    background: #000000bf;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100111111111;
    width: 100%;
    height: 100%;
`
