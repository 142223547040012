import { addMatchEvent, handelGoal } from 'components/store'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import echoInstance from './echo'

const useFixturePusher = (squadSlug, fixtureDate) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    if (!user || !window.Echo) return
    const channelName = `fixture.${squadSlug}.date.${fixtureDate}`

    useEffect(() => {
        echoInstance
        .channel(channelName)
        .listen('SquadMatchEventsAdd', e => {
            const { fixture_id, match_event } = e
            const { event_type } = match_event
            dispatch(addMatchEvent(match_event))
            dispatch(handelGoal(fixture_id, event_type))
            console.log('affff', e)

        }).listen('SquadMatchEventsDelete', e => {
            const { fixture_id, match_event } = e

            console.log(e)
            // const { event_type } = match_event
            // dispatch(addMatchEvent(match_event))
            // dispatch(handelGoal(fixture_id, event_type))
        })

        return () => echoInstance.leave(channelName)
    }, [])
}

export default useFixturePusher
