import { getPlayerName } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildTitle = event => {
    const { player } = event
    return (
        <>
            Red Card <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}

export const MatchEventRedCard = ({ event }) => {
    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            title={buildTitle(event)}
            // secondary={buildTitle(event)}
            // small={"Red Card"}
            image='<svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(216 48 74)"><title>Yellow card</title><g id="Symbols" stroke="none" stroke-width="1" fill="var(--score-rating-s65)" fill-rule="evenodd" style="min-width: 24px; display: inline-block;"><g id="ic_card_yellow"><rect id="Yellow-Card" x="5" y="2" width="14" height="20" rx="1"></rect></g></g></svg>'
        />
    )
}
