import 'bootstrap/dist/css/bootstrap.min.css'
import { AdminRoutes, LandingRoutes, PrivateRoutes, PublicRoutes } from 'components/routes'
import config from 'config'
import { useEffect } from 'react'
import ReactGa from 'react-ga'
import { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import NotificationPush from 'utils/NotificationPush'
import './../scss/themes/light/light.scss'
import Loading from './shared/Loading'
import { checkLoginStatus } from './store/actions'

const App = () => {
    const dispatch = useDispatch()
    ReactGa.initialize(config.GA_TRACKING_ID)

    useEffect(() => {
        const checkLogin = async () => dispatch(checkLoginStatus())
        checkLogin()
    }, [])

    return (
        <BrowserRouter>
            <NotificationPush />
            <Loading />
            <AdminRoutes />
            <PublicRoutes />
            <PrivateRoutes />
            <LandingRoutes />
            <Toaster
                toastOptions={{
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                }}
            />
        </BrowserRouter>
    )
}

export default App
