import { getSubstitutionEvents } from 'components/store'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getSubstitutes } from 'utils'
import BenchPlayer from './BenchPlayer'

const BenchSubs = () => {
    const matchEvents = useSelector(getSubstitutionEvents)
    const { players } = useSelector(state => state.fixture.data)
    const substitutes = getSubstitutes(players)

    const sortedSubs = useMemo(
        () =>
            substitutes.sort((playerA, playerB) => {
                const isPlayerASubbed = matchEvents?.find(event => event.player2.id === playerA.player.id)
                const isPlayerBSubbed = matchEvents?.find(event => event.player2.id === playerB.player.id)
                if (isPlayerASubbed && isPlayerBSubbed) {
                    return isPlayerASubbed.minute - isPlayerBSubbed.minute
                }
                if (isPlayerASubbed) {
                    return -1
                }
                if (isPlayerBSubbed) {
                    return 1
                }
                return 0
            }),
        [substitutes]
    )

    return sortedSubs.map(({ player }) => <BenchPlayer player={player} showRating key={player.id} />)
}
export default BenchSubs
