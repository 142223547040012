import { debounce, isBoolean, values } from 'lodash'
import { validateEmail } from 'utils'
import { deleteRequest, getRequest, postRequest, putRequest } from './apiRequest'

export const getSquad = squadSlug => {
    const url = `/squads/get/${squadSlug}`
    return getRequest(url)
}

export const saveSquad = (squadSlug, data) => {
    const url = `/squads/save-squad/${squadSlug}`
    return postRequest(url, { values: data })
}

export const saveSquadBrand = (squadSlug, data) => {
    const url = `/squads/save-squad-brand/${squadSlug}`
    return postRequest(url, { values: data })
}

export const getSquadFixtures = (squadSlug, matchType, page = 1) => {
    const url = `/squads/get-fixtures/${squadSlug}`
    return getRequest(url, { dir: matchType, page })
}

export const getFixture = (squadSlug, fixtureDate) => {
    const url = `/squads/get-fixture/${squadSlug}/${fixtureDate}`
    return getRequest(url)
}

// Cancel Match
export const cancelMatch = (squadSlug, data) => {
    const url = `/squads/cancel-match/${squadSlug}`
    return postRequest(url, { values: data })
}

// Archive Match
export const archiveMatch = (squadSlug, data) => {
    const url = `/squads/archive-match/${squadSlug}`
    return postRequest(url, { values: data })
}

// Save squad match event
export const saveSquadMatchEvent = (squadSlug, data) => {
    const url = `/squads/save-squad-match-event/${squadSlug}`
    return putRequest(url, { values: data })
}

// delete squad match event
export const deleteSquadMatchEvent = (squadSlug, data) => {
    const url = `/squads/delete-squad-match-event/${squadSlug}`
    return deleteRequest(url, { values: data })
}

// Send Email Invitation
export const sendEmailInvitation = (squadSlug, data) => {
    const url = `/email-invitations/squads/${squadSlug}/send`
    return postRequest(url, data)
}

// stuff
export const getSquadStuff = squadSlug => {
    const url = `/squads/all-squad-staff/${squadSlug}`
    return getRequest(url)
}

export const requestToJoin = (squadSlug, data) => {
    const url = `/squads/join-squad/${squadSlug}`
    return postRequest(url, data)
}

export const terminate = (squadSlug, data) => {
    const url = `/squads/terminate-contract/${squadSlug}`
    return deleteRequest(url, data)
}

export const setCaptain = (squadSlug, playerId) => {
    const url = `/squads/set-captain/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const setNickname = (squadSlug, playerId, nickname) => {
    const url = `/squads/set-nickname/${squadSlug}`
    return postRequest(url, {
        values: {
            player_id: playerId,
            nickname: nickname,
        },
    })
}

export const setPlayerPositionGroup = async (squadSlug, playerId, position) => {
    const url = `/squads/set-player-position-group/${squadSlug}`
    return await putRequest(url, {
        values: {
            user_id: playerId,
            pos: position,
        },
    })
}

export const requestToLeave = (squadSlug, playerId) => {
    const url = `/squads/leave-squad/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const cancelRequestToLeave = (squadSlug, playerId) => {
    const url = `/squads/cancel-leave-squad/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const rejectRequestToLeave = (squadSlug, playerId) => {
    const url = `/squads/reject-leave-squad/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const acceptRequestToLeave = (squadSlug, playerId) => {
    const url = `/squads/accept-leave-squad/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const managerRejectsApproach = (squadSlug, playerId) => {
    const url = `/squads/reject-approach/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const managerAcceptsApproach = (squadSlug, playerId) => {
    const url = `/squads/accept-approach/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const cancelRequestToJoin = (squadSlug, playerId) => {
    const url = `/squads/cancel-request-join/${squadSlug}`
    return postRequest(url, {
        values: { player_id: playerId },
    })
}

export const saveMatchRating = (squadSlug, ratings, matchId, motm) => {
    const url = `/squads/save-match-rating/${squadSlug}`
    return postRequest(url, {
        values: { ratings, matchId, motm },
    })
}

export const completeOnboarding = (squadSlug, emails, bio, shirts) => {
    const url = `/squads/complete-onboarding/${squadSlug}`
    return postRequest(url, {
        values: { emails, bio, shirts },
    })
}

export const uploadSquadLogo = (squadSlug, file) => {
    const url = `/squads/upload-squad-logo/${squadSlug}`
    return postRequest(
        url,
        {
            base_image: file,
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

// /update-match-location
export const updateMatchLocation = (
    squadSlug,
    matchId,
    matchDate,
    startTime,
    arriveBefore,
    address,
    city,
    country,
    region,
    lat,
    lng,
    timezone
) => {
    const url = `/squads/update-match-location/${squadSlug}`
    return putRequest(url, {
        values: {
            match_id: matchId,
            match_date: matchDate,
            start_time: startTime,
            arrive_before: arriveBefore,
            address,
            city,
            country,
            region,
            lat,
            lng,
            location_timezone: timezone,
        },
    })
}

export const rsvpMatch = (squadSlug, player_id, decision, match_id) => {
    const url = `/squads/set-rsvp-match/${squadSlug}`
    const values = {
        player_id,
        decision,
        match_id,
    }
    return postRequest(url, { values })
}

export const rsvpTraining = (squadSlug, player_id, decision, training_session_id, date) => {
    const url = `/squads/set-rsvp-training/${squadSlug}`
    // const sessionWeek = session_week.split()
    const values = {
        player_id,
        decision,
        training_session_id,
        date,
    }
    return postRequest(url, { values })
}

export const validateOverlapDates = debounce(
    async (match_date, squad, matchId = null) => {
        let error = null
        if (!match_date) {
            error = 'Pick a date'
        } else {
            const response = await checkOverLapDates(squad, match_date, matchId).then(e => e)
            if (response.status === 'exists') {
                error = response.message
            }
        }

        return error
    },
    350,
    { leading: false, trailing: true }
)

async function checkOverLapDates(squadDetail, date, match_id = null) {
    const data = match_id ? { date, match_id } : { date }
    const result = postRequest(`squads/validate-match/${squadDetail.squad_slug}`, data).then(e => e.data)

    return result
}

export const validateUserInvitedStaff = debounce(
    async (email, squad) => {
        let error = null
        if (!email) {
            error = 'Email is required'
        } else {
            if (!validateEmail(email)) {
                error = 'Invalid email address'
            } else {
                const response = await checkEmail(email, squad).then(e => e)
                if (response.status === 'exists') {
                    error = response.message
                }
            }
        }
        return error
    },
    350,
    { leading: true, trailing: true }
)

async function checkEmail(email, squadDetail) {
    const result = postRequest(`squads/validate-staff/${squadDetail.squad_slug}`, { email }).then(e => e.data)
    return result
}

// Get all squad transfers
export const getSquadTransfers = (squadSlug, params) => {
    const url = `/squads/get-all-squad-transfers/${squadSlug}`
    return getRequest(url, params)
}

// Get all squad transfers
export const getAllPlayersSquad = squadSlug => {
    const url = `/squads/get-all-players-squad/${squadSlug}`
    return getRequest(url)
}

// Get Squad Analytics
export const getSquadAnalytics = squadSlug => {
    const url = `/squads/get-analytics/${squadSlug}`
    return getRequest(url)
}

// Get Squad Analytics
export const sentSquadAnnoucement = (squadSlug, data) => {
    const url = `/squads/send-announcement/${squadSlug}`
    return postRequest(url, {
        values: {
            ...data,
        },
    })
}

// Set Match Clock
export const setMatchClock = (squadSlug, matchId, start, half, timestamp) => {
    if (half < 1 && 4 < half) return
    if (!isBoolean(start)) return
    const matchHalf = half === 1 ? '1st' : half === 2 ? '2nd' : half === 3 ? '1st-et' : '2nd-et'
    const url = `/squads/match-clock/${squadSlug}`
    return postRequest(url, {
        values: {
            match_id: matchId,
            start_stop: start ? 'start' : 'stop',
            half: matchHalf,
            timestamp,
        },
    })
}

// End Match
export const endMatch = (squadSlug, matchId) => {
    const url = `/squads/squad-end-match/${squadSlug}`
    return putRequest(url, {
        values: {
            match_id: matchId,
        },
    })
}
// /squads/save-squad-settings/{squad_slug}
export const saveSquadSettings = (squadSlug, data) => {
    const url = `/squads/save-squad-settings/${squadSlug}`
    return postRequest(url, { values: data })
}
