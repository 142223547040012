import { 
    SET_EVENT_DETAIL_DATA,
    SET_EVENT_ONLY_DETAIL_DATA
} from '../actions/actionTypes'
const initialState = {}
const eventDetail = (state = initialState, action) => {
    switch (action.type) {
        case SET_EVENT_DETAIL_DATA:
            return { ...action.payload }
        case SET_EVENT_ONLY_DETAIL_DATA:
            return { 
                ...state,
                event: action.payload
            }
        default:
            return state
    }
}
export default eventDetail