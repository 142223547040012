import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA'
const RESET_CALENDAR_DATA = 'RESET_CALENDAR_DATA'
const SET_CALENDAR_LOADING = 'SET_CALENDAR_LOADING'
const SET_SELECTED_DAY = 'SET_SELECTED_DAY'
const RESET_SELECTED_DAY = 'RESET_SELECTED_DAY'
const SET_SELECTED_DAY_EVENTS = 'SET_SELECTED_DAY_EVENTS'
const RESET_SELECTED_DAY_EVENTS = 'RESET_SELECTED_DAY_EVENTS'
const UPDATE_SELECTED_DAY_EVENTS = 'UPDATE_SELECTED_DAY_EVENTS'
const DELETE_SELECTED_DAY_EVENT = 'DELETE_SELECTED_DAY_EVENT'
const CALENDAR_OPENED = 'CALENDAR_OPENED'
const CALENDAR_CLOSED = 'CALENDAR_CLOSED'
const CALENDAR_TOGGLED = 'CALENDAR_TOGGLED'

const initialState = {
    show: false,
    data: [],
    isLoading: false,
    selectedDay: null,
    selectedDayEvents: null,
}

const calenderReducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case SET_CALENDAR_DATA:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case RESET_CALENDAR_DATA:
            return cloneDeep(initialState)
        case CALENDAR_OPENED:
            return {
                ...state,
                show: true,
            }
        case CALENDAR_CLOSED:
            return {
                ...state,
                show: false,
            }
        case CALENDAR_TOGGLED:
            return {
                ...state,
                show: !state.show,
            }
        case SET_CALENDAR_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case SET_SELECTED_DAY:
            return {
                ...state,
                selectedDay: action.payload,
            }
        case RESET_SELECTED_DAY:
            return {
                ...state,
                selectedDay: null,
            }
        case SET_SELECTED_DAY_EVENTS:
            return {
                ...state,
                selectedDayEvents: action.payload,
            }
        case RESET_SELECTED_DAY_EVENTS:
            return {
                ...state,
                selectedDayEvents: null,
            }
        case UPDATE_SELECTED_DAY_EVENTS: {
            const { selectedDayEvents } = state
            if (!selectedDayEvents) return state
            const updatedEvents = selectedDayEvents.map(event => {
                if (
                    event.type === action.payload.type &&
                    event.date === action.payload.date &&
                    (event.id || event.parent_id) === action.payload.id
                ) {
                    return {
                        ...event,
                        ...action.payload.data,
                    }
                }
                return event
            })
            return {
                ...state,
                selectedDayEvents: updatedEvents,
            }
        }
        case DELETE_SELECTED_DAY_EVENT: {
            const { selectedDayEvents } = state
            if (!selectedDayEvents) return state
            const updatedEvents = selectedDayEvents.filter(event => {
                if (
                    event.type === action.payload.type &&
                    action.payload.deletion_type === 'all_sessions' &&
                    event.parent_id === action.payload.parent_id
                ) {
                    return false
                }
                if (
                    event.type === action.payload.type &&
                    action.payload.deletion_type === 'this_and_future' &&
                    event.parent_id === action.payload.parent_id
                ) {
                    return moment(event.date).isBefore(action.payload.date)
                }
                if (
                    event.type === action.payload.type &&
                    event.date === action.payload.date &&
                    (event.id || event.parent_id) === action.payload.id
                ) {
                    return false
                }
                return true
            })
            return {
                ...state,
                selectedDayEvents: updatedEvents,
            }
        }
        default:
            return state
    }
}
export default calenderReducer

// Action Creators
export const loadCalendar = data => ({
    type: SET_CALENDAR_DATA,
    payload: data,
})

export const resetCalendar = () => ({
    type: RESET_CALENDAR_DATA,
})

export const setCalendarLoading = () => ({
    type: SET_CALENDAR_LOADING,
})

export const setSelectedDay = data => ({
    type: SET_SELECTED_DAY,
    payload: data,
})

export const resetSelectedDay = () => ({
    type: RESET_SELECTED_DAY,
})

export const setSelectedDayEvents = data => ({
    type: SET_SELECTED_DAY_EVENTS,
    payload: data,
})

export const resetSelectedDayEvents = () => ({
    type: RESET_SELECTED_DAY_EVENTS,
})

export const updateSelectedDayEvents = event => ({
    type: UPDATE_SELECTED_DAY_EVENTS,
    payload: event,
})

export const deleteSelectedDayEvent = event => ({
    type: DELETE_SELECTED_DAY_EVENT,
    payload: event,
})

export const openCalendar = () => ({
    type: CALENDAR_OPENED,
})

export const closeCalendar = () => ({
    type: CALENDAR_CLOSED,
})

export const toggleCalendar = () => ({
    type: CALENDAR_TOGGLED,
})
