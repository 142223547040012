import classNames from 'classnames'
import Avatar from 'components/shared/Avatar'
import { LoadingSquadCards } from 'components/shared/Skeleton'
import { inbox, pushToRight, stadium } from 'components/shared/svg/svgs'
import { toggleCalendar } from 'components/store'
import { pinSidebar, setSidebar, toggleChat } from 'components/store/actions'
import { useWindowSize } from 'hooks'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Calendar, MessageCircle, PlusCircle, Shield, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { toggleSidebar } from 'utils'
import UserDropdown from './UserDropdown'

const Sidebar = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { width } = useWindowSize()

    const showSidebar = useSelector(state => state.sidebar)
    const sideBarPinned = useSelector(state => state.sidebarPin)
    const chatPinned = useSelector(state => state.chatPin)
    const user = useSelector(state => state.user)
    const isLoading = isEmpty(user)
    const iconSize = 20
    const strokeWidth = 2

    const [isPinned, setIsPinned] = useState(sideBarPinned)

    const clickHandler = () => {
        if (width <= 768) {
            const bodyClassList = document.body.classList
            bodyClassList.remove('sidebar-open')
            dispatch(setSidebar(false))
        }
    }

    const runPinSidebar = () => {
        const pinState = isPinned ? false : true
        setIsPinned(pinState)
        dispatch(pinSidebar(pinState))
    }

    const onChatClick = () => dispatch(toggleChat())

    const handleCalendarClick = () => dispatch(toggleCalendar())

    useEffect(() => {
        document.body.classList.add('menu-pin')
    }, [])

    useEffect(() => {
        if (sideBarPinned) {
            document.body.classList.add('menu-pin')
        } else {
            document.body.classList.remove('menu-pin')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPinned])

    useEffect(() => {
        if (chatPinned) {
            document.body.classList.add('no-overflow')
        } else {
            document.body.classList.remove('no-overflow')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatPinned])

    // useEffect(() => {
    //     console.log('shoon', sideBarPinned)
    // }, [sideBarPinned])

    const menu = [
        {
            title: 'Home',
            url: '/dashboard',
            // detailed: () => `${locale?.cityName}, ${locale?.countryCode}`,
            // icon: () => <Home size={iconSize} strokeWidth={strokeWidth} />,
            icon: () => <div className="custom-con" dangerouslySetInnerHTML={{ __html: stadium }} />,
        },
        {
            title: 'Inbox',
            url: '/dashboard/inbox',
            unread: !isLoading && user.total_unread > 0 ? (user.total_unread > 99 ? '99+' : user.total_unread) : null,
            icon: () => <div className="custom-con md" dangerouslySetInnerHTML={{ __html: inbox }} />,
            // icon: () => <Inbox size={iconSize} strokeWidth={strokeWidth} />,
        },
        // {
        //     title: 'Search',
        //     url: '/dashboard/search',
        //     icon: () => <Search size={iconSize} strokeWidth={strokeWidth} />,
        // },
        // {
        //     title: 'People',
        //     url: '/dashboard/search/people',
        //     icon: () => <div className="custom-con" dangerouslySetInnerHTML={{ __html: player }} />,
        // },
        {
            title: 'Squads',
            url: '/dashboard/search/squads',
            icon: () => <Shield size={iconSize} strokeWidth={strokeWidth} />,
        },
        {
            title: 'Calendar',
            onClick: handleCalendarClick,
            icon: () => <Calendar size={iconSize} strokeWidth={strokeWidth} />,
        },
        {
            title: 'Chat',
            onClick: onChatClick,
            icon: () => <MessageCircle size={iconSize} strokeWidth={strokeWidth} />,
        },
        // {
        //     title: "Clubs",
        //     url: "/search/clubs",
        //     icon: () => <IconWrap
        //             dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        //             <g>
        //                 <g>
        //                     <path d="M458.756,221.964l-22.049-77.644c-1.132-3.985-1.706-8.133-1.706-12.326c0-12.904,5.556-25.207,15.244-33.755    l11.527-10.173l-44.62-48.125l-10.655,7.197c-13.333,9.008-28.641,13.575-45.499,13.575c-33.034,0-62.938-20.237-76.185-51.557    L280.94,0h-49.88l-3.873,9.157c-13.246,31.319-43.15,51.557-76.185,51.557c-16.858,0-32.166-4.567-45.499-13.575l-10.655-7.197    l-44.62,48.125L61.755,98.24c9.688,8.548,15.244,20.851,15.244,33.755c0,4.193-0.574,8.341-1.706,12.326l-22.049,77.644    c-4.144,14.593-6.245,29.743-6.245,45.03c0,50.433,22.621,97.437,62.13,129.01L256,512l146.938-116.049    c39.442-31.521,62.063-78.524,62.063-128.957C465.001,251.707,462.899,236.557,458.756,221.964z M360.998,90.714    c18.424,0,35.563-4.057,51.116-12.082l7.831,8.445c-9.618,12.864-14.944,28.602-14.944,44.917c0,6.966,0.958,13.87,2.847,20.521    l2.692,9.479H270.986l-0.126-117.18C291.942,73.321,325.081,90.714,360.998,90.714z M99.886,78.632    c15.554,8.025,32.692,12.082,51.116,12.082c35.759,0,68.764-17.239,89.859-45.523l0.125,116.803H101.461l2.692-9.479    c1.889-6.651,2.847-13.556,2.847-20.521c0-16.315-5.326-32.053-14.944-44.917L99.886,78.632z M92.942,191.994h326.117    l10.838,38.165c2.027,7.135,3.442,14.44,4.263,21.835H77.841c0.821-7.395,2.236-14.7,4.263-21.835L92.942,191.994z     M143.999,385.318l-16.208-12.802c-28.33-22.641-46.052-55.038-49.965-90.522h66.173V385.318z M241,461.937l-67.001-52.923    v-127.02h67.003v32.136c-22.498,6.518-39,27.294-39,51.864s16.501,45.345,38.998,51.864V461.937z M232.002,365.994    c0-13.233,10.767-24,24-24c13.233,0,24,10.767,24,24c0,13.233-10.767,24-24,24C242.769,389.994,232.002,379.227,232.002,365.994z     M338.001,409.014L271,461.937v-44.078c22.499-6.517,39.002-27.294,39.002-51.865c0-24.57-16.502-45.346-39-51.864v-32.136h66.999    V409.014z M384.275,372.463l-16.274,12.855V281.994h66.173C430.262,317.477,412.547,349.868,384.275,372.463z"/>
        //                 </g>
        //             </g>
        //             </svg>`
        //         }}
        //     />
        // },
        // {
        //     title: "Squads",
        //     url: "/squads",
        //     icon: () => <Shield size={20}/>,
        //     borderTop: true
        // },
    ]

    return (
        <Wrapper>
            <nav className={`page-sidebar ${showSidebar.shown && 'visible'}`} data-pages="sidebar">
                <div className="sidebar-header">
                    <div className="pin">
                        <button
                            onClick={runPinSidebar}
                            aria-label="Pin Menu"
                            type="button"
                            className={`btn btn-icon-link invert d-lg-inline-block d-none ${
                                !isPinned ? 'un-pinned' : 'pinned'
                            }`}
                            data-toggle-pin="sidebar">
                            {/* <Menu size={17} /> */}
                            <div
                                className="btn-icon-link text-white d28"
                                dangerouslySetInnerHTML={{ __html: pushToRight }}
                            />
                        </button>
                        <button
                            onClick={toggleSidebar}
                            aria-label="Pin Menu"
                            type="button"
                            className="btn btn-icon-link invert d-inline-block d-lg-none"
                            data-toggle-pin="sidebar">
                            <X size={17} />
                        </button>
                    </div>
                    <div className="logo-container">
                        <Link exact="true" to="/">
                            <img
                                src="/img/scouty_dark_beta.png"
                                alt="logo"
                                className="brand"
                                data-src="/img/scouty_dark_beta.png"
                                data-src-retina="/img/scouty_green_outline.png"
                                width="100"
                            />
                        </Link>
                    </div>
                </div>
                <div className="sidebar-menu">
                    <div className="menu-items">
                        <ul className="menu-items scrollbar-hidden">
                            {menu.map((menuItem, i) => {
                                const Component = menuItem.url ? Link : 'button'
                                const componentProps = menuItem.url
                                    ? { to: menuItem.url }
                                    : { onClick: menuItem.onClick }
                                if (menuItem.hidden) return null
                                return (
                                    <li
                                        key={i}
                                        className={classNames({
                                            active: location.pathname === menuItem.url,
                                            'm-t-10': i === 0,
                                        })}
                                        onClick={clickHandler}>
                                        <span className="icon-thumbnail">
                                            <Component
                                                {...componentProps}
                                                className={classNames({
                                                    'btn-text': !menuItem.url,
                                                })}>
                                                {menuItem.icon()}
                                            </Component>
                                        </span>
                                        <Component
                                            {...componentProps}
                                            className={classNames({
                                                'btn-text-full': !menuItem.url,
                                                detailed: menuItem.detailed,
                                            })}>
                                            <span className="title">
                                                {menuItem.title}
                                                {menuItem.detailed && (
                                                    <span className="details">{menuItem.detailed()}</span>
                                                )}
                                            </span>
                                        </Component>
                                        {menuItem.unread && menuItem.unread > 0 && (
                                            <span className="badge badge-success unread">{menuItem.unread}</span>
                                        )}
                                    </li>
                                )
                            })}
                            <li className="py-0">
                                <hr className="m-t-10 m-b-10 w-100" />
                            </li>
                            <li className="divider-title">
                                MY SQUADS
                                {/* <span className="fs-12">({user.subscribed_squads.length})</span> */}
                            </li>
                            {isLoading ? (
                                <LoadingSquadCards
                                    number={2}
                                    row
                                    style={{ marginLeft: '15px', minHeight: '46px', paddingTop: '7px' }}
                                />
                            ) : (
                                user.subscribed_squads.map((squad, i) => {
                                    const isActive = location.pathname.startsWith(squad.squad_link) ? 'active' : ''
                                    return (
                                        <li key={i} onClick={clickHandler} className={`my-squads--row ${isActive}`}>
                                            <span className="icon-thumbnail">
                                                <Link to={squad.squad_link} className="flex-center">
                                                    <Avatar
                                                        width={30}
                                                        height={30}
                                                        boxed
                                                        src={squad.photo_url_xs}
                                                        alt={squad.name}
                                                    />
                                                </Link>
                                            </span>
                                            <Link to={squad.squad_link} className="detailed">
                                                <span className="title">
                                                    {squad.name}
                                                    <span className="details">{squad.total_members} members</span>
                                                </span>
                                            </Link>
                                        </li>
                                    )
                                })
                            )}
                            <li onClick={clickHandler}>
                                <span className="icon-thumbnail">
                                    <Link to="/dashboard/create-squad/free_squad" className="detailed">
                                        <div
                                            className="squad-logo thumbnail-wrapper circular d-flex"
                                            style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <PlusCircle size={iconSize} strokeWidth={strokeWidth} />
                                        </div>
                                    </Link>
                                </span>
                                <Link to="/dashboard/create-squad/free_squad">
                                    <span className="title">Create Squad</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="user-section">
                            <UserDropdown clickHandler={clickHandler} />
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                {/* <div className="expander">
                    <div className="grabber" />
                </div> */}
            </nav>
        </Wrapper>
    )
}

export default Sidebar

const Wrapper = styled.div`
    .sidebar-header {
        display: flex;
        .logo-container {
            // margin-left: auto;
            // order: 2;
        }
        .pin {
            margin-left: auto;
            order: 2;
            .btn {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
`
