import { SET_LOCATION_DATA } from '../actions/actionTypes'

const locale = (state = {}, action) => {
    switch (action.type) {
        case SET_LOCATION_DATA: {
            const newState = {
                ...state,
                ...action.payload,
            }
            return newState
        }
        default:
            return state
    }
}

export default locale
