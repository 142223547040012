import { LOGIN_MODAL } from '../actions/actionTypes'

const initialState = false

const loginModal = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_MODAL:
            return action.payload
        default:
            return state
    }
}

export default loginModal