import { getRequest, putRequest } from './apiRequest'

// get conversation's users
export const getConversationUsers = (squad_slug, ids) => {
    const url = `/chat/get-chat-members/${squad_slug}`
    const data = {
        ids: JSON.stringify(ids),
    }
    return getRequest(url, data)
}

export const getSquadChatData = (squad_slug, page = 1) => {
    const url = `/chat/get-all-lines/${squad_slug}`
    return getRequest(url, { page })
}

export const addChatLineSquadChannel = (squad_slug, message, user_id) => {
    const url = `/chat/add-squad-line/${squad_slug}`
    const payload = {
        values: {
            message,
            user_id,
        },
    }
    return putRequest(url, payload)
}
