import { useWindowSize } from 'hooks'
import { Offcanvas } from 'react-bootstrap'

export const BottomSideDrawer = ({ open, onClose, children, ...rest }) => {
    const { width } = useWindowSize()

    const Component = Offcanvas

    if (!open) return null

    return (
        <Component
            placement={width >= 768 ? 'end' : 'bottom'}
            show={open}
            centered
            onEscapeKeyDown={() => false}
            onHide={onClose}
            className="z-1010 h-unset"
            backdrop="static"
            backdropClassName="z-1000"
            {...rest}>
            {children}
        </Component>
    )
}
