import { toggleChat } from 'components/store/actions'
import { useDispatch, useSelector } from 'react-redux'
// import ChatBox from './ChatBox'
import ChatChannels from './ChatChannels'
import Messages from './Messages'

const QuickView = () => {
    const dispatch = useDispatch()
    const chatPin = useSelector(state => state.chatPin)
    const { selectedConversation } = useSelector(state => state.conversation)

    const onChatClose = () => dispatch(toggleChat())

    if (!chatPin) return null
    return (
        <div
            id="quickview"
            className={`quickview-wrapper ${chatPin === true ? 'open' : 'false'}`}
            data-pages="quickview">
            {chatPin === true && (
                <>
                    <button type="button" className="btn-icon-link invert quickview-toggle" onClick={onChatClose}>
                        <i className="pg-icon">close</i>
                    </button>
                    <div className="nav-tabs">
                        <div className="tab-content">
                            <div className="tab-pane active no-padding" id="quickview-chat" role="tabpanel">
                                <div className="view-port clearfix" id="chat">
                                    {selectedConversation ? <Messages /> : <ChatChannels />}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default QuickView
