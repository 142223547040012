import store, { addSingleRowToBottomOfChat } from 'components/store'
import echoInstance from './echo'
import { addNotOnBoardedSquadsToUser } from 'components/store'

const userMainPusher = () => {
    const { user } = store.getState()
    if (!user || !window.Echo) return
    const channelName = `user_main_channel.${user.username}`

    console.log('userMainPusher', echoInstance.channel(channelName))
    echoInstance.channel(channelName)
        .listen('UserNotOnboardedSquads', e => {
            const { squad } = e
            store.dispatch(addNotOnBoardedSquadsToUser(squad))
        })
        .listen('UserNotificationsPush', e => {
            console.log('notification', e)
            // const { squad } = e
            // store.dispatch(addNotOnBoardedSquadsToUser(squad))
        })
        .listen('UserNotificationsUpdate', e => {
            console.log('notification', e)
            // const { squad } = e
            // store.dispatch(addNotOnBoardedSquadsToUser(squad))
        })
        .listen('UserNotificationsDelete', e => {
            console.log('notification', e)
            // const { squad } = e
            // store.dispatch(addNotOnBoardedSquadsToUser(squad))
        })
}

export default userMainPusher
