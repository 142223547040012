const INITIATE_CHAT = 'INITIATE_CHAT'
const ADD_CHAT_SINGLE_LINE = 'ADD_CHAT_SINGLE_LINE'
const SET_CHAT_LOADING = 'SET_CHAT_LOADING'
const SET_CHAT_PAGINATION = 'SET_CHAT_PAGINATION'

const initialState = {
    list: [],
    pagination: {},
    isLoading: false,
}

const chat = (state = initialState, action) => {
    switch (action.type) {
        case INITIATE_CHAT:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case ADD_CHAT_SINGLE_LINE:
            return {
                ...state,
                list: [...state.list, action.payload],
                isLoading: false,
            }
        case SET_CHAT_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case SET_CHAT_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            }
        default:
            return state
    }
}

export default chat

export const initiateChat = data => ({
    type: INITIATE_CHAT,
    payload: data,
})

export const loadChats = data => (dispatch, getState) => dispatch(initiateChat(data.reverse()))

export const loadingChats = () => ({
    type: SET_CHAT_LOADING,
})

export const setChatsPagination = data => ({
    type: SET_CHAT_PAGINATION,
    payload: data,
})

export const addMoreChats = data => (dispatch, getState) => {
    const { chat } = getState()
    const updatedChat = [...data.reverse(), ...chat.list]
    dispatch(initiateChat(updatedChat))
}

export const addSingleRowToBottomOfChat = data => ({
    type: ADD_CHAT_SINGLE_LINE,
    payload: data,
})
