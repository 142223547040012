import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import {
    loadCalendar,
    resetCalendar,
    resetSelectedDay,
    resetSelectedDayEvents,
    setSelectedDay,
    setSelectedDayEvents,
} from 'components/store'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { calendarEvents, getRequest } from 'services'
import DayDrawer from './DayDrawer'
import MonthPicker from './MonthPicker'

const DATE_FORMAT = 'YYYY-MM-DD'

const Calendar = () => {
    const dispatch = useDispatch()
    const { data: events, selectedDay, selectedDayEvents } = useSelector(state => state.calender)
    const calendarRef = useRef(null)
    const [showDate, setShowDate] = useState(false)

    const handleTitleClick = () => setShowDate(true)
    const handleDayClose = () => {
        dispatch(resetSelectedDay())
        dispatch(resetSelectedDayEvents())
    }

    const handleDatesSet = async ({ start, end }) => {
        // dispatch(resetCalendar())
        // Format the start and end dates to YYYY-MM-DD format
        const diffInMilliseconds = moment(end).diff(moment(start))
        const middlePoint = diffInMilliseconds / 2
        const avarageDate = moment(start).add(middlePoint, 'milliseconds')
        const fistDayOfThePreviousMonth = moment(avarageDate).subtract(1, 'months').startOf('month')
        const lastDayOfTheNextMonth = moment(avarageDate).add(1, 'months').endOf('month')
        const startDate = fistDayOfThePreviousMonth.subtract(6, 'days').format(DATE_FORMAT)
        const endDate = lastDayOfTheNextMonth.add(6, 'days').format(DATE_FORMAT)

        // Fetch data for the new date range
        const response = await calendarEvents(startDate, endDate)

        if (response && response.status === 200 && response.data) {
            const { data } = response
            const matches = data.matches.map(match => ({
                ...match,
                id: Math.random(),
                start: match.start_time,
                type: 'matches',
            }))
            const trainings = data.trainings.map(training => ({
                ...training,
                id: Math.random(),
                start: training.start_time,
                type: 'trainings',
            }))
            const events = [...matches, ...trainings]
            // Update the events state with the new data
            dispatch(loadCalendar(events))
        }
    }

    const getSelectedDayEvents = async eventDate => {
        const response = await getRequest(`/user/get-single-calendar-day?date=${eventDate}`)

        if (response && response.status === 200 && response.data) {
            const { data } = response
            const match = data.match
                ? {
                      ...data.match,
                      type: 'matches',
                  }
                : null
            const trainings = data.trainings.map(training => ({
                ...training,
                type: 'trainings',
            }))
            const events = match ? [match, ...trainings] : trainings
            dispatch(setSelectedDayEvents(events))
        }
    }

    useEffect(() => {
        if (calendarRef.current) {
            const title = calendarRef.current.elRef.current.querySelector('.fc-toolbar-title')
            title.addEventListener('click', handleTitleClick)
            return () => {
                title.removeEventListener('click', handleTitleClick)
                dispatch(resetCalendar())
            }
        }
        return () => dispatch(resetCalendar())
    }, [])

    return (
        <div className="calender-page">
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                ref={calendarRef}
                fixedWeekCount={false}
                dateClick={info => {
                    const selectedDay = info.dateStr
                    dispatch(setSelectedDay(selectedDay))
                    getSelectedDayEvents(selectedDay)
                }}
                events={events}
                datesSet={handleDatesSet}
                timeZone="UTC"
            />
            <MonthPicker showDate={showDate} setShowDate={setShowDate} calendarRef={calendarRef} />
            {selectedDay && (
                <DayDrawer selectedDay={selectedDay} handleDayClose={handleDayClose} events={selectedDayEvents} />
            )}
        </div>
    )
}

export default Calendar
