import Avatar from 'components/shared/Avatar'
import { useWindowSize } from 'hooks'
import moment from 'moment'
import { Suspense, lazy, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getAddressByLocation } from 'utils'

import RSVPTraining from '../RSVPTraining'

import TrainingDelete from './TrainingDelete'
import TrainingTitle from './TrainingTitle'

const TrainingDescription = lazy(() => import('../TrainingDescription'))
const TrainingMap = lazy(() => import('../TrainingMap'))
// const SpecialInstructions = lazy(() => import('../SpecialInstructions'))
const TrainingAttendance = lazy(() => import('../TrainingAttendance'))

const TABS = [
    {
        label: 'Description',
        key: 'description',
        content: TrainingDescription,
    },
    // {
    //     label: 'Instructions',
    //     key: 'instructions',
    //     content: SpecialInstructions,
    //     hidden: ({ data }) => !data.instructions,
    // },
    {
        label: 'Map',
        key: 'map',
        content: TrainingMap,
    },
    {
        label: 'Attendance',
        key: 'attendance',
        content: TrainingAttendance,
        // hidden: ({ width }) => width >= 768,
    },
]

const TrainingDetails = ({ handleClose }) => {
    const { width } = useWindowSize()
    const selectedTraining = useSelector(state => state.training.selected)
    const filteredTabs = TABS.filter(tab => !tab.hidden?.({ width, data: selectedTraining }))
    const squad = useSelector(state => state.clubSquadDetail)
    const defaultActiveKey = filteredTabs[0].key
    const [key, setKey] = useState(defaultActiveKey)

    const localTime = moment.utc(selectedTraining?.start_time).tz(selectedTraining?.location?.timezone)

    const coach = selectedTraining?.coaches?.[0]?.user

    return (
        <div className="flex-col">
            <div className="d-flex flex-column px-5c pt-3 flex-none">
                <TrainingTitle training={selectedTraining} />
                <p className="mt-1 m-b-0 text-white">
                    {localTime.format("MMM D, 'YY")} <span className="hint-text">-</span> {localTime.format('H:mm a')}
                </p>
                <p className="text-sm hint-text-less">{getAddressByLocation(selectedTraining?.location)}</p>
                <div className="mt-3 d-flex sm-flex-none flex-column flex-md-row">
                    <div className="flex-grow-1">
                        {coach && (
                            <div className="d-flex align-items-center justify-content-start mt-2">
                                <Avatar
                                    width={24}
                                    height={24}
                                    className="m-r-5"
                                    src={coach.photo_url_xs}
                                    alt={coach.username}
                                />
                                <span className="hint-text">{coach.name || coach.username}</span>
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-row align-items-end justify-content-end gap-2">
                        <RSVPTraining data={selectedTraining} squad={squad} />
                        <TrainingDelete data={selectedTraining} handleClose={handleClose} />
                    </div>
                </div>
            </div>
            <div className="training-modal-tabs flex-1">
                <Tab.Container
                    defaultActiveKey={defaultActiveKey}
                    id="training-modal-tabs"
                    activeKey={key}
                    onSelect={k => setKey(k)}>
                    <Nav variant="border" className="justify-content-around ">
                        {filteredTabs.map(({ label, key }) => (
                            <Nav.Item key={key}>
                                <Nav.Link eventKey={key}>{label}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    {filteredTabs
                        .filter(({ key: k }) => k === key)
                        .map(({ key, content: Component }) => (
                            <Tab.Pane key={key} eventKey={key} className="no-padding">
                                <Suspense fallback={<div />}>
                                    <Component />
                                </Suspense>
                            </Tab.Pane>
                        ))}
                </Tab.Container>
            </div>
        </div>
    )
}
export default TrainingDetails
