import { GooglePlacesComponent } from 'components/shared'
import FinalFormError from 'components/shared/FinalFormError'
import GridButtonSelector from 'components/shared/GridButtonSelector'
import ReactSelectAdapter from 'components/shared/ReactSelectAdapter'
import utils from 'components/shared/utils'
import { setUserData } from 'components/store/actions'
import { useState } from 'react'
import { Form as BsForm, Card, Col, Row } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Field } from 'react-final-form'
import { getLatLng } from 'react-google-places-autocomplete'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getAddressByGeocode, getGeocodeByAddress, getTimezoneByLatLng, onBoardingComplete } from 'services'
import { toLowerCase } from 'utils'
import Wizard from './Wizard'
import validation from './validation'
import { coachMain, soccerPlayeFemale1 } from 'components/shared/svg/svgs'

// function that sets the next time making sure it falls within correct parameters
// like if the location chosen in paris, time should reflect that

const OnBoarding = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const { countries, languages } = useSelector(state => state.masterData)
    const [newLocationAddress, setNewLocationAddress] = useState(user.address ?? null)
    // const [newLatLongData, setLatLongData] = useState({})
    const weights = useSelector(state => state.weights)

    const onSubmit = async values => {
        values = {
            ...values,
            height: values.height ? values.height.value : null,
            weight: values.weight ? values.weight.value : null,
            gender: values.gender,
            joining_as: values.joining_as,
            nationality: values.nationality.value,
        }
        const response = await onBoardingComplete({ values })

        if (response.status === 200 && response.data && response.data.status === 'success') {
            const { user } = response.data
            dispatch(setUserData(user))
            toast.success('Your profile has been updated.')
        } else {
            toast.error('Something went wrong.')
        }
    }

    const setFlag = (direction, flagObj, mutators) => {
        if (!flagObj.value || !['next', 'prev'].includes(direction)) {
            // alert('error!!')
            console.log('f', flagObj)
        }
        //prev and next instructions, setting them by the order in the flags array
        // if we are on the last
        countries.forEach((element, i) => {
            if (element.value === flagObj.value) {
                let chosenFlag = null
                if (direction == 'prev') {
                    chosenFlag = countries[i - 1]
                    if (i === 0) {
                        // if we are in the start and going previous of the flags array, go to the last flag on the list
                        chosenFlag = countries[countries.length - 1]
                    }
                }
                if (direction == 'next') {
                    chosenFlag = countries[i + 1]
                    if (i === countries.length - 1) {
                        // if we are in the end and going next of the flags array, go to the 1st flag on the list
                        chosenFlag = countries[0]
                    }
                }
                mutators.setNationality(chosenFlag)
                return
            }
        })
    }

    const placeOnChange = async (place, mutators) => {
        const geocode = await getGeocodeByAddress(place)
        const { formattedAddress, city, region, country } = await getAddressByGeocode(geocode)
        const { lat, lng } = await getLatLng(geocode).catch(err => console.log(err))
        setNewLocationAddress(formattedAddress)
        // setLatLongData({ lat, lng })
        mutators.setAddress(formattedAddress)
        mutators.setCity(city)
        mutators.setRegion(region)
        mutators.setCountry(country)
        mutators.setLatLongValue({ lat, lng })
        await getTimezoneByLatLng(lat, lng)
            .then(({ timeZoneId }) => {
                mutators.setTimezone(timeZoneId)
            })
            .catch(err => {
                console.log(err)
            })
    }

    if (!user) return null
    if (!countries) return null

    const initialValues = {
        nationality: user.nationality
            ? {
                  value: user.nationality,
                  label: user.nationality,
              }
            : {
                  value: countries[0].value,
                  label: countries[0].nationality,
              },
        joining_as: user.user_type,
        gender: user.gender && utils.gender.find(e => e.value == user.gender)?.value,
        city: user.address
            ? {
                  value: user.address,
                  label: user.address,
              }
            : {
                  value: '',
                  label: '',
              },
        address: user.address || null,
        region: user.region || null,
        country: user.country || null,
        lat: user.lat || null,
        lng: user.lng || null,
    }

    return (
        <div className="on-boarding">
            <Wizard initialValues={initialValues} onSubmit={onSubmit}>
                <Wizard.Page validate={validation.nationality}>
                    {(mutators, values) => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Choose Your Nationality</h4>
                                <p>
                                    Select your country of origin to help us connect you with local teams, players, and
                                    coaches
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <div
                                    className="d-flex justify-content-center align-items-center w-100 "
                                    style={{ height: 200 }}>
                                    <div
                                        className="pointer m-r-10"
                                        onClick={() => setFlag('prev', values.nationality, mutators)}>
                                        <ChevronLeft size={40} />
                                    </div>
                                    <div>
                                        <img
                                            src={`/img/flags/${toLowerCase(values?.nationality.value)}.svg`}
                                            width={250}
                                            alt={values?.nationality?.value}
                                        />
                                    </div>
                                    {/* <span className={`fflag ff-xl fflag-${values?.nationality?.value}`}></span> */}
                                    <div
                                        className="pointer m-l-10"
                                        onClick={() => setFlag('next', values.nationality, mutators)}>
                                        <ChevronRight size={40} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="w100">
                                        {/* <label className="form-label">Choose your Nationality</label> */}
                                        <Field name="nationality">
                                            {({ input, meta }) => (
                                                <>
                                                    <ReactSelectAdapter
                                                        {...input}
                                                        typing
                                                        style={{ width: 300 }}
                                                        placeholder="Choose your nationality"
                                                        options={countries.map(flag => ({
                                                            value: flag.value,
                                                            label: flag.nationality,
                                                        }))}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.biometrics}>
                    {() => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Your Gender</h4>
                                <p>
                                    Please select you player gender category. This will help us connect you with the
                                    right teams.
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <Field name="gender">
                                    {({ input, meta }) => (
                                        <>
                                            <GridButtonSelector
                                                array={[
                                                    { label: 'Male', value: 'male' },
                                                    { label: 'Female', value: 'female' },
                                                ]}
                                                valueOnly
                                                {...input}
                                            />
                                            <FinalFormError meta={meta} />
                                        </>
                                    )}
                                </Field>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.profile}>
                    {(mutators, values) => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>My main profile</h4>
                                <p>
                                    What will be your primary profile, a <strong>Coach</strong> or a{' '}
                                    <strong>Player</strong>? Don&apos;t worry even if you are a coach you can still be a
                                    player.
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <Field name="joining_as">
                                    {({ input, meta }) => (
                                        <>
                                            <GridButtonSelector
                                                array={[
                                                    {
                                                        label: 'Coach',
                                                        value: 'staff',
                                                        icon: coachMain,
                                                        iconCustom: true,
                                                        iconWidth: 80,
                                                    },
                                                    {
                                                        label: 'Player',
                                                        value: 'player',
                                                        icon:
                                                            values.gender === 'female'
                                                                ? soccerPlayeFemale1
                                                                : soccerPlayeFemale1,
                                                        iconCustom: true,
                                                        iconWidth: 80,
                                                    },
                                                ]}
                                                valueOnly
                                                {...input}
                                            />
                                            <FinalFormError meta={meta} />
                                        </>
                                    )}
                                </Field>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.coachBio} hide={values => values.joining_as !== 'staff'}>
                    {() => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Your Coaching Bio</h4>
                                <p>
                                    Please write anything regarding your coaching background. You don&apos;t have to add
                                    a lot, but some information about you as a coach is required.
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <Field name="coach_bio">
                                    {({ input, meta }) => (
                                        <>
                                            <textarea
                                                {...input}
                                                className="form-control"
                                                placeholder="Write your coaching experience here"
                                                rows="5"
                                            />
                                            <FinalFormError meta={meta} />
                                        </>
                                    )}
                                </Field>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page
                    validate={validation.footballingAttributes}
                    hide={values => values.joining_as !== 'player'}>
                    {() => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Footballing Attributes</h4>
                                <p>
                                    These optional items provide additional stats for players, aiding coaches in better
                                    understanding individual performance.
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <BsForm.Group as={Row} className="form-group">
                                    <label className="col-md-4 form-label">Height</label>
                                    <Col md={8}>
                                        <Field name="height">
                                            {({ input, meta }) => (
                                                <>
                                                    <ReactSelectAdapter
                                                        {...input}
                                                        placeholder="Select your height"
                                                        options={utils.height}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </BsForm.Group>
                                <BsForm.Group as={Row} className="form-group">
                                    <label className="col-md-4 form-label">Weight</label>
                                    <Col md={8}>
                                        <Field name="weight">
                                            {({ input, meta }) => (
                                                <>
                                                    <ReactSelectAdapter
                                                        {...input}
                                                        placeholder="Select your weight"
                                                        options={weights}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </BsForm.Group>
                                <BsForm.Group as={Row} className="form-group">
                                    <label className="col-md-4 form-label">Preferred Foot</label>
                                    <Col md={8}>
                                        <Field name="preferred_foot">
                                            {({ input, meta }) => (
                                                <>
                                                    <ReactSelectAdapter
                                                        {...input}
                                                        placeholder="Which foot is your stronger one"
                                                        options={utils.footed}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </BsForm.Group>
                                <BsForm.Group as={Row} className="form-group">
                                    <label className="col-md-4 form-label">Spoken Languages (3 max)</label>
                                    <Col md={8}>
                                        <Field name="spoken_languages">
                                            {({ input, meta }) => (
                                                <>
                                                    <ReactSelectAdapter
                                                        {...input}
                                                        isMulti
                                                        typing
                                                        maxLimit={3}
                                                        placeholder="Select languages you can speak"
                                                        options={languages}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                </BsForm.Group>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.location}>
                    {mutators => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Finally your location</h4>
                                <p>
                                    Adding your location to your team's profile helps you connect with local teams,
                                    players, and coaches
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <BsForm.Group as={Row} className="form-group">
                                    <label className="col-md-4 form-label">City</label>
                                    <Col md={8}>
                                        <Field name="city">
                                            {({ input, meta }) => (
                                                <div style={{ width: '100%' }}>
                                                    <GooglePlacesComponent
                                                        // textInputProps={{ placeholderTextColor: 'pink' }}
                                                        selectProps={{
                                                            value: newLocationAddress
                                                                ? {
                                                                      label: newLocationAddress,
                                                                      value: newLocationAddress,
                                                                  }
                                                                : null,
                                                            onChange: e => {
                                                                input.onChange(e.label)
                                                                placeOnChange(e.label, mutators)
                                                            },
                                                            placeholder: "Start typing your city's name",
                                                        }}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                // country: [values.group_country && values.group_country.value]
                                                                // country: ['CA', 'GB', 'US'],
                                                            },
                                                            types: ['(cities)'],
                                                        }}
                                                    />
                                                    <FinalFormError meta={meta} />
                                                </div>
                                            )}
                                        </Field>
                                    </Col>
                                </BsForm.Group>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
            </Wizard>
        </div>
    )
}

export default OnBoarding
