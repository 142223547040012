const CONVERSATION_SELECTED = 'CONVERSATION_SELECTED'
const CONVERSATION_CLEARED = 'CONVERSATION_CLEARED'
const CONVERSATION_USERS_LOADED = 'CONVERSATION_USERS_LOADED'
const CONVERSATION_USERS_CLEARED = 'CONVERSATION_USERS_CLEARED'

const initialState = {
    selectedConversation: null,
    users: [],
}

const conversation = (state = initialState, action) => {
    switch (action.type) {
        case CONVERSATION_SELECTED:
            return {
                ...state,
                selectedConversation: action.payload,
            }
        case CONVERSATION_CLEARED:
            return {
                ...state,
                selectedConversation: null,
            }
        case CONVERSATION_USERS_LOADED:
            return {
                ...state,
                users: action.payload,
            }
        case CONVERSATION_USERS_CLEARED:
            return {
                ...state,
                users: [],
            }
        default:
            return state
    }
}

export default conversation

// action creators
export const selectConversation = conversation => ({
    type: CONVERSATION_SELECTED,
    payload: conversation,
})

export const clearConversation = () => ({
    type: CONVERSATION_CLEARED,
})

export const loadConversationUsers = users => ({
    type: CONVERSATION_USERS_LOADED,
    payload: users,
})

export const clearConversationUsers = () => ({
    type: CONVERSATION_USERS_CLEARED,
})

// selectors
export const getSelectedConversation = state => state.conversation.selectedConversation

export const getConversationUsers = state => state.conversation.users

export const getConversationUser = (state, userId) => {
    const users = getConversationUsers(state)
    return users.find(user => user.id === userId)
}
