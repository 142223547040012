import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import Bench from './Bench'
import FormationDisplay from './LineUp/Fields/FormationDisplay'

const Lineup = () => {
    const { isLoading, data: fixture } = useSelector(state => state.fixture)
    const { formation } = fixture

    if (isEmpty(fixture) || isLoading) return null

    return (
        <div>
            <div className="fixture-view">
                <h4 className="mt-1 mb-2 text-center">
                    <span className="">{formation}</span>
                </h4>
                <FormationDisplay />
                <Bench />
            </div>
        </div>
    )
}
export default Lineup
