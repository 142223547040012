import { isMatchEnded, isMatchFifteenMinsAgo, isMatchThreeHoursAgo, matchHasStarted } from 'components/shared/shared2'
import { LINES, MATCH_EVENT_TYPES, MATCH_HALF_OPTIONS, POSITION_GROUP } from 'constants'
import cloneDeep from 'lodash/cloneDeep'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import moment from 'moment'

export { default as isFormation } from './isFormation'

// convert to normal case
export const toNormalCase = string => {
    if (typeof string !== 'string') return
    const result = string
        .replace(/[^A-Za-z0-9]/gi, ' ')
        .replace(/([A-Z])/g, ' $1')
        .trim()
    return result.charAt(0).toUpperCase() + result.slice(1)
}

// Capitalize only the first letter of the string
export const capitalizeFirst = string => toNormalCase(string)

// To Lower Case
export const toLowerCase = string => (typeof string === 'string' ? string.toLowerCase() : string)

// ToFixedDecimal
export const formatDecimal = (number, decimal = 2) =>
    isNumber(number) || isString(number)
        ? parseFloat(number)
              .toFixed(decimal)
              .replace(/[.,]00$/, '')
        : number

//  formatDecimal without zero
export const formatDecimalWithoutZero = (number, decimal = 2) =>
    isNumber(number) || isString(number) ? formatDecimal(number, decimal).replace(/^0[.,]/, '.') : number

// Url Params String to Params Object
export const pramsStringToObj = str => {
    const searchParams = new URLSearchParams(str)
    const params = {}
    for (let entry of searchParams.entries()) {
        params[entry[0]] = entry[1]
    }
    return params
}

// convert to title case
export const toTitleCase = string => toNormalCase(string).replace(/\b\w+/g, word => toNormalCase(word))

// is day passed
export const isDayPassed = (timestamp, days) => {
    const now = moment().unix()
    const dayPassed = moment
        .unix(timestamp)
        .add({
            days,
        })
        .unix()
    return dayPassed < now
}

// Get Url with Params
export const getUrlWithParams = (url, params, prevPrams) => {
    const searchParams = new URLSearchParams({ ...pramsStringToObj(prevPrams), ...params })
    return `${url}?${searchParams.toString()}`
}

// get all search params
export const getAllSearchParams = searchParams => {
    const params = {}
    for (const [key, value] of searchParams.entries()) {
        params[key] = value
    }
    return params
}

// object to url param string
export const objToUrlParamString = obj => new URLSearchParams(obj).toString()

// Get Squad Roles
export const getSquadRoles = squad => {
    const { am_staff, am_player, am_admin, am_admin_super, am_manager } = squad

    const isStaff = am_staff || am_admin
    const isPlayer = am_player ?? false
    const isManager = am_manager ?? false
    const isAdmin = am_admin ?? false
    const isSuperAdmin = am_admin && am_admin_super
    const hasRole = isStaff || isPlayer || isAdmin

    return { isManager, isStaff, isPlayer, isAdmin, isSuperAdmin, hasRole }
}
// Get Match Roles
export const getMatchRoles = squad => {
    const { am_staff, am_player, am_admin, am_manager, am_stat } = squad

    const isStaff = am_staff || am_admin
    const isPlayer = am_player ?? false
    const isManager = am_manager ?? false
    const isAdmin = am_admin ?? false
    const isStatLogger = am_stat ?? false
    const hasRole = isStaff || isPlayer || isAdmin

    return { isManager, isStaff, isPlayer, isAdmin, isStatLogger, hasRole }
}

// Get Squad Settings
export const getSquadSettings = squad => {
    if (!squad) return {}
    if (!squad?.squad_settings) return {}
    const {
        is_private,
        players_multi_sub,
        players_see_ratings,
        stream_type,
        youtube_name,
        twitch_name,
        last_five_ratings,
    } = squad.squad_settings

    const isPrivate = !!is_private
    const matchMultiSub = !!players_multi_sub
    const playersSeeRatings = !!players_see_ratings
    const streamType = stream_type
    const youtubeChannel = youtube_name || null
    const twitchChannel = twitch_name || null
    const lastFiveRatings = !!last_five_ratings

    return { isPrivate, matchMultiSub, playersSeeRatings, streamType, youtubeChannel, twitchChannel, lastFiveRatings }
}

export const getPermissions = (squad, staffId) => {
    if (!staffId) return {}
    if (!squad) return {}
    const { staff } = squad
    const selectedStaff = staff.find(item => item.id === staffId)
    if (!selectedStaff) return {}
    const { isManager } = getSquadRoles(squad)
    if (isManager)
        return { createMatch: true, createStats: true, ratePlayers: true, invitePlayers: true, createTraining: true }
    const { permissions } = selectedStaff
    if (!permissions) return {}
    const { create_match, create_stats, rate_players, invite_players, create_training } = permissions
    const createMatch = !!create_match
    const createStats = !!create_stats
    const ratePlayers = !!rate_players
    const invitePlayers = !!invite_players
    const createTraining = !!create_training
    return { createMatch, createStats, ratePlayers, invitePlayers, createTraining }
}

// validate email
export const validateEmail = email => {
    const emailRegex = new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    return emailRegex.test(email)
}

export const playersRsvpNoFixture = fixture => {
    const { players } = fixture
    if (!players) return []
    return players.filter(({ player_attended }) => player_attended === 'no')
}

// check if window is defined
export const isBrowser = () => typeof window !== 'undefined'

// Get football minute
export const getFootballMinute = number => {
    if (typeof number !== 'number') number = parseInt(number)

    if (number < 0 || number > 120) throw new Error('Invalid minute number')

    if (number >= 11 && number <= 13) return number + 'th'

    switch (number % 10) {
        case 1:
            return number + 'st'
        case 2:
            return number + 'nd'
        case 3:
            return number + 'rd'
        default:
            return number + 'th'
    }
}

export const groupBy = (list, keyGetter) => {
    const map = new Map()
    list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })
    const array = Array.from(map, ([name, value]) => ({ name, value }))
    return array
}

export const groupByKey = (arr, property) =>
    arr.reduce((grouped, item) => {
        const key = item[property]
        if (!grouped[key]) {
            grouped[key] = []
        }
        grouped[key].push(item)
        return grouped
    }, {})

// Get Event Parent
export const getEventParent = event => (event ? MATCH_EVENT_TYPES.find(item => item.children.includes(event.id)) : null)

export const getEventParentId = event => {
    const parent = getEventParent(event)
    return parent ? parent.id : null
}

export const getEventsFirstPersonLabel = eventId => {
    switch (eventId) {
        case 'goal_scored':
            return 'Goal scored by'
        case 'goal_conceded':
            return 'Own Goal? (Leave blank if not)'
        case 'yellow_card':
            return 'Player carded'
        case 'red_card':
            return 'Player carded'
        case 'goal_saved':
            return 'Goal saved by'
        case 'shot':
            return 'Shot by'
        case 'shot-saved':
            return 'Shot saved by'
        case 'shot-blocked':
            return 'Shot blocked by'
        case 'key-pass':
            return 'Key pass by'
        case 'successful-dribbles':
            return 'Dribble by'
        case 'header':
            return 'Header by'
        case 'interception':
            return 'Intercepted by'
        case 'tackle':
            return 'Tackled by'
        case 'substitution':
            return 'Player out'
        case 'corner':
            return 'Corner taken by'
        default:
            return ''
    }
}

export const getEventsSecondPersonLabel = eventId => {
    switch (eventId) {
        case 'goal_scored':
            return 'Assisted by (optional)'
        case 'substitution':
            return 'Player in'
        default:
            return ''
    }
}

// get the city name
export const getCityName = city => (city.includes('|') ? city.split('|')[0] : city)

// Get address by location
export const getAddressByLocation = location => {
    const { city, city2, region, country } = location
    const cityName = city ? city : city2
    const address = `${cityName}, ${region}, ${country}`
    return address
}

export const isSubPlayer = player => player.position.includes('SUB')

export const isSubPosition = position => isString(position) && position.includes('SUB')

export const toggleSidebar = () => {
    const bodyClassList = document.body.classList

    if (bodyClassList.contains('sidebar-open')) {
        bodyClassList.remove('sidebar-open')
        // props.setSidebar(false)
    } else {
        bodyClassList.add('sidebar-open')
        // props.setSidebar(true)
    }
}

export const flattenObject = obj => {
    const flattened = {}

    Object.keys(obj).forEach(key => {
        const value = obj[key]

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value))
        } else {
            flattened[key] = value
        }
    })

    return flattened
}

export const getInputId = input => `${input.name}${input.value}`

export const isHome = ground => ['home', 'neutral'].includes(ground)

export const isGoalScored = eventType => eventType === 'goal_scored'

export const isBlunder = eventType => eventType === 'blunder'

export const isGoalConceded = eventType => eventType === 'goal_conceded'

export const isShot = eventType => eventType === 'shot'

export const isHeader = eventType => eventType === 'header'

export const isTackle = eventType => eventType === 'tackle'

export const isInterception = eventType => eventType === 'interception'

export const isSubstitution = eventType => eventType === 'substitution'

export const isShotSaved = eventType => eventType === 'shot-saved'

export const isKeyPass = eventType => eventType === 'key-pass'

export const isSuccessfulDribbles = eventType => eventType === 'successful-dribbles'

export const isShotBlocked = eventType => eventType === 'shot-blocked'

export const isYellowCard = eventType => eventType === 'yellow_card'

export const isRedCard = eventType => eventType === 'red_card'

export const isFoul = eventType => eventType === 'foul'

export const isCard = eventType => isYellowCard(eventType) || isRedCard(eventType)

export const isTechnical = eventType => {
    const technical = MATCH_EVENT_TYPES.find(item => item.id === 'technical')
    return technical.children.includes(eventType)
}

export const getMinute = (minute, half) => {
    switch (half) {
        case '1st':
            return minute <= 45 ? (
                `${minute}'`
            ) : (
                <>
                    45
                    <sup>
                        <b>+</b>
                        {minute - 45}
                    </sup>
                    &apos;
                </>
            )
        case '2nd':
            return minute <= 90 ? (
                `${minute}'`
            ) : (
                <>
                    90
                    <sup>
                        <b>+</b>
                        {minute - 90}
                    </sup>
                    &apos;
                </>
            )
        case 'et-1':
            return minute <= 105 ? (
                `${minute}'`
            ) : (
                <>
                    105
                    <sup>
                        <b>+</b>
                        {minute - 105}
                    </sup>
                    &apos;
                </>
            )
        case 'et-2':
            return minute <= 120 ? (
                `${minute}'`
            ) : (
                <>
                    120
                    <sup>
                        <b>+</b>
                        {minute - 120}
                    </sup>
                    &apos;
                </>
            )
        default:
            return minute
    }
}

export const getHalf = half => {
    switch (half) {
        case '1st':
            return 'First Half'
        case '2nd':
            return 'Second Half'
        case 'et-1':
            return 'Extra Time First Half'
        case 'et-2':
            return 'Extra Time Second Half'
        default:
            return half
    }
}

export const validateYouTubeUrl = url => {
    // Regular expression to match YouTube video URLs with a valid 11-character video ID
    const pattern =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|embed\/|v\/)?[a-zA-Z0-9\-_]{11}(\?.*)?$/

    return pattern.test(url)
}

export const allDaysInMonth = (dateString = null) => {
    var year = moment(dateString).format('YYYY')
    var monthIndex = moment(dateString).format('MM') - 1 // 0..11 instead of 1..12
    var names = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
    var date = new Date(year, monthIndex, 1)
    var result = []
    let weekPush = 0
    let r = 1
    while (date.getMonth() == monthIndex) {
        const array = {
            date: date.getDate(),
            datetime: date,
            day: names[date.getDay()],
        }
        result[weekPush] ? result[weekPush].push(array) : result.push([array])
        if (r % 7 == 0) {
            weekPush++
        }
        date.setDate(date.getDate() + 1)
        r++
    }
    return result
}

export const randomNumBetweenTwo = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
}

export const getFormationName = positions => {
    const formationCounts = Object.keys(LINES).reduce((counts, line) => {
        counts[line] = positions.filter(position => LINES[line].includes(position)).length
        return counts
    }, {})

    return Object.values(formationCounts)
        .filter(count => count > 0)
        .reverse()
        .join('-')
}

export const getGroupName = position => {
    if (position === 'GK') return 'GK'
    if (position.includes('SUB')) return 'SUB'
    for (const groupName in LINES) {
        if (LINES[groupName].includes(position)) {
            return groupName
        }
    }
    return null
}

export const getGroupShortName = position => {
    const groupName = getGroupName(position)

    switch (groupName) {
        case 'GK':
            return 'GK'
        case 'SUB':
            return 'SUB'
        case 'strikerLine':
            return 'FW'
        case 'attackingMidLine':
            return 'MID'
        case 'midfielderLine':
            return 'MID'
        case 'defensiveLine':
            return 'DEF'
        case 'backLine':
            return 'DEF'
        default:
            return null
    }
}

export const isCoach = user => user.member_type === 'staff'

export const splitName = (name, maxLength = 14) => {
    const parts = name.split(' ')
    if (parts.length === 1) return parts

    const result = []
    let currentPart = ''

    parts.forEach(word => {
        if (currentPart.length + word.length <= maxLength) {
            currentPart += `${word} `
        } else {
            result.push(currentPart.trim())
            currentPart = `${word} `
        }
    })

    result.push(currentPart.trim())
    return result
}

export const getTeamName = (name, shortName, maxLength = 8) => (name.length > maxLength ? shortName : name)

export const convertRatingToRated = value => {
    const rating = (value * 10) / 100
    if (rating < 10) {
        return rating.toFixed(1)
    }
    return rating.toFixed(0)
}

export const isDropDownPlayersOpen = selectedPlayerPosition =>
    isString(selectedPlayerPosition) || isNumber(selectedPlayerPosition)

// Get Player Name
export const getPlayerName = player => (player ? player.nickname || player.name : '')

export const getPlayer = (playerId, players) => players.find(player => player.id === playerId)

export const getPlayerNewName = (player, players) => (player ? getPlayerName(getPlayer(player.id, players)) : '')

export const getTimerHalfShort = timer =>
    timer.et_second_half_start_time
        ? '2H ET'
        : timer.et_first_half_start_time
        ? '1H ET'
        : timer.second_half_start_time
        ? '2H'
        : timer.first_half_start_time
        ? '1H'
        : ''

export const getTimerHalfValue = timer =>
    timer.et_second_half_start_time
        ? 'et-2'
        : timer.et_first_half_start_time
        ? 'et-1'
        : timer.second_half_start_time
        ? '2nd'
        : timer.first_half_start_time
        ? '1st'
        : '1st'

export const getPosition = (position, lineup) => {
    if (isNumber(position)) return lineup[position].position
    return position
}

export const getPositionPlayer = (position, lineup, substitutions) => {
    if (isString(position)) return substitutions.find(s => s.position === position)?.player
    if (isNumber(position)) return lineup[position].player
    return position
}

const getDurationInMinutes = (startTime, endTime, floor = false) => {
    const start = moment(startTime)
    const end = moment(endTime)
    const duration = moment.duration(end.diff(start))
    const minutes = duration.asMinutes()
    const roundedMinutes = floor ? Math.floor(minutes) : Math.ceil(minutes)
    return roundedMinutes
}
// Get Timer Minute
export const getTimerMinute = (timer, endTime, floor = false) => {
    const { first_half_start_time, second_half_start_time, et_first_half_start_time, et_second_half_start_time } = timer

    if (isExtraTimeSecondHalf(timer)) return getDurationInMinutes(et_second_half_start_time, endTime, floor) + 105
    if (isExtraTimeFirstHalf(timer)) return getDurationInMinutes(et_first_half_start_time, endTime, floor) + 90
    if (isSecondHalf(timer)) return getDurationInMinutes(second_half_start_time, endTime, floor) + 45
    if (isFirstHalf(timer)) return getDurationInMinutes(first_half_start_time, endTime, floor)

    return false
}

export const setMinMaxMinutes = selectedMatchHalf => {
    let minMax = null
    // The switch statement code remains the same
    switch (selectedMatchHalf) {
        case '1st':
            minMax = { min: 0, max: 70 }
            break
        case '2nd':
            minMax = { min: 46, max: 115 }
            break
        case 'et-1':
            minMax = { min: 91, max: 115 }
            break
        case 'et-2':
            minMax = { min: 106, max: 130 }
            break
        default:
            minMax = { min: 0, max: 120 }
            break
    }
    return minMax
}

export const getMatchHalfLabel = value => MATCH_HALF_OPTIONS.find(item => item.value === value)?.label

export const getMatchHalfLabelShort = value => MATCH_HALF_OPTIONS.find(item => item.value === value)?.labelShort

export const isUrl = string => {
    try {
        new URL(string)
    } catch (_) {
        return false
    }

    return true
}

export const isTimerStarted = timer => timer && Boolean(timer.first_half_start_time)

export const isMatchTimerStarted = fixture => isTimerStarted(fixture.timer)

export const formatNumberWithPadding = (number, minimumDigits = 2) =>
    !isNumber(number)
        ? number
        : number.toLocaleString('en-US', { minimumIntegerDigits: minimumDigits, useGrouping: false })

export const isFirstHalf = timer => timer.first_half_start_time && !timer.first_half_end_time

export const isSecondHalf = timer => timer.second_half_start_time && !timer.second_half_end_time

export const isExtraTimeFirstHalf = timer => timer.et_first_half_start_time && !timer.et_first_half_end_time

export const isExtraTimeSecondHalf = timer => timer.et_second_half_start_time && !timer.et_second_half_end_time

export const isFirstHalfAndExtraTime = (timer, minute) => isFirstHalf(timer) && minute > 45

export const isSecondHalfAndExtraTime = (timer, minute) => isSecondHalf(timer) && minute > 90

export const isExtraTimeFirstHalfAndExtraTime = (timer, minute) => isExtraTimeFirstHalf(timer) && minute > 105

export const isExtraTimeSecondHalfAndExtraTime = (timer, minute) => isExtraTimeSecondHalf(timer) && minute > 120

export const isExtraTime = timer => isExtraTimeFirstHalf(timer) || isExtraTimeSecondHalf(timer)

export const isFirstHalfEnded = timer =>
    timer.first_half_start_time && timer.first_half_end_time && !timer.second_half_start_time

export const isSecondHalfEnded = timer =>
    timer.second_half_start_time && timer.second_half_end_time && !timer.et_first_half_start_time

export const isExtraTimeFirstHalfEnded = timer =>
    timer.et_first_half_start_time && timer.et_first_half_end_time && !timer.et_second_half_start_time

export const isExtraTimeSecondHalfEnded = timer => timer.et_second_half_start_time && timer.et_second_half_end_time

export const isTimerEnded = timer => timer && Boolean(timer.match_ended)

export const canNotStartFirstHalf = fixture => !isTimerStarted(fixture.timer) && isMatchFifteenMinsAgo(fixture)

export const canNotStartSecondHalf = fixture =>
    isFirstHalfEnded(fixture.timer) &&
    getDurationInMinutes(fixture.timer.first_half_end_time, moment.utc().format()) > 15

export const handleMatchTimer = (fixture, time) => {
    const { timer } = fixture
    if (isTimerEnded(timer)) return 'Full Time'
    if (matchHasStarted(fixture) && !isTimerStarted(timer)) return 'Live'
    if (isExtraTimeSecondHalfEnded(timer)) return 'ET Ended'
    if (isExtraTimeSecondHalf(timer) && time.asMinutes() > 130) return 'ET Ended'
    if (isExtraTimeFirstHalfEnded(timer)) return 'ET Break'
    if (isExtraTimeFirstHalf(timer) && time.asMinutes() > 115) return 'ET Break'
    if (isSecondHalfEnded(timer)) return 'Full Time'
    if (isSecondHalf(timer) && time.asMinutes() > 115) return 'Full Time'
    if (canNotStartFirstHalf(fixture)) return 'Live'
    if (isFirstHalfEnded(timer)) return 'Half Time'
    if (isFirstHalf(timer) && time.asMinutes() > 70) return 'Half Time'
    if (canNotStartFirstHalf(fixture)) return 'Live'
    return false
}

export const getTimerHalf = fixture => {
    const { timer } = fixture
    if (!timer) return false
    if (isTimerEnded(timer)) return 'Match Ended'
    if (isExtraTimeSecondHalf(timer)) return 'Extra Time Second Half'
    if (isExtraTimeFirstHalf(timer)) return 'Extra Time First Half'
    if (isSecondHalf(timer)) return 'Second Half'
    if (canNotStartSecondHalf(fixture)) return false
    if (isFirstHalf(timer)) return 'First Half'
    if (canNotStartFirstHalf(fixture)) return false
    if (!isTimerStarted(timer)) return 'Start Timer'
    return false
}

export const getMinutesAndExtraTime = (timer, minute) => {
    if (isFirstHalfAndExtraTime(timer, minute)) {
        const min = Math.floor(minute)
        return {
            minute: 45,
            extraTime: min - 45,
        }
    }
    if (isSecondHalfAndExtraTime(timer, minute)) {
        const min = Math.floor(minute)
        return {
            minute: 90,
            extraTime: min - 90,
        }
    }
    if (isExtraTimeFirstHalfAndExtraTime(timer, minute)) {
        const min = Math.floor(minute)
        return {
            minute: 105,
            extraTime: min - 105,
        }
    }
    if (isExtraTimeSecondHalfAndExtraTime(timer, minute)) {
        const min = Math.floor(minute)
        return {
            minute: 120,
            extraTime: min - 120,
        }
    }
    return {
        minute: Math.floor(minute),
        extraTime: 0,
    }
}

export const isMatchOrTimerStarted = fixture => matchHasStarted(fixture) || isMatchTimerStarted(fixture)

export const isFullTime = fixture => isMatchEnded(fixture) || isMatchThreeHoursAgo(fixture)

export const isLive = fixture => isMatchOrTimerStarted(fixture) && !isFullTime(fixture)

export const getHomeGoals = fixture => (isMatchOrTimerStarted(fixture) ? fixture.home_goals : '-')

export const getAwayGoals = fixture => (isMatchOrTimerStarted(fixture) ? fixture.away_goals : '-')

export const getStartingEleven = players => getNormalizedPlayers(players.filter(player => !isSubPlayer(player)))

export const getGoalkeeper = players => getNormalizedPlayers(players.filter(player => player.position === 'GK'))

export const getPlayersWithoutGoalkeeper = players =>
    getStartingEleven(players).filter(player => player.position !== 'GK')

export const getPlayersFilteredById = (players, id) =>
    getStartingEleven(players).filter(player => player.player.id !== id)

export const getSubstitutes = players => getNormalizedPlayers(players.filter(isSubPlayer))

export const normalizePlayer = player => {
    const { x, y, position, squad_number: number, player: playerData, ...rest } = player
    return { x, y, position, player: { ...playerData, ...rest, number } }
}

export const getNormalizedPlayers = players => players.map(player => normalizePlayer(player))

export const flattenedPlayer = player => {
    const { player: playerData, ...playerPosition } = player
    return { ...playerData, ...playerPosition }
}

export const getFlattenedPlayers = players => players.map(player => flattenedPlayer(player))

export const formatAddress = string => {
    // Split the address by commas
    const parts = string.split(',').map(part => part.trim())

    // Extract postal code if present
    let postalCode = ''
    const lastPart = parts[parts.length - 1]
    const postalRegex = /\b\d{5}(?:[-\s]?\d{4})?|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d\b/ // Updated postal code regex pattern
    if (postalRegex.test(lastPart)) {
        postalCode = lastPart
        parts.pop() // Remove postal code from parts
    }

    // Format the address
    let formattedAddress = parts.join(',\n')
    if (postalCode) {
        formattedAddress += `, ${postalCode}` // Include postal code if present
    }

    return formattedAddress
}

export const isExtraTimeThisHalf = values => {
    const { match_half, minute } = values
    if (match_half === 'et-2' && minute > 120) return true
    if (match_half === 'et-1' && minute > 105) return true
    if (match_half === '2nd' && minute > 90) return true
    if (match_half === '1st' && minute > 45) return true
    return false
}

export const getPositionLabel = position => POSITION_GROUP.find(item => item.value === position)?.label

export const getOpponentName = (opponent, maxLength = 18) =>
    opponent?.name.length > maxLength ? opponent?.short_name : opponent?.name

export const isMatch = event => event.type === 'matches'

export const getLoadingData = number =>
    Array.from(Array(number).keys()).map(() => ({
        isLoading: true,
    }))

export const getPlayerDetails = player =>
    player ? `#${player.number} ${POSITION_GROUP.find(i => i.value === player.pos_group)?.label}` : ''

export const getPlayersByPosition = (players, position) => players?.filter(player => player.pos_group === position)

export const getMatchParamValue = fixture => `${fixture.squad.squad_slug}|${fixture.date}`

export const getMatchParam = fixture => ({ match: getMatchParamValue(fixture) })

export const getHalfNumber = half => MATCH_HALF_OPTIONS.find(item => item.value === half)?.number

export const sortMatchEvents = (matchEvents, ascending = false) =>
    cloneDeep(matchEvents).sort((a, b) =>
        ascending ? parseInt(a.minute) - parseInt(b.minute) : parseInt(b.minute) - parseInt(a.minute)
    )

export const getWindowSize = () => ({
    width: isBrowser() ? window.innerWidth : 0,
    height: isBrowser() ? window.innerHeight : 0,
})

