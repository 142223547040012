import classNames from 'classnames'
import isString from 'lodash/isString'

const GridButtonSelector = ({
    array,
    value,
    onChange,
    noPoint,
    valueOnly = false,
    size = 'md',
    horizontal = false,
    wrap = false,
    scroll = false,
    rounded = false,
    className,
    darkButton = false,
}) => {
    return (
        <div
            className={classNames(
                'grid-component',
                size,
                {
                    noPoint: noPoint,
                    horizontal: horizontal,
                    scroll: scroll,
                    rounded: rounded,
                    wrap: wrap,
                },
                className
            )}>
            {array.map(item => (
                <div
                    key={item.value}
                    className={classNames('child-button', {
                        selected: isString(value) ? value === item.value : value?.value === item.value,
                        'dark-button': darkButton,
                    })}
                    onClick={() =>
                        onChange &&
                        (valueOnly
                            ? onChange(item.value)
                            : onChange({
                                  label: item.label,
                                  value: item.value,
                              }))
                    }>
                    {item.icon && (item.iconCustom ? (
                        <div className="custom-svg" style={{ width: item.iconWidth || 50 }} dangerouslySetInnerHTML={{ __html: item.icon }} />
                    ) : (
                        <div className="custom-svg" style={{ width: item.iconWidth || 50 }}>
                            {item.icon}
                        </div>
                    ))}
                    <p className="name text-center lh-1 semi-bold">{item.label}</p>
                </div>
            ))}
        </div>
    )
}

export default GridButtonSelector
