import {
    isCard,
    isGoalConceded,
    isGoalScored,
    isHeader,
    isInterception,
    isKeyPass,
    isShot,
    isShotSaved,
    isSubstitution,
    isTackle,
    isTechnical,
} from 'utils'

const validation = {
    furtherAction: (values, squad) => {
        const errors = {}
        const { event_type, first_id } = values

        // ========== Goal Scored ==========
        if (isGoalScored(event_type)) {
            const { goal_type } = values
            // We Need The Scorer Only
            const { goal_location, goal_scored_with, goal_scored_from } = values

            if (!goal_type) {
                errors.error_message = 'What kind of goal was scored'
                return errors
            }

            if (!first_id) {
                errors.error_message = 'Who scored the goal?'
                return errors
            }
            if (goal_type === 'open-play') {
                if (!goal_scored_from) {
                    errors.error_message = 'What resulted in the goal?'
                    return errors
                }
                if (!goal_location) {
                    errors.error_message = 'Where was the goal scored from?'
                    return errors
                }

                if (!goal_scored_with) {
                    errors.error_message = 'How was the goal scored?'
                    return errors
                }
            }
        }
        // ========== Goal Conceded ==========
        if (isGoalConceded(event_type)) {
            const { goal_type } = values
            // We Need The Scorer Only
            const { goal_scored_from } = values

            if (!goal_type) {
                errors.error_message = 'What kind of goal was scored'
                return errors
            }

            // if (!first_id) {
            //     errors.error_message = 'Who scored the goal?'
            // }

            if (goal_type === 'open-play' && !goal_scored_from) {
                errors.error_message = 'What resulted in the goal?'
                return errors
            }
        }

        // ========== Cards ==========
        if (isCard(event_type)) {
            if (!first_id) {
                errors.error_message = 'Who was the card shown to?'
                return errors
            }
        }

        // ========== Technical ==========
        if (isTechnical(event_type)) {
            if (isTackle(event_type)) {
                const { tackle_made } = values
                if (!first_id) {
                    errors.error_message = 'Who made the tackle?'
                    return errors
                }

                if (!tackle_made) {
                    errors.error_message = 'Was the tackle successful?'
                    return errors
                }
            }

            if (isShot(event_type)) {
                const { shot_on_target } = values
                if (!first_id) {
                    errors.error_message = 'Who made the shot?'
                    return errors
                }

                if (!shot_on_target) {
                    errors.error_message = 'Was the shot successful?'
                    return errors
                }
            }

            if (isInterception(event_type)) {
                const { interception_made } = values
                if (!first_id) {
                    errors.error_message = 'Who made the interception?'
                    return errors
                }

                if (!interception_made) {
                    errors.error_message = 'Was the interception successful?'
                    return errors
                }
            }

            if (isHeader(event_type)) {
                const { header_made } = values
                if (!first_id) {
                    errors.error_message = 'Who made the header?'
                    return errors
                }

                if (!header_made) {
                    errors.error_message = 'Was the header successful?'
                    return errors
                }
            }

            if (isShotSaved(event_type) && !first_id) {
                errors.error_message = 'Who saved the shot?'
                return errors
            }

            if (isKeyPass(event_type) && !first_id) {
                errors.error_message = 'Who made the key pass?'
                return errors
            }
        }

        // ========== Substitutions ==========
        if (isSubstitution(event_type)) {
            if (!first_id) {
                errors.error_message = 'Who was the substitution for?'
                return errors
            }

            if (!values.second_id) {
                errors.error_message = 'Who was the substitution?'
                return errors
            }
        }

        return errors
    },
    typeOfGoal: values => {
        const errors = {}
        const { goal_type } = values

        if (!goal_type) {
            errors.error_message = 'What kind of goal was scored'
            return errors
        }

        return errors
    },
    first_id: event => values => {
        const errors = {}
        const { first_id } = values

        // ========== Goal Scored ==========
        if (isGoalScored(event) && !first_id) {
            errors.error_message = 'Who scored the goal?'
            return errors
        }

        // ========== Cards ==========
        if (isCard(event) && !first_id) {
            errors.error_message = 'Who was the card shown to?'
            return errors
        }

        // ========== Technical ==========
        if (isTechnical(event)) {
            if (isTackle(event) && !first_id) {
                errors.error_message = 'Who made the tackle?'
                return errors
            }

            if (isShot(event) && !first_id) {
                errors.error_message = 'Who made the shot?'
                return errors
            }

            if (isInterception(event) && !first_id) {
                errors.error_message = 'Who made the interception?'
                return errors
            }

            if (isHeader(event) && !first_id) {
                errors.error_message = 'Who made the header?'
                return errors
            }

            if (isShotSaved(event) && !first_id) {
                errors.error_message = 'Who saved the shot?'
                return errors
            }

            if (isKeyPass(event) && !first_id) {
                errors.error_message = 'Who made the key pass?'
                return errors
            }
        }

        // ========== Substitutions ==========
        if (isSubstitution(event) && !first_id) {
            errors.error_message = 'Who was the substitution for?'
            return errors
        }

        return errors
    },
    second_id: event => values => {
        const errors = {}
        const { second_id } = values

        if (isSubstitution(event) && !second_id) {
            errors.error_message = 'Who was the substitution?'
            return errors
        }

        return errors
    },
    extraStep: event => values => {
        const errors = {}

        // ========== Goal Scored ==========
        if (isGoalScored(event)) {
            const { goal_type } = values
            // We Need The Scorer Only
            const { goal_location, goal_scored_with, goal_scored_from } = values

            if (goal_type === 'open-play') {
                if (!goal_scored_from) {
                    errors.error_message = 'What resulted in the goal?'
                    return errors
                }
                if (!goal_location) {
                    errors.error_message = 'Where was the goal scored from?'
                    return errors
                }

                if (!goal_scored_with) {
                    errors.error_message = 'How was the goal scored?'
                    return errors
                }
            }
        }

        return errors
    },
}

export default validation
