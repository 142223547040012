import { Timestamp, arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from './firebase'

//Add data to Cloud Firestore v9
export const addMessageToChat = async (chatId, message, userId) => {
    try {
        const chatMessage = {
            message,
            userId,
            time: Timestamp.now(),
        }
        return await updateDoc(doc(db, 'chats', chatId), {
            messages: arrayUnion(chatMessage),
        })
    } catch (error) {
        return error
    }
}

// Update last message in conversation
export const updateLastMessage = async (conversationId, message, messageBy) => {
    try {
        return await updateDoc(doc(db, 'conversations', conversationId), {
            lastMessage: message,
            lastMessageBy: messageBy,
            time: Timestamp.now(),
            members: arrayUnion(messageBy),
            isRead: [messageBy],
            delivered: [],
        })
    } catch (error) {
        return error
    }
}

// Add conversation to conversations
export const addNewConversation = async conversationId => {
    try {
        const conversation = {
            isRead: [],
            lastMessage: null,
            squadId: conversationId,
        }
        return await updateDoc(doc(db, 'conversations', conversationId), conversation)
    } catch (error) {
        return error
    }
}
