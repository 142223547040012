import Toast from 'components/shared/Toast'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

const NotificationPush = ({ addToast }) => {
    const user = useSelector(st => st.user)
    useEffect(() => {
        try {
            window.Pusher = Pusher
            window.Echo = new Echo({
                broadcaster: process.env.REACT_APP_BROADCAST_DRIVER,
                key: process.env.REACT_APP_PUSHER_APP_KEY,
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                encrypted: true,
                forceTLS: true,
            })
        } catch (e) {
            console.log('error ' + e)
        }
        listen()
    }, [])

    const listen = async () => {
        user &&
            window.Echo
                .channel(`user.${user.id}`)
                .listen('ToastPusher', ({ message }) => {
                    const { title, body, photo } = message
                    addToast(<>{body}</>, {
                        status: 'success',
                        title: title,
                        photo: photo || null,
                        autoDismiss: true,
                    })
                })
    }

    return null
}

export default Toast(NotificationPush)
