const defaultValue = [
    { value: 'home', label: 'Home'},
    { value: 'away', label: 'Away'},
    { value: 'neutral', label: 'Neutral'}
];

const matchGround = (state = defaultValue, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default matchGround