import moment from 'moment'
import React from 'react'

const TrainingTitle = ({ training }) => {
    const { is_custom, module, title } = training

    return module.name === 'custom' ? (
        <h2 className="mt-1 m-b-0 lh-1 bold">
            {title}
        </h2>
    ) : (
        is_custom ? 
        <h2 className="mt-1 m-b-0 lh-1 bold">
            {title}
        </h2>
        :
        <h2 className="mt-1 m-b-0 lh-1 bold">
            {module.label}
        </h2>
    )
}

export default TrainingTitle

{/* {moment.utc(training?.start_time).tz(training?.location?.timezone).format('dddd, H:mm A')} */}