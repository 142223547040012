import classNames from 'classnames'
import { isUrl } from 'utils'
import { shield } from './svg/svgs'

const Avatar = ({
    src = null,
    alt = '?',
    width = 80,
    height = 80,
    size = 0,
    boxed = false,
    className,
    children,
    style,
    // log = false,
    ...props
}) => {
    const styles = { width: size || width, minWidth: size || width, height: size || height, minHeight: size || height, ...style, borderRadius: boxed ? '4px' : '50%' }

    return (
        <div
            // width={size || width}
            // height={size || height}
            className={classNames('avatar', className)}
            {...props}
            style={styles}>
            {isUrl(src) ? (
                <img src={src} alt={alt} />
            ) : children ? (
                children
            ) : boxed ? (
                <div className="d-flex align-items-center justify-content-center fs-3">?</div>
            ) : (
                <svg
                    viewBox="-50 -50 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        backgroundColor: '#ccc',
                    }}
                    {...props}>
                    <text
                        fontFamily="scouty-regular"
                        fontSize="50px"
                        fontWeight="normal"
                        fill="#111"
                        dy="0.35em"
                        textAnchor="middle">
                        {alt ? alt.charAt(0).toUpperCase() : '?'}
                    </text>
                </svg>
            )}
        </div>
    )
}
export default Avatar
