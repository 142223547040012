import OnBoarding from 'components/Dashboard/pages/OnBoarding'
import VerifyEmail from 'components/Layout/VerifyEmail'
import { hidePopup, showPopup } from 'components/store'
import { useDispatch, useSelector } from 'react-redux'

export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const USER_ONBOARDING = 'USER_ONBOARDING'

const GlobalPopup = () => {
    const dispatch = useDispatch()
    const { show, type } = useSelector(state => state.popup)

    const handleClose = () => dispatch(hidePopup())
    const handleShow = () => dispatch(showPopup())

    if (show) {
        switch (type) {
            case VERIFY_EMAIL:
                return <VerifyEmail show={show} handleShow={handleShow} handleClose={handleClose} />
            default:
                return <></>
        }
    }
}

export default GlobalPopup
