import React from 'react'
import { openMatchEventsModal, closeMatchEventsModal } from 'components/store'
import { BottomSideDrawer } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { MatchEvents } from '../MatchEvents'

const MatchEventsModal = () => {
    const dispatch = useDispatch()
    const { showMatchEventsModal } = useSelector(st => st.fixture)

    const openDrawer = () => dispatch(openMatchEventsModal())
    const closeDrawer = () => dispatch(closeMatchEventsModal())

    return (
        <BottomSideDrawer
            open={showMatchEventsModal}
            onClose={closeDrawer}
            backdropClassName="z-1055"
            className="z-1060 h-unset"
            backdrop={true}>
            <MatchEvents deleteable tactical />
        </BottomSideDrawer>
    )
}

export default MatchEventsModal
