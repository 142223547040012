import { resetFixture, resetSelectedFixture } from 'components/store'
import { useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { ChevronLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getAllSearchParams, getMatchRoles, isBrowser } from 'utils'
import useFixturePusher from 'utils/useFixturePusher'
import ChangeLineup from './ChangeLineup'
import EditMatch from './EditMatch'
import { AddEvent } from './Event'
import EventDrawer from './Event/AddEventForm/EventDrawer'
import RatePlayers from './Event/RatePlayerForm/RatePlayers'
import FixtureLeft from './FixtureLeft'
import FixtureMenu from './FixtureMenu'
import FixtureRight from './FixtureRight'
import FixtureTop from './FixtureTop'
import MatchTimer from './MatchTimer/MatchTimer'
import MatchEventsModal from './Event/AddEventForm/MatchEventsModal'
import { amInLineUp } from 'components/shared/shared2'
import { matchIsMoreThanThreeHoursAway } from 'components/shared/shared2'
import RSVPMatch from './RSVPMatch'

const FixtureBody = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)
    const {
        isLoading,
        squadSlug,
        changeLineup: showModal,
        // select0ed: selectedFixture,
        data: fixture,
    } = useSelector(state => state.fixture)
    const { date, is_cancelled } = fixture
    const { clubSquadDetail: squad, user } = useSelector(state => state)

    const { isManager, isStatLogger, isPlayer } = useMemo(() => getMatchRoles(fixture), [fixture])

    const handleScroll = event => {
        const navbar = document.querySelector('.fixture-top')
        const width = isBrowser() ? window.innerWidth : 0
        const scrollHeight = width >= 768 ? 0 : 100
        const sticky = event.target.scrollTop > scrollHeight
        if (sticky) return navbar.classList.add('sticky')
        // return navbar.classList.remove('sticky')
        return setTimeout(() => navbar.classList.remove('sticky'), 1)
    }

    const handleClose = () => {
        const { match, ...rest } = params
        setSearchParams(rest)
        dispatch(resetSelectedFixture())
        dispatch(resetFixture())
    }

    useFixturePusher(squadSlug, date)

    if (isLoading) return null

    return (
        <>
            <FixtureTop handleClose={handleClose} />
            {isManager && !is_cancelled && (
                <>
                    <FixtureMenu />
                    <RatePlayers />
                    {/* <AddEvent /> */}
                    <MatchEventsModal />
                    <EventDrawer />
                    <MatchTimer />
                    <EditMatch />
                    {showModal && <ChangeLineup />}
                </>
            )}
            <div className="fixture-body-wrapper" onScroll={handleScroll}>
                <div className="fixture-body-content">
                    <Container className="h-100 px-0">
                        <div className="d-flex h-100 py-md-3 gap-3 align-flex-start px-2 sm-padding-0 position-relative">
                            <button type="button" onClick={handleClose} className="btn-close d-md-none">
                                <ChevronLeft size={32} />
                                <span>Back</span>
                            </button>
                            <FixtureLeft className="d-none" />
                            <FixtureRight />
                        </div>
                    </Container>
                </div>
            </div>
            {amInLineUp(fixture, user) && !is_cancelled && matchIsMoreThanThreeHoursAway(fixture) && (
                <RSVPMatch playerInLineup={amInLineUp(fixture, user)} />
            )}
        </>
    )
}
export default FixtureBody
