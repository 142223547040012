import moment from 'moment'

const SET_FIXTURE_DATA = 'SET_FIXTURE_DATA'
const SET_FIXTURE_LOADING = 'SET_FIXTURE_LOADING'
const RESET_FIXTURE_LOADING = 'RESET_FIXTURE_LOADING'
const RESET_FIXTURE_DATA = 'RESET_FIXTURE_DATA'
const SET_FIXTURE_SELECTED = 'SET_FIXTURE_SELECTED'
const RESET_FIXTURE_SELECTED = 'RESET_FIXTURE_SELECTED'
const SET_FIXTURE_ADD_EVENT = 'SET_FIXTURE_ADD_EVENT'
const RESET_FIXTURE_ADD_EVENT = 'RESET_FIXTURE_ADD_EVENT'
const SET_FIXTURE_EDIT_MATCH = 'SET_FIXTURE_EDIT_MATCH'
const RESET_FIXTURE_EDIT_MATCH = 'RESET_FIXTURE_EDIT_MATCH'
const SET_FIXTURE_CHANGE_LINEUP = 'SET_FIXTURE_CHANGE_LINEUP'
const RESET_FIXTURE_CHANGE_LINEUP = 'RESET_FIXTURE_CHANGE_LINEUP'
const MATCH_EVENTS_OPENED = 'MATCH_EVENTS_OPENED'
const MATCH_EVENTS_CLOSED = 'MATCH_EVENTS_CLOSED'
const MATCH_EVENTS_TOGGLED = 'MATCH_EVENTS_TOGGLED'
const MATCH_TIMER_SHOWED = 'MATCH_TIMER_SHOWED'
const MATCH_TIMER_HIDDEN = 'MATCH_TIMER_HIDDEN'
const MATCH_EVENTS_MODAL_SHOWED = 'MATCH_EVENTS_MODAL_SHOWED'
const MATCH_EVENTS_MODAL_HIDDEN = 'MATCH_EVENTS_MODAL_HIDDEN'
const MATCH_EVENTS_MODAL_TOGGLED = 'MATCH_EVENTS_MODAL_TOGGLED'

const initialState = {
    data: {},
    squadSlug: null,
    isLoading: false,
    selected: null,
    addEvent: false,
    selectedEvent: '',
    matchEvents: false,
    editMatch: false,
    changeLineup: false,
    showTimer: false,
    showMatchEventsModal: false
}

const fixtureReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIXTURE_DATA:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case SET_FIXTURE_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_FIXTURE_LOADING:
            return {
                ...state,
                isLoading: false,
            }
        case RESET_FIXTURE_DATA:
            return initialState
        case SET_FIXTURE_SELECTED:
            return {
                ...state,
                selected: action.payload.date,
                squadSlug: action.payload.squadSlug,
            }
        case RESET_FIXTURE_SELECTED:
            return {
                ...state,
                selected: null,
                squadSlug: null,
            }
        case SET_FIXTURE_ADD_EVENT:
            return {
                ...state,
                addEvent: moment.utc().format(),
                selectedEvent: action.payload,
            }
        case RESET_FIXTURE_ADD_EVENT:
            return {
                ...state,
                addEvent: false,
                selectedEvent: '',
            }
        case MATCH_EVENTS_OPENED:
            return {
                ...state,
                matchEvents: true,
            }
        case MATCH_EVENTS_CLOSED:
            return {
                ...state,
                matchEvents: false,
            }
        case MATCH_EVENTS_TOGGLED:
            return {
                ...state,
                matchEvents: !state.matchEvents,
            }
        case SET_FIXTURE_EDIT_MATCH:
            return {
                ...state,
                editMatch: true,
            }
        case RESET_FIXTURE_EDIT_MATCH:
            return {
                ...state,
                editMatch: false,
            }
        case SET_FIXTURE_CHANGE_LINEUP:
            return {
                ...state,
                changeLineup: true,
            }
        case RESET_FIXTURE_CHANGE_LINEUP:
            return {
                ...state,
                changeLineup: false,
            }
        case MATCH_TIMER_SHOWED:
            return {
                ...state,
                showTimer: true,
            }
        case MATCH_TIMER_HIDDEN:
            return {
                ...state,
                showTimer: false,
            }
        case MATCH_EVENTS_MODAL_SHOWED:
            return {
                ...state,
                showMatchEventsModal: true,
            }
        case MATCH_EVENTS_MODAL_HIDDEN:
            return {
                ...state,
                showMatchEventsModal: false,
            }
        default:
            return state
    }
}
export default fixtureReducer

// Fixture
export const loadFixture = data => ({
    type: SET_FIXTURE_DATA,
    payload: data,
})

export const loadingFixture = () => ({
    type: SET_FIXTURE_LOADING,
})

export const resetLoadingFixture = () => ({
    type: RESET_FIXTURE_LOADING,
})

export const resetFixture = () => ({
    type: RESET_FIXTURE_DATA,
})

export const setSelectedFixture = (date, squadSlug) => ({
    type: SET_FIXTURE_SELECTED,
    payload: { date, squadSlug },
})

export const resetSelectedFixture = () => ({
    type: RESET_FIXTURE_SELECTED,
})

export const openAddEvent = event => ({
    type: SET_FIXTURE_ADD_EVENT,
    payload: event,
})

export const openMatchEventsModal = () => ({
    type: MATCH_EVENTS_MODAL_SHOWED,
})

export const closeMatchEventsModal = () => ({
    type: MATCH_EVENTS_MODAL_HIDDEN,
})

export const closeAddEvent = () => ({
    type: RESET_FIXTURE_ADD_EVENT,
})

export const openEditMatch = () => ({
    type: SET_FIXTURE_EDIT_MATCH,
})

export const closeEditMatch = () => ({
    type: RESET_FIXTURE_EDIT_MATCH,
})

export const openChangeLineup = () => ({
    type: SET_FIXTURE_CHANGE_LINEUP,
})

export const closeChangeLineup = () => ({
    type: RESET_FIXTURE_CHANGE_LINEUP,
})

export const openMatchEvents = () => ({
    type: MATCH_EVENTS_OPENED,
})

export const closeMatchEvents = () => ({
    type: MATCH_EVENTS_CLOSED,
})

export const toggleMatchEvents = () => ({
    type: MATCH_EVENTS_TOGGLED,
})

export const showMatchTimer = () => ({
    type: MATCH_TIMER_SHOWED,
})

export const hideMatchTimer = () => ({
    type: MATCH_TIMER_HIDDEN,
})

// Load fixture players
export const loadFixturePlayers = players => (dispatch, getState) => {
    const { fixture } = getState()
    const updatedFixture = { ...fixture.data, players }
    dispatch(loadFixture(updatedFixture))
}

export const updateFixtureDataHomeGoal = data => (dispatch, getState) => {
    const { fixture } = getState()
    const updatedFixture = { ...fixture.data, home_goals: data }
    dispatch(loadFixture(updatedFixture))
}

export const updateFixtureDataAwayGoal = data => (dispatch, getState) => {
    const { fixture } = getState()
    const updatedFixture = { ...fixture.data, away_goals: data }
    dispatch(loadFixture(updatedFixture))
}
