const utils = {
    months: [
        { label: 'Jan', value: '01' },
        { label: 'Feb', value: '02' },
        { label: 'Mar', value: '03' },
        { label: 'Apr', value: '04' },
        { label: 'May', value: '05' },
        { label: 'Jun', value: '06' },
        { label: 'Jul', value: '07' },
        { label: 'Aug', value: '08' },
        { label: 'Sep', value: '09' },
        { label: 'Oct', value: '10' },
        { label: 'Nov', value: '11' },
        { label: 'Dec', value: '12' },
    ],
    yearRange: () => {
        const now = new Date().getUTCFullYear()
        return Array(now - (now - 80))
            .fill('')
            .map((v, idx) => now - idx)
    },
    footed: [
        { label: 'Both', value: 'both' },
        { label: 'Left', value: 'left' },
        { label: 'Right', value: 'right' },
    ],
    gender: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
    ],
    teamGender: [
        { label: 'Co-ed', value: 'co-ed' },
        { label: 'Mens', value: 'mens' },
        { label: 'Womens', value: 'womens' },
    ],
    yesNo: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ],
    groupTypes: [
        { label: 'Coaching', value: 'coaching' },
        { label: 'Meetup', value: 'meetup' },
        { label: 'Single Game', value: 'singlegame' },
    ],
    verificationTypes: [
        { label: 'Passport', value: 'passport' },
        { label: 'Drivers license', value: 'drivers-license' },
        { label: 'State or Province ID', value: 'state-id' },
        { label: 'Other (Please specify)', value: 'other' },
    ],
    skillLevel: [
        { label: 'Beginner', value: 'beginner' },
        { label: 'Intermediate', value: 'intermediate' },
        { label: 'Advanced', value: 'advanced' },
    ],
    filterDistanceKm: [
        { label: '5km', value: 5 },
        { label: '10km', value: 10 },
        { label: '25km', value: 25 },
        { label: '50km', value: 50 },
        { label: '100km', value: 100 },
        { label: '200km', value: 200 },
        { label: '100km', value: 100 },
        { label: '200km', value: 200 },
    ],
    sports: [
        { label: 'Soccer (futsal/outdoor)', value: 'soccer' },
        { label: 'Basketball', value: 'basketball' },
        { label: 'American Football', value: 'american-football' },
        { label: 'Volleyball', value: 'volleyball' },
        { label: 'Ice Hockey', value: 'ice-hockey' },
    ],
    proLevels: [
        { label: 'Amateur', value: 'amateur' },
        { label: 'Semi-pro', value: 'semi-pro' },
        { label: 'Professional', value: 'professional' },
        { label: 'Elite', value: 'elite' },
    ],
    rosterType: [
        { label: 'Mens', value: 'mens' },
        { label: 'Womens', value: 'womens' },
        { label: 'Co-ed', value: 'co-ed' },
    ],
    ageGroups: [
        // { label: '15–19', value: '15–19' },
        { label: '16-25', value: '16-25' },
        { label: '25–29', value: '25–29' },
        { label: '30–34', value: '30–34' },
        { label: '35–39', value: '35–39' },
        { label: '40–49', value: '40–49' },
        { label: '50–59', value: '50–59' },
        { label: '60–69', value: '60–69' },
        { label: '70+', value: '70+' },
    ],
    height: [
        { value: 122, label: '122cm / 4ft-0 in' },
        { value: 123, label: '123cm / 4ft-0.4 in' },
        { value: 124, label: '124cm / 4ft-0.8 in' },
        { value: 125, label: '125cm / 4ft-1.2 in' },
        { value: 126, label: '126cm / 4ft-1.6 in' },
        { value: 127, label: '127cm / 4ft-2 in' },
        { value: 128, label: '128cm / 4ft-2.4 in' },
        { value: 129, label: '129cm / 4ft-2.8 in' },
        { value: 130, label: '130cm / 4ft-3.2 in' },
        { value: 131, label: '131cm / 4ft-3.6 in' },
        { value: 132, label: '132cm / 4ft-4 in' },
        { value: 133, label: '133cm / 4ft-4.4 in' },
        { value: 134, label: '134cm / 4ft-4.8 in' },
        { value: 135, label: '135cm / 4ft-5.1 in' },
        { value: 136, label: '136cm / 4ft-5.5 in' },
        { value: 137, label: '137cm / 4ft-5.9 in' },
        { value: 138, label: '138cm / 4ft-6.3 in' },
        { value: 139, label: '139cm / 4ft-6.7 in' },
        { value: 140, label: '140cm / 4ft-7.1 in' },
        { value: 141, label: '141cm / 4ft-7.5 in' },
        { value: 142, label: '142cm / 4ft-7.9 in' },
        { value: 143, label: '143cm / 4ft-8.3 in' },
        { value: 144, label: '144cm / 4ft-8.7 in' },
        { value: 145, label: '145cm / 4ft-9.1 in' },
        { value: 146, label: '146cm / 4ft-9.5 in' },
        { value: 147, label: '147cm / 4ft-9.9 in' },
        { value: 148, label: '148cm / 4ft-10.3 in' },
        { value: 149, label: '149cm / 4ft-10.7 in' },
        { value: 150, label: '150cm / 4ft-11.1 in' },
        { value: 151, label: '151cm / 4ft-11.4 in' },
        { value: 152, label: '152cm / 4ft-11.8 in' },
        { value: 153, label: '153cm / 5ft-0.2 in' },
        { value: 154, label: '154cm / 5ft-0.6 in' },
        { value: 155, label: '155cm / 5ft-1 in' },
        { value: 156, label: '156cm / 5ft-1.4 in' },
        { value: 157, label: '157cm / 5ft-1.8 in' },
        { value: 158, label: '158cm / 5ft-2.2 in' },
        { value: 159, label: '159cm / 5ft-2.6 in' },
        { value: 160, label: '160cm / 5ft-3 in' },
        { value: 161, label: '161cm / 5ft-3.4 in' },
        { value: 162, label: '162cm / 5ft-3.8 in' },
        { value: 163, label: '163cm / 5ft-4.2 in' },
        { value: 164, label: '164cm / 5ft-4.6 in' },
        { value: 165, label: '165cm / 5ft-5 in' },
        { value: 166, label: '166cm / 5ft-5.4 in' },
        { value: 167, label: '167cm / 5ft-5.7 in' },
        { value: 168, label: '168cm / 5ft-6.1 in' },
        { value: 169, label: '169cm / 5ft-6.5 in' },
        { value: 170, label: '170cm / 5ft-6.9 in' },
        { value: 171, label: '171cm / 5ft-7.3 in' },
        { value: 172, label: '172cm / 5ft-7.7 in' },
        { value: 173, label: '173cm / 5ft-8.1 in' },
        { value: 174, label: '174cm / 5ft-8.5 in' },
        { value: 175, label: '175cm / 5ft-8.9 in' },
        { value: 176, label: '176cm / 5ft-9.3 in' },
        { value: 177, label: '177cm / 5ft-9.7 in' },
        { value: 178, label: '178cm / 5ft-10.1 in' },
        { value: 179, label: '179cm / 5ft-10.5 in' },
        { value: 180, label: '180cm / 5ft-10.9 in' },
        { value: 181, label: '181cm / 5ft-11.3 in' },
        { value: 182, label: '182cm / 5ft-11.7 in' },
        { value: 183, label: '183cm / 6ft-0 in' },
        { value: 184, label: '184cm / 6ft-0.4 in' },
        { value: 185, label: '185cm / 6ft-0.8 in' },
        { value: 186, label: '186cm / 6ft-1.2 in' },
        { value: 187, label: '187cm / 6ft-1.6 in' },
        { value: 188, label: '188cm / 6ft-2 in' },
        { value: 189, label: '189cm / 6ft-2.4 in' },
        { value: 190, label: '190cm / 6ft-2.8 in' },
        { value: 191, label: '191cm / 6ft-3.2 in' },
        { value: 192, label: '192cm / 6ft-3.6 in' },
        { value: 193, label: '193cm / 6ft-4 in' },
        { value: 194, label: '194cm / 6ft-4.4 in' },
        { value: 195, label: '195cm / 6ft-4.8 in' },
        { value: 196, label: '196cm / 6ft-5.2 in' },
        { value: 197, label: '197cm / 6ft-5.6 in' },
        { value: 198, label: '198cm / 6ft-6 in' },
        { value: 199, label: '199cm / 6ft-6.3 in' },
        { value: 200, label: '200cm / 6ft-6.7 in' },
        { value: 201, label: '201cm / 6ft-7.1 in' },
    ],
}

export default utils
