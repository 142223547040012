import ls from 'components/shared/LocalStorage'
import { sortPlayers } from 'components/shared/shared2'
import { LS_USER_DATA } from 'constants'
import { getRequest, logout as logoutUser } from 'services'
import { CLEAR_USER_STORE, setUser, verifyEmailClear } from '../reducers'
import {
    ADD_BLOCKED_MEMBERS_TO_GROUP,
    ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE,
    ADD_EXISTING_MEMBERS_TO_GROUP,
    ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE,
    ADD_PAST_EVENTS_TO_GROUP,
    ADD_PENDING_MEMBERS_TO_GROUP,
    ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE,
    ADD_TO_LINEUP,
    ADD_UPCOMING_EVENTS_TO_GROUP,
    CLEAR_CLUB_SQUAD_DETAIL_DATA,
    DELETE_FROM_LINEUP,
    EMPTY_GROUP_DETAIL_DATA,
    FILTER_LINEUP,
    FILTER_SUBSTITUTUIONS,
    HAS_NOTIFICATION_DATA,
    LOADER,
    LOAD_CLUB_DETAIL_DATA,
    LOAD_GROUP_DETAIL_DATA,
    LOGIN_MODAL,
    RESET_LINEUP,
    RESET_SUBSTITUTIONS,
    SET_CLUB_DETAIL_DATA,
    SET_CLUB_SQUAD_DETAIL_DATA,
    SET_EVENT_DETAIL_DATA,
    SET_EVENT_ONLY_DETAIL_DATA,
    SET_FORMATION_PICKER_DEFAULT_VALUES,
    SET_GROUP_DETAIL_DATA,
    SET_IS_CUSTOM_FORMATION,
    SET_LINEUP,
    SET_LOCATION_DATA,
    SET_MASTER_DATA,
    SET_MATCH_DETAIL_DATA,
    SET_NEW_FORMATION,
    SET_SEARCH_CLUBS_DATA,
    SET_SEARCH_GAMES_DATA,
    SET_SEARCH_GROUPS_DATA,
    SET_SEARCH_PLAYERS_DATA,
    SET_SQUAD_PLAYER_DATA,
    SET_STAFF_DATA,
    SET_SUBSTITUTIONS,
    SIDEBAR,
    SIDEBAR_PIN,
    TOGGLE_PIN,
} from './actionTypes'

export const setMasterData = data => ({
    type: SET_MASTER_DATA,
    payload: data,
})
export const showLoader = data => ({
    type: LOADER,
    payload: data,
})
export const setMatchDetailData = data => ({
    type: SET_MATCH_DETAIL_DATA,
    payload: data,
})
export const showLoginModal = data => ({
    type: LOGIN_MODAL,
    payload: data,
})
export const setLocale = data => ({
    type: SET_LOCATION_DATA,
    payload: data,
})
export const setStaff = data => ({
    type: SET_STAFF_DATA,
    payload: data,
})
export const showSidebar = data => ({
    type: SIDEBAR,
    payload: data,
})
export const pinSidebar = data => ({
    type: SIDEBAR_PIN,
    payload: data,
})
export const toggleChat = () => ({
    type: TOGGLE_PIN,
})
export const ringBell = data => ({
    type: HAS_NOTIFICATION_DATA,
    payload: data,
})
export const updateEventDetail = data => ({
    type: SET_EVENT_DETAIL_DATA,
    payload: data,
})
export const updateOnlyEventDetailData = data => ({
    type: SET_EVENT_ONLY_DETAIL_DATA,
    payload: data,
})
export const updateClubDetail = data => ({
    type: SET_CLUB_DETAIL_DATA,
    payload: data,
})
export const updateClubSquadDetail = data => ({
    type: SET_CLUB_SQUAD_DETAIL_DATA,
    payload: data,
})
export const updateSquadPlayers = data => ({
    type: SET_SQUAD_PLAYER_DATA,
    payload: data,
})
export const resetClubSquadDetail = () => ({
    type: CLEAR_CLUB_SQUAD_DETAIL_DATA,
})

export const loadClubDetail = data => ({
    type: LOAD_CLUB_DETAIL_DATA,
    payload: data,
})
export const loadGroupDetail = data => ({
    type: LOAD_GROUP_DETAIL_DATA,
    payload: data,
})
export const emptyGroupDetail = () => ({
    type: EMPTY_GROUP_DETAIL_DATA,
    payload: {},
})
export const updateGroupDetail = data => ({
    type: SET_GROUP_DETAIL_DATA,
    payload: data,
})
export const addUpcomingEventsToGroupDetail = data => ({
    type: ADD_UPCOMING_EVENTS_TO_GROUP,
    payload: data,
})
export const addPastEventsToGroupDetail = data => ({
    type: ADD_PAST_EVENTS_TO_GROUP,
    payload: data,
})
export const addExistingMembersToGroupDetail = data => ({
    type: ADD_EXISTING_MEMBERS_TO_GROUP,
    payload: data,
})
export const addMembersPendingToGroupDetail = data => ({
    type: ADD_PENDING_MEMBERS_TO_GROUP,
    payload: data,
})
export const addExistingMembersPaginateGroupDetail = data => ({
    type: ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE,
    payload: data,
})
export const addMembersPendingPaginateGroupDetail = data => ({
    type: ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE,
    payload: data,
})
export const addMembersBlockedToGroupDetail = data => ({
    type: ADD_BLOCKED_MEMBERS_TO_GROUP,
    payload: data,
})
export const addMembersBlockedPaginateGroupDetail = data => ({
    type: ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE,
    payload: data,
})
export const setSearchGroupData = data => ({
    type: SET_SEARCH_GROUPS_DATA,
    payload: data,
})
export const setSearchPlayerData = data => ({
    type: SET_SEARCH_PLAYERS_DATA,
    payload: data,
})
export const setSearchGameData = data => ({
    type: SET_SEARCH_GAMES_DATA,
    payload: data,
})
export const setSearchClubData = data => ({
    type: SET_SEARCH_CLUBS_DATA,
    payload: data,
})

// --------------------------------------------
// FORMATION PICKER
export const setFormationPickerDefaultValues = data => ({
    type: SET_FORMATION_PICKER_DEFAULT_VALUES,
    payload: data,
})
export const setLineup =
    (data, history = true) =>
    (dispatch, getState) => {
        const { positions } = getState().masterData
        const types = Object.keys(positions)

        return dispatch({
            type: SET_LINEUP,
            payload: {
                lineup: [...data].sort((a, b) => sortPlayers(types, a, b)),
                history,
            },
        })
    }
export const setSubstitutions = data => ({
    type: SET_SUBSTITUTIONS,
    payload: data,
})
export const resetLineup = () => ({
    type: RESET_LINEUP,
})
export const addToLineup = data => ({
    type: ADD_TO_LINEUP,
    payload: data,
})
export const deleteFromLineup = data => ({
    type: DELETE_FROM_LINEUP,
    payload: data,
})

export const setNewFormation = data => ({
    type: SET_NEW_FORMATION,
    payload: data,
})
export const setIsCustomFormation = data => ({
    type: SET_IS_CUSTOM_FORMATION,
    payload: data,
})
export const filterSubstitutions = data => ({
    type: FILTER_SUBSTITUTUIONS,
    payload: data,
})
export const filterLineup = data => ({
    type: FILTER_LINEUP,
    payload: data,
})
export const resetSubstitutions = () => ({
    type: RESET_SUBSTITUTIONS,
})
// --------------------------------------------
export const clearStore = () => dispatch => {
    dispatch({ type: CLEAR_USER_STORE })
    dispatch(verifyEmailClear())
    dispatch(resetClubSquadDetail())
}

export const setUserData = data => async dispatch => dispatch(setUser(data))

export const setLocData = data => {
    return async dispatch => {
        dispatch(setLocale(data))
    }
}

export const checkLoginStatus = () => {
    return async dispatch => {
        const loginCheckRequest = await getRequest(`/user/login-check`)
        if (!loginCheckRequest) return

        const { data: requestData, status } = loginCheckRequest
        if (status !== 200) return
        const { is_login, user, is_admin } = requestData

        if (!is_login) {
            dispatch(setMasterData(null))
            dispatch(clearStore())
            return
        }

        if (is_admin) {
            user.is_admin = true
        }

        dispatch(setUserData(user))
        ls.saveState(LS_USER_DATA, user, true) // true because we are saving this as JSON
        dispatch(loadMasterData())
    }
}

export const getUserLocale = () => {
    return dispatch => {
        return getRequest(`/get-locale`).then(res => {
            const { locale, staff } = res.data

            dispatch(setLocale(locale))
            dispatch(setStaff(staff))
        })
    }
}

export const logout = () => {
    return async dispatch => {
        await logoutUser().then(res => {
            dispatch(setMasterData(null))
            dispatch(clearStore())
        })
    }
}

export const setLoginModal = data => {
    return async dispatch => {
        dispatch(showLoginModal(data))
    }
}

export const updateEventDetailData = data => {
    return async dispatch => {
        dispatch(updateEventDetail(data))
    }
}

export const setSearchGroupsData = data => {
    return async dispatch => {
        dispatch(setSearchGroupData(data))
    }
}

export const setSearchPlayersData = data => {
    return async dispatch => {
        dispatch(setSearchPlayerData(data))
    }
}

export const setSearchGamesData = data => {
    return async dispatch => {
        dispatch(setSearchGameData(data))
    }
}

export const setSearchClubsData = data => {
    return async dispatch => {
        dispatch(setSearchClubData(data))
    }
}

export const loadMasterData = () => {
    return async dispatch => {
        try {
            await getRequest(`/master-data`).then(res => {
                dispatch(setMasterData(res.data))
            })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateGroupDetailData = data => {
    return async dispatch => {
        dispatch(updateGroupDetail(data))
    }
}

export const emptyGroupDetailData = data => {
    return async dispatch => {
        dispatch(emptyGroupDetail(data))
    }
}

export const updateGroupDetailUpcomingEvents = data => {
    return async dispatch => {
        dispatch(addUpcomingEventsToGroupDetail(data))
    }
}

export const updateGroupDetailPastEvents = data => {
    return async dispatch => {
        dispatch(addPastEventsToGroupDetail(data))
    }
}

export const updateExistingMembersToGroupDetail = (data, paginate = false) => {
    return async dispatch => {
        !paginate
            ? dispatch(addExistingMembersToGroupDetail(data))
            : dispatch(addExistingMembersPaginateGroupDetail(data))
    }
}

export const updateMembersPendingToGroupDetail = (data, paginate = false) => {
    return async dispatch => {
        !paginate
            ? dispatch(addMembersPendingToGroupDetail(data))
            : dispatch(addMembersPendingPaginateGroupDetail(data))
    }
}

export const updateMembersBlockedToGroupDetail = (data, paginate = false) => {
    return async dispatch => {
        !paginate
            ? dispatch(addMembersBlockedToGroupDetail(data))
            : dispatch(addMembersBlockedPaginateGroupDetail(data))
    }
}

export const setLoader = data => {
    return dispatch => dispatch(showLoader(data))
}

export const setSidebar = data => {
    return dispatch => {
        dispatch(pinSidebar(data))
    }
}

export const setBell = data => {
    return dispatch => dispatch(ringBell(data))
}
