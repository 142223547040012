import cloneDeep from 'lodash/cloneDeep'
import isBoolean from 'lodash/isBoolean'
import {
    ADD_TO_LINEUP,
    FILTER_LINEUP,
    FILTER_SUBSTITUTUIONS,
    RESET_LINEUP,
    RESET_SUBSTITUTIONS,
    SET_FORMATION_PICKER_DEFAULT_VALUES,
    SET_IS_CUSTOM_FORMATION,
    SET_LINEUP,
    SET_NEW_FORMATION,
    SET_SUBSTITUTIONS,
} from '../actions/actionTypes'

const SELECT_PLAYER = 'SELECT_PLAYER'
const ADD_SUBSTITUTUION = 'ADD_SUBSTITUTUION'
const PLAYER_POSITION_SELECTED = 'PLAYER_POSITION_SELECTED'
const PLAYER_POSITION_RESET = 'PLAYER_POSITION_RESET'
const DROP_DOWN_PLAYER_TOGGLED = 'DROP_DOWN_PLAYER_TOGGLED'
const DROP_DOWN_PLAYER_OPENED = 'DROP_DOWN_PLAYER_OPENED'
const DROP_DOWN_PLAYER_CLOSED = 'DROP_DOWN_PLAYER_CLOSED'
const SET_FORMATION_PLAYERS = 'SET_FORMATION_PLAYERS'
const SET_PREVIOUS_LINEUP = 'SET_PREVIOUS_LINEUP'
const RESET_FORMATION_PLAYERS = 'RESET_FORMATION_PLAYERS'
const PLAYERS_POSITION_PICKER_OPENED = 'PLAYERS_POSITION_PICKER_OPENED'
const PLAYERS_POSITION_PICKER_CLOSED = 'PLAYERS_POSITION_PICKER_CLOSED'
const PLAYERS_POSITION_PICKER_TOGGLED = 'PLAYERS_POSITION_PICKER_TOGGLED'
const PLAYERS_POSITION_MODAL_OPENED = 'PLAYERS_POSITION_MODAL_OPENED'
const PLAYERS_POSITION_MODAL_CLOSED = 'PLAYERS_POSITION_MODAL_CLOSED'
const PLAYERS_POSITION_MODAL_TOGGLED = 'PLAYERS_POSITION_MODAL_TOGGLED'
const ADD_FORMATION_HISTORY = 'ADD_FORMATION_HISTORY'
const RESET_FORMATION_HISTORY = 'RESET_FORMATION_HISTORY'
const UNDO_FORMATION_HISTORY = 'UNDO_FORMATION_HISTORY'
const REDO_FORMATION_HISTORY = 'REDO_FORMATION_HISTORY'

const initialState = {
    formation: undefined,
    lineup: [],
    substitutions: [],
    history: [],
    historyIndex: -1,
    isCustom: false,
    selectedPlayerPosition: false,
    showSubBench: false,
    players: [],
    previousLineup: null,
    playersPositionPicker: false,
    playersPositionModal: false,
}

const reducer = (state = cloneDeep(initialState), action) => {
    let newLineup = []
    let subsArray = []

    switch (action.type) {
        case SET_LINEUP: {
            if (!action.payload.history) {
                return {
                    ...state,
                    lineup: action.payload.lineup,
                }
            }

            const history = getHistory(state, isBoolean(action.payload.history) ? false : action.payload.history)
            const historyIndex = history.length
            return {
                ...state,
                lineup: action.payload.lineup,
                history,
                historyIndex,
            }
        }
        case RESET_LINEUP:
            return initialState
        case SET_SUBSTITUTIONS:
            return {
                ...state,
                substitutions: action.payload,
            }
        case RESET_SUBSTITUTIONS:
            return {
                ...state,
                substitutions: [],
            }
        case SET_FORMATION_PICKER_DEFAULT_VALUES:
            return {
                ...action.payload,
            }
        case SELECT_PLAYER: {
            const history = getHistory(state)
            const historyIndex = history.length

            let currentLineUp = [...state.lineup]
            let currentSubs = [...state.substitutions]
            const hasPlayer = action.payload.player
            if (!hasPlayer) {
                delete currentLineUp[action.payload.node].player
                return {
                    ...state,
                    lineup: currentLineUp,
                    history,
                    historyIndex,
                }
            }
            // check if player is already in lineup
            const found = currentLineUp.find(item => item.player && item.player.id === action.payload.player.id)
            // find the player in the substitutions
            const foundSub = currentSubs.find(item => item.player && item.player.id === action.payload.player.id)
            // if player is not in lineup add them
            if (!found && !foundSub) {
                currentLineUp[action.payload.node].player = action.payload.player
            } else {
                // get the current node player
                const currentNodePlayer = currentLineUp[action.payload.node].player
                // find the index of the player in the lineup
                const foundIndex = found
                    ? currentLineUp.findIndex(item => item.player && item.player.id === action.payload.player.id)
                    : currentSubs.findIndex(item => item.player && item.player.id === action.payload.player.id)
                // if the current node has a player
                if (!currentNodePlayer) {
                    // if current node is empty add player to current node
                    currentLineUp[action.payload.node].player = action.payload.player
                    // remove player from old node by finding the index of the player and setting it to null
                    if (found) {
                        delete currentLineUp[foundIndex].player
                    } else {
                        currentSubs = currentSubs.filter(
                            item => item.player && item.player.id !== action.payload.player.id
                        )
                        // has player in subs
                        if (currentSubs.length > 0) {
                            currentSubs = currentSubs.map((item, index) => ({
                                ...item,
                                position: `SUB-${index + 1}`,
                            }))
                        }
                    }
                } else {
                    // if it has a player in the current node swap the players
                    // swap the players
                    currentLineUp[action.payload.node].player = action.payload.player
                    if (found) {
                        currentLineUp[foundIndex].player = currentNodePlayer
                    } else {
                        currentSubs[foundIndex].player = currentNodePlayer
                    }
                }
            }

            return {
                ...state,
                lineup: currentLineUp,
                substitutions: currentSubs,
                history,
                historyIndex,
            }
        }
        case SET_NEW_FORMATION: {
            const isHistory = action.payload?.history
            if (isHistory) {
                const history = getHistory(state)
                const historyIndex = history.length
                return {
                    ...state,
                    formation: action.payload.formation,
                    history,
                    historyIndex,
                }
            }
            return {
                ...state,
                formation: action.payload.formation,
            }
        }
        case ADD_TO_LINEUP: {
            const draggedItem = state.lineup.find(item => item.position === action.payload.draggedItem.position)
            const slotItem = state.lineup.find(item => item.position === action.payload.slotItem.position)
            let slotTemp = slotItem
            let dragTemp = draggedItem

            if (draggedItem && slotItem) {
                newLineup = state.lineup
                    .filter(item => item.position !== slotItem.position)
                    .filter(item => item.position !== draggedItem.position)

                newLineup.push({ ...action.payload.draggedItem, position: slotTemp.position })
                newLineup.push({ ...action.payload.slotItem, position: dragTemp.position })
            }

            if (!slotItem) {
                newLineup = state.lineup.filter(item => item.position !== action.payload.draggedItem.position)
                newLineup.push({ ...action.payload.draggedItem, position: action.payload.slotItem.position })
            }

            return {
                ...state,
                lineup: newLineup,
            }
        }
        case FILTER_LINEUP:
            newLineup = state.lineup.map(item => {
                if (item.player && item.player.id === action.payload.id) return { position: item.position }
                return item
            })
            return {
                ...state,
                // lineup: newLineup,
            }
        case ADD_SUBSTITUTUION: {
            const history = getHistory(state)
            const historyIndex = history.length
            const currentLineUp = [...state.lineup]
            let currentSubs = [...state.substitutions]
            // has current node in the subs array
            const hasCurrentNode = currentSubs.find(item => item.position === action.payload.position)

            const hasPlayer = action.payload.player
            // if no player remove the player from the sub position
            if (!hasPlayer) {
                if (hasCurrentNode) {
                    currentSubs = currentSubs
                        .filter(item => item.position !== action.payload.position)
                        .map((item, index) => ({
                            ...item,
                            position: `SUB-${index + 1}`,
                        }))
                }
                return {
                    ...state,
                    substitutions: currentSubs,
                    history,
                    historyIndex,
                }
            }
            // check if player is already in lineup
            const foundLineUp = currentLineUp.find(item => item.player && item.player.id === action.payload.player.id)
            // find the player in the substitutions
            const foundSubBench = currentSubs.find(item => item.player && item.player.id === action.payload.player.id)
            // if player is not in lineup or subs
            if (!foundLineUp && !foundSubBench) {
                // find the index of the sub position
                const subIndex = currentSubs.findIndex(sub => sub.position === action.payload.position)
                // if the sub position already has a player
                if (subIndex !== -1) {
                    // if subs array is full return
                    if (action.payload?.insert && currentSubs.length === 10) return state
                    // add the player to the subs array
                    currentSubs.splice(subIndex, action.payload?.insert ? 0 : 1, {
                        position: action.payload.position,
                        player: action.payload.player,
                    })
                    // return the new subs array
                    return {
                        ...state,
                        substitutions: currentSubs.map((item, index) => ({
                            ...item,
                            position: `SUB-${index + 1}`,
                        })),
                        history,
                        historyIndex,
                    }
                }
                const subNum = currentSubs.length + 1
                currentSubs.push({
                    position: `SUB-${subNum}`,
                    player: action.payload.player,
                })
            } else {
                // find the index of the player in the lineup or subs
                const foundIndex = foundLineUp
                    ? currentLineUp.findIndex(item => item.player && item.player.id === action.payload.player.id)
                    : currentSubs.findIndex(item => item.player && item.player.id === action.payload.player.id)
                // if the current node has no player
                if (!hasCurrentNode) {
                    // if the player is in the lineup remove him
                    if (foundLineUp) {
                        // remove player from lineup
                        delete currentLineUp[foundIndex].player
                        const subNum = currentSubs.length + 1
                        // add player to subs
                        currentSubs.push({
                            position: `SUB-${subNum}`,
                            player: action.payload.player,
                        })
                    }
                } else {
                    // swap the players
                    if (foundLineUp) {
                        const foundPlayer = currentLineUp[foundIndex].player
                        currentLineUp[foundIndex].player = hasCurrentNode.player
                        currentSubs.map(item => {
                            if (item.position === action.payload.position) {
                                item.player = foundPlayer
                            }
                            return item
                        })
                    } else {
                        const currentIndex = currentSubs.findIndex(item => item.position === action.payload.position)
                        currentSubs.splice(foundIndex, 1)
                        currentSubs.splice(currentIndex, 0, {
                            position: action.payload.position,
                            player: action.payload.player,
                        })
                        return {
                            ...state,
                            substitutions: currentSubs.map((item, index) => ({
                                ...item,
                                position: `SUB-${index + 1}`,
                            })),
                            history,
                            historyIndex,
                        }
                    }
                }
            }
            return {
                ...state,
                substitutions: currentSubs,
                lineup: currentLineUp,
                history,
                historyIndex,
            }
        }
        case PLAYERS_POSITION_PICKER_OPENED:
            return {
                ...state,
                playersPositionPicker: true,
            }
        case PLAYERS_POSITION_PICKER_CLOSED:
            return {
                ...state,
                playersPositionPicker: false,
            }
        case PLAYERS_POSITION_PICKER_TOGGLED:
            return {
                ...state,
                playersPositionPicker: !state.playersPositionPicker,
            }
        case PLAYERS_POSITION_MODAL_OPENED:
            return {
                ...state,
                playersPositionModal: true,
            }
        case PLAYERS_POSITION_MODAL_CLOSED:
            return {
                ...state,
                playersPositionModal: false,
            }
        case PLAYERS_POSITION_MODAL_TOGGLED:
            return {
                ...state,
                playersPositionModal: !state.playersPositionModal,
            }
        case SET_IS_CUSTOM_FORMATION:
            return {
                ...state,
                isCustom: action.payload,
            }
        case PLAYER_POSITION_SELECTED:
            return {
                ...state,
                selectedPlayerPosition: action.payload,
            }
        case PLAYER_POSITION_RESET:
            return {
                ...state,
                selectedPlayerPosition: false,
            }
        case DROP_DOWN_PLAYER_TOGGLED:
            return {
                ...state,
                showSubBench: !state.showSubBench,
            }
        case DROP_DOWN_PLAYER_OPENED:
            return {
                ...state,
                showSubBench: true,
            }
        case DROP_DOWN_PLAYER_CLOSED:
            return {
                ...state,
                showSubBench: false,
            }
        case SET_FORMATION_PLAYERS:
            return {
                ...state,
                players: action.payload,
            }
        case RESET_FORMATION_PLAYERS:
            return {
                ...state,
                players: [],
            }
        case SET_PREVIOUS_LINEUP: {
            const playerIds = state.players.map(item => item.id)
            const assignedPlayerIds = action.payload.starting.map(item => item.player.id)
            const subPlayerIds = action.payload.subs.map(item => item.player.id)
            const justAssignedPlayerIds = []

            const assignPlayersToPositions = positions => {
                return positions.map(item => {
                    const found = state.players.some(p => p.id === item.player.id)
                    if (found) return item
                    const notAssignedPlayers = state.players.filter(
                        p =>
                            !assignedPlayerIds.includes(p.id) &&
                            !subPlayerIds.includes(p.id) &&
                            !justAssignedPlayerIds.includes(p.id)
                    )
                    const player = notAssignedPlayers[0]
                    justAssignedPlayerIds.push(player.id)
                    return {
                        ...item,
                        player,
                    }
                })
            }

            const lineup = assignPlayersToPositions(action.payload.starting)

            const substitutions = assignPlayersToPositions(action.payload.subs)

            return {
                ...state,
                previousLineup: action.payload,
                lineup,
                substitutions,
            }
        }
        case ADD_FORMATION_HISTORY: {
            const history = [...state.history, action.payload]
            return {
                ...state,
                history,
                historyIndex: history.length,
            }
        }
        case RESET_FORMATION_HISTORY:
            return {
                ...state,
                history: [],
                historyIndex: -1,
            }
        case UNDO_FORMATION_HISTORY: {
            if (state.historyIndex <= 0) return state
            const isFirstUndo = state.historyIndex === state.history.length
            const historyIndex = state.historyIndex - 1
            return {
                ...state,
                history: isFirstUndo ? getHistory(state) : state.history,
                historyIndex,
                lineup: state.history[historyIndex].lineup,
                substitutions: state.history[historyIndex].substitutions,
                formation: state.history[historyIndex].formation,
            }
        }
        case REDO_FORMATION_HISTORY: {
            if (state.historyIndex === -1) return state
            if (state.historyIndex >= state.history.length - 1) return state
            const historyIndex = state.historyIndex + 1
            return {
                ...state,
                historyIndex,
                lineup: state.history[historyIndex].lineup,
                substitutions: state.history[historyIndex].substitutions,
                formation: state.history[historyIndex].formation,
            }
        }
        // TODO: Old Code Need to check if this is still needed
        case FILTER_SUBSTITUTUIONS:
            subsArray = state.substitutions.filter(item => item.position !== action.payload.position)
            subsArray = subsArray.filter(item => item.player.id !== action.payload.id)

            return {
                ...state,
                substitutions: subsArray,
            }
        default:
            return state
    }
}

export default reducer

const getHistory = (state, history = false) => {
    const newHistory =
        history ||
        cloneDeep({
            lineup: state.lineup,
            substitutions: state.substitutions,
            formation: state.formation,
        })
    if (state.historyIndex === -1 || state.historyIndex === state.history.length) {
        return cloneDeep([...state.history, newHistory])
    }
    return cloneDeep([...state.history.slice(0, state.historyIndex), newHistory])
}

export const selectPlayer = data => ({ type: SELECT_PLAYER, payload: data })

export const addSubstitution = data => ({ type: ADD_SUBSTITUTUION, payload: data })

export const selectPlayerPosition = position => ({ type: PLAYER_POSITION_SELECTED, payload: position })

export const resetPlayerPosition = () => ({ type: PLAYER_POSITION_RESET })

export const toggleDropDownPlayer = () => ({ type: DROP_DOWN_PLAYER_TOGGLED })

export const openDropDownPlayer = () => ({ type: DROP_DOWN_PLAYER_OPENED })

export const closeDropDownPlayer = () => ({ type: DROP_DOWN_PLAYER_CLOSED })

export const setFormationPlayers = players => ({ type: SET_FORMATION_PLAYERS, payload: players })

export const resetFormationPlayers = () => ({ type: RESET_FORMATION_PLAYERS })

export const setSquadPreviousLineup = lineup => ({ type: SET_PREVIOUS_LINEUP, payload: lineup })

export const openPlayersPositionPicker = () => ({ type: PLAYERS_POSITION_PICKER_OPENED })

export const closePlayersPositionPicker = () => ({ type: PLAYERS_POSITION_PICKER_CLOSED })

export const togglePlayersPositionPicker = () => ({ type: PLAYERS_POSITION_PICKER_TOGGLED })

export const openPlayersPositionModal = () => ({ type: PLAYERS_POSITION_MODAL_OPENED })

export const closePlayersPositionModal = () => ({ type: PLAYERS_POSITION_MODAL_CLOSED })

export const togglePlayersPositionModal = () => ({ type: PLAYERS_POSITION_MODAL_TOGGLED })

export const addFormationHistory = formation => ({ type: ADD_FORMATION_HISTORY, payload: formation })

export const resetFormationHistory = () => ({ type: RESET_FORMATION_HISTORY })

export const undoFormationHistory = () => ({ type: UNDO_FORMATION_HISTORY })

export const redoFormationHistory = () => ({ type: REDO_FORMATION_HISTORY })

// export const selectPlayer = data => (dispatch, getState) => {
//     const { formationPicker } = getState()
//     const { lineup, substitutions, formation } = formationPicker
//     const payload = {
//         lineup,
//         substitutions,
//         formation,
//     }
//     dispatch(addFormationHistory(payload)).then(() => dispatch(selectPlayerAction(data)))
// }
// export const addSubstitution = data => (dispatch, getState) => {
//     const { formationPicker } = getState()
//     const { lineup, substitutions, formation } = formationPicker
//     const payload = {
//         lineup,
//         substitutions,
//         formation,
//     }
//     dispatch(addFormationHistory(payload)).then(() => dispatch(addSubstitutionAction(data)))
// }
