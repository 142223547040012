import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import classNames from 'classnames'
import {
    addSubstitution,
    closeDropDownPlayer,
    resetPlayerPosition,
    selectPlayer,
    selectPlayerPosition,
} from 'components/store'
import { NUMBER_OF_SUBSTITUTES, POSITION_GROUP } from 'constants'
import { cloneDeep, isNumber } from 'lodash'
import isObject from 'lodash/isObject'
import { useEffect, useRef, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayerName, getPosition, getPositionPlayer, isBrowser, isSubPosition } from 'utils'
import Avatar from '../Avatar'
import { DirectedDesktop } from '../DirectedDesktop'

const SubingIcon = () => (
    <div className="subing-icon bg-white animate-spin">
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 452 512">
            <path
                d="M235.605 203.52v25.742c0 8.175 1.633 15.035 4.856 20.379 4.445 7.375 11.742 11.601 20.027 11.601 8.117 0 16.184-3.953 23.973-11.742l85.023-85.023c18.668-18.668 18.668-49.043 0-67.711l-85.023-85.024C276.67 3.95 268.605 0 260.488 0c-12.386 0-24.883 9.89-24.883 31.98v23.38C114.895 62.487 14.965 155.124.113 276.41a14.997 14.997 0 009.367 15.77 14.997 14.997 0 0017.625-5.086c39.836-54.43 103.797-86.926 171.094-86.926a211.82 211.82 0 0137.406 3.352zm0 0"
                data-original="#1474e1"
                fill="#1474e1"
            />
            <path
                d="M438.254 219.82a14.995 14.995 0 00-17.625 5.09c-39.832 54.426-103.793 86.922-171.09 86.922a211.82 211.82 0 01-37.406-3.352v-25.742c0-22.09-12.5-31.98-24.883-31.98-8.117 0-16.184 3.953-23.977 11.746l-85.023 85.02c-18.664 18.667-18.664 49.042.004 67.714l85.02 85.02C171.065 508.05 179.132 512 187.25 512c12.383 0 24.883-9.89 24.883-31.98v-23.38c120.715-7.128 220.64-99.765 235.492-221.046a14.997 14.997 0 00-9.371-15.774zm0 0"
                data-original="#1474e1"
                fill="#1474e1"
            />
        </svg>
    </div>
)

const getSubPosition = position => isSubPosition(position) && parseInt(position.split('-')[1])

const checkSubPosition = (position, number) => getSubPosition(position) === number

// const SubRemaining = ({ number, selected, onSlotClick, position, node }) => (
//     <div
//         className={classNames('player-wrapper', { selected })}
//         onClick={() => onSlotClick(position, node, `SUB-${number}`)}>
//         <div className="player-slot" />
//         <p className="player-name">SUB {number}</p>
//     </div>
// )

// const SubsRemaining = ({ subPlayers, position }) => {
//     const dispatch = useDispatch()
//     const { lineup, selectedPlayerPosition: node } = useSelector(state => state.formationPicker)
//     const [open, setOpen] = useState(false)

//     const onSlotClick = (position, node, subPosition) => {
//         if (isSubPosition(position)) return
//         if (isObject(position)) {
//             dispatch(addSubstitution({ player: position, position: subPosition }))
//             return dispatch(resetPlayerPosition())
//         }
//         if (isNumber(node)) {
//             const player = lineup[node].player
//             if (!player) return
//             dispatch(addSubstitution({ player, position: subPosition }))
//             return dispatch(resetPlayerPosition())
//         }
//     }

//     const subsRemainingArr = Array.from(Array(NUMBER_OF_SUBSTITUTES).keys())
//         .map(i => i + 1)
//         .slice(subPlayers.length)

//     if (!subsRemainingArr.length) return null

//     const selected = isString(position) && !isSubPosition(position)

//     if (subsRemainingArr.length <= 2)
//         return subsRemainingArr.map(number => (
//             <SubRemaining
//                 key={number}
//                 number={number}
//                 selected={selected}
//                 onSlotClick={onSlotClick}
//                 position={position}
//                 node={node}
//             />
//         ))

//     return (
//         <>
//             <SubRemaining
//                 key={subsRemainingArr[0]}
//                 number={subsRemainingArr[0]}
//                 selected={selected}
//                 onSlotClick={onSlotClick}
//                 position={position}
//                 node={node}
//             />
//             {!open && (
//                 <div className="flex-center mb-2">
//                     <IconButton onClick={() => setOpen(true)} size="small" aria-label="Expand" className="bg-complete">
//                         <ChevronDown size={18} />
//                     </IconButton>
//                 </div>
//             )}
//             <Collapse in={open}>
//                 <div className="">
//                     {subsRemainingArr.slice(1).map(number => (
//                         <SubRemaining
//                             key={number}
//                             number={number}
//                             selected={selected}
//                             onSlotClick={onSlotClick}
//                             position={position}
//                             node={node}
//                         />
//                     ))}
//                     <div className="flex-center mb-2">
//                         <IconButton
//                             onClick={() => setOpen(false)}
//                             size="small"
//                             aria-label="Expand"
//                             className="bg-complete">
//                             <ChevronUp size={18} />
//                         </IconButton>
//                     </div>
//                 </div>
//             </Collapse>
//         </>
//     )
// }

// let scrollTimeout = null

// const handleStatsScroll = e => {
//     // Save the reference to the scrolling container
//     const scrollContainer = e.target

//     // Clear the previous timeout if it exists
//     if (scrollTimeout) {
//         clearTimeout(scrollTimeout)
//     }

//     // Set a timeout to reset the scroll position after 1 second
//     scrollTimeout = setTimeout(() => {
//         scrollContainer.scrollTo({
//             left: 0,
//             behavior: 'smooth',
//         })
//     }, 1000)
// }

const PlayerCard = ({ provided, player, flash, onPlayerClick, position, node, subbing }) => {
    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classNames('player-wrapper', {
                flash: flash === player.id,
            })}
            data-id={player.id}
            onClick={() => onPlayerClick(position, node, player)}>
            <div className="position-relative flex-none">
                <Avatar width={30} height={30} src={player.photo_url_sm} alt={getPlayerName(player)} />
                {subbing && <SubingIcon />}
            </div>
            <div className="d-flex flex-column justify-content-center flex-1">
                <p className="player-name">{getPlayerName(player)}</p>
                <p className="player-position">{`#${player.number} ${
                    POSITION_GROUP.find(i => i.value === player.pos_group)?.label
                }`}</p>
            </div>
            {/* <div
                className="player-stats d-flex gap-3 d-md-none ms-auto scrollbar-hidden scrollbar-x"
                onScroll={handleStatsScroll}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{player.total_appearances}</h4>
                    <p className="text-xxs uppercase lh-1 my-0">Apps</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{player.goals_scored}</h4>
                    <p className="text-xxs uppercase lh-1 my-0">Goal</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{player.goals_assisted}</h4>
                    <p className="text-xxs uppercase lh-1 my-0">Asst</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{player.yellow_cards}</h4>
                    <p className="whitespace-nowrap text-xxs uppercase lh-1 my-0">Yellow</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{player.red_cards}</h4>
                    <p className="whitespace-nowrap text-xxs uppercase lh-1 my-0">RED</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{formatDecimalWithoutZero(player.avg_tackle)}</h4>
                    <p className="whitespace-nowrap text-xxs uppercase lh-1 my-0">Tck %</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{formatDecimalWithoutZero(player.avg_header)}</h4>
                    <p className="whitespace-nowrap text-xxs uppercase lh-1 my-0">Head %</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="stat my-0">{formatDecimalWithoutZero(player.shots_on_target)}</h4>
                    <p className="whitespace-nowrap text-xxs uppercase lh-1 my-0">Sh Tar</p>
                </div>
            </div> */}
        </div>
    )
}

const SubsBench = () => {
    const dispatch = useDispatch()
    const headerRef = useRef(null)
    const {
        lineup,
        substitutions,
        players,
        selectedPlayerPosition: node,
        showSubBench,
    } = useSelector(state => state.formationPicker)
    const positionPlayer = getPositionPlayer(node, lineup, substitutions)
    const filteredLineup = lineup.filter(player => player.player)
    const lineupPlayers = filteredLineup.map(player => player.player)
    const subPlayers = substitutions.map(player => player.player)
    const reservePlayers = players.filter(player => {
        const isPlayerInLineup = lineupPlayers.find(p => p.id === player.id)
        const isPlayerInSubs = subPlayers.find(p => p.id === player.id)
        return !isPlayerInLineup && !isPlayerInSubs
    })
    const [reserveLocal, setReserveLocal] = useState(reservePlayers)
    const [flash, setFlash] = useState(false)
    const [header, setHeader] = useState('Subs')
    const position = getPosition(node, lineup)

    const onPlayerClick = (position, node, player) => {
        const subPosition = substitutions.find(s => s.player.id === player.id)?.position
        // if the player is not selected yet
        if (!position) return dispatch(selectPlayerPosition(subPosition || player))
        // if the player is already selected
        // if source is reserves
        if (isObject(position)) {
            // if destination is reserves
            if (!isSubPosition(subPosition)) {
                dispatch(resetPlayerPosition())
                // swap the players
                const sourcePlayer = position
                const destinationPlayer = player
                const newReservePlayers = cloneDeep(reserveLocal).map(p => {
                    if (p.id === sourcePlayer.id) return destinationPlayer
                    if (p.id === destinationPlayer.id) return sourcePlayer
                    return p
                })
                setReserveLocal(newReservePlayers)
                setFlash(false)
                return
            }
            // if destination is substitutes
            dispatch(
                addSubstitution({
                    position: subPosition,
                    player: position,
                })
            )
            dispatch(resetPlayerPosition())
            const newReservePlayers = cloneDeep(reserveLocal).map(p => {
                if (p.id === position.id) return player
                return p
            })
            setFlash(position.id)
            setReserveLocal(newReservePlayers)
            return
        }

        const payload = {
            player,
            position,
            node,
        }
        const isDestinationReserves = reserveLocal.find(p => p.id === player.id)
        // if source is substitutes
        if (isSubPosition(position)) {
            dispatch(addSubstitution(payload))
            dispatch(resetPlayerPosition())
            // if destination is reserves
            if (isDestinationReserves) {
                // insert the player from the substitutes to the reserves
                const subPlayer = substitutions.find(s => s.position === position).player
                const newReservePlayers = cloneDeep(reserveLocal).map(p => {
                    if (p.id === player.id) return subPlayer
                    return p
                })
                setFlash(subPlayer.id)
                setReserveLocal(newReservePlayers)
            } else {
                setFlash(false)
            }
            return
        }
        // if source is lineups
        // if destination is reserves
        const lineUpPlayer = lineup[node].player
        if (lineUpPlayer && isDestinationReserves) {
            // insert the player from the lineups to the reserves
            const newReservePlayers = cloneDeep(reserveLocal).map(p => {
                if (p.id === player.id) return lineUpPlayer
                return p
            })
            setReserveLocal(newReservePlayers)
        }
        if (lineUpPlayer) setFlash(lineUpPlayer.id)
        dispatch(selectPlayer(payload))
        dispatch(resetPlayerPosition())
        return
    }

    const onDragEnd = result => {
        const { source, destination } = result
        if (!destination) return
        // reset the player position if the player is already selected
        dispatch(resetPlayerPosition())
        // if the player is dragged from the substitutes to the reserves
        if (source.droppableId === 'substitutes' && destination.droppableId === 'reserves') {
            const payload = {
                position: `SUB-${source.index + 1}`,
            }
            dispatch(addSubstitution(payload))
            const destinationIndex = destination.index
            const newReservePlayers = cloneDeep(reserveLocal)
            newReservePlayers.splice(destinationIndex, 0, subPlayers[source.index])
            setFlash(subPlayers[source.index].id)
            setReserveLocal(newReservePlayers)
        }
        // if the player is dragged from the reserves to the substitutes
        if (source.droppableId === 'reserves' && destination.droppableId === 'substitutes') {
            const reservePlayers = cloneDeep(reserveLocal)
            const payload = {
                player: reservePlayers[source.index],
                position: `SUB-${destination.index + 1}`,
                insert: true,
            }
            dispatch(addSubstitution(payload))
            const sourcePlayer = reservePlayers[source.index]
            const newReservePlayers = reservePlayers.filter(player => player.id !== sourcePlayer.id)
            setFlash(sourcePlayer.id)
            setReserveLocal(newReservePlayers)
        }
        // if the player is dragged from the substitutes to the substitutes
        if (source.droppableId === 'substitutes' && destination.droppableId === 'substitutes') {
            const payload = {
                position: `SUB-${destination.index + 1}`,
                player: substitutions[source.index].player,
            }
            dispatch(addSubstitution(payload))
            setFlash(false)
        }
        // if the player is dragged from the reserves to the reserves
        if (source.droppableId === 'reserves' && destination.droppableId === 'reserves') {
            // set setPrevSubsBench in the local state
            // sort the reservePlayers array by source.index and destination.index
            const newReservePlayers = cloneDeep(reserveLocal)
            const [removed] = newReservePlayers.splice(source.index, 1)
            newReservePlayers.splice(destination.index, 0, removed)
            setReserveLocal(newReservePlayers)
            setFlash(false)
        }
    }

    const onSubsBenchClose = () => {
        dispatch(closeDropDownPlayer())
        setFlash(false)
    }

    const handelSubsScroll = () => {
        const header = headerRef.current
        if (!header) return
        const width = isBrowser() ? window.innerWidth : 0
        const isMobile = width < 768
        const top = isMobile ? 120 : 0
        const isReserves = header.getBoundingClientRect().top <= top
        setHeader(isReserves ? 'Reserves' : 'Subs')
    }
    const onPlayerDeselect = () => dispatch(resetPlayerPosition())

    const getShowAddSubstitute = position => {
        if (substitutions.length >= 10) return false
        if (!position) return false
        if (isObject(position)) return true
        if (isSubPosition(position)) return false
        const hasPlayer = lineup[node].player
        if (hasPlayer) return true
        return false
    }

    const getShowMakeReserve = position => {
        if (!position) return false
        if (isObject(position)) return false
        if (isSubPosition(position)) {
            const hasPlayer = substitutions.find(s => s.position === position)?.player
            if (hasPlayer) return true
            return false
        }
        const hasPlayer = lineup[node].player
        if (hasPlayer) return true
        return false
    }

    const onAddSubstitute = () => {
        if (!position) return
        if (isSubPosition(position)) return
        const subPosition = `SUB-${substitutions.length + 1}`
        const player = isObject(position) ? position : lineup[node].player
        dispatch(addSubstitution({ position: subPosition, player }))
        dispatch(resetPlayerPosition())
    }

    const onPlayerReserve = () => {
        if (!position) return
        if (isObject(node)) return
        const payload = {
            node,
            position,
        }
        if (isSubPosition(position)) dispatch(addSubstitution(payload))
        if (isNumber(node)) dispatch(selectPlayer(payload))
        dispatch(resetPlayerPosition())
    }

    useEffect(() => {
        // if reservePlayers is changed
        if (reserveLocal.length !== reservePlayers.length) {
            // if player is removed from the reservePlayers
            if (reserveLocal.length > reservePlayers.length) {
                const reserveIds = reservePlayers.map(p => p.id)
                const removedReservePlayer = reserveLocal.find(p => !reserveIds.includes(p.id))
                const newReservePlayers = reserveLocal.filter(p => p.id !== removedReservePlayer.id)
                setReserveLocal(newReservePlayers)
            } else {
                const localIds = reserveLocal.map(p => p.id)
                const newReservePlayer = reservePlayers.find(p => !localIds.includes(p.id))
                setFlash(newReservePlayer.id)
                const newReservePlayers = cloneDeep(reserveLocal)
                newReservePlayers.push(newReservePlayer)
                setReserveLocal(newReservePlayers)
            }
        } else {
            // if not sync between reservePlayers and reserveLocal
            const reserveIds = reservePlayers.map(p => p.id)
            const notSync = reserveLocal.some(p => !reserveIds.includes(p.id))
            if (reserveIds.length && notSync) {
                const localIds = cloneDeep(reserveLocal).map(p => p.id)
                const newReservePlayers = cloneDeep(reserveLocal).map(p => {
                    if (reserveIds.includes(p.id)) return p
                    const newReservePlayer = reservePlayers.find(rp => !localIds.includes(rp.id))
                    return newReservePlayer
                })
                setReserveLocal(newReservePlayers)
            }
        }
    }, [reservePlayers])

    useEffect(() => {
        if (isNumber(flash)) {
            const playerElement = document.querySelector(`.player-wrapper[data-id="${flash}"]`)
            if (playerElement) {
                playerElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }, [flash])

    return (
        <>
            <div
                className={classNames('subs-bench', {
                    show: showSubBench,
                })}>
                <div className="subs-bench-bg">
                    <button
                        type="button"
                        onClick={onSubsBenchClose}
                        className="subs-bench-close btn-x btn-clear d-md-none">
                        <X size={16} />
                    </button>
                    {position && (
                        <>
                            <div className="d-flex gap-2 py-2 px-3.5 flex-none">
                                {positionPlayer && (
                                    <Avatar
                                        width={48}
                                        height={48}
                                        src={positionPlayer?.photo_url_sm}
                                        alt={getPlayerName(positionPlayer)}
                                    />
                                )}
                                <div className="d-flex flex-column justify-content-center">
                                    <p className="subs-bench-player-name text-lg text-white text-ellipsis mt-0 mb-1 lh-1">
                                        {positionPlayer ? getPlayerName(positionPlayer) : `Subbing ${position}`}
                                    </p>
                                    {getShowAddSubstitute(position) && (
                                        <span
                                            onClick={onAddSubstitute}
                                            className={classNames('place-here-wrapper text-sm', {
                                                'cursor-not-allowed': substitutions.length === NUMBER_OF_SUBSTITUTES,
                                            })}>
                                            To Subs
                                            {substitutions.length === NUMBER_OF_SUBSTITUTES ? ' (FULL)' : ''}
                                        </span>
                                    )}
                                    {getShowMakeReserve(position) && (
                                        <span className="place-here-wrapper text-sm" onClick={onPlayerReserve}>
                                            To Reserves
                                        </span>
                                    )}
                                    <span className="text-complete bright text-sm fw-normal" onClick={onPlayerDeselect}>
                                        Deselect
                                    </span>
                                </div>
                            </div>
                            <div className="px-3"></div>
                            <div className="border-b border-main-lightest pt-2" />
                        </>
                    )}
                    <div className="subs-bench-header">
                        <h5 className="d-inline-flex my-0 text-base hint-text uppercase whitespace-nowrap text-white">
                            {header}
                        </h5>
                    </div>
                    <div className="subs-bench-wrapper" onScroll={handelSubsScroll}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="substitutes">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <div
                                            className={classNames('players-wrapper', {
                                                dragging: snapshot.isDraggingOver,
                                            })}>
                                            {subPlayers.map((player, index) => (
                                                <Draggable
                                                    key={player.id}
                                                    draggableId={`player_${player.id}`}
                                                    index={index}>
                                                    {provided => (
                                                        <PlayerCard
                                                            provided={provided}
                                                            player={player}
                                                            flash={flash}
                                                            onPlayerClick={onPlayerClick}
                                                            position={position}
                                                            node={node}
                                                            subbing={checkSubPosition(position, index + 1)}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            {/* <SubsRemaining subPlayers={subPlayers} position={position} /> */}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="reserves">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <h5
                                            ref={headerRef}
                                            className="ps-3 pt-2 my-0 text-base hint-text uppercase whitespace-nowrap text-white">
                                            Reserves
                                        </h5>
                                        <div
                                            className={classNames('players-wrapper', {
                                                dragging: snapshot.isDraggingOver,
                                            })}>
                                            {reserveLocal.map((player, index) => (
                                                <Draggable
                                                    key={player.id}
                                                    draggableId={`player_${player.id}`}
                                                    index={index}>
                                                    {provided => (
                                                        <PlayerCard
                                                            provided={provided}
                                                            player={player}
                                                            flash={flash}
                                                            onPlayerClick={onPlayerClick}
                                                            position={position}
                                                            node={node}
                                                            players={players}
                                                            subbing={isObject(position) && position.id === player.id}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className="bg-blur" />
            </div>
            {showSubBench && <div className="subs-bench-backdrop" />}
            {showSubBench && <div className="subs-bench-backdrop" />}
            {showSubBench && <div className="subs-bench-backdrop" />}
            {showSubBench && <div className="subs-bench-backdrop" />}
            {showSubBench && <div className="subs-bench-backdrop" />}
            <DirectedDesktop value={showSubBench} onChange={onSubsBenchClose} />
        </>
    )
}

export default SubsBench
