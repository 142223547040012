import Avatar from 'components/shared/Avatar'
import { matchHasStarted } from 'components/shared/shared2'
import { getSubstitutionEvents } from 'components/store'
import moment from 'moment'
import { Check, X } from 'react-feather'
import { useSelector } from 'react-redux'
import { convertRatingToRated, getPlayerName, getPositionLabel, getSquadRoles, getSquadSettings } from 'utils'
import BenchPlayerRedCard from './BenchPlayerRedCard'
import BenchPlayerYellowCard from './BenchPlayerYellowCard'

const BenchPlayer = ({ player, rsvp, showRating }) => {
    const matchEvents = useSelector(getSubstitutionEvents)
    const squad = useSelector(state => state.clubSquadDetail)
    const { data: fixture } = useSelector(state => state.fixture)
    const { players } = fixture

    const playerIsSubbed = matchEvents?.find(event => event.player2.id === player.id)

    const { isManager, isStaff, hasRole, isPlayer } = getSquadRoles(squad)
    const { playersSeeRatings } = getSquadSettings(squad)

    const isPassed = moment.utc(fixture.start_time) < moment.utc()

    const getPlayerRatingByPlayerId = player_id => {
        const rated = players.find(e => e.player.id === player_id)

        if (rated && rated?.rating?.rating) {
            return rated.rating.rating
        }

        return null
    }

    const playerName = hasRole ? getPlayerName(player) : player.username

    return (
        <div className="bench--player">
            <div className="d-flex align-items-center">
                <Avatar width={40} height={40} src={player.photo_url_sm} alt={playerName} />
            </div>
            <div className="player-name ms-3c">
                <span className="player text-base lh-1">{playerName}</span>
                <span className="hint-text">{`#${player.number} ${getPositionLabel(player.pos_group)}`}</span>
                {playerIsSubbed && (
                    <p className="d-flex align-items-center my-0">
                        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 7">
                            <path fill="#4fbe30" d="M4 2.16L2 0 0 2.16l.018.05H1.02V7h1.958V2.21H3.98" />
                            <path d="M8.02 4.837l-2 2.16-2-2.16.017-.05H5.04v-4.79h1.957v4.79H8" fill="#e43b3b" />
                        </svg>
                        <span className="hint-text text-sm ms-1">
                            {`${playerIsSubbed.minute}' Out: ${getPlayerName(playerIsSubbed.player)}`}
                        </span>
                    </p>
                )}
            </div>
            <div className="player-cards">
                <BenchPlayerYellowCard player={player} />
                <BenchPlayerRedCard player={player} />
            </div>
            {rsvp && hasRole && (
                <div className="player-right">
                    {rsvp === 'undecided' && !isPassed && <h6 className="player-right-text text-secondary">Undecided</h6>}
                    {rsvp === 'yes' && (
                        <h6 className="player-right-text" style={{ color: '#2ec52e' }}>
                            <Check size={22} strokeWidth={3} /> {isPassed ? 'Went' : 'Going'}
                        </h6>
                    )}
                    {rsvp === 'no' && (
                        <h6 className="player-right-text">
                            <X size={17} strokeWidth={3} color="red" /> {isPassed ? "Didn't go" : 'Not Going'}
                        </h6>
                    )}
                </div>
            )}
            {(isStaff || (isPlayer && playersSeeRatings)) &&
                isPassed &&
                showRating &&
                (getPlayerRatingByPlayerId(player.id) ? (
                    <div className={`rating-badge rating-${getPlayerRatingByPlayerId(player.id)} ms-auto`}>
                        {convertRatingToRated(getPlayerRatingByPlayerId(player.id))}
                    </div>
                ) : (
                    <></>
                    // <div className="rating-badge text-white small bg-info ms-auto"></div>
                ))}
        </div>
    )
}

export default BenchPlayer
