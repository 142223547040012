import { useSelector } from 'react-redux'
import ChatInput from './ChatInput'
import ConversationHeader from './ConversationHeader'
import Messages from './Messages'

const ConversationChat = () => {
    const { selectedConversation } = useSelector(state => state.conversation)

    if (!selectedConversation) return null

    return (
        <div className="bg-main-dark flex-grow-1 d-flex flex-column h-100">
            <ConversationHeader selectedConversation={selectedConversation} />
            <div className="conversation-chat-messages flex-grow-1 d-flex flex-column">
                <Messages selectedConversation={selectedConversation} />
                <ChatInput selectedConversation={selectedConversation} />
            </div>
        </div>
    )
}
export default ConversationChat
