import { EventSquadLayout } from 'components/Layout'
import {
    loadingFixture,
    resetUserProfileLoading,
    setUserProfile,
    setUserProfileLoading,
    setUserProfileSquad,
} from 'components/store'
import { setLoader } from 'components/store/actions'
import { BottomSideDrawer } from 'components/ui'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getUserSquad } from 'services'
import { getAllSearchParams } from 'utils'
import User from './User'
import { resetUserProfile } from 'components/store'

const PlayerModal = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)
    const { player } = params
    const { isLoading, data: userProfile } = useSelector(state => state.userProfile)
    const [isRequesting, setIsRequesting] = useState(false)

    const reset = () => {
        const { player, ...rest } = params
        setSearchParams(rest)
        dispatch(resetUserProfile())
    }

    const getData = async (squadSlug, username) => {
        if (isRequesting) return
        if (!squadSlug || !username) return
        dispatch(setUserProfileLoading())
        setIsRequesting(true)
        const response = await getUserSquad(username, squadSlug)
        if (response && response.status === 202 && response.data && response.data.status === 'success') {
            const {
                data: { user },
            } = response
            dispatch(setUserProfileSquad(squadSlug))
            dispatch(setUserProfile(user))
        } else {
            dispatch(resetUserProfileLoading())
            reset()
        }
        setIsRequesting(false)
    }

    if (!isLoading && isEmpty(userProfile) && player) {
        const [squadSlug, username] = player.split('|')
        getData(squadSlug, username)
    }

    if (!player) return null

    return (
        <BottomSideDrawer open={true} onClose={reset} className="z-1010 h-100 h-md-unset">
            <EventSquadLayout event="userProfile">
                {isLoading || isEmpty(userProfile) ? (
                    <div className="h-100 loader-scouty">
                        <div className="loader-container">
                            <div className="loader-ball"> </div>
                        </div>
                    </div>
                ) : (
                    <User />
                )}
            </EventSquadLayout>
        </BottomSideDrawer>
    )
}
export default PlayerModal
