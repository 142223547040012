import { 
    EMPTY_GROUP_DETAIL_DATA,
    SET_GROUP_DETAIL_DATA,
    ADD_PAST_EVENTS_TO_GROUP,
    ADD_UPCOMING_EVENTS_TO_GROUP,
    ADD_EXISTING_MEMBERS_TO_GROUP,
    ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE,
    ADD_PENDING_MEMBERS_TO_GROUP,
    ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE,
    ADD_BLOCKED_MEMBERS_TO_GROUP,
    ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE
} from '../actions/actionTypes'

const initialState = {}

const groupDetail = (state = initialState, action) => {
    switch (action.type) {
        case EMPTY_GROUP_DETAIL_DATA:
            return { }
        case SET_GROUP_DETAIL_DATA:
            return { ...state, ...action.payload }
        case ADD_PAST_EVENTS_TO_GROUP:
            return { 
                ...state, 
                past_events: !state.past_events ? [ ...action.payload ]
                    : [ ...state.past_events, ...action.payload ]
            }
        case ADD_UPCOMING_EVENTS_TO_GROUP:
            return { 
                ...state, 
                upcoming_events: !state.upcoming_events ? [ ...action.payload ]
                    : [ ...state.upcoming_events, ...action.payload ]
            }
        case ADD_EXISTING_MEMBERS_TO_GROUP:
            return { 
                ...state, 
                existing_members: { ...action.payload }
            }
        case ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE:
            return { 
                ...state, 
                existing_members: {
                    data: [ state.existing_members.data, ...action.payload.data ],
                    links: { ...action.payload.links },
                    meta: { ...action.payload.meta }
                }
            }
        case ADD_PENDING_MEMBERS_TO_GROUP:
            return { 
                ...state, 
                pending_members: { ...action.payload }
            }
        case ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE:
            return { 
                ...state, 
                pending_members: {
                    data: [ ...state.pending_members.data, ...action.payload.data ],
                    links: { ...action.payload.links },
                    meta: { ...action.payload.meta }
                }
            }
        case ADD_BLOCKED_MEMBERS_TO_GROUP:
            return { 
                ...state, 
                blocked_members: { ...action.payload }
            }
        case ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE:
            return { 
                ...state, 
                blocked_members: {
                    data: [ ...state.blocked_members.data, ...action.payload.data ],
                    links: { ...action.payload.links },
                    meta: { ...action.payload.meta }
                }
            }
        default:
            return state
    }
}
export default groupDetail