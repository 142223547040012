import classNames from 'classnames'
import { useWindowSize } from 'hooks'
import { Modal, Offcanvas } from 'react-bootstrap'

export const BottomDrawer = ({ open, onClose, children }) => {
    const { width } = useWindowSize()

    const isMobile = width < 768
    const Component = isMobile ? Offcanvas : Modal

    if (!open) return null

    return (
        <Component
            placement="bottom"
            show={open}
            centered
            onHide={onClose}
            className={classNames('z-1060', isMobile ? 'h-unset' : 'h-100vh')}
            backdropClassName="z-1055"
            PaperProps={{
                sx: {
                    minWidth: '600px',
                },
            }}>
            {children}
        </Component>
    )
}
