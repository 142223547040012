import moment from 'moment'
import { useEffect, useState } from 'react'
import { getTimerMinute } from 'utils'

export const useTimerMinute = timer => {
    const now = moment.utc().format()
    const [timerMinute, setTimerMinute] = useState(getTimerMinute(timer, now, true))

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment.utc().format()
            setTimerMinute(getTimerMinute(timer, now, true))
        }, 1000)

        return () => clearInterval(interval)
    }, [timer])

    return timerMinute
}
