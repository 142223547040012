import Avatar from 'components/shared/Avatar'
import { getUserConversation, selectConversation } from 'components/store'
// import moment from 'moment'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreDocument } from 'services'

const ConversationContent = ({ conversation }) => {
    const dispatch = useDispatch()
    const {
        // lastMessage,
        squadId,
        // time
    } = conversation
    const squad = useSelector(state => getUserConversation(state, squadId))

    const handelConversationSelect = () => {
        dispatch(selectConversation(conversation))
    }

    // return (
    //     <div onClick={handelConversationSelect} className="bg-main-light shadow rounded-4 p-3">
    //         <div className="d-flex">
    //             <div className="">
    //                 <Avatar width={50} height={50}>
    //                     <div dangerouslySetInnerHTML={{ __html: squad?.photo_html }} />
    //                 </Avatar>
    //             </div>
    //             <div className="d-flex flex-column flex-grow-1 ms-3">
    //                 <div className="d-flex align-items-center justify-content-between">
    //                     <h6 className="conversation-name fw-bold mt-0 mb-1">{squad.name}</h6>
    //                     {time && <p className="text-xs my-0">&nbsp;{moment(time.toDate()).format('LT')}</p>}
    //                 </div>
    //                 <p className="last-message text-secondary text-sm">
    //                     {lastMessage ? lastMessage : 'You have joined the conversation'}
    //                 </p>
    //             </div>
    //         </div>
    //     </div>
    // )
    const squadTotalMembers = squad?.total_members || 1
    return (
        <li onClick={handelConversationSelect} className="chat-user-list clearfix">
            <div
                data-view-animation="push-parrallax"
                data-view-port="#chat"
                data-navigate="view"
                // onClick={() => chooseChannel(squad)}
                className="">
                <Avatar width={34} height={34} boxed src={squad?.photo_url_xs} alt={squad.name} />
                <p className="p-l-10">
                    <span className="text-color">{squad.name}</span>
                    <span className="block text-color hint-text fs-12">
                        {squadTotalMembers} {pluralize('member', squadTotalMembers)}
                    </span>
                </p>
            </div>
        </li>
    )
}

const Conversation = ({ id }) => {
    // useFirestoreDocument
    const { data: conversation, loading } = useFirestoreDocument('conversations', id)

    if (loading) return null
    if (!conversation) return null

    return <ConversationContent conversation={conversation} />
}

export default Conversation
