import { isEmpty } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { Button, ButtonGroup, Container, Modal } from 'react-bootstrap'
import { Check, ChevronRight, X } from 'react-feather'
import { useSelector } from 'react-redux'

const FixturePlayerMenu = () => {
    const [show, setShow] = useState(false)
    const { isLoading, data: fixture } = useSelector(state => state.fixture)
    const isFixtureEmpty = isEmpty(fixture)

    const { id: userId } = useSelector(state => state.user)
    const { players, start_time: startTime } = fixture
    const playerIds = !isFixtureEmpty ? players.map(({ player }) => player.id) : []
    const startTimeMoment = moment(`${startTime}z`)
    const now = moment()
    const hoursUntilMatch = startTimeMoment.diff(now, 'hours')
    const isMoreThanThreeHours = hoursUntilMatch > 3
    const isMatchStarted = startTimeMoment.isBefore(now)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleYes = () => {
        if (!isMoreThanThreeHours) return handleShow()
        console.log('🔥 yes')
    }
    const handleNo = () => {
        if (!isMoreThanThreeHours) return handleShow()
        console.log('🔥 no')
    }

    if (isFixtureEmpty || isLoading) return null

    if (!playerIds.includes(userId) || isMatchStarted) return null

    return (
        <>
            <Container>
                {show && (
                    <Modal show={show} onHide={handleClose} backdrop={false} centered className="fixture-menu-modal">
                        <Modal.Header className="pt-2" closeButton>
                            <Modal.Title>{`Match with ${fixture.opponent.name} at ${moment
                                .utc(fixture.date)
                                .format('Do MMM')}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Sorry, you can&apos;t change your availability within 3 hours of the match.</h5>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Container>
        </>
    )
}
export default FixturePlayerMenu
