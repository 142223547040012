import Countdown from 'components/shared/Countdown/Countdown'
import moment from 'moment'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { resendVerifyEmail } from 'services'

const VerifyEmail = () => {
    const { user } = useSelector(state => state)
    const { email_unverified: emailUnverified } = user
    let emailResentValue = false
    let timeLeftValue = 120
    if (emailUnverified === true) {
        emailResentValue = false
    } else {
        const now = moment.utc()
        const emailUnverifiedAt = moment.utc(emailUnverified)
        const diffInSeconds = now.diff(emailUnverifiedAt, 'seconds')
        emailResentValue = diffInSeconds < 120
        timeLeftValue = 120 - diffInSeconds
    }
    const [emailResent, setEmailResent] = useState(emailResentValue)
    const [isLoading, setIsLoading] = useState(false)
    const [timeLeft, setTimeLeft] = useState(timeLeftValue)

    const resendEmail = async () => {
        setIsLoading(true)
        const {
            data: { status },
        } = await resendVerifyEmail()
        if (status === 'success') {
            setEmailResent(true)
            setTimeLeft(120)
        }
        setIsLoading(false)
    }

    // useEffect(() => {
    //     if (emailUnverified === true) {
    //         setEmailResent(true)
    //     } else {
    //         const now = moment.utc()
    //         const emailUnverifiedAt = moment.utc(emailUnverified)
    //         const diffInSeconds = now.diff(emailUnverifiedAt, 'seconds')
    //         setEmailResent(diffInSeconds < 120)
    //         setTimeLeft(120 - diffInSeconds)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [emailUnverified])

    const onChange = () => setEmailResent(false)

    return (
        <Modal
            show={true}
            size="lg"
            backdrop="static"
            animation={false}
            keyboard={false}
            centered
            className="fill-in colorized-darkest fade modal-lg"
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title className="text-4xl fw-bold">Verify your email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {emailResent ? (
                        <>
                            <strong className="me-2">Email Sent!</strong>We just sent you an email, please check your
                            inbox. Or the spam folder if you cannot find it. If you didn't get it, please wait to resend
                            it again.
                        </>
                    ) : (
                        <>
                            Please verify your email address. We have already sent you a token to your email <span className="noticable bold">{user.email}</span> when you registered.  
                            Please check your inbox, or your spam folder to retrieve the email and follow the link provided.
                            <br/>
                            <br/>
                            If you didn't find the email, please click on the link below to re-send the verification token.
                        </>
                    )}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button size="lg" variant="complete" onClick={resendEmail} disabled={emailResent || isLoading}>
                    {emailResent ? (
                        <>
                            Resend Token in&nbsp;
                            <Countdown
                                onChange={onChange}
                                eventTime={moment.utc().add(timeLeft, 'seconds')}
                                interval={1000}
                            />
                        </>
                    ) : (
                        'Resend Token'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default VerifyEmail
