import Avatar from 'components/shared/Avatar'
import { Badge, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getInputId, getPlayerName, getSquadRoles } from 'utils'

const AVATAR_SIZE = 62

const AddEvenPlayer = ({ input, option, values, event }) => {
    const squad = useSelector(state => state.clubSquadDetail)
    const { hasRole } = getSquadRoles(squad)
    const onChangeHandler = (input, option) => input.onChange(option.value)

    const { player } = option.data
    const playerName = hasRole ? getPlayerName(player) : player.username

    return (
        <div
            className="player-input"
            role="button"
            tabIndex={0}
            onKeyDown={e => e.key === 'Enter' && onChangeHandler(input, option)}
            htmlFor={getInputId(input)}
            onClick={() => onChangeHandler(input, option)}>
            <div className="position-relative">
                <Avatar width={AVATAR_SIZE} height={AVATAR_SIZE} src={player.photo_url_sm} alt={playerName} />
                <Form.Check
                    className={`player-input-check ${event}`}
                    id={getInputId(input)}
                    type="radio"
                    name={input.name}
                    value={option.value}
                    checked={values[input.name] === option.value}
                    style={{
                        opacity: values[input.name] === option.value ? 1 : 0,
                        pointerEvents: 'none',
                    }}
                />
            </div>
            <div className="player-input-label text-center lh-14 mt-2">{option.label}</div>
            <div className="d-flex align-items-center gap-1 text-center small text-secondary">
                <span className="">{player.number}</span>/<span>{option.data.position}</span>
                {/* <Badge bg="secondary" className="text-white bg-main-light-plus fw-normal rounded-2">
                </Badge> */}
            </div>
        </div>
    )
}

export default AddEvenPlayer
