import { getNonTacticalEvents } from 'components/store'
import { useSelector } from 'react-redux'
import { getPlayerName, isYellowCard, sortMatchEvents } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildTitle = (event, num = 1) => {
    const { player } = event
    if (num === 2)
        return (
            <>
                2nd Yellow Card <span className="text-white semi-bolds">{getPlayerName(player)}</span>
            </>
        )

    return (
        <>
            Yellow Card <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}

const checkIfThisCardIsSecondYellow = (event, events) => {
    const allYellows = events.filter(e => isYellowCard(e.event_type) && event.player.id === e.player.id)
    if (allYellows?.length > 1 && event.minute === allYellows[0]?.minute) {
        return true
    }
    return false
}

export const MatchEventYellowCard = ({ event }) => {
    const keyMoments = useSelector(getNonTacticalEvents)
    const sortedKeyMoments = sortMatchEvents(keyMoments)

    if (checkIfThisCardIsSecondYellow(event, sortedKeyMoments)) {
        return (
            <MatchEventTemplate
                time={event?.minute}
                half={event?.half}
                title={buildTitle(event, 2)}
                // secondary={buildTitle(event)}
                small={'(Player Suspended)'}
                image='<svg width="24" height="24" viewBox="0 0 24 24"><g fill-rule="evenodd"><path d="M7 7v12h8v3H4V7h3z" fill="#d9b62a"></path><path fill="#e35c47" d="M9 2h11v15H9z"></path></g></svg>'
            />
        )
    }

    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            title={buildTitle(event)}
            // secondary={buildTitle(event)}
            // small={"Foul"}
            image='<svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(216 182 48)" class="sc-bYMpWt gILbRJ"><g id="Symbols" stroke="none" stroke-width="1" fill="rgb(216 182 48)" fill-rule="evenodd"><g id="ic_card_yellow"><rect id="Yellow-Card" x="5" y="2" width="14" height="20" rx="1"></rect></g></g></svg>'
        />
    )
}
