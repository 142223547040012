import classNames from 'classnames'
import { getConversationUser } from 'components/store'
import moment from 'moment'
import { useSelector } from 'react-redux'

const Message = ({ message, currentUserId }) => {
    const { userId, message: text, time } = message

    const isCurrentUser = currentUserId === userId
    const user = useSelector(state => getConversationUser(state, userId))

    return (
        <div
            className={classNames('d-flex flex-column mw-75 rounded px-3 py-2', {
                'align-self-end bg-user-message': isCurrentUser,
                'align-self-start bg-main-light': !isCurrentUser,
            })}>
            {!isCurrentUser && (
                <div className="d-flex">
                    <p className="text-brand fw-semibold m-0 mb-1">{user?.username}</p>
                </div>
            )}
            <p
                className={classNames('m-0', {
                    'text-white': isCurrentUser,
                })}
                style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                }}>
                {text}
            </p>
            <p
                className={classNames('fs-10 m-0 ms-auto', {
                    'text-secondary': !isCurrentUser,
                    'text-right': isCurrentUser,
                })}>
                {moment(time.toDate()).format('LT')}
            </p>
        </div>
    )
}
export default Message
