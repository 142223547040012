import classNames from 'classnames'
import { ChevronRight } from 'react-feather'
import { useSelector } from 'react-redux'
import { isTechnical } from 'utils'

const DrawerHeader = ({ eventKey, values, isFirstPage }) => {
    const { match_events: matchEvents } = useSelector(state => state.masterData)

    if (!matchEvents || !eventKey) return null

    console.log('aaa', eventKey)
    const event = matchEvents[eventKey]

    const showMinute = !isTechnical(eventKey)

    const showingMinute = showMinute && isFirstPage

    return (
        <div
            className={classNames(
                'd-flex align-items-center gap-2 pt-3 pb-1 px-5c justify-content-between',
                'offcanvas-header'
            )}>
            <div className="d-flex align-items-center gap-2">
                {showMinute && (
                    <div className="bg-main-light-plus-plus text-white px-1 py-1.5 rounded">
                        <h5 className="text-center text-lg fw-bold lh-1 my-0">{values.minute}&apos;</h5>
                        <p className="text-xxs whitespace-nowrap lh-1 my-0">{values.match_half} Half</p>
                    </div>
                )}
                <div
                    className={classNames({
                        // 'text-center': showMinute,
                    })}>
                    <h5 className="d-inline-flex align-items-center gap-1 text-white my-0">
                        {showingMinute ? (
                            'Adjust time for this event'
                        ) : (
                            <>
                                <span className="">Add</span>
                                <ChevronRight size={16} />
                                <span className="text-warning">{event.label}</span>
                            </>
                        )}
                    </h5>
                    <p className="hint-text text-xs my-0">
                        {showingMinute
                            ? 'Select the half and minute of the event'
                            : 'Add further details about this event'}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default DrawerHeader
