import { IconButton, Tooltip } from '@mui/material'
import classNames from 'classnames'
import ReactSelectAdapter from 'components/shared/ReactSelectAdapter'
import {
    addSubstitution,
    openDropDownPlayer,
    openPlayersPositionPicker,
    redoFormationHistory,
    resetPlayerPosition,
    selectPlayer,
    selectPlayerPosition,
    toggleDropDownPlayer,
    undoFormationHistory,
} from 'components/store'
import {
    resetSubstitutions,
    setIsCustomFormation,
    setLineup,
    setNewFormation,
    setSubstitutions,
} from 'components/store/actions'
import { ZONES } from 'constants'
import { drag, select } from 'd3'
import cloneDeep from 'lodash/cloneDeep'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import { minus, times } from 'number-precision'
import { useEffect, useRef, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { Save, XCircle } from 'react-feather'
import { IoShirt } from 'react-icons/io5'
import { MdRedo, MdUndo } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLongPress } from 'use-long-press'
import {
    getFormationName,
    getGroupShortName,
    getPlayerName,
    getWindowSize,
    isDropDownPlayersOpen,
    splitName,
} from 'utils'
import ShirtPickerSet from '../ShirtPickerSet'
import { sortPlayers } from '../shared2'
import { playerWithBall, subsBench } from '../svg/svgs'
import DropdownPosition from './DropdownPosition'
import IndoorField from './IndoorField'
import OutdoorField from './OutdoorField'
import PlayersPositionPicker from './PlayersPositionPicker'
import PickerControlPanelSVG from './PickerControlPanelSVG'
import { set } from 'lodash'
import { PLAYER_SHIRTS } from '../svg/shirts'

const DropdownZone = ({ zone, onZoneClick, onZoneCancel }) => {
    // Don't render anything if no circle is selected or no zone is selected
    return (
        <div
            className={classNames('dropdown-wrapper', {
                show: !!zone,
            })}>
            {!!zone && (
                <div className="dropdown dropdown-zone">
                    <p className="dropdown-title">Select Position</p>
                    <div className="badge-group">
                        {zone.position.map(position => (
                            <Badge
                                bg="info"
                                key={position}
                                onClick={() => onZoneClick(zone.selectedCircle, position, zone.lineup)}>
                                {position}
                            </Badge>
                        ))}
                        <Badge bg="danger" onClick={() => onZoneCancel(zone)}>
                            Cancel
                        </Badge>
                    </div>
                </div>
            )}
        </div>
    )
}

const Node = ({
    x,
    y,
    position,
    player,
    index,
    horizontal,
    onPlayerClick,
    shirtGK,
    shirtPlayer,
    selectedPlayerPosition,
    getPlayerLetter,
    getPlayerShirt,
    players,
    fake = false,
}) => {
    const [shirtImageUrl, setShirtImageUrl] = useState('');
    const dispatch = useDispatch()
    const bind = useLongPress(() => {
        const { width } = getWindowSize()
        if (width >= 768) return
        const svg = document.querySelector('.formation-svg')
        const isDragging = svg.classList.contains('dragging')
        if (isDragging) return
        dispatch(selectPlayerPosition(index))
        dispatch(openDropDownPlayer())
    })
    
    return (
        <a className={classNames('position-a', { rotated: horizontal, fake })}>
            <g
                className={classNames('position-g', {
                    empty: !player,
                })}>
                <image
                    width="16"
                    height="16"
                    x={x}
                    y={y}
                    data-index={index}
                    data-x={x}
                    data-y={y}
                    data-position={position}
                    className={`position-img`}
                    style={{ transform: 'translate(-8px, -8px)' }}
                    xlinkHref={getPlayerShirt(position, shirtGK, shirtPlayer)}
                    onClick={() => onPlayerClick(index)}
                    onContextMenu={e => e.preventDefault()}
                    {...bind()}
                />
                {isNumber(selectedPlayerPosition) && selectedPlayerPosition === index && (
                    <g className="spin-g">
                        <g className="sub-g">
                            <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} xmlSpace="preserve" className="sub-svg">
                                <circle cx={9} cy={9} r={9} fill="#fff" />
                                <path
                                    fill="#1474e1"
                                    d="M9.3 7.3v.8c0 .3.1.5.2.7.1.2.4.4.6.4.3 0 .5-.1.8-.4l2.8-2.7c.6-.6.6-1.6 0-2.2l-2.8-2.7c-.3-.2-.5-.4-.8-.4-.4 0-.8.3-.8 1v.7c-3.9.3-7.2 3.3-7.7 7.2 0 .2.1.4.3.5.2.1.4 0 .6-.2 1.3-1.7 3.4-2.8 5.6-2.8.4 0 .8 0 1.2.1z"
                                />
                                <path
                                    fill="#1474e1"
                                    d="M15.9 7.8c-.2-.1-.4 0-.6.2-1.3 1.7-3.4 2.8-5.6 2.8-.4 0-.8 0-1.2-.1v-.8c0-.7-.4-1-.8-1-.2-.1-.5.1-.8.3l-2.8 2.7c-.6.6-.6 1.6 0 2.2l2.8 2.7c.3.2.5.4.8.4.4 0 .8-.3.8-1v-.7c3.9-.2 7.2-3.2 7.7-7.1.1-.3-.1-.5-.3-.6z"
                                />
                            </svg>
                        </g>
                    </g>
                )}
                {player && (
                    <text
                        x={x}
                        y={y}
                        dy="4"
                        textAnchor="middle"
                        className={`position-number number ${getPlayerLetter(position, shirtGK, shirtPlayer)}`}>
                        {player.number}
                    </text>
                )}
                {player ? (
                    <g>
                        <text x={x} y={y} dy="4" textAnchor="middle" className="position-text">
                            {splitName(getPlayerName(player)).map((name, i) => {
                                const number = i + 1
                                return (
                                    <tspan
                                        className="position-tspan"
                                        x={x}
                                        y={y}
                                        dy={`${minus(times(number, 1.2), number === 1 ? 0 : times(number, 0.1))}em`}
                                        key={i}
                                        fill="#000">
                                        {name}
                                    </tspan>
                                )
                            })}
                        </text>
                    </g>
                ) : (
                    <text
                        className="position-text"
                        x={x}
                        y={y}
                        dy="4"
                        textAnchor="middle"
                        style={{ fill: '#ff6d6d', fontFamly: 'scouty-bold' }}>
                        {position}
                    </text>
                )}
            </g>
        </a>
    )
}

const SVGFormation = ({ horizontal, shirts, shirtPlayer, shirtGK, meta, title, titleClass, autoAssign = true }) => {
    const svgRef = useRef(null)
    const dispatch = useDispatch()
    const { max_players, shirts: squad_shirts } = useSelector(store => store.clubSquadDetail)
    const { formations: FORMATIONS } = useSelector(store => store.masterData)
    const availableFormations = FORMATIONS[max_players]
    const {
        lineup,
        formation = Object.keys(availableFormations)[0],
        selectedPlayerPosition,
        players,
        substitutions,
        history,
        historyIndex,
        showSubBench,
    } = useSelector(store => store.formationPicker)
    const lineupHasPlayer = lineup.some(item => item.player)
    const isPlayersOpen = isDropDownPlayersOpen(selectedPlayerPosition)
    const { positions: positionFormations } = useSelector(store => store.masterData)
    const types = Object.keys(positionFormations)
    // const positionPlayer = getPositionPlayer(selectedPlayerPosition, lineup, substitutions)
    const [strokeLineDefault, setStrokeLineDefault] = useState(true)
    const fieldFill = !strokeLineDefault ? '#2f4554' : 'rgb(38 117 59)'
    const fieldLineStroke = !strokeLineDefault ? '#717e89' : '#75c289'

    const [showShirtPicker, setShowShirtPicker] = useState(false)
    const [selectedZone, setSelectedZone] = useState(null)

    const handleShowShirtPicker = () => setShowShirtPicker(true)
    const handleCloseShirtPicker = () => setShowShirtPicker(false)

    const handleChange = value => {
        dispatch(setNewFormation({ formation: value, history: true }))
        // formation has players in it
        const hasPlayers = lineup.filter(item => item.player).length > 0
        const initialLineup = cloneDeep(availableFormations[value].line_up)
        // if it does not have players
        if (!hasPlayers) {
            return dispatch(setLineup(initialLineup))
        }

        //  if it has players the take every player to the new formation
        const newLineup = initialLineup
            .sort((player1, player2) => sortPlayers(types, player1, player2))
            .map((item, index) => {
                const player = lineup[index].player
                if (player) {
                    return {
                        ...item,
                        player,
                    }
                }
                return item
            })
        dispatch(setLineup(newLineup))
    }

    const onZoneClick = (selectedCircle, position, oldLineup) => {
        const newData = lineup.map((data, index) => {
            if (index === selectedCircle) {
                return { ...data, position }
            }
            return data
        })
        const formationName = getFormationName(newData.map(item => item.position))
        dispatch(setNewFormation({ formation: formationName, history: false }))
        dispatch(setIsCustomFormation(true))
        dispatch(
            setLineup(newData, {
                lineup: oldLineup,
                substitutions,
                formation,
            })
        )
        setSelectedZone(null)
    }

    const onZoneCancel = zone => {
        // reset the previous circle
        const prevCircle = select(svgRef.current).select(`.position-img[data-index="${zone.selectedCircle}"]`)
        prevCircle.attr('x', zone.prevX)
        prevCircle.attr('y', zone.prevY)
        const prevText = select(prevCircle.node().parentNode).select('.position-text')
        prevText.attr('x', zone.prevX)
        prevText.attr('y', zone.prevY)
        const prevTextNumber = select(prevCircle.node().parentNode).select('.position-number')
        prevTextNumber.attr('x', zone.prevX)
        prevTextNumber.attr('y', zone.prevY)
        setSelectedZone(null)
        const newLineup = lineup.map((data, index) => {
            if (index === zone.selectedCircle) {
                return { ...data, x: zone.prevX, y: zone.prevY }
            }
            return data
        })
        dispatch(setLineup(newLineup, false))
    }

    const onPlayerClick = position => {
        if (isObject(selectedPlayerPosition)) {
            const payload = {
                player: selectedPlayerPosition,
                position: lineup[position].position,
                node: position,
            }
            dispatch(resetPlayerPosition())
            return dispatch(selectPlayer(payload))
        }
        if (isString(selectedPlayerPosition)) {
            const player = substitutions.find(s => s.position === selectedPlayerPosition).player
            const payload = {
                player,
                position: lineup[position].position,
                node: position,
            }
            dispatch(resetPlayerPosition())
            return dispatch(selectPlayer(payload))
        }
        if (isNumber(selectedPlayerPosition)) {
            const hasPlayer = lineup[position].player
            const payload = {
                player: hasPlayer ? lineup[position].player : lineup[selectedPlayerPosition].player,
                position: hasPlayer ? lineup[selectedPlayerPosition].position : lineup[position].position,
                node: hasPlayer ? selectedPlayerPosition : position,
            }

            dispatch(resetPlayerPosition())
            return dispatch(selectPlayer(payload))
        }
        // dispatch(openDropDownPlayer())
        return dispatch(selectPlayerPosition(position))
    }

    const getPlayerLetter = (position, shirtGK, shirtPlayer) => {
        return position === 'GK'
            ? PLAYER_SHIRTS[`keeper_${shirtGK}`].color 
            : PLAYER_SHIRTS[`player_${shirtPlayer}`].color
    }

    const getPlayerShirt = (position, shirtGK, shirtPlayer) => {
        return position === 'GK'
            ? PLAYER_SHIRTS[`keeper_${shirtGK}`].image 
            : PLAYER_SHIRTS[`player_${shirtPlayer}`].image
    }

    const checkOutOfBounds = (event, int = false) => {
        let x = int ? parseInt(event.x) : event.x
        let y = int ? parseInt(event.y) : event.y
        if (x < 14) x = 14
        if (x > 106) x = 106
        if (y < 14) y = 14
        if (y > 166) y = 166
        return { x, y }
    }

    const updatePosition = (jersey, x, y) => {
        // update the circle position
        jersey.attr('x', x)
        jersey.attr('y', y)
        // update the text position
        const text = select(jersey.node().parentNode).select('.position-text')
        text.attr('x', x)
        text.attr('y', y)
        // update the text number position
        const textNumber = select(jersey.node().parentNode).select('.position-number')
        textNumber.attr('x', x)
        textNumber.attr('y', y)

        // position-tspan
        const tspans = text.selectAll('.position-tspan')
        tspans.attr('x', x)
        tspans.attr('y', y)

        const sub = select(jersey.node().parentNode).select('.sub-svg')
        sub.attr('x', x)
        sub.attr('y', y)
    }

    const isOnGoalArea = (x, y) => x >= 31 && x <= 89 && y >= 150 && y <= 166

    const detectJerseyCollision = (x, y, jersey, lineup, tolerance) => {
        const jerseyIndex = parseInt(jersey.attr('data-index'))
        const otherJerseys = lineup.filter((_, index) => index !== jerseyIndex)

        const otherJersey = otherJerseys.find(
            otherJersey =>
                Math.abs(otherJersey.x - x) < tolerance &&
                Math.abs(otherJersey.y - y) < tolerance &&
                otherJersey.position !== 'GK'
        )

        const jerseyGroup = select(jersey.node().parentNode)
        jerseyGroup.classed('jersey-collision', Boolean(otherJersey))
        return otherJersey
    }

    const handleOpenPlayersPositionPicker = () => dispatch(openPlayersPositionPicker())

    const autoAssignPlayers = (initialLineup, players) => {
        const assignedPlayers = {}
        const lineupHasPlayers = initialLineup.filter(item => item.player).length > 0
        if (lineupHasPlayers) return initialLineup
        const lineup = initialLineup.map(item => {
            const { position } = item
            const player = players.find(p => p.pos_group === getGroupShortName(position) && !assignedPlayers[p.id])
            if (player) {
                assignedPlayers[player.id] = true
                return { ...item, player }
            }
            // if there is no player for this position then return the item with a random player
            const randomPlayer = players.find(p => !assignedPlayers[p.id])
            assignedPlayers[randomPlayer.id] = true
            return { ...item, player: randomPlayer }
        })
        const notAssignedPlayers = players.filter(p => !assignedPlayers[p.id])
        // if there are players that are not assigned then assign them to the bench
        // take the first 10 players and assign them to the sub bench
        const subBench = notAssignedPlayers.slice(0, 10).map((player, i) => ({
            player,
            position: `SUB-${i + 1}`,
        }))
        dispatch(setSubstitutions(subBench))
        return lineup
    }

    const isWithinThreshold = (prevX, prevY, x, y, threshold) => {
        return Math.abs(prevX - x) < threshold && Math.abs(prevY - y) < threshold
    }

    const toggleDropdown = () => dispatch(toggleDropDownPlayer())

    // const onPlayerDeselect = () => dispatch(resetPlayerPosition())

    // const onSelectedPlayerClick = () => dispatch(togglePlayersPositionModal())

    const handleClearLineup = () => {
        dispatch(resetPlayerPosition())
        const newLineup = cloneDeep(lineup).map(item => {
            const { player, ...rest } = item
            return rest
        })
        dispatch(setLineup(newLineup))
        // clear the substitutions
        dispatch(resetSubstitutions())
    }

    const handleUndo = () => {
        dispatch(resetPlayerPosition())
        return dispatch(undoFormationHistory())
    }

    const handleRedo = () => {
        dispatch(resetPlayerPosition())
        return dispatch(redoFormationHistory())
    }

    useEffect(() => {
        if (!lineup.length && autoAssign) {
            const initialLineup = cloneDeep(availableFormations[formation].line_up)
            const sortedLineup = initialLineup.sort((a, b) => sortPlayers(types, a, b))
            const newLineup = autoAssignPlayers(sortedLineup, players)
            dispatch(setLineup(newLineup, false))
            dispatch(setNewFormation({ formation, history: false }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const svg = svgRef.current
        if (!svg) return
        const subsBench = document.querySelector('.subs-bench')
        const isSubsBench = () => subsBench?.classList.contains('show')
        // loop through circle in the svgRef
        const jerseys = select(svgRef.current).selectAll('.position-img')
        jerseys.each(function () {
            const jersey = select(this)
            const shirtPosition = jersey.attr('data-position')
            if (shirtPosition === 'GK') return
            const handleDrag = drag()
                .subject(() => ({ x: parseInt(jersey.attr('x')), y: parseInt(jersey.attr('y')) }))
                .on('drag', function (event) {
                    if (isSubsBench()) return
                    // add class to svg "dragging"
                    svg.classList.add('dragging')
                    // check if the x, y is out of bound
                    const { x, y } = checkOutOfBounds(event)
                    // if it is in the goalkeepers zone
                    if (isOnGoalArea(x, y)) return
                    // if the jersey is too close to the other jersey
                    detectJerseyCollision(x, y, jersey, lineup, 15)
                    // update the circle position
                    updatePosition(jersey, x, y)
                })
                .on('end', function (event) {
                    if (isSubsBench()) return
                    // remove class to svg "dragging"
                    svg.classList.remove('dragging')
                    // remove class to jersey selected
                    // select(jersey.node().parentNode.parentNode).classed('selected', false)
                    // check if the x, y is out of bound
                    const { x, y } = checkOutOfBounds(event, true)
                    // if only 5 pixel away from the previous position then return
                    const prevX = parseInt(jersey.attr('data-x'))
                    const prevY = parseInt(jersey.attr('data-y'))
                    if (isWithinThreshold(prevX, prevY, x, y, 1)) {
                        updatePosition(jersey, prevX, prevY)
                        return
                    }
                    // if it is in the goalkeepers zone
                    if (isOnGoalArea(x, y)) return
                    // if the jersey is too close to the other jersey
                    const isOverlapping = detectJerseyCollision(x, y, jersey, lineup, 15)
                    if (isOverlapping) {
                        updatePosition(jersey, prevX, prevY)
                        select(jersey.node().parentNode).classed('jersey-collision', false)
                        const jerseyPlayer = lineup.find(item => item.x === prevX && item.y === prevY)?.player
                        if (!jerseyPlayer) return
                        const otherJerseyPlayer = isOverlapping?.player
                        if (!otherJerseyPlayer) return
                        // swap the players
                        const newLineup = lineup.map(item => {
                            if (item.player?.id === jerseyPlayer.id) {
                                return { ...item, player: otherJerseyPlayer }
                            }
                            if (item.player?.id === otherJerseyPlayer.id) {
                                return { ...item, player: jerseyPlayer }
                            }
                            return item
                        })
                        dispatch(setLineup(newLineup))
                        return
                    }
                    if (prevX === x && prevY === y) return
                    const selectedCircle = parseInt(jersey.attr('data-index'))
                    // check if the circle is in which zone
                    const zone = ZONES.find(zone => x >= zone.x1 && x <= zone.x2 && y >= zone.y1 && y <= zone.y2)
                    setSelectedZone({ ...zone, prevX, prevY, selectedCircle, lineup })
                    const newLineup = lineup.map((data, index) => {
                        if (index === selectedCircle) {
                            return { ...data, x, y }
                        }
                        return data
                    })
                    dispatch(setLineup(newLineup, false))
                })

            handleDrag(jersey)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineup, svgRef])

    return (
        <div className="svg-formation">
            {title && <h4 className={classNames('lineup-formation-title', titleClass)}>{title}</h4>}

            <div className="svg-wrapper">
                <DropdownZone zone={selectedZone} onZoneClick={onZoneClick} onZoneCancel={onZoneCancel} />
                <PlayersPositionPicker />
                <DropdownPosition />
                <svg
                    viewBox="0 0 120 195"
                    ref={svgRef}
                    className="formation-svg"
                    transform={horizontal ? 'rotate(90)' : ''}>
                    {/* place this g under the g above so basically the y value will start after the y value ends of the field which has a height of 180 */}
                    <g transform="translate(0, 179)">
                        <PickerControlPanelSVG
                            showShirtPicker={handleShowShirtPicker}
                            handleUndo={handleUndo}
                            handleRedo={handleRedo}
                            hideShirtPicker={handleCloseShirtPicker}
                        />
                    </g>
                    {[5, 7].includes(max_players) ? (
                        <IndoorField hasControlPanel={true} />
                    ) : (
                        <OutdoorField hasControlPanel={true} />
                    )}
                    {lineup?.length &&
                        lineup.map(({ x, y, position, player }, i) => (
                            <g key={i}>
                                <Node
                                    key={i}
                                    x={x}
                                    y={y}
                                    position={position}
                                    player={player}
                                    index={i}
                                    horizontal={horizontal}
                                    onPlayerClick={onPlayerClick}
                                    shirtGK={shirtGK}
                                    shirtPlayer={shirtPlayer}
                                    selectedPlayerPosition={selectedPlayerPosition}
                                    getPlayerLetter={getPlayerLetter}
                                    getPlayerShirt={getPlayerShirt}
                                    players={players}
                                />
                            </g>
                        ))}
                </svg>
            </div>
        </div>
    )
}

export default SVGFormation
