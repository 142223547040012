import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

const SET_UPCOMING_EVENTS = 'SET_UPCOMING_EVENTS'
const RESET_UPCOMING_EVENTS = 'RESET_UPCOMING_EVENTS'
const SET_UPCOMING_EVENTS_LOADING = 'SET_UPCOMING_EVENTS_LOADING'
const RESET_UPCOMING_EVENTS_LOADING = 'RESET_UPCOMING_EVENTS_LOADING'
const UPDATE_UPCOMING_EVENT = 'UPDATE_UPCOMING_EVENT'
const DELETE_UPCOMING_EVENT = 'DELETE_UPCOMING_EVENT'

const initialState = {
    events: null,
    isLoading: false,
}

const homeReducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case SET_UPCOMING_EVENTS: {
            // const matches = action.payload.matches.map(match => ({
            //     ...match,
            //     type: 'matches',
            // }))
            // const trainings = action.payload.trainings.map(training => ({
            //     ...training,
            //     type: 'training',
            // }))
            // const events = [...matches, ...trainings]
            return {
                ...state,
                events: action.payload,
                isLoading: false,
            }
        }
        case RESET_UPCOMING_EVENTS:
            return cloneDeep(initialState)

        case UPDATE_UPCOMING_EVENT: {
            const { events } = state
            const updatedEvents = {
                ...events,
                [action.payload.type]: events[action.payload.type]?.map(event => {
                    if (event.date === action.payload.date && (event.id || event.parent_id) === action.payload.id) {
                        return {
                            ...event,
                            ...action.payload.data,
                        }
                    }
                    return event
                }),
            }

            return {
                ...state,
                events: updatedEvents,
            }
        }
        case DELETE_UPCOMING_EVENT: {
            const { events } = state

            const updatedEvents = {
                ...events,
                [action.payload.type]: events[action.payload.type].filter(event => {
                    if (
                        action.payload.deletion_type === 'all_sessions' &&
                        event.parent_id === action.payload.parent_id
                    ) {
                        return false
                    }
                    if (
                        action.payload.deletion_type === 'this_and_future' &&
                        event.parent_id === action.payload.parent_id
                    ) {
                        return moment(event.date).isBefore(action.payload.date)
                    }
                    if (event.date === action.payload.date && (event.id || event.parent_id) === action.payload.id) {
                        return false
                    }
                    return true
                }),
            }

            return {
                ...state,
                events: updatedEvents,
            }
        }
        case SET_UPCOMING_EVENTS_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_UPCOMING_EVENTS_LOADING:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
export default homeReducer

// Action Creators
export const loadUpcomingEvents = data => ({
    type: SET_UPCOMING_EVENTS,
    payload: data,
})

export const resetUpcomingEvents = () => ({
    type: RESET_UPCOMING_EVENTS,
})

export const setUpcomingEventsLoading = () => ({
    type: SET_UPCOMING_EVENTS_LOADING,
})

export const resetUpcomingEventsLoading = () => ({
    type: RESET_UPCOMING_EVENTS_LOADING,
})

export const updateUpcomingEvent = event => ({
    type: UPDATE_UPCOMING_EVENT,
    payload: event,
})

export const deleteUpcomingEvent = event => ({
    type: DELETE_UPCOMING_EVENT,
    payload: event,
})
