import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'

const PickerControlPanelSVG = ({ currrentFormation, handleOpenPlayersPositionPicker, handleRedo, handleUndo }) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [currFormation, setCurrFormation] = useState(currrentFormation)
    const dropdownRef = useRef(null)

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleRowClick = () => {
        setShowDropdown(false)
    }

    return (
        <svg width="120" height="50">
            <rect width="120" height="16" rx="1" fill="#434343"></rect>
            <line x1="0" y1="0" x2="120" y2="0" stroke="black" strokeWidth="1" />
            {/*  <line x1="0" y1="0" x2="0" y2="15.5" stroke="#ccc" strokeWidth="1" />
            <line x1="120" y1="0" x2="120" y2="15.5" stroke="#ccc" strokeWidth="1" /> */}
            <text
                x="5"
                y="11"
                width="50"
                fontSize="8"
                dominantBaseline="top"
                fill="white"
                onClick={() => setShowDropdown(!showDropdown)}
                style={{ fontSize: 5, fontWeight: 'bold', cursor: 'pointer', fontStretch: 'condensed' }}>
                {currFormation || '4-4-2'}
            </text>
            <g transform="translate(53, 7)">
                <circle cx="2.5" cy="2.5" r="4" fill="#333"></circle>
                <svg width="5" height="5" viewBox="0 0 24 24" fill="#fff" stroke-width="2">
                    <path
                        d="M19 12v5l-2 2H7v3H6l-4-4 4-4h1v3h10v-5h2zM18 2l4 4-4 4h-1V7H7v5H5V7l2-2h10V2h1z"
                        fill-rule="evenodd"></path>
                </svg>
            </g>
            <g transform="translate(63, 7)" onClick={handleOpenPlayersPositionPicker}>
                <circle cx="2.5" cy="2.5" r="4" fill="#333"></circle>

                <svg viewBox="0 0 319.925 319.925" height="5" width="5" fill="#fff">
                    <g>
                        <path d="M195.622 272.938c12.65 0 22.903 10.253 22.903 22.908 0 12.645-10.253 22.897-22.903 22.897s-22.902-10.253-22.902-22.897c-.001-12.655 10.251-22.908 22.902-22.908zM181.206 0c12.153 0 22.008 9.856 22.008 22.012s-9.854 22.01-22.008 22.01c-12.158 0-22.012-9.854-22.012-22.01C159.194 9.856 169.047 0 181.206 0zM242.826 152.291a13.09 13.09 0 0 0 9.289 3.85c3.361 0 6.722-1.284 9.29-3.85 9.507-9.507 15.523-25.06 18.895-36.428 2.071-6.972 6.598-24.099 3.972-32.488-1.698-5.45-3.63-11.622-59.678-33.544a13.349 13.349 0 0 0-2.683-.725l.037-.182H143.47c.005.021.005.044.019.07-1.37-.039-2.752.08-4.138.492-4.246 1.278-19.524 8.15-37.715 42.929-16.808 32.138-24.78 47.043-24.853 47.191-3.42 6.4-1.007 14.359 5.394 17.779a13.044 13.044 0 0 0 6.183 1.554c4.691 0 9.229-2.519 11.594-6.946.08-.147 8.093-15.139 24.964-47.403 10.193-19.48 18.188-26.861 21.417-29.27-.583 27.134-11.33 50.58-18.225 62.87l-61.161 85.121a17.852 17.852 0 0 0-1.962 3.5l-28.729 68.315c-3.824 9.098.451 19.567 9.543 23.399a17.874 17.874 0 0 0 6.92 1.398c6.978 0 13.605-4.111 16.479-10.946l27.949-66.456 62.78-87.373 5.924 1.229-8.006 54.806-27.716 10.62c-9.217 3.531-13.823 13.861-10.294 23.079 2.727 7.104 9.504 11.475 16.689 11.475 2.125 0 4.283-.378 6.387-1.187l37.489-14.363a17.876 17.876 0 0 0 11.289-14.101l11.211-76.724c.062-.424.025-.836.057-1.255l14.017-73.646c15.778 6.237 33.73 14.1 41.56 18.462-1.449 10.19-7.721 32.188-15.705 40.172-5.142 5.128-5.142 13.444-.005 18.576z"></path>
                    </g>
                </svg>
            </g>
            <g transform="translate(73, 7)" onClick={handleUndo}>
                <circle cx="2.5" cy="2.5" r="4" fill="#333"></circle>
                <svg
                    stroke="#fff"
                    fill="#fff"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="5"
                    width="5"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"></path>
                </svg>
            </g>
            <g transform="translate(83, 7)" onClick={handleRedo}>
                <circle cx="2.5" cy="2.5" r="4" fill="#333"></circle>
                <svg
                    stroke="#fff"
                    fill="#fff"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="5"
                    width="5"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16a8.002 8.002 0 017.6-5.5c1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"></path>
                </svg>
            </g>
            <g transform="translate(93, 7)">
                <circle cx="2.5" cy="2.5" r="4" fill="#333"></circle>
                <svg
                    stroke="#fff"
                    fill="#fff"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="5"
                    width="5"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M256 96c33.08 0 60.71-25.78 64-58 .3-3-3-6-6-6a13 13 0 00-4.74.9c-.2.08-21.1 8.1-53.26 8.1s-53.1-8-53.26-8.1a16.21 16.21 0 00-5.3-.9h-.06a5.69 5.69 0 00-5.38 6c3.35 32.16 31 58 64 58z"></path>
                    <path d="M485.29 89.9L356 44.64a4 4 0 00-5.27 3.16 96 96 0 01-189.38 0 4 4 0 00-5.35-3.16L26.71 89.9A16 16 0 0016.28 108l16.63 88a16 16 0 0013.92 12.9l48.88 5.52a8 8 0 017.1 8.19l-7.33 240.9a16 16 0 009.1 14.94A17.49 17.49 0 00112 480h288a17.49 17.49 0 007.42-1.55 16 16 0 009.1-14.94l-7.33-240.9a8 8 0 017.1-8.19l48.88-5.52a16 16 0 0013.92-12.9l16.63-88a16 16 0 00-10.43-18.1z"></path>
                </svg>
            </g>
        </svg>
    )
}

export default PickerControlPanelSVG
