import { getRequest, postRequest } from './apiRequest'

// Verify Email
export const verifyEmail = hash => {
    const url = `/user/verify-email/${hash}`
    return getRequest(url)
}

export const onBoardingComplete = data => {
    const url = '/user/on-boarding/complete'
    return postRequest(url, data)
}

export const savePositions = data => {
    const url = '/user/save-positions'
    return postRequest(url, data)
}

// Resend Verify Email
export const resendVerifyEmail = () => {
    const url = '/user/resend-verify-email'
    return getRequest(url)
}

// Ger User
export const getUserSquad = (username, squad_slug) => {
    const url = `/user/get/${username}/${squad_slug}`
    return getRequest(url)
}

// Ger Player Games
export const getPlayerSquadGames = (username, squad_slug, page = 1) => {
    const url = `/user/get/${username}/${squad_slug}/games`
    return getRequest(url, { page })
}

// Follow User
export const followUser = id => {
    const url = `/user/follow`
    return postRequest(url, { values: { player: id } })
}

export const uploadUserAvatar = file => {
    const url = `/user/upload-image`
    return postRequest(
        url,
        {
            base_image: file,
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

// upcoming-events
export const getUpcomingEvents = () => {
    const url = `/user/get-my-upcoming-events`
    return getRequest(url)
}

// get-calendar-events
export const calendarEvents = (from, to, params = {}) => {
    const url = `/user/get-calendar-events`
    return getRequest(url, { dayfrom: from, dayto: to, ...params })
}
