import { matchHasStarted } from 'components/shared/shared2'
import { minus } from 'number-precision'
import { useSelector } from 'react-redux'
import { getPlayerName, getSquadRoles, getSquadSettings, getStartingEleven, splitName } from 'utils'
import LineUpPlayerGoalScored from '../../LineUpPlayerGoalScored'
import LineUpPlayerRating from '../../LineUpPlayerRating'
import LineUpPlayerRedCard from '../../LineUpPlayerRedCard'
import LineUpPlayerSubstitution from '../../LineUpPlayerSubstitution'
import LineUpPlayerYellowCard from '../../LineUpPlayerYellowCard'
import OutdoorField from 'components/shared/SVGFormation/OutdoorField'
import IndoorField from 'components/shared/SVGFormation/IndoorField'
import { useEffect, useState } from 'react'
import { PLAYER_SHIRTS } from 'components/shared/svg/shirts'

const FormationDisplay = () => {
    const { data: fixture } = useSelector(state => state.fixture)
    const squad = useSelector(state => state.clubSquadDetail)
    const { shirts: jerseys } = useSelector(st => st.masterData)
    const { players, shirts } = fixture
    const { isManager, isStaff, isPlayer } = getSquadRoles(squad)
    const { playersSeeRatings } = getSquadSettings(squad)
    const [shirtImageUrl, setShirtImageUrl] = useState('')
    const starting = getStartingEleven(players)

    const fetchShirtImageUrl = (position, matchShirts) => {
        return position === 'GK' ? PLAYER_SHIRTS[`keeper_${matchShirts.gk}`].image : PLAYER_SHIRTS[`player_${matchShirts.player}`].image
    }

    const checkIfPlayerIsNotComing = user => {
        if (players && players.find(e => e?.player?.id == user?.id && e?.player_attended === 'no')) {
            return true
        }
        return false
    }

    const getPlayerLetter = (position, shirtGK, shirtPlayer) => {
        return position === 'GK' ? PLAYER_SHIRTS[`keeper_${shirtGK}`].color : PLAYER_SHIRTS[`player_${shirtPlayer}`].color
    }

    if (!jerseys) return null

    return (
        <div className="svg-formation display">
            <div className="svg-wrapper">
                <svg
                    // width="100%"
                    // height="100%"
                    // ref={svgRef}
                    viewBox="0 0 120 180"
                    className="formation-svg">
                    {squad.max_players === 11 ? <OutdoorField /> : <IndoorField />}
                    {starting?.map(({ x, y, player, position }, i) => (
                        <a className="position-a" key={i}>
                            <g className="position-g">
                                <image
                                    width={'12'}
                                    height={'12'}
                                    x={x}
                                    y={y}
                                    data-index={i}
                                    data-x={x}
                                    data-y={y}
                                    className="position-img"
                                    style={{ transform: 'translate(-6px, -6px)' }}
                                    xlinkHref={fetchShirtImageUrl(position, shirts)}
                                />
                                {player && (
                                    <text
                                        x={x}
                                        y={y}
                                        dy="4"
                                        textAnchor="middle"
                                        className={`position-number ${shirts.player} number ${getPlayerLetter(
                                            position,
                                            shirts.gk,
                                            shirts.player
                                        )}`}>
                                        {player.number}
                                    </text>
                                )}
                                <g>
                                    <text
                                        x={x}
                                        y={y}
                                        dy="4"
                                        textAnchor="middle"
                                        className={`position-text ${
                                            checkIfPlayerIsNotComing(player) && isManager && !matchHasStarted(fixture)
                                                ? 'blink'
                                                : ''
                                        }`}>
                                        {splitName(getPlayerName(player)).map((name, i) => {
                                            const number = i + 1
                                            return (
                                                <tspan
                                                    x={x}
                                                    y={y}
                                                    dy={`${minus(number * 1.2, number === 1 ? 0 : number * 0.1)}em`}
                                                    key={i}
                                                    fill={
                                                        checkIfPlayerIsNotComing(player) &&
                                                        !matchHasStarted(fixture) &&
                                                        isManager
                                                            ? 'red'
                                                            : '#fff'
                                                    }>
                                                    {name}
                                                </tspan>
                                            )
                                        })}
                                    </text>
                                </g>
                                <LineUpPlayerRedCard player={player} x={x} y={y} />
                                <LineUpPlayerYellowCard player={player} x={x} y={y} />
                                <LineUpPlayerGoalScored player={player} x={x} y={y} />
                                <LineUpPlayerSubstitution player={player} x={x} y={y} />
                                {(isStaff || (isPlayer && playersSeeRatings)) && (
                                    <LineUpPlayerRating player={player} x={x} y={y} />
                                )}
                            </g>
                        </a>
                    ))}
                </svg>
            </div>
        </div>
    )
}

export default FormationDisplay
