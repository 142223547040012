import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PLAYER_SHIRTS } from './svg/shirts'
import { randomNumBetweenTwo } from 'utils'

const ShirtPicker = ({ shirtNumber, value, isKeeper, onChange }) => {
    const [showModal, setShowModal] = useState(false)

    const [initialValue, setInitialValue] = useState(isKeeper ? `keeper_${value || 1}` : `player_${value || 1}`)
    const [modalShirtValue, setModalShirtValue] = useState(initialValue)

    if (!initialValue) return null

    const allShirts = Object.keys(PLAYER_SHIRTS).filter(key => key.startsWith(isKeeper ? 'keeper_' : 'player_'));

    const openShirtsModal = () => setShowModal(true)
    const closeShirtsModal = () => {
        setModalShirtValue(initialValue)
        setShowModal(false)
    }

    const onSelectShirt = shirt => {
        setModalShirtValue(shirt)
    }

    const setShirt = () => {
        const explodedShirt = modalShirtValue.split('_')
        onChange(parseInt(explodedShirt[1]))
        setInitialValue(modalShirtValue)
        closeShirtsModal(true)
    }

    return (
        <>
            {initialValue && (
                <div
                    className="shirt-inline"
                    onClick={openShirtsModal}
                    style={{
                        backgroundImage: `url(${PLAYER_SHIRTS[initialValue].image})`,
                    }}>
                    <span className={`number ${PLAYER_SHIRTS[initialValue].color}`}>{shirtNumber || 10}</span>
                </div>
            )}
            <Modal
                show={showModal}
                size="lg"
                backdrop="static"
                animation={false}
                aria-labelledby="contained-modal-title-vcenter"
                keyboard={false}
                className="shirt-picker-modal"
                onHide={e => setShowModal(false)}
                centered>
                <Modal.Header className="p-b-20" closeButton>
                    <Modal.Title>
                        <h4>Pick Your Shirt</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="shirt-picker-container">
                        {allShirts.map((shirt, index) => (
                            <div
                                key={index}
                                className={`padding-20 match-shirt-selection ${
                                    modalShirtValue === shirt ? 'selected' : ''
                                }`}
                                onClick={e => onSelectShirt(shirt)}>
                                <div
                                    className="shirt-inline"
                                    style={{
                                        backgroundImage: `url(${PLAYER_SHIRTS[shirt].image})`,
                                    }}>
                                    <span className={`number ${PLAYER_SHIRTS[shirt].color}`}>{isKeeper ? index++ : index + 2 }</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-t-10">
                    <Button variant="info" onClick={closeShirtsModal}>
                        Cancel
                    </Button>
                    <Button variant="complete" onClick={e => setShirt(modalShirtValue)}>
                        Select Shirt
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShirtPicker
