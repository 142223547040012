import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Nav } from 'react-bootstrap'
import { FastForward, Pause, Play, Rewind } from 'react-feather'
import { BsForward } from 'react-icons/bs'
import { MdArrowForward, MdChevronRight, MdDoubleArrow, MdForward, MdUndo } from 'react-icons/md'

const TestComponent = () => {
    const [timeElapsed, setTimeElapsed] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const [half, setHalf] = useState(1)
    const [timeoutId, setTimeoutId] = useState(null)
    const [intervalId, setIntervalId] = useState(null)
    const [extraTime, setExtraTime] = useState(0)

    useEffect(() => {
        let timer
        if (isRunning) {
            timer = setInterval(() => {
                setTimeElapsed(prev => prev + 1)
            }, 1000)
        }
        return () => clearInterval(timer)
    }, [isRunning])

    useEffect(() => {
        if (timeElapsed > 45 * 60) {
            setExtraTime(timeElapsed - 45 * 60)
        } else {
            setExtraTime(0)
        }
    }, [timeElapsed])

    const handleStart = () => setIsRunning(true)
    const handlePause = () => setIsRunning(false)
    const handleReset = () => {
        setIsRunning(false)
        if (half === 2) {
            setTimeElapsed(2700) // 45 minutes * 60 seconds
        } else {
            setTimeElapsed(0)
        }
    }

    const handleHalfChange = selectedHalf => {
        setHalf(selectedHalf)
        if (selectedHalf === 2) {
            setTimeElapsed(2700) // 45 minutes * 60 seconds
        } else {
            setTimeElapsed(0)
        }
    }

    const handleMouseDown = (event, initialChange) => {
        event.preventDefault()
        let change = initialChange
        const timeoutId = setTimeout(() => {
            change = 60 // Change increment to 60 after 4 seconds
            const intervalId = setInterval(() => {
                setTimeElapsed(prevTime => {
                    let newTime = prevTime + change
                    if (newTime < 0) {
                        newTime = 0 // Don't go below 0
                    }
                    if (prevTime < 10 && change < 0) {
                        newTime = 0 // If under 10 seconds and rewinding, set to 0
                    }
                    return newTime
                })
            }, 200) // Change time every 200ms
            setIntervalId(intervalId)
        }, 4000) // Start repeating after 4000ms
        setTimeoutId(timeoutId)
        // Immediately change the time once
        setTimeElapsed(prevTime => {
            let newTime = prevTime + change
            if (newTime < 0) {
                newTime = 0 // Don't go below 0
            }
            if (prevTime < 10 && change < 0) {
                newTime = 0 // If under 10 seconds and rewinding, set to 0
            }
            return newTime
        })
    }

    const handleMouseUp = event => {
        event.preventDefault()
        // If the button was released before the interval started, clear the timeout
        if (timeoutId) {
            clearTimeout(timeoutId)
            setTimeoutId(null)
        }
        // If the interval is running, clear it
        if (intervalId) {
            clearInterval(intervalId)
            setIntervalId(null)
        }
    }

    const getLowerLimit = () => {
        return half === 2 ? 2700 : 0
    }

    const minutes = Math.floor(timeElapsed / 60)
    const seconds = timeElapsed % 60

    let displayMinutes = minutes
    let displaySeconds = seconds

    if (half === 'first' && minutes >= 45) {
        displayMinutes = 45
        displaySeconds = 0
    } else if (half === 'second' && minutes >= 90) {
        displayMinutes = 90
        displaySeconds = 0
    }

    const extraMinutes = Math.floor((timeElapsed - displayMinutes * 60) / 60)
    const extraSeconds = (timeElapsed - displayMinutes * 60) % 60

    return (
        <div className="container mx-auto px-4 mt-8 min-h-screen">
            <div className="d-flex flex-column align-items-center">
                <h2 className="text-2xl font-bold mb-4">Soccer Match Timer</h2>
                <Nav variant="pills" className="mb-4">
                    <Nav.Item>
                        <Nav.Link eventKey={1} active={half === 1} onClick={() => handleHalfChange(1)}>
                            1st Half
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={2} active={half === 2} onClick={() => handleHalfChange(2)}>
                            2nd Half
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <h1 id="timer-display" className="font-bold mx-4">
                    {`${displayMinutes.toString().padStart(2, '0')}:${displaySeconds.toString().padStart(2, '0')}`}
                </h1>
                {(half === 'first' && minutes >= 45) || (half === 'second' && minutes >= 90) ? (
                    <span className="extra-time">
                        +{`${extraMinutes.toString().padStart(2, '0')}:${extraSeconds.toString().padStart(2, '0')}`}
                    </span>
                ) : null}
                <div className="d-flex align-items-center justify-content-center mt-4">
                    {/* <Button
                        onMouseDown={event => handleMouseDown(event, -60)}
                        onMouseUp={event => handleMouseUp(event)}
                        disabled={timeElapsed <= getLowerLimit()}
                        variant="info"
                        className="btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center">
                      </Button> */}
                    <Button
                        onMouseDown={event => handleMouseDown(event, -10)}
                        onMouseUp={event => handleMouseUp(event)}
                        disabled={timeElapsed <= getLowerLimit()}
                        variant="info"
                        className="btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center">
                        <Rewind size={20} fill="#fff" />
                        {/* <Play
                            size={20}
                            fill="#fff"
                            style={{
                                transform: 'rotate(180deg)',
                            }}
                        /> */}
                    </Button>
                    <Button
                        onClick={handleReset}
                        variant="info"
                        className="px-4 py-2 btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center">
                        <MdUndo size={20} fill="#fff" />
                    </Button>
                    <Button
                        onClick={isRunning ? handlePause : handleStart}
                        variant="info"
                        className={`px-4 py-2 btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center`}>
                        {isRunning ? <Pause size={20} fill="#fff" /> : <Play size={20} fill="#fff" />}
                    </Button>
                    <Button
                        onMouseDown={event => handleMouseDown(event, 10)}
                        onMouseUp={handleMouseUp}
                        variant="info"
                        className="btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center">
                        <FastForward size={20} fill="#fff" />
                        {/* <Play size={20} fill="#fff" /> */}
                    </Button>
                    {/* <Button
                        onMouseDown={event => handleMouseDown(event, 60)}
                        onMouseUp={handleMouseUp}
                        variant="info"
                        className="btn-rounded w-20 h-20 mx-1 d-flex align-items-center justify-content-center">
                    </Button> */}
                </div>
            </div>
        </div>
    )
}

export default TestComponent
