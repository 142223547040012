import { geocodeByAddress, geocodeByLatLng, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete'

export const getGeocodeByPlaceId = async placeId => {
    try {
        const results = await geocodeByPlaceId(placeId)
        return results[0]
    } catch (error) {
        return error
    }
}

export const getGeocodeByAddress = async address => {
    try {
        const results = await geocodeByAddress(address)
        return results[0]
    } catch (error) {
        return error
    }
}

export const getGeocodeByLatLng = async latLng => {
    try {
        const results = await geocodeByLatLng(latLng)
        return results[0]
    } catch (error) {
        return error
    }
}

export const getLatLngByAddress = async address => {
    try {
        const results = await geocodeByAddress(address)
        const latLng = await getLatLng(results[0])
        return latLng
    } catch (error) {
        return error
    }
}

export const getLatLngByGeocode = async geocode => {
    try {
        const latLng = await getLatLng(geocode)
        return latLng
    } catch (error) {
        return error
    }
}

export const getAddressByGeocode = geocode => {
    if (typeof geocode.address_components === 'undefined') return null
    const addressComponents = geocode.address_components
    const formattedAddress = geocode.formatted_address
    const addressObj = {}

    addressComponents.forEach(component => {
        const types = component.types
        const isLocality = types.includes('locality')
        if (isLocality) addressObj.locality = component.long_name
        const isSublocality = types.includes('sublocality')
        if (isSublocality) addressObj.sublocality = component.long_name
        const isPostalTown = types.includes('postal_town')
        if (isPostalTown) addressObj.postal_town = component.long_name
        const isAdministrativeAreaLevel1 = types.includes('administrative_area_level_1')
        if (isAdministrativeAreaLevel1) addressObj.administrativeAreaLevel1 = component.long_name
        const isAdministrativeAreaLevel2 = types.includes('administrative_area_level_2')
        if (isAdministrativeAreaLevel2) addressObj.administrativeAreaLevel2 = component.long_name
        const isAdministrativeAreaLevel3 = types.includes('administrative_area_level_3')
        if (isAdministrativeAreaLevel3) addressObj.administrativeAreaLevel3 = component.long_name
        const isCountry = types.includes('country')
        if (isCountry) addressObj.country = component.short_name
        const isPostalCode = types.includes('postal_code')
        if (isPostalCode) addressObj.postalCode = component.long_name
    })

    const city =
        addressObj.locality ||
        addressObj.postal_town ||
        addressObj.sublocality ||
        addressObj.administrativeAreaLevel3 ||
        addressObj.administrativeAreaLevel2 ||
        addressObj.administrativeAreaLevel1
    const region = addressObj.administrativeAreaLevel1 || addressObj.administrativeAreaLevel2 || city
    const country = addressObj.country
    const postalCode = addressObj.postalCode

    const address = {
        formattedAddress,
        city,
        region,
        country,
    }

    if (postalCode) address.postalCode = postalCode

    return address
}
