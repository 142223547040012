import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
    formatNumberWithPadding,
    getMinutesAndExtraTime,
    handleMatchTimer,
    isExtraTimeFirstHalf,
    isExtraTimeFirstHalfEnded,
    isExtraTimeSecondHalf,
    isExtraTimeSecondHalfEnded,
    isFirstHalf,
    isFirstHalfEnded,
    isSecondHalf,
    isSecondHalfEnded,
    isTimerEnded,
} from 'utils'

const calculateTime = timer => {
    const time = isExtraTimeSecondHalfEnded(timer)
        ? moment(timer.et_second_half_end_time).add(105, 'm')
        : isExtraTimeSecondHalf(timer)
        ? moment.utc().add(105, 'm')
        : isExtraTimeFirstHalfEnded(timer)
        ? moment(timer.et_first_half_end_time).add(90, 'm')
        : isExtraTimeFirstHalf(timer)
        ? moment.utc().add(90, 'm')
        : isSecondHalfEnded(timer)
        ? moment(timer.second_half_end_time).add(45, 'm')
        : isSecondHalf(timer)
        ? moment.utc().add(45, 'm')
        : isFirstHalfEnded(timer)
        ? moment(timer.first_half_end_time)
        : isFirstHalf(timer)
        ? moment.utc()
        : moment.utc()

    return time
}

const isFirstRender = formattedTime => formattedTime === '0:00'

const handleFirstRender = formattedTime =>
    isFirstRender(formattedTime) ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> : formattedTime

const showTimer = (formattedTime, minutesAndExtraTime) =>
    minutesAndExtraTime.extraTime === 0 ? (
        handleFirstRender(formattedTime)
    ) : (
        <>
            {minutesAndExtraTime.minute}
            <sup className="italics text-60p">+{minutesAndExtraTime.extraTime}</sup>
        </>
    )

const getFormattedTime = (currentTime, timer) => {
    const minutesAndExtraTime = getMinutesAndExtraTime(timer, currentTime.asMinutes())
    const formattedSeconds = formatNumberWithPadding(currentTime.seconds())
    const timeString = `${Math.floor(currentTime.asMinutes())}:${formattedSeconds}`
    return { timeString, minutesAndExtraTime }
}

const TimerComponent = ({ fixture, className, liveHeight = 3 }) => {
    const { timer } = fixture

    const [currentTime, setCurrentTime] = useState(moment.duration(0))
    const startTime =
        timer.et_second_half_start_time ||
        timer.et_first_half_start_time ||
        timer.second_half_start_time ||
        timer.first_half_start_time
    const endTime =
        isExtraTimeSecondHalfEnded(timer) ||
        (isExtraTimeSecondHalf(timer) && currentTime.asMinutes() > 130) ||
        isExtraTimeFirstHalfEnded(timer) ||
        (isExtraTimeFirstHalf(timer) && currentTime.asMinutes() > 115) ||
        isSecondHalfEnded(timer) ||
        (isSecondHalf(timer) && currentTime.asMinutes() > 115) ||
        isTimerEnded(timer) ||
        isFirstHalfEnded(timer) ||
        (isFirstHalf(timer) && currentTime.asMinutes() > 70) ||
        false
    // console.log(`🔥 | file: TimerComponent.jsx:73 | endTime:`, endTime)

    const handleCurrentTime = timer => {
        const time = calculateTime(timer)
        const difference = moment.duration(time.diff(moment(startTime)))
        // if (checkTimeDifference(difference, timer, dispatch, startTime)) return
        setCurrentTime(difference)
    }

    useEffect(() => {
        let interval
        if (endTime) {
            handleCurrentTime(timer)
        } else if (startTime) {
            interval = setInterval(() => handleCurrentTime(timer), 1000)
        } else {
            clearInterval(interval)
            setCurrentTime(moment.duration(0))
        }
        return () => clearInterval(interval)
    }, [fixture])

    const { timeString, minutesAndExtraTime } = getFormattedTime(currentTime, timer)

    return (
        <div>
            <span className={classNames('leading-none text-success text-center fw-bold d-flex', className)}>
                {handleMatchTimer(fixture, currentTime) || showTimer(timeString, minutesAndExtraTime)}
            </span>
            <div
                className="live"
                style={{
                    height: `${liveHeight}px`,
                }}>
                <div className="live-move">
                    <div className="live-spin"></div>
                </div>
            </div>
        </div>
    )
}

export default TimerComponent
