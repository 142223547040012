import axios from 'axios'
import config from 'config'
import { LS_TOKEN_EXPIRES, LS_USER_DATA, LS_USER_TOKEN, LS_VERIFY_EMAIL_CANCELED } from 'constants'

// Set base url
axios.defaults.baseURL = config.API_URL

// Logout user if the status code is 401
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            const userLocalStorage = JSON.parse(localStorage.getItem(LS_USER_DATA))
            if (userLocalStorage) {
                localStorage.removeItem(LS_USER_TOKEN)
                localStorage.removeItem(LS_USER_DATA)
                localStorage.removeItem(LS_TOKEN_EXPIRES)
                localStorage.removeItem(LS_VERIFY_EMAIL_CANCELED)
            }
            window.location.href = '/login'
        }
        if(error.response.status === 422) {
            console.log('hi')
        }
        return Promise.reject(error)
    }
)

// Get default headers
export const getHeaders = headers => {
    return {
        // 'Content-Type': 'application/json',
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${localStorage.getItem(LS_USER_TOKEN)}`,
        ...headers,
    }
}

// get request with axios
export const getRequest = async (url, params = {}, headers = {}) => {
    try {
        return await axios.get(url, {
            params,
            headers: getHeaders(headers),
        })
    } catch (error) {
        const response = error ? error.response : error
        console.log(`Error: Request Url ${url} Error Response:`, response)
        return response
    }
}

// put request with axios
export const putRequest = async (url, data = {}, headers = {}) => {
    try {
        return await axios.put(url, data, { headers: getHeaders(headers) })
    } catch (error) {
        const response = error ? error.response : error
        console.log(`Error: Request Url ${url} Error Response:`, response)
        return response
    }
}

// post request with axios
export const postRequest = async (url, data = {}, headers = {}) => {
    try {
        return await axios.post(url, data, { headers: getHeaders(headers) })
    } catch (error) {
        const response = error ? error.response : error
        console.log(`Error: Request Url ${url} Error Response:`, response)
        return response
    }
}

// delete request with axios
export const deleteRequest = async (url, data = {}, headers = {}) => {
    try {
        return await axios.delete(url, { data, headers: getHeaders(headers) })
    } catch (error) {
        const response = error ? error.response : error
        console.log(`Error: Request Url ${url} Error Response:`, response)
        return response
    }
}
