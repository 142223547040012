import { getPlayerName, toNormalCase } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildTitle = event => {
    const { player, event_type: eventType } = event
    return (
        <>
            {toNormalCase(eventType)} <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}

const MatchEventTechnical = ({ event }) => {
    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            title={buildTitle(event)}
            image='<svg fill="grey" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="24" height="24" x="0" y="0" viewBox="0 0 319.925 319.925" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M195.622 272.938c12.65 0 22.903 10.253 22.903 22.908 0 12.645-10.253 22.897-22.903 22.897s-22.902-10.253-22.902-22.897c-.001-12.655 10.251-22.908 22.902-22.908zM181.206 0c12.153 0 22.008 9.856 22.008 22.012s-9.854 22.01-22.008 22.01c-12.158 0-22.012-9.854-22.012-22.01C159.194 9.856 169.047 0 181.206 0zM242.826 152.291a13.09 13.09 0 0 0 9.289 3.85c3.361 0 6.722-1.284 9.29-3.85 9.507-9.507 15.523-25.06 18.895-36.428 2.071-6.972 6.598-24.099 3.972-32.488-1.698-5.45-3.63-11.622-59.678-33.544a13.349 13.349 0 0 0-2.683-.725l.037-.182H143.47c.005.021.005.044.019.07-1.37-.039-2.752.08-4.138.492-4.246 1.278-19.524 8.15-37.715 42.929-16.808 32.138-24.78 47.043-24.853 47.191-3.42 6.4-1.007 14.359 5.394 17.779a13.044 13.044 0 0 0 6.183 1.554c4.691 0 9.229-2.519 11.594-6.946.08-.147 8.093-15.139 24.964-47.403 10.193-19.48 18.188-26.861 21.417-29.27-.583 27.134-11.33 50.58-18.225 62.87l-61.161 85.121a17.852 17.852 0 0 0-1.962 3.5l-28.729 68.315c-3.824 9.098.451 19.567 9.543 23.399a17.874 17.874 0 0 0 6.92 1.398c6.978 0 13.605-4.111 16.479-10.946l27.949-66.456 62.78-87.373 5.924 1.229-8.006 54.806-27.716 10.62c-9.217 3.531-13.823 13.861-10.294 23.079 2.727 7.104 9.504 11.475 16.689 11.475 2.125 0 4.283-.378 6.387-1.187l37.489-14.363a17.876 17.876 0 0 0 11.289-14.101l11.211-76.724c.062-.424.025-.836.057-1.255l14.017-73.646c15.778 6.237 33.73 14.1 41.56 18.462-1.449 10.19-7.721 32.188-15.705 40.172-5.142 5.128-5.142 13.444-.005 18.576z" data-original="#000000" class=""></path></g></svg>'
        />
    )
}

export default MatchEventTechnical
