// this reducer is for rate players model
const SHOW_RATE_PLAYERS_MODAL = 'SHOW_RATE_PLAYERS_MODAL'
const SHOW_RATE_PLAYERS_DONE_MODAL = 'SHOW_RATE_PLAYERS_DONE_MODAL'
const HIDE_RATE_PLAYERS_DONE_MODAL = 'HIDE_RATE_PLAYERS_DONE_MODAL'
const HIDE_RATE_PLAYERS_MODAL = 'HIDE_RATE_PLAYERS_MODAL'

const initialState = {
    showModal: false,
    showDoneModal: false,
    motm: null,
    ratings: [],
}

const ratePlayersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_RATE_PLAYERS_MODAL:
            return {
                ...state,
                showModal: true,
            }
        case HIDE_RATE_PLAYERS_MODAL:
            return {
                ...state,
                showModal: false,
            }
        case SHOW_RATE_PLAYERS_DONE_MODAL:
            return {
                ...state,
                showDoneModal: true,
            }
        case HIDE_RATE_PLAYERS_DONE_MODAL:
            return {
                ...state,
                showDoneModal: false,
            }
        default:
            return state
    }
}

export default ratePlayersReducer

// Rate Players
export const showRatePlayersModal = () => ({
    type: SHOW_RATE_PLAYERS_MODAL,
})

export const showRatePlayersDoneModal = () => ({
    type: SHOW_RATE_PLAYERS_DONE_MODAL,
})

export const hideRatePlayersDoneModal = () => ({
    type: HIDE_RATE_PLAYERS_DONE_MODAL,
})

export const hideRatePlayersModal = () => ({
    type: HIDE_RATE_PLAYERS_MODAL,
})
