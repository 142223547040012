import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import { Children, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Form } from 'react-final-form'
import { isTechnical } from 'utils'
import DrawerHeader from './DrawerHeader'

const Wizard = props => {
    const [page, setPage] = useState(props.page || 0)
    const [values, setValues] = useState(props.initialValues || {})
    const [pageFields, setPageFields] = useState([])

    const children = props.children.filter(child => (child.props?.hide ? !child.props.hide(values) : true))
    const activePage = Children.toArray(children)[page]
    const totalPages = Children.count(children)
    const isFirstPage = page === 0
    const isLastPage = page === totalPages - 1
    const showMinute = !isTechnical(props.event)

    const clearValuesWithoutFields = fields => {
        if (!fields) return
        const newValues = {}
        fields.forEach(field => {
            newValues[field] = values[field]
        })
        setValues(newValues)
    }
    const clearValuesUntilLastPage = () => {
        clearValuesWithoutFields(pageFields[Math.max(page - 2, 0)])
        setPageFields(pageFields.slice(0, Math.max(page - 1, 0)))
    }

    const getFieldsByValues = values => Object.keys(values)

    const nextPage = () => setPage(currentPage => Math.min(currentPage + 1, children.length - 1))

    const previousPage = () => {
        clearValuesUntilLastPage()
        setPage(currentPage => Math.max(currentPage - 1, 0))
    }

    const validate = values => (activePage && activePage.props.validate ? activePage.props.validate(values) : {})

    const handleFormSubmit = async values => {
        const { onSubmit } = props
        if (values.force_substitution || isLastPage) {
            const submitResult = await onSubmit(values)
            if (submitResult === false) return submitResult
            setValues({
                ...props.initialValues,
            })
            setPage(0)
            return submitResult
        } else {
            const fields = getFieldsByValues(values)
            setPageFields([...pageFields, fields])

            setValues(values)
            await nextPage()
        }
    }

    if (!activePage) return null

    return (
        <Form
            mutators={{
                ...arrayMutators,
                setEventType: (args, state, utils) => {
                    utils.changeValue(state, 'event_type', () => args[0])
                },
                setMatchHalf: (args, state, utils) => {
                    utils.changeValue(state, 'match_half', () => args[0])
                },
                setMinute: (args, state, utils) => {
                    utils.changeValue(state, 'minute', () => args[0])
                },
                setMinMaxMinutes: (args, state, utils) => {
                    const selectedMatchHalf = args[0]
                    let minMax = null
                    // The switch statement code remains the same
                    switch (selectedMatchHalf) {
                        case '1st':
                            minMax = { min: 0, max: 70 }
                            break
                        case '2nd':
                            minMax = { min: 46, max: 115 }
                            break
                        case 'et-1':
                            minMax = { min: 91, max: 115 }
                            break
                        case 'et-2':
                            minMax = { min: 106, max: 130 }
                            break
                        default:
                            minMax = { min: 0, max: 120 }
                            break
                    }
                    utils.changeValue(state, 'min_max_mins', () => minMax)
                    return minMax
                },
                setFirstId: (args, state, utils) => {
                    utils.changeValue(state, 'first_id', () => args[0])
                },
                setForceSubstitution: (args, state, utils) => {
                    utils.changeValue(state, 'force_substitution', () => args[0])
                },
            }}
            initialValues={values}
            validate={validate}
            onSubmit={handleFormSubmit}>
            {({ handleSubmit, form, submitting, values, valid }) => (
                <form onSubmit={handleSubmit} className="mw-100vw">
                    <DrawerHeader eventKey={props.event} values={values} isFirstPage={isFirstPage} />
                    {showMinute ? (
                        <div className="py-2 px-5c">
                            <div className="d-flex gap-2">
                                {Array.from(Array(totalPages).keys()).map(pageNumber => (
                                    <div
                                        className={classNames(
                                            'flex-1 h-1 rounded-pill',
                                            pageNumber < page + 1 ? 'bg-danger bright' : 'bg-main-light-plus-plus'
                                        )}
                                        key={pageNumber}></div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <hr className="mt-1 mb-2" />
                    )}
                    <div className="py-2">
                        <activePage.type
                            {...activePage.props}
                            mutators={form.mutators}
                            values={values}
                            nextPage={handleSubmit}
                            previousPage={previousPage}
                            form={form}
                        />
                    </div>
                    <hr className="mt-4 mb-0" />
                    <div className="py-2 px-5c py-md-3 d-flex align-items-center gap-3">
                        {/* <Button
                                    type="button"
                                    size="md"
                                    variant="danger"
                                    onClick={e => {
                                        e.preventDefault()
                                        form.mutators.setMatchHalf(minute.match_half)
                                        form.mutators.setMinMaxMinutes(minute.match_half)
                                        form.mutators.setMinute(minute.minute)
                                        setMinute(false)
                                    }}
                                    className="rounded-pill">
                                    Cancel
                                </Button>
                                <Button
                                    size="md"
                                    variant="success"
                                    type="button"
                                    onClick={e => {
                                        e.preventDefault()
                                        setMinute(false)
                                    }}
                                    className="rounded-pill ms-auto">
                                    Set Time
                                </Button> */}

                        {!isFirstPage && (
                            <Button
                                size="md"
                                variant="link"
                                type="button"
                                onClick={
                                    values.event_type === 'substitution'
                                        ? () => {
                                              previousPage()
                                              previousPage()
                                          }
                                        : previousPage
                                }>
                                <ChevronLeft size={18} />
                                Prev
                            </Button>
                        )}
                        {!isLastPage ? (
                            <Button size="md" variant="link" disabled={!valid} type="submit" className="ms-auto">
                                Next
                                <ChevronRight size={18} />
                            </Button>
                        ) : (
                            <Button
                                size="md"
                                variant="success"
                                type="submit"
                                disabled={!valid || submitting}
                                className="rounded-pill ms-auto">
                                Complete
                            </Button>
                        )}
                    </div>
                </form>
            )}
        </Form>
    )
}

Wizard.Page = ({ children, mutators, values, nextPage, form, previousPage }) => {
    if (typeof children === 'function') {
        return children(mutators, values, nextPage, form, previousPage)
    }
    return children
}

export default Wizard
