import { LOADER } from '../actions/actionTypes'

const initialState = false

const loader = (state = initialState, action) => {
    switch (action.type) {
        case LOADER:
            return action.payload
        default:
            return state
    }
}

export default loader