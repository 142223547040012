import { MatchEvents } from '../Event/MatchEvents'
import Info from './Info'

const MatchDetails = ({ tactical = false, details = true, deleteable = false }) => {
    return (
        <>
            {details && <Info />}
            <MatchEvents tactical={tactical} deleteable={deleteable} />
        </>
    )
}
export default MatchDetails
