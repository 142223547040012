import { SET_MASTER_DATA } from '../actions/actionTypes'

const masterData = (state = {}, action) => {
    switch (action.type) {
        case SET_MASTER_DATA: {
            if (action.payload === null) {
                return {}
            }
            const newState = {
                ...state,
                ...action.payload,
            }
            return newState
        }
        default:
            return state
    }
}

export default masterData
