import { getRequest, postRequest } from './apiRequest'

export const getNotifications = (page = 1) => {
    const url = `/notifications/get-notifications`
    return getRequest(url, { page })
}

export const isUnread = () => {
    const url = `/notifications/replenish`
    return getRequest(url)
}

export const setIsRead = notification_id => {
    const url = `/notifications/update-read`
    const data = {
        values: {
            notification_id,
        },
    }
    return postRequest(url, data)
}
