import moment from 'moment'

//TRAINING
const SET_TRAINING_DATA = 'SET_TRAINING_DATA'
const SET_TRAINING_LOADING = 'SET_TRAINING_LOADING'
const SET_SELECTED_TRAINING_LOADING = 'SET_SELECTED_TRAINING_LOADING'
const RESET_SELECTED_TRAINING_LOADING = 'RESET_SELECTED_TRAINING_LOADING'
const RESET_TRAINING_DATA = 'RESET_TRAINING_DATA'
const SET_TRAINING_SELECTED = 'SET_TRAINING_SELECTED'
const RESET_TRAINING_SELECTED = 'RESET_TRAINING_SELECTED'
const SET_TRAINING_SQUAD = 'SET_TRAINING_SQUAD'
const RESET_TRAINING_SQUAD = 'RESET_TRAINING_SQUAD'
const SET_TRAINING_EDIT = 'SET_TRAINING_EDIT'
const RESET_TRAINING_EDIT = 'RESET_TRAINING_EDIT'
const SET_TRAINING_DAYS = 'SET_TRAINING_DAYS'
const RESET_TRAINING_DAYS = 'RESET_TRAINING_DAYS'
const SET_ADD_TRAINING = 'SET_ADD_TRAINING'
const RESET_ADD_TRAINING = 'RESET_ADD_TRAINING'
const SET_TRAINING_PAGE = 'SET_TRAINING_PAGE'
const RESET_TRAINING_PAGE = 'RESET_TRAINING_PAGE'

const startDate = moment().startOf('week').format('YYYY-MM-DD')
const endDate = moment().endOf('week').format('YYYY-MM-DD')

const initialState = {
    list: [],
    isLoading: false,
    isSelectedLoading: false,
    selected: null,
    squadSlug: null,
    edit: null,
    add: null,
    page: 0,
    startDate,
    endDate,
}

const training = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRAINING_DATA:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case SET_TRAINING_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case SET_SELECTED_TRAINING_LOADING:
            return {
                ...state,
                isSelectedLoading: true,
            }
        case RESET_SELECTED_TRAINING_LOADING:
            return {
                ...state,
                isSelectedLoading: false,
            }
        case RESET_TRAINING_DATA:
            return initialState
        case SET_TRAINING_SELECTED:
            return {
                ...state,
                selected: action.payload,
                isSelectedLoading: false,
            }
        case RESET_TRAINING_SELECTED:
            return {
                ...state,
                selected: null,
            }

        case SET_TRAINING_SQUAD:
            return {
                ...state,
                squadSlug: action.payload,
            }
        case RESET_TRAINING_SQUAD:
            return {
                ...state,
                squadSlug: null,
            }
        case SET_TRAINING_EDIT:
            return {
                ...state,
                edit: action.payload,
            }
        case RESET_TRAINING_EDIT:
            return {
                ...state,
                edit: null,
            }
        case SET_ADD_TRAINING:
            return {
                ...state,
                add: action.payload,
            }
        case RESET_ADD_TRAINING:
            return {
                ...state,
                add: null,
            }
        case SET_TRAINING_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case RESET_TRAINING_PAGE:
            return {
                ...state,
                page: 0,
            }
        case SET_TRAINING_DAYS:
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            }
        case RESET_TRAINING_DAYS:
            return {
                ...state,
                startDate: null,
                endDate: null,
            }
        default:
            return state
    }
}

export default training

export const setTrainingData = data => ({
    type: SET_TRAINING_DATA,
    payload: data,
})

export const loadingTraining = () => ({
    type: SET_TRAINING_LOADING,
})

export const loadingSelectedTraining = () => ({
    type: SET_SELECTED_TRAINING_LOADING,
})

export const resetLoadingSelectedTraining = () => ({
    type: RESET_SELECTED_TRAINING_LOADING,
})

export const resetTraining = () => ({
    type: RESET_TRAINING_DATA,
})

export const setSelectedTraining = data => ({
    type: SET_TRAINING_SELECTED,
    payload: data,
})

export const resetSelectedTraining = () => ({
    type: RESET_TRAINING_SELECTED,
})

export const setTrainingSquad = data => ({
    type: SET_TRAINING_SQUAD,
    payload: data,
})

export const resetTrainingSquad = () => ({
    type: RESET_TRAINING_SQUAD,
})

export const setAddTraining = data => ({
    type: SET_ADD_TRAINING,
    payload: data,
})

export const resetAddTraining = () => ({
    type: RESET_ADD_TRAINING,
})

export const setEditTraining = data => ({
    type: SET_TRAINING_EDIT,
    payload: data,
})

export const resetEditTraining = () => ({
    type: RESET_TRAINING_EDIT,
})

export const setPage = data => ({
    type: SET_TRAINING_PAGE,
    payload: data,
})

export const resetTrainingPage = () => ({
    type: RESET_TRAINING_PAGE,
})

export const setTrainingDays = (startDate, endDate) => ({
    type: SET_TRAINING_DAYS,
    payload: { startDate, endDate },
})

export const resetTrainingDays = () => ({
    type: RESET_TRAINING_DAYS,
})

export const getTrainingDaysByDate = date => dispatch => {
    const startOfThisWeek = moment().startOf('week')
    const startOfWeek = moment(date).startOf('week')
    const endOfWeek = moment(date).endOf('week')
    dispatch(setTrainingDays(startOfWeek.format('YYYY-MM-DD'), endOfWeek.format('YYYY-MM-DD')))
    const page = startOfWeek.diff(startOfThisWeek, 'weeks')
    dispatch(setPage(page))
}

export const setTrainingPage = page => dispatch => {
    const start = page ? moment().add(page, 'week') : moment()
    dispatch(getTrainingDaysByDate(start))
}

// push the data to the store
export const addTraining = data => (dispatch, getState) => {
    const state = getState()
    const training = state.training.list
    training.push(data)
    dispatch(setTrainingData(training))
}

// remove the data from the store

export const removeTraining = id => (dispatch, getState) => {
    const state = getState()
    const training = state.training.list.filter(item => (item.id || item.parent_id) !== id)
    dispatch(setTrainingData(training))
}

//  add attendance to the store
export const addTrainingAttendance = data => (dispatch, getState) => {
    const { training } = getState()
    const { list } = training
    const newList = list.map(item => {
        if (item.id === data.id) {
            return data
        }
        return item
    })
    dispatch(setTrainingData(newList))
    dispatch(setSelectedTraining(data))
}

export const updateTraining = (data, id) => (dispatch, getState) => {
    const { training } = getState()
    const { list } = training
    const newList = list.map(item => {
        if (item.id === null ? item.parent_id === id : item.id === id) {
            return data
        }
        return item
    })
    dispatch(setTrainingData(newList))
}
