import { EventSquadLayout } from 'components/Layout'
import {
    loadFixture,
    loadingFixture,
    resetLoadingFixture,
    setMatchEventsData,
    setSelectedFixture,
} from 'components/store'
import { setLoader } from 'components/store/actions'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getFixture } from 'services'
import { getAllSearchParams } from 'utils'
import FixtureBody from '../FixtureBody'

const FixtureModal = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)
    const { match } = params
    const { isLoading, data: fixture } = useSelector(state => state.fixture)
    const [isRequesting, setIsRequesting] = useState(false)

    const reset = () => {
        const { match, ...rest } = params
        setSearchParams(rest)
    }

    const getData = async (squadSlug, date) => {
        if (isRequesting) return
        if (!squadSlug || !date) return
        dispatch(setLoader(true))
        dispatch(loadingFixture())
        setIsRequesting(true)
        const response = await getFixture(squadSlug, date)
        if (response && response.status === 202 && response.data && response.data.status === 'success') {
            const { data: fixtureData } = response.data
            const { match_events } = fixtureData
            dispatch(setSelectedFixture(date, squadSlug))
            dispatch(loadFixture(fixtureData))
            dispatch(setMatchEventsData(match_events))
        } else {
            dispatch(resetLoadingFixture())
            reset()
        }
        dispatch(setLoader(false))
        setIsRequesting(false)
    }

    if (!isLoading && isEmpty(fixture) && match) {
        const [squadSlug, date] = match.split('|')
        getData(squadSlug, date)
    }

    if (!match) return null
    if (isLoading) return null
    if (isEmpty(fixture)) return null

    return (
        <Modal
            show={true}
            // onHide={handleClose}
            backdrop={false}
            centered
            className="fixture-modal fill-in colorized">
            <Modal.Body>
                <EventSquadLayout event="fixture">
                    <FixtureBody />
                </EventSquadLayout>
            </Modal.Body>
        </Modal>
    )
}
export default FixtureModal
