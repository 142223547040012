import { getPlayerName } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildTitle = event => {
    const { player } = event
    return (
        <>
            Corner Kick <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}
const MatchEventCorner = ({ event }) => {
    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            title={buildTitle(event)}
            image='<svg width="24" height="24" fill="#e8e8e8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path class="st0" d="M15.7,20c-0.1-1.6-0.6-5.1-3.4-7.9C9.4,9.3,6,8.8,4,8.8V3.2H2.5v18.3h18.2V20H15.7z M4,20v-9.7 c1.8,0,4.8,0.4,7.2,2.8c2.5,2.4,2.9,5.4,3,6.9H4z"/></svg>'
        />
    )
}
export default MatchEventCorner
