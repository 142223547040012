import { useSelector } from 'react-redux'

const LineUpPlayerYellowCard = ({ player, x, y }) => {
    const { list: matchEvents } = useSelector(state => state.matchEvents)

    const playerHasYellow = matchEvents?.find(
        event => event.event_type === 'yellow_card' && event.player.id === player.id
    )
    const playerHasMultipleYellows =
        matchEvents?.filter(event => event.event_type === 'yellow_card' && event.player.id === player.id)?.length > 1

    if (playerHasMultipleYellows) {
        return (
            <g
                fillRule="evenodd"
                style={{
                    transform: 'translate(-5.5px, -15px) scale(0.35)',
                    transformBox: 'fill-box',
                    transformOrigin: 'center',
                }}>
                <svg x={x} y={y}>
                    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="2nd-yellow-right">
                            <rect id="Rectangle-558" x="0" y="0" width="24" height="24" />
                            <rect
                                id="yellow"
                                stroke="#F57F17"
                                fill="#FFEB3B"
                                transform="translate(10.000000, 12.000000) rotate(-346.000000) translate(-10.000000, -12.000000) "
                                x="5"
                                y="5"
                                width="10"
                                height="14"
                                rx="2"
                            />
                            <rect
                                id="red"
                                stroke="#B0120A"
                                fill="#E51C23"
                                transform="translate(14.000000, 11.000000) rotate(-346.000000) translate(-14.000000, -11.000000) "
                                x="9"
                                y="4"
                                width="10"
                                height="14"
                                rx="2"
                            />
                        </g>
                    </g>
                </svg>
            </g>
        )
    }

    if (!playerHasYellow) return null

    return (
        <rect
            style={{
                transform: 'translate(3.5px, -6.3px) rotate(20deg) scale(0.65)',
                transformBox: 'fill-box',
                transformOrigin: 'center',
            }}
            id="red"
            stroke="#F57F17"
            fill="#FFEB3B"
            x={x}
            y={y}
            width="5"
            height="7"
            rx="1"
            strokeWidth="0.1"
        />
    )
}

export default LineUpPlayerYellowCard
