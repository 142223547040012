import { getRequest, postRequest } from './apiRequest'

// logout
export const logout = () => {
    const url = `/user/logout`
    return postRequest(url)
}

// check hash
export const checkHash = hash => {
    const url = `/user/check-hash/${hash}`
    return getRequest(url)
}

// reset password
export const resetPassword = values => {
    const url = `/user/update-password`
    return postRequest(url, values)
}
