import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from './firebase'

const useFirestoreDocument = (collectionName, docId) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const docRef = doc(db, collectionName, docId)
        const unsubscribe = onSnapshot(docRef, doc => {
            if (doc.exists()) {
                setData({ id: doc.id, ...doc.data() })
                setLoading(false)
            } else {
                setData(null)
                setLoading(false)
            }
        })
        return unsubscribe
    }, [collectionName, docId])

    return { data, loading }
}

export default useFirestoreDocument
