import { useCurrentPath } from 'hooks'
import { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { anyRoute } from './LandingRoutes'

const Login = lazy(() => import('components/FullPage/pages/Login'))
const Register = lazy(() => import('components/FullPage/pages/Register'))
const ForgotPassword = lazy(() => import('components/FullPage/pages/ForgotPassword'))

export const publicRoutes = [
    {
        path: '/login',
        element: Login,
    },
    {
        path: '/register',
        element: Register,
    },
    {
        path: '/forgot-password',
        element: ForgotPassword,
    },
]

export const publicRoutesPath = publicRoutes.map(route => route.path)

const PublicRoutes = () => {
    const user = useSelector(state => state.user)
    const currentPath = useCurrentPath([...publicRoutes, ...anyRoute])
    const isAnyRoute = anyRoute.map(r => r.path).includes(currentPath)
    const isPublicRoute = publicRoutesPath.includes(currentPath)

    if (!isPublicRoute || user || isAnyRoute) return null

    return (
        <Suspense fallback={<div />}>
            <Routes>
                {publicRoutes.map(({ path, element: Component }, index) => {
                    return <Route path={path} element={<Component />} key={index} />
                })}
            </Routes>
        </Suspense>
    )
}

export default PublicRoutes
