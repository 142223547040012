export const LOADER = 'LOADER'
export const SIDEBAR = 'SIDEBAR'
export const SIDEBAR_PIN = 'SIDEBAR_PIN'
export const TOGGLE_PIN = 'TOGGLE_PIN'
export const LOGIN_MODAL = 'LOGIN_MODAL'
export const SET_MASTER_DATA = 'MASTER_DATA'

export const ADD_PAST_EVENTS_TO_GROUP = 'ADD_PAST_EVENTS_TO_GROUP'
export const ADD_UPCOMING_EVENTS_TO_GROUP = 'ADD_UPCOMING_EVENTS_TO_GROUP'
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'
export const SET_STAFF_DATA = 'SET_STAFF_DATA'

export const SET_MATCH_DETAIL_DATA = 'SET_MATCH_DETAIL_DATA'

export const SET_SEARCH_GROUPS_DATA = 'SET_SEARCH_GROUPS_DATA'
export const SET_SEARCH_PLAYERS_DATA = 'SET_SEARCH_PLAYERS_DATA'
export const SET_SEARCH_GAMES_DATA = 'SET_SEARCH_GAMES_DATA'
export const SET_SEARCH_CLUBS_DATA = 'SET_SEARCH_CLUBS_DATA'

export const EMPTY_GROUP_DETAIL_DATA = 'EMPTY_GROUP_DETAIL_DATA'
export const LOAD_GROUP_DETAIL_DATA = 'LOAD_GROUP_DETAIL_DATA'
export const SET_GROUP_DETAIL_DATA = 'SET_GROUP_DETAIL_DATA'
export const SET_EVENT_DETAIL_DATA = 'SET_EVENT_DETAIL_DATA'
export const SET_EVENT_ONLY_DETAIL_DATA = 'SET_EVENT_ONLY_DETAIL_DATA'
export const HAS_NOTIFICATION_DATA = 'HAS_NOTIFICATION_DATA'

export const UPDATE_SINGLE_NOTIFICATION = 'UPDATE_SINGLE_NOTIFICATION'

export const LOAD_CLUB_DETAIL_DATA = 'LOAD_CLUB_DETAIL_DATA'
export const SET_CLUB_DETAIL_DATA = 'SET_CLUB_DETAIL_DATA'
export const SET_CLUB_SQUAD_DETAIL_DATA = 'SET_CLUB_SQUAD_DETAIL_DATA'
export const SET_SQUAD_PLAYER_DATA = 'SET_SQUAD_PLAYER_DATA'
export const CLEAR_CLUB_SQUAD_DETAIL_DATA = 'CLEAR_CLUB_SQUAD_DETAIL_DATA'

export const ADD_EXISTING_MEMBERS_TO_GROUP = 'ADD_EXISTING_MEMBERS_TO_GROUP'
export const ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE = 'ADD_EXISTING_MEMBERS_TO_GROUP_PAGINATE'
export const ADD_PENDING_MEMBERS_TO_GROUP = 'ADD_PENDING_MEMBERS_TO_GROUP'
export const ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE = 'ADD_PENDING_MEMBERS_TO_GROUP_PAGINATE'
export const ADD_BLOCKED_MEMBERS_TO_GROUP = 'ADD_BLOCKED_MEMBERS_TO_GROUP'
export const ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE = 'ADD_BLOCKED_MEMBERS_TO_GROUP_PAGINATE'

// FORMATION PICKER ACTIONS
export const SET_FORMATION_PICKER_DEFAULT_VALUES = 'SET_FORMATION_PICKER_DEFAULT_VALUES'
export const SET_LINEUP = 'SET_LINEUP'
export const ADD_TO_LINEUP = 'ADD_TO_LINEUP'
export const DELETE_FROM_LINEUP = 'REMOVE_FROM_LINEUP'
export const SET_NEW_FORMATION = 'SET_FORMATION'
export const SET_IS_CUSTOM_FORMATION = 'SET_IS_CUSTOM_FORMATION'
export const SET_SUBSTITUTIONS = 'SET_SUBSTITUTIONS'
export const FILTER_SUBSTITUTUIONS = 'FILTER_SUBSTITUTUIONS'
export const FILTER_LINEUP = 'FILTER_LINEUP'
export const RESET_SUBSTITUTIONS = 'RESET_SUBSTITUTIONS'
export const RESET_LINEUP = 'RESET_LINEUP'
