import { Button, Menu, MenuItem } from '@mui/material'
import { logout } from 'components/store/actions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const AdminLayout = ({ children }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // handleLogout = () => {
    //     this.props.logout().then(e =>
    //         this.setState({
    //             redirect: true,
    //         })
    //     )
    // }
    const handleLogout = () => {
        handleClose()
        dispatch(logout())
    }

    return (
        <div className="d-flex flex-column vh-100">
            <nav className="nav d-flex py-3 align-items-center shadow">
                <div className="container d-flex justify-content-between align-items-center">
                    <h4 className="admin-heading my-0">Scouty Admin Panel</h4>
                    <div>
                        <Button
                            id="user-button"
                            variant="complete"
                            aria-controls={open ? 'user-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            {user.name}
                        </Button>
                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'user-button',
                            }}>
                            <MenuItem onClick={handleClose} as={Link} to="/dashboard">
                                Dashboard
                            </MenuItem>
                            <MenuItem onClick={handleClose} as={Link} to="/dashboard/profile">
                                My Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </div>
            </nav>
            <div className="container py-3 flex-grow-1 overflow-hidden">{children}</div>
        </div>
    )
}
export default AdminLayout
