import React, { useState } from 'react'

const OutdoorField = ({ hasControlPanel = false }) => {
    const [strokeLineDefault, setStrokeLineDefault] = useState(true)
    const fieldFill = !strokeLineDefault ? '#2f4554' : '#3c673c'
    const fieldLineStroke = !strokeLineDefault ? '#717e89' : 'rgb(179 226 191)'

    return (
        <>
            <g fill="none" fillRule="evenodd">
                <rect fill={fieldFill} width="120" height="180" rx="1.4"></rect>
                <path stroke={fieldLineStroke} strokeWidth="0.5" strokeLinejoin="round" d="M4 4h112v86H4z"></path>
                <path
                    d="M72 90c0-6.627-5.373-12-12-12s-12 5.373-12 12M31 4h58v26H31zM50 30c.156.28.322.555.499.82 2.084 3.13 5.562 5.18 9.5 5.18 3.942 0 7.422-2.052 9.505-5.184.176-.264.34-.537.496-.816"
                    stroke={fieldLineStroke}
                    strokeWidth="0.5"
                    strokeLinejoin="round"></path>
                <path stroke={fieldLineStroke} strokeWidth="0.5" strokeLinejoin="round" d="M46 4h28v8H46z"></path>
                <circle cx="1" cy="1" r="1" transform="translate(59 22)" fill={fieldLineStroke}></circle>
                <path
                    d="M4 8a4 4 0 0 0 4-4M112 4a4 4 0 0 0 4 4"
                    stroke={fieldLineStroke}
                    strokeWidth="0.5"
                    strokeLinejoin="round"></path>
                <g>
                    <path stroke={fieldLineStroke} strokeWidth="0.5" strokeLinejoin="round" d="M4 176h112V90H4z"></path>
                    <path
                        d="M72 90c0 6.627-5.373 12-12 12s-12-5.373-12-12M31 176h58v-26H31zM50 150c.156-.28.322-.555.499-.82 2.084-3.13 5.562-5.18 9.5-5.18 3.942 0 7.422 2.052 9.505 5.184.176.264.34.537.496.816"
                        stroke={fieldLineStroke}
                        strokeWidth="0.5"
                        strokeLinejoin="round"></path>
                    <path
                        stroke={fieldLineStroke}
                        strokeWidth="0.5"
                        strokeLinejoin="round"
                        d="M46 176h28v-8H46z"></path>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 59 158)" fill={fieldLineStroke}></circle>
                    <path
                        d="M4 172a4 4 0 0 1 4 4M112 176a4 4 0 0 1 4-4"
                        stroke={fieldLineStroke}
                        strokeWidth="0.5"
                        strokeLinejoin="round"></path>
                </g>
                {/* <rect fill="yellow" width="30" height="20" x="4" y="4"></rect>
            <rect fill="pink" width="15" height="30" x="34" y="4"></rect>
            <rect fill="red" width="22" height="30" x="49" y="4"></rect>
            <rect fill="pink" width="15" height="30" x="71" y="4"></rect>
            <rect fill="green" width="30" height="20" x="86" y="4"></rect>
            <rect fill="purple" width="30" height="40" x="4" y="24"></rect>
            <rect fill="white" width="30" height="40" x="86" y="24"></rect>
            <rect fill="maroon" width="52" height="30" x="34" y="34"></rect>
            <rect fill="beige" width="52" height="40" x="34" y="64"></rect>
            <rect fill="orange" width="30" height="40" x="4" y="64"></rect>
            <rect fill="lightgreen" width="52" height="30" x="34" y="104"></rect>
            <rect fill="blue" width="30" height="30" x="4" y="104"></rect>
            <rect fill="orange" width="30" height="40" x="86" y="64"></rect>
            <rect fill="blue" width="30" height="30" x="86" y="104"></rect>
            <rect fill="magenta" width="30" height="42" x="4" y="134"></rect>
            <rect fill="grey" width="52" height="25" x="34" y="134"></rect>
            <rect fill="magenta" width="30" height="42" x="86" y="134"></rect> */}
            </g>
        </>
    )
}

export default OutdoorField
