import classNames from 'classnames'
import Avatar from 'components/shared/Avatar'
import { Container } from 'react-bootstrap'
import { ChevronLeft } from 'react-feather'
import { useSelector } from 'react-redux'
import { getAwayGoals, getHomeGoals, isHome } from 'utils'

const AVATAR_SIZE = 30

const FixtureTop = ({ handleClose }) => {
    const { data: fixture } = useSelector(state => state.fixture)
    const { squad, ground, opponent } = fixture

    if (!fixture || !squad) return null

    return (
        <div className="fixture-top">
            <Container className="d-flex justify-content-between">
                {/* <Breadcrumbs separator="›" aria-label="breadcrumb"> */}
                {/* {width >= 768 && (
                        <>
                            <Link as={RouterLink} color="inherit" to={`/dashboard/squads/${squad.squad_slug}`}>
                                {squad.name}
                            </Link>
                        </>
                    )} */}
                {/* <Link as={RouterLink} color="inherit" to={`/dashboard/squads/${squad.squad_slug}/fixtures`}>
                        Matches
                    </Link> */}
                {/* <Typography as="span" color="text.primary">
                        {`${squad.name} vs ${opponent.name} at ${moment.utc(date).format('Do MMM')}`}
                    </Typography> */}
                {/* </Breadcrumbs> */}
                <div
                    className={classNames(
                        isHome(ground) ? 'flex-row' : 'flex-row-reverse',
                        'd-flex align-items-center justify-content-between w-100 d-md-none'
                    )}>
                    <Avatar width={AVATAR_SIZE} height={AVATAR_SIZE} boxed src={squad.photo_url_sm} alt={squad.name} />

                    {/* <div className="d-flex align-items-center justify-content-between w-13"> */}
                    <h3 className="my-0 leading-none">{getHomeGoals(fixture)}</h3>
                    <span>FT</span>
                    <h3 className="my-0 leading-none">{getAwayGoals(fixture)}</h3>
                    {/* </div> */}

                    <Avatar width={AVATAR_SIZE} height={AVATAR_SIZE} boxed />
                </div>
                <div
                    className={classNames(
                        'fixture-top-teams d-none d-md-inline-flex text-white',
                        isHome(ground) ? 'flex-row' : 'flex-row-reverse'
                    )}>
                    <div className="fixture-top-team-name">{squad.name}</div>
                    <div className="fixture-top-score-divider">vs</div>
                    <div className="fixture-top-team-name">{opponent.name}</div>
                </div>
                <button type="button" onClick={handleClose} className="btn-close d-none d-md-flex">
                    <ChevronLeft size={32} />
                    <span>Back</span>
                </button>
            </Container>
        </div>
    )
}

export default FixtureTop
