import { SIDEBAR } from '../actions/actionTypes'

const initialState = {
    shown: true
}

const sidebar = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR:
            return {
                ...state,
                shown: action.payload
            }
        default:
            return state
    }
}

export default sidebar