import RSVPMatch from 'components/Dashboard/pages/Squad/Fixtures/FixtureBody/RSVPMatch'
import RSVPTraining from 'components/Dashboard/pages/Squad/Training/TrainingModal/RSVPTraining'
import { updateSelectedDayEvents, updateUpcomingEvent } from 'components/store'
import moment from 'moment-timezone'
import { useState } from 'react'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { Check, Circle, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { rsvpMatch, rsvpTraining } from 'services'
import { isMatch } from 'utils'

export const RSVPButton = ({ event }) => {
    console.log('event', event)
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [submitting, setSubmitting] = useState(false)
    const [modal, showModal] = useState(false)

    const handleClose = () => showModal(false)
    const handleShow = () => showModal(true)

    const setRSVP = async status => {
        if (!['yes', 'no'].includes(status)) return
        setSubmitting(true)
        const response = isMatch(event)
            ? await rsvpMatch(event.squad.squad_slug, user.id, status, event.id)
            : await rsvpTraining(event.squad.squad_slug, user.id, status, event.id || event.parent_id, event.date)

        if (response && response.status === 202 && response.data && response.data.status === 'success') {
            const {
                data: { data: newData },
            } = response
            const im_attending = status === 'yes'
            const payload = {
                id: event.id || event.parent_id,
                date: event.date,
                type: event.type,
                data: {
                    im_attending,
                },
            }
            if (!isMatch(event)) payload.data.id = newData.id
            dispatch(updateUpcomingEvent(payload))
            dispatch(updateSelectedDayEvents(payload))
        }
        setSubmitting(false)
    }

    const eventPassed = ev => {
        const { start_time } = ev
        const now = moment.utc()
        const startTime = moment.utc(start_time)

        if (startTime.diff(now) <= 0) {
            return true
        }

        return false
    }

    return (
        <>
            {eventPassed(event) ? (
                event.im_attending ? (
                    <div className="d-flex align-items-center">
                        <div>
                            <Circle fill="#66bb6a" stroke="#66bb6a" size={16} />
                        </div>
                        <div className="m-l-5 user-select-none">
                            <span>Went</span>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <div className="m-l-5 user-select-none">
                            <Circle fill="#f44336" stroke="#f44336" size={16} />
                            <span>Didnt Go</span>
                        </div>
                    </div>
                )
            ) : (
                <DropdownButton
                    variant="info"
                    id="dropdown-basic-button"
                    className="event-dropdown"
                    onSelect={setRSVP}
                    disabled={submitting || eventPassed(event)}
                    title={
                        <div className="d-flex align-items-center user-select-none">
                            {event.im_attending ? (
                                <>
                                    <div>
                                        <Circle fill="#66bb6a" stroke="#66bb6a" size={16} />
                                    </div>
                                    <div className="m-l-5 fw-semibold">
                                        <span>Going</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Circle fill="#f44336" stroke="#f44336" size={16} />
                                    <div className="m-l-5 fw-semibold">
                                        <span>Not Going</span>
                                    </div>
                                </>
                            )}
                        </div>
                    }>
                    {[
                        { value: 'yes', label: 'Going' },
                        { value: 'no', label: 'Not going' },
                    ].map(option => (
                        <Dropdown.Item key={option.value} eventKey={option.value}>
                            {option.label}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            )}
        </>
    )
}
