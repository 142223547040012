import { useSelector } from 'react-redux'
import { convertRatingToRated } from 'utils'

const LineUpPlayerRating = ({ player, x, y }) => {
    const {
        data: { players },
    } = useSelector(state => state.fixture)
    const ratePlayer = players.find(p => p?.player?.id === player?.id)
    const rating = ratePlayer?.rating?.rating

    if (!rating) return null

    return (
        <>
            <rect
                x={x}
                y={y}
                width="4.7"
                height="4.7"
                rx="0.3"
                fontSize={2.7}
                strokeWidth="0.4"
                fontWeight="bold"
                style={{ transform: 'translate(-8px, -5.5px)' }}
                className={`player--rating-rating rating-${parseInt(rating)}`}>
                <text x={x} y={y} textAnchor="middle" className="position-text">
                    {convertRatingToRated(rating)}
                </text>
            </rect>
            <text
                x={x}
                y={y}
                height="4.7"
                width="4.7"
                fontWeight="bold"
                ali
                fontSize={3}
                letterSpacing="-0.5"
                style={{ transform: `translate(-7.8px, -2px)` }}>
                {convertRatingToRated(rating)}
            </text>
        </>
    )
}

export default LineUpPlayerRating
