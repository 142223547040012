import FinalFormError from 'components/shared/FinalFormError'
import { useWindowSize } from 'hooks'
import isString from 'lodash/isString'
import { useState } from 'react'
import { Search, X } from 'react-feather'
import { LocationPicker } from './LocationPicker'

export const LocationPickerMobile = ({
    value,
    onChange,
    meta,
    selectProps,
    placeholder,
    placeholderMobile,
    cityOnly = false,
    ...rest
}) => {
    const { width } = useWindowSize()
    const [showLocation, setShowLocation] = useState(false)

    if (showLocation && width >= 768) setShowLocation(false)

    return (
        <>
            {width >= 768 ? (
                <LocationPicker
                    value={isString(value) ? value : value?.label}
                    onChange={onChange}
                    className="relative"
                    cityOnly={cityOnly}
                    selectProps={{
                        ...selectProps,
                        placeholder,
                    }}
                    {...rest}
                />
            ) : (
                <>
                    <div className="location-picker-placeholder" onClick={() => setShowLocation(true)}>
                        <Search size={16} className="text-white" />
                        <p className="location-picker-placeholder-text">
                            {(isString(value) ? value : value?.label) || placeholder}
                        </p>
                    </div>
                    {showLocation && (
                        <div className="location-modal">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="">{placeholder}</h4>
                                <button
                                    type="button"
                                    className="w-5 h-5 p-0 flex-center btn-clear text-white hint-text"
                                    onClick={() => setShowLocation(false)}>
                                    <X />
                                </button>
                            </div>
                            <LocationPicker
                                value={isString(value) ? value : value?.label}
                                onChange={e => {
                                    onChange(e)
                                    setShowLocation(false)
                                }}
                                className="relative"
                                cityOnly={cityOnly}
                                selectProps={{
                                    ...selectProps,
                                    placeholder: placeholderMobile || placeholder,
                                }}
                                {...rest}
                            />
                        </div>
                    )}
                </>
            )}
            {meta && <FinalFormError meta={meta} />}
        </>
    )
}
