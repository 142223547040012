import FinalFormError from 'components/shared/FinalFormError'
import ReactSelectAdapter from 'components/shared/ReactSelectAdapter'
import Toast from 'components/shared/Toast'
import { setUser } from 'components/store'
import { updateClubSquadDetail } from 'components/store/actions'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Form as BsForm, Card } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { completeOnboarding, getSquad } from 'services'
import { validateEmail } from 'utils'
import EditShirts from '../Squad/EditSquad/EditShirts'
import LogoCropper from '../Squad/EditSquad/LogoCropper'
import Wizard from './Wizard'
import validation from './validation'

// function that sets the next time making sure it falls within correct parameters
// like if the location chosen in paris, time should reflect that

const OnBoardingSquad = ({ addToast, squadSlug }) => {
    // console.log(`🔥 | file: index.js:20 | squad:`, squad)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { clubSquadDetail: squad, user } = useSelector(state => state)
    // const squadDetail = useSelector(state => state.clubSquadDetail)
    // const { backroom_staffs: staffTypes } = useSelector(state => state.masterData)

    // invite players
    const [selected, setSelected] = useState([])

    const [helperText, setHelperText] = useState('')

    // const { photo: isPhoto, photo_html: photo } = squad

    const onSubmit = async data => {
        const { bio, shirts } = data
        const emails = selected.map(item => item.value)
        const response = await completeOnboarding(squadSlug, emails, bio, shirts)

        if (response.status === 200 && response.data && response.data.status === 'success') {
            const {
                data: { user, squad },
            } = response
            dispatch(setUser(user))
            dispatch(updateClubSquadDetail(squad))
        }
    }

    const fetchSquad = async () => {
        try {
            const squadResponse = await getSquad(squadSlug)
            const squadData = squadResponse.data

            if (squadData && squadData.status === 'success') {
                dispatch(updateClubSquadDetail(squadData.data))
            } else if (squadData && squadData.status === 'error') {
                navigate('/dashboard')
            }
        } catch (error) {
            console.log(error)
            navigate('/dashboard')
        }
    }

    const handleInviteTextChange = (value, input) => {
        input.onChange(value)
        if (value) setHelperText('')
    }

    const handleInviteBlur = (event, input) => {
        const { value } = event.target

        if (!value) return
        if (!validateEmail(value)) {
            input.onChange(value)
            return setHelperText('Please enter a valid email address')
        }

        const isExist = selected.find(item => item.value === value)
        if (isExist) {
            setHelperText('This email address is already added')
            input.onChange(value)
            return
        }
        setSelected([...selected, { value: value, label: value }])
        input.onChange('')
    }

    const handleInviteOnChange = value => setSelected(value)

    const handleInviteKey = (event, input) => {
        if (!['Enter', 'Tab', ' '].includes(event.key)) return
        event.preventDefault()
        handleInviteBlur(event, input)
    }

    useEffect(() => {
        if (squadSlug && squadSlug !== squad?.squad_slug) fetchSquad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        !isEmpty(squad) &&
        user && (
            <Wizard
                squad={squad}
                initialValues={{
                    bio: squad.bio,
                    // invite_player: null,
                    logo: squad?.photo_url_md ? squad?.photo_url_md : null,
                    oldLogo: squad?.photo_url_md ? squad?.photo_url_md : null,
                    shirts: squad?.shirts || {
                        home: 3,
                        away: 2,
                        gk1: 1,
                        gk2: 2,
                    },
                }}
                user={user}
                onSubmit={onSubmit}>
                <Wizard.Page validate={validation.bio}>
                    {(mutators, values) => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>About {squad.name}</h4>
                                <p>Tell us a little bio about your squad</p>
                            </Card.Header>
                            <Card.Body>
                                <Field name="bio">
                                    {({ input, meta }) => (
                                        <BsForm.Group className="mb-4">
                                            <div className="relative">
                                                <textarea
                                                    {...input}
                                                    autoComplete="off"
                                                    placeholder="Briefly history of the club"
                                                    className="form-control scrollbar-theme"
                                                    rows={5}
                                                    style={{ resize: 'none' }}
                                                />
                                                <FinalFormError inline meta={meta} />
                                                <span className="d-flex pull-right bold">
                                                    <span
                                                        className={`text-${
                                                            (input.value.length < 100 || input.value.length > 500) &&
                                                            'danger bright'
                                                        } pull-right`}>
                                                        {input.value.length}
                                                    </span>
                                                    /500
                                                </span>
                                            </div>
                                        </BsForm.Group>
                                    )}
                                </Field>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page buttonText={selected.length ? 'Next' : 'Skip for now'} validate={validation.invite_player}>
                    {() => (
                        <Card className="card-transparent">
                            <Card.Header className="separator">
                                <h4>Invite players</h4>
                                <p>
                                    Be at ease by inviting multiple players to your squad. You can do this now or later.
                                    You can freely keep inviting more players to your squad.
                                </p>
                            </Card.Header>
                            <Card.Body>
                                <Field name="invite_player">
                                    {({ input }) => (
                                        <>
                                            <ReactSelectAdapter
                                                {...input}
                                                options={!input ? [] : [{ value: input, label: input }]}
                                                inputValue={input.value}
                                                onInputChange={value => handleInviteTextChange(value, input)}
                                                isMulti
                                                value={selected}
                                                onChange={handleInviteOnChange}
                                                onBlur={e => handleInviteBlur(e, input)}
                                                className="hide-indicator"
                                                placeholder="Enter email address"
                                                menuIsOpen={false}
                                                onKeyDown={e => handleInviteKey(e, input)}
                                                typing
                                            />
                                            {helperText && <small className="text-danger">{helperText}</small>}
                                        </>
                                    )}
                                </Field>
                            </Card.Body>
                        </Card>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.logo}>
                    {(mutators, values) => (
                        <>
                            <Card className="card-transparent">
                                <Card.Header className="separator">
                                    <h4>Squad Logo</h4>
                                    <p>
                                        Be at ease by inviting multiple players to your squad. You can do this now or
                                        later. You can freely keep inviting more players to your squad.
                                    </p>
                                </Card.Header>
                                <Card.Body>
                                    <Field name="logo">
                                        {({ input, meta }) => (
                                            <div>
                                                <LogoCropper
                                                    centered
                                                    squadSlug={squadSlug}
                                                    photo={squad.photo_url_md}
                                                    onSuccess={photo => input.onChange(photo)}
                                                />
                                                <FinalFormError meta={meta} />
                                            </div>
                                        )}
                                    </Field>
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </Wizard.Page>
                <Wizard.Page validate={validation.invite_staff}>
                    {(mutators, values) => (
                        <>
                            <Card className="card-transparent">
                                <Card.Header className="separator">
                                    <h4>Set your shirts</h4>
                                    <p>Choose the shirts that closely resemble your team&apos;s colors</p>
                                </Card.Header>
                                <Card.Body>
                                    <Field
                                        name="shirts"
                                        render={({ input, meta }) => (
                                            <EditShirts
                                                onChange={val => {
                                                    input.onChange(val)
                                                }}
                                                value={input.value}
                                            />
                                        )}
                                    />
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </Wizard.Page>
            </Wizard>
        )
    )
}

export default Toast(OnBoardingSquad)
