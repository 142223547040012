import { SIDEBAR_PIN } from '../actions/actionTypes'

const initialState = true

const sidebarPin = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_PIN:
            return action.payload
        default:
            return state
    }
}

export default sidebarPin