import arrayMutators from 'final-form-arrays'
import { Children, useState } from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'

const Wizard = props => {
    const user = useSelector(state => state.user)
    const [initial, setInitial] = useState(true)
    const [page, setPage] = useState(props.page || 0)
    const [values, setValues] = useState(props.initialValues || {})
    const [pageFields, setPageFields] = useState([])

    const children = props.children.filter(child => (child.props?.hide ? !child.props.hide(values) : true))
    const activePage = Children.toArray(children)[page]
    const totalPages = Children.count(children)
    const isFirstPage = page === 0
    const isLastPage = page === totalPages - 1

    const clearValuesWithoutFields = fields => {
        if (!fields) return
        const newValues = {}
        fields.forEach(field => {
            newValues[field] = values[field]
        })
        setValues(newValues)
    }
    const clearValuesUntilLastPage = () => {
        clearValuesWithoutFields(pageFields[Math.max(page - 2, 0)])
        setPageFields(pageFields.slice(0, Math.max(page - 1, 0)))
    }

    const getFieldsByValues = values => Object.keys(values)

    const nextPage = () => setPage(currentPage => Math.min(currentPage + 1, children.length - 1))

    const previousPage = () => {
        clearValuesUntilLastPage()
        setPage(currentPage => Math.max(currentPage - 1, 0))
    }

    const validate = values => (activePage && activePage.props.validate ? activePage.props.validate(values) : {})

    const handleFormSubmit = async values => {
        const { onSubmit } = props
        if (values.force_substitution || isLastPage) {
            const submitResult = await onSubmit(values)
            if (submitResult === false) return submitResult
            setValues({
                ...props.initialValues,
            })
            // setPage(0)
            return submitResult
        } else {
            const fields = getFieldsByValues(values)
            setPageFields([...pageFields, fields])

            setValues(values)
            await nextPage()
        }
    }

    const close = () => {}

    if (!activePage) return null

    return (
        <Modal
            show={true}
            size="lg"
            backdrop="static"
            animation={false}
            keyboard={false}
            onHide={close}
            className="fill-in fade modal-lg colorized-darkest"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div>
                {initial ? (
                    <>
                        <Container>
                            <Row className="welcome-message px-0">
                                <Col md={12} className=" px-0">
                                    <Row>
                                        <Col>
                                            <Card className="card-transparent">
                                                <Card.Body>
                                                    <h2 className="sub-title">Welcome to SCOUTY</h2>
                                                    <h3 className="wizard-header welcome">
                                                        Hey <strong className="noticable">{user.username}</strong>,
                                                        welcome to SCOUTY! Before you start, we just need a few more
                                                        things from you...
                                                    </h3>
                                                    <Button
                                                        size="lg"
                                                        variant="success"
                                                        className="bold btn-rounded"
                                                        style={{ marginTop: 25 }}
                                                        onClick={() => setInitial(false)}>
                                                        Lets go!
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </>
                ) : (
                    <Form
                        initialValues={values}
                        validate={validate}
                        onSubmit={handleFormSubmit}
                        mutators={{
                            ...arrayMutators,
                            setNationality: (args, state, utils) => {
                                utils.changeValue(state, 'nationality', () => args[0])
                            },
                            setAddress: (args, state, utils) => {
                                utils.changeValue(state, 'address', () => args[0])
                            },
                            setCountry: (args, state, utils) => {
                                utils.changeValue(state, 'country', () => args[0])
                            },
                            setCity: (args, state, utils) => {
                                utils.changeValue(state, 'city', () => args[0])
                            },
                            setRegion: (args, state, utils) => {
                                utils.changeValue(state, 'region', () => args[0])
                            },
                            setCompetition: (args, state, utils) => {
                                utils.changeValue(state, 'competition', () => args[0])
                            },
                            setLatLongValue: (args, state, utils) => {
                                utils.changeValue(state, 'lat', () => args[0].lat)
                                utils.changeValue(state, 'lng', () => args[0].lng)
                            },
                            setTimezone: (args, state, utils) => {
                                utils.changeValue(state, 'location_timezone', () => args[0])
                            },
                        }}>
                        {({ handleSubmit, form: { mutators }, submitting, values, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Container>
                                    <activePage.type {...activePage.props} mutators={mutators} values={values} />
                                </Container>
                                <Container>
                                    <Row className="justify-content-md-center">
                                        <Col md={10} sm={12}>
                                            <hr style={{ margin: '20px 0 10px' }} />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col xs={3}>
                                                    {!isFirstPage && (
                                                        <Button
                                                            size="lg"
                                                            variant="info"
                                                            type="button"
                                                            className="bold btn-block btn-link"
                                                            onClick={previousPage}>
                                                            <ChevronLeft />
                                                            &nbsp;Prev
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col xs={6} />
                                                <Col xs={3}>
                                                    {!isLastPage ? (
                                                        <Button
                                                            disabled={!valid}
                                                            size="lg"
                                                            variant="info"
                                                            type="submit"
                                                            className="pull-right bold btn-block btn-link">
                                                            Next&nbsp;
                                                            <ChevronRight />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            disabled={!valid}
                                                            size="lg"
                                                            variant="success"
                                                            type="submit"
                                                            className="pull-right bold btn-rounded">
                                                            Update Profile!
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </form>
                        )}
                    </Form>
                )}
            </div>
        </Modal>
    )
}

Wizard.Page = ({ children, mutators, values, nextPage, form, previousPage }) => {
    if (typeof children === 'function') {
        return children(mutators, values, nextPage, form, previousPage)
    }
    return children
}

export default Wizard

// export default class Wizard extends React.Component {
//     static propTypes = {
//         onSubmit: PropTypes.func.isRequired,
//     }

//     static Page = ({ children, mutators, values }) => {
//         if (typeof children === 'function') {
//             return children(mutators, values)
//         }

//         return children
//     }

//     constructor(props) {
//         super(props)
//         this.state = {
//             page: 0,
//             initial: true,
//             values: props.initialValues || {},
//         }
//     }

//     next = values => {
//         this.setState(state => ({
//             page: Math.min(state.page + 1, this.props.children.length - 1),
//             values,
//         }))
//     }

//     previous = values => {
//         this.setState(
//             state => ({
//                 page: Math.max(state.page - 1, 0),
//             }),
//             function () {
//                 this.validate(values)
//             }
//         )
//     }

//     /**
//      * NOTE: Both validate and handleSubmit switching are implemented
//      * here because 🏁 Redux Final Form does not accept changes to those
//      * functions once the form has been defined.
//      */

//     validate = values => {
//         const activePage = React.Children.toArray(this.props.children)[this.state.page]

//         return activePage.props.validate ? activePage.props.validate(values) : {}
//     }

//     handleSubmit = values => {
//         const { children, onSubmit } = this.props
//         const { page } = this.state
//         const isLastPage = page === React.Children.count(children) - 1

//         if (isLastPage) {
//             return onSubmit(values)
//         } else {
//             this.next(values)
//         }
//     }

//     close = () => {}

//     render() {
//         const { children, user } = this.props
//         const { page, initial, values, formInValid, formError } = this.state
//         const activePage = React.Children.toArray(children)[page]
//         const totalPages = React.Children.count(children)
//         const isLastPage = page === React.Children.count(children) - 1
//         return (
//             <Modal
//                 show={true}
//                 size="lg"
//                 backdrop="static"
//                 animation={false}
//                 keyboard={false}
//                 onHide={e => this.close()}
//                 className="fill-in fade modal-lg colorized-darkest"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered>
//                 <div>
//                     {initial ? (
//                         <>
//                             <Container>
//                                 <Row className="welcome-message px-0">
//                                     <Col md={12} className=" px-0">
//                                         <Row>
//                                             <Col>
//                                                 <Card className="card-transparent">
//                                                     <Card.Body>
//                                                         <h2 className="sub-title">Welcome to SCOUTY</h2>
//                                                         <h3 className="wizard-header welcome">
//                                                             Hey <strong className="noticable">{user.username}</strong>,
//                                                             welcome to SCOUTY! Before you start, we just need a few more
//                                                             things from you...
//                                                         </h3>
//                                                         <Button
//                                                             size="lg"
//                                                             variant="success"
//                                                             className="bold btn-rounded"
//                                                             style={{ marginTop: 25 }}
//                                                             onClick={e => this.setState({ initial: false })}>
//                                                             Lets go!
//                                                         </Button>
//                                                     </Card.Body>
//                                                 </Card>
//                                             </Col>
//                                         </Row>
//                                     </Col>
//                                 </Row>
//                             </Container>
//                         </>
//                     ) : (
//                         <>
//                             {/* <div className="hint-text text-center text-uppercase m-t-20 m-b-10">
//                                 Onboarding step {page + 1} of {totalPages}
//                             </div> */}
//                             <Form
//                                 initialValues={values}
//                                 validate={values => {
//                                     return this.validate(values)
//                                 }}
//                                 onSubmit={this.handleSubmit}
//                                 mutators={{
//                                     ...arrayMutators,
//                                     setNationality: (args, state, utils) => {
//                                         utils.changeValue(state, 'nationality', () => args[0])
//                                     },
//                                     setAddress: (args, state, utils) => {
//                                         utils.changeValue(state, 'address', () => args[0])
//                                     },
//                                     setCountry: (args, state, utils) => {
//                                         utils.changeValue(state, 'country', () => args[0])
//                                     },
//                                     setCity: (args, state, utils) => {
//                                         utils.changeValue(state, 'city', () => args[0])
//                                     },
//                                     setRegion: (args, state, utils) => {
//                                         utils.changeValue(state, 'region', () => args[0])
//                                     },
//                                     setCompetition: (args, state, utils) => {
//                                         utils.changeValue(state, 'competition', () => args[0])
//                                     },
//                                     setLatLongValue: (args, state, utils) => {
//                                         utils.changeValue(state, 'lat', () => args[0].lat)
//                                         utils.changeValue(state, 'lng', () => args[0].lng)
//                                     },
//                                     setTimezone: (args, state, utils) => {
//                                         utils.changeValue(state, 'location_timezone', () => args[0])
//                                     },
//                                 }}>
//                                 {({ handleSubmit, form: { mutators }, submitting, values, valid }) => (
//                                     <form onSubmit={handleSubmit}>
//                                         {console.log('form values', values)}
//                                         <FormSpy
//                                             subscription={{ values: true, valid: true }}
//                                             onChange={state => {
//                                                 const { values, valid } = state
//                                                 // this.setState({ formInValid: !valid })
//                                                 if (formError) {
//                                                     this.setState({ formError: false })
//                                                 }
//                                             }}
//                                         />
//                                         <Container>
//                                             <activePage.type
//                                                 {...activePage.props}
//                                                 mutators={mutators}
//                                                 values={values}
//                                             />
//                                         </Container>
//                                         <Container>
//                                             <Row className="justify-content-md-center">
//                                                 <Col md={10} sm={12}>
//                                                     <hr style={{ margin: '20px 0 10px' }} />
//                                                     <Row style={{ marginTop: 20 }}>
//                                                         <Col xs={3}>
//                                                             {page > 0 && (
//                                                                 <Button
//                                                                     size="lg"
//                                                                     variant="info"
//                                                                     type="button"
//                                                                     className="bold btn-block btn-link"
//                                                                     onClick={this.previous}>
//                                                                     <ChevronLeft/>&nbsp;Prev
//                                                                 </Button>
//                                                             )}
//                                                         </Col>
//                                                         <Col xs={6} />
//                                                         <Col xs={3}>
//                                                             {!isLastPage ? (
//                                                                 <Button
//                                                                     disabled={!valid}
//                                                                     size="lg"
//                                                                     variant="info"
//                                                                     type="submit"
//                                                                     className="pull-right bold btn-block btn-link">
//                                                                     Next&nbsp;<ChevronRight/>
//                                                                 </Button>
//                                                             ) : (
//                                                                 <Button
//                                                                     disabled={!valid}
//                                                                     size="lg"
//                                                                     variant="success"
//                                                                     type="submit"
//                                                                     className="pull-right bold btn-rounded">
//                                                                     Update Profile!
//                                                                 </Button>
//                                                             )}
//                                                         </Col>
//                                                     </Row>
//                                                 </Col>
//                                             </Row>
//                                         </Container>
//                                     </form>
//                                 )}
//                             </Form>
//                         </>
//                     )}
//                 </div>
//             </Modal>
//         )
//     }
// }
