import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

const echoInstance = new Echo({
    broadcaster: process.env.REACT_APP_BROADCAST_DRIVER,
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    forceTLS: true,
})

export default echoInstance
