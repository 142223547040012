import { TOGGLE_PIN } from '../actions/actionTypes'

const initialState = false

const chatPin = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_PIN:
            return !state
        default:
            return state
    }
}

export default chatPin
