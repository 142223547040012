import { deleteRequest, getRequest, postRequest } from './apiRequest'

// Update Training Attendance
export const updateTrainingAttendance = (squadSlug, userId, trainingSessionId, attendance) => {
    const payload = {
        values: {
            user_id: userId,
            training_session_id: trainingSessionId,
            attendance: Number(attendance),
        },
    }
    const url = `/squads/update-training-attendance/${squadSlug}`
    return postRequest(url, payload)
}

// Delete training
export const deleteTraining = (squadSlug, trainingSessionId, deletionType, repeatEndDate) => {
    const payload = {
        values: {
            training_session_id: trainingSessionId,
            deletion_type: deletionType,
            repeat_end_date: repeatEndDate,
        },
    }
    const url = `/squads/delete-training/${squadSlug}`
    return deleteRequest(url, payload)
}

// Get Trainings
export const getTrainings = (squadSlug, dayFrom, dayTo) => {
    const url = `/squads/get-training/${squadSlug}`
    const params = {
        dayfrom: dayFrom,
        dayto: dayTo,
    }
    return getRequest(url, params)
}

// Get Training
export const getTraining = (squadSlug, trainingId, trainingDate, params = {}) => {
    const url = `/squads/get-training/${squadSlug}/${trainingId}/${trainingDate}`
    return getRequest(url, params)
}
