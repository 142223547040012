import Tooltip from '@mui/material/Tooltip'
import { last } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { convertRatingToRated } from 'utils'

const RecentForm = ({ lastFive, className }) => {
    // const [avgRating, setAvgRating] = useState(null)

    const avgRating = row => {
        // console.log(row)
        if (row && row.length > 0) {
            const total = row.map(i => i.rating).reduce((a, b) => a + b)

            return convertRatingToRated(total / row?.length)
        }

        return null
    }

    return lastFive && lastFive.length ? (
        <div>
            <div className="last-five-container">
                <div className={`last-five ${className}`}>
                    <>
                        <>
                            <div className="ratings">
                                {lastFive.map((e, i) => (
                                    <Tooltip
                                        key={i}
                                        title={
                                            <div>
                                                <strong>vs {e.opponent}</strong>:{' '}
                                                <strong className="stat">{convertRatingToRated(e.rating)}</strong>
                                                <small className="block fs-10">{moment.utc(e.date).fromNow()}</small>
                                            </div>
                                        }>
                                        <div
                                            className={`bar rating-${parseInt(e.rating)}`}
                                            style={{
                                                height: 30 * (e.rating / 100),
                                            }}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </>
                    </>
                </div>
                <div className="avg-rating">
                    <span className="bold">{avgRating(lastFive)}</span>
                    <small className="hint-text m-l-5">avg</small>
                </div>
            </div>
        </div>
    ) : (
        <small className="text-center w-100 text-info hint-text">No rating</small>
    )
}

export default RecentForm
