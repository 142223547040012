import classNames from 'classnames'
import { amInLineUp, matchIsMoreThanThreeHoursAway } from 'components/shared/shared2'
import moment from 'moment'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getAwayGoals, getHomeGoals, isFullTime, isLive } from 'utils'
import FixtureTeam from '../FixtureTeam'
import TimerComponent from './MatchTimer/TimerComponent'
import RSVPMatch from './RSVPMatch'
import { matchHasStarted } from 'components/shared/shared2'

const AVATAR_SIZE = 70
const FixtureHeader = () => {
    const { data: fixture } = useSelector(state => state.fixture)
    const { user } = useSelector(state => state)
    const {
        squad,
        home_goals,
        away_goals,
        ground,
        opponent,
        date,
        start_time,
        location,
        is_cancelled: match_cancelled,
    } = fixture

    if (!fixture || !squad) return <div className="split-body-header" />

    const eventTimeLocal = moment.utc(start_time).tz(location?.timezone || 'UTC')
    const isMatchStarted = matchHasStarted(fixture)

    return (
        <div className="split-body-header">
            <div
                className={classNames(
                    ground !== 'home' && 'flex-row-reverse',
                    'py-3 d-flex flex-row align-items-center'
                )}>
                <Col
                    xs={4}
                    className={classNames(
                        'd-flex flex-column align-items-center',
                        ground === 'home' ? 'ps-xl-5' : 'pe-xl-5'
                    )}>
                    <FixtureTeam
                        squad={squad}
                        photo={squad.photo_url_sm}
                        name={squad.name}
                        ground={ground === 'home'}
                        size={AVATAR_SIZE}
                    />
                </Col>
                <Col xs={4} className="flex-col align-items-center">
                    {!match_cancelled && isMatchStarted && (
                        <div
                            className="d-flex align-items-center fw-bold mt-2.5"
                            style={{ height: 40, marginTop: '40px !important' }}>
                            <h3 className={`lh-1 bold ${home_goals > away_goals ? 'text-white' : 'hint-text'}`}>
                                {getHomeGoals(fixture)}
                            </h3>
                            <h3 className="lh-1 hint-text bold m-r-15 m-l-15">-</h3>
                            <h3 className={`lh-1 bold ${home_goals < away_goals ? 'text-white' : 'hint-text'}`}>
                                {getAwayGoals(fixture)}
                            </h3>
                        </div>
                    )}

                    {match_cancelled ? (
                        <p className="my-0 text-md text-danger bright bold">Match Cancelled</p>
                    ) : isFullTime(fixture) ? (
                        <p className="my-0 text-md">Full Time</p>
                    ) : isLive(fixture) ? (
                        <TimerComponent fixture={fixture} className="text-lg" />
                    ) : (
                        <div className="d-flex flex-column align-items-center space-between lh-1 mx-3 mt-4">
                            <span className="block text-md text-white">{eventTimeLocal.format('MMM Do')}</span>
                            <span className="block hint-text text-sm lh-1">{eventTimeLocal.format('hh:mm')}</span>
                        </div>
                    )}
                </Col>
                <Col
                    xs={4}
                    className={classNames(
                        'd-flex flex-column align-items-center',
                        ground === 'home' ? 'pe-xl-5' : 'ps-xl-5'
                    )}>
                    <FixtureTeam
                        squad={{ name: opponent.name, nickname: opponent.short_name }}
                        name={opponent.short_name}
                        ground={ground === 'home'}
                        size={AVATAR_SIZE}
                        opponent
                    />
                </Col>
            </div>
        </div>
    )
}
export default FixtureHeader
