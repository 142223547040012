import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Form, FormSpy } from 'react-final-form'
import { validateEmail } from 'utils'

export default class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    }

    static Page = ({ children, mutators, values }) => {
        if (typeof children === 'function') {
            return children(mutators, values)
        }

        return children
    }

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            initial: true,
            squad: props.squad,
            values: props.initialValues || {},
        }
    }

    next = values => {
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values,
        }))
    }

    previous = values => {
        this.setState(
            state => ({
                page: Math.max(state.page - 1, 0),
            }),
            function () {
                this.validate(values)
            }
        )
    }

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[this.state.page]

        return activePage.props.validate ? activePage.props.validate(values) : {}
    }

    // Add a method to reset the form values
    reset = form => {
        form.reset() // This will reset the form to its initial values
        this.setState({ page: 0, initial: true, squad: this.props.squad, values: this.props.initialValues }) // Reset the 'page' and 'initial' state properties
    }

    handleSubmit = async (values, form) => {
        const { children, onSubmit } = this.props
        const { page } = this.state
        const isLastPage = page === React.Children.count(children) - 1

        if (isLastPage) {
            const data = await onSubmit(values)
            this.reset(form)
            return data
        } else {
            this.next(values)
        }
    }

    close = () => {}

    render() {
        const { children, user, squad } = this.props
        const { page, initial, values, formInValid, formError } = this.state
        const activePage = React.Children.toArray(children)[page]
        const totalPages = React.Children.count(children)
        const isLastPage = page === React.Children.count(children) - 1
        return (
            <Modal
                show={true}
                size="lg"
                backdrop="static"
                animation={false}
                keyboard={false}
                onHide={e => this.close()}
                className="fill-in fade modal-lg colorized-darkest"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <div>
                    {initial ? (
                        <>
                            <Container className="no-padding overflow-hidden">
                                <Row className="welcome-message">
                                    <Col md={12} className="px-0">
                                        <Row>
                                            <Col>
                                                <Card className="card-transparent">
                                                    <Card.Body>
                                                        <h2 className="sub-title lh-1">Set Up {squad?.name}</h2>
                                                        <h3 className="wizard-header welcome">
                                                            <strong className="noticable">{squad?.name}</strong> has
                                                            been approved! Please complete these steps to finish setting
                                                            up your squad.
                                                        </h3>
                                                        <br />
                                                        <Button
                                                            size="lg"
                                                            variant="success"
                                                            className="bold btn-rounded"
                                                            style={{ marginTop: 25 }}
                                                            onClick={e => this.setState({ initial: false })}>
                                                            Lets go!
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ) : (
                        <>
                            <Form
                                initialValues={values}
                                validate={values => {
                                    return this.validate(values)
                                }}
                                onSubmit={this.handleSubmit}
                                mutators={{
                                    ...arrayMutators,
                                    setNationality: (args, state, utils) => {
                                        utils.changeValue(state, 'nationality', () => args[0])
                                    },
                                    setAddress: (args, state, utils) => {
                                        utils.changeValue(state, 'address', () => args[0])
                                    },
                                    setCountry: (args, state, utils) => {
                                        utils.changeValue(state, 'country', () => args[0])
                                    },
                                    setCity: (args, state, utils) => {
                                        utils.changeValue(state, 'city', () => args[0])
                                    },
                                    setRegion: (args, state, utils) => {
                                        utils.changeValue(state, 'region', () => args[0])
                                    },
                                    setCompetition: (args, state, utils) => {
                                        utils.changeValue(state, 'competition', () => args[0])
                                    },
                                    setLatLongValue: (args, state, utils) => {
                                        utils.changeValue(state, 'lat', () => args[0].lat)
                                        utils.changeValue(state, 'lng', () => args[0].lng)
                                    },
                                    setTimezone: (args, state, utils) => {
                                        utils.changeValue(state, 'location_timezone', () => args[0])
                                    },
                                }}>
                                {({ handleSubmit, form: { mutators }, submitting, values, valid }) => (
                                    <form onSubmit={handleSubmit}>
                                        {/* {console.log('vaaa', values)} */}
                                        <FormSpy
                                            subscription={{ values: true, valid: true }}
                                            onChange={state => {
                                                const { values, valid } = state
                                                // this.setState({ formInValid: !valid })
                                                if (formError) {
                                                    this.setState({ formError: false })
                                                }
                                            }}
                                        />
                                        {/* <Container>
                                            <Row className="justify-content-md-center">
                                                <Col xs={12}>
                                                    <h2 className="no-margin text-center text-white bold">
                                                        Setup <span className="">{squad?.name}</span>
                                                    </h2>
                                                </Col>
                                            </Row>
                                        </Container> */}
                                        <Container className="no-padding">
                                            <activePage.type
                                                {...activePage.props}
                                                mutators={mutators}
                                                values={values}
                                            />
                                        </Container>
                                        <Container className="">
                                            <hr style={{ margin: '20px 0 10px' }} />
                                            <Row style={{ marginTop: 20 }}>
                                                <Col xs={3}>
                                                    {page > 0 && (
                                                        <Button
                                                            size="md"
                                                            variant="info"
                                                            type="button"
                                                            className="bold btn-link"
                                                            onClick={this.previous}>
                                                            <ChevronLeft />
                                                            &nbsp;Prev
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col xs={6} />
                                                <Col xs={3}>
                                                    {!isLastPage ? (
                                                        <Button
                                                            disabled={!valid}
                                                            size="md"
                                                            variant="info"
                                                            type="submit"
                                                            className="pull-right btn-link">
                                                            <>
                                                                {validateEmail(values.invite_player) ||
                                                                !activePage.props?.buttonText ? (
                                                                    <> Next</>
                                                                ) : (
                                                                    activePage.props?.buttonText
                                                                )}
                                                                &nbsp;
                                                                <ChevronRight />
                                                            </>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            disabled={!valid}
                                                            size="lg"
                                                            variant="success"
                                                            type="submit"
                                                            className="pull-right btn-rounded">
                                                            Update Squad!
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </form>
                                )}
                            </Form>
                        </>
                    )}
                </div>
            </Modal>
        )
    }
}
