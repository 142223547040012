import { getGoalScoredEvents } from 'components/store'
import { useSelector } from 'react-redux'

const LineUpPlayerGoalScored = ({ player, x, y }) => {
    const matchEvents = useSelector(getGoalScoredEvents)
    const playerScored = matchEvents?.filter(event => event.player.id === player.id)

    if (!playerScored.length) return null

    return (
        <g
            style={{
                transform: `translate(${playerScored.length > 1 ? '-10.5px, -0.2px' : '-10.5px, -0.2px'}) scale(0.3)`,
                transformBox: 'fill-box',
                transformOrigin: 'top left',
            }}>
            <svg x={x} y={y}>
                <defs>
                    <path
                        id="path-1"
                        d="M7.5,15 C11.6421358,15 15,11.6421358 15,7.5 C15,3.35786417 11.6421358,0 7.5,0 C3.35786417,0 0,3.35786417 0,7.5 C0,11.6421358 3.35786417,15 7.5,15 Z"
                    />
                </defs>
                <g id="Icons" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <g id="goal">
                        <rect id="Rectangle-557" x={0} y={0} width={24} height={24} />
                        <g id="Soccer-ball" transform="translate(10,5)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <use id="Oval-1" stroke="#000000" fill="#FFFFFF" xlinkHref="#path-1" />
                            <polygon
                                id="Polygon-1"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="7.5 5 9.87764129 6.72745751 8.96946313 9.52254249 6.03053687 9.52254249 5.12235871 6.72745751 ">
                                <g transform="translate(7.500000, 7.500000) rotate(-36.000000) translate(-7.500000, -7.500000) " />
                            </polygon>
                            <polygon
                                id="Polygon-1-copy"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="7.5 -2 9.87764129 -0.272542486 8.96946313 2.52254249 6.03053687 2.52254249 5.12235871 -0.272542486 ">
                                <g transform="translate(7.500000, 0.500000) scale(1, -1) rotate(-36.000000) translate(-7.500000, -0.500000) " />
                            </polygon>
                            <polygon
                                id="Polygon-1-copy-2"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="3.5 11 5.87764129 12.7274575 4.96946313 15.5225425 2.03053687 15.5225425 1.12235871 12.7274575 ">
                                <g transform="translate(3.500000, 13.500000) rotate(-72.000000) translate(-3.500000, -13.500000) " />
                            </polygon>
                            <polygon
                                id="Polygon-1-copy-3"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="11.5 11 13.8776413 12.7274575 12.9694631 15.5225425 10.0305369 15.5225425 9.12235871 12.7274575 ">
                                <g transform="translate(11.500000, 13.500000) scale(-1, 1) rotate(-72.000000) translate(-11.500000, -13.500000) " />
                            </polygon>
                            <polygon
                                id="Polygon-1-copy-3"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="0.5 3 2.87764129 4.72745751 1.96946313 7.52254249 -0.969463131 7.52254249 -1.87764129 4.72745751 ">
                                <g transform="translate(0.500000, 5.500000) scale(1, -1) rotate(-108.000000) translate(-0.500000, -5.500000) " />
                            </polygon>
                            <polygon
                                id="Polygon-1-copy-4"
                                fill="#000000"
                                mask="url(#mask-2)"
                                points="14.5 3 16.8776413 4.72745751 15.9694631 7.52254249 13.0305369 7.52254249 12.1223587 4.72745751 ">
                                <g transform="translate(14.500000, 5.500000) scale(-1, -1) rotate(-108.000000) translate(-14.500000, -5.500000) " />
                            </polygon>
                            {playerScored.length > 1 && (
                                <>
                                    <circle
                                        cx="-2"
                                        cy="7.75"
                                        r="7.5"
                                        fill="#fff"
                                        stroke="#000"
                                        strokeWidth="1"></circle>
                                    <text
                                        x="-2"
                                        y="7.75"
                                        dy="4"
                                        fontSize="8"
                                        fontWeight="bold"
                                        textAnchor="middle"
                                        style={{ transform: 'translate(0, -1.5px)' }}
                                        fill="#000000">
                                        {playerScored.length}
                                    </text>
                                </>
                            )}
                        </g>
                    </g>
                </g>
            </svg>
        </g>
    )
}

export default LineUpPlayerGoalScored
// style={{
//     transform: 'translate(7px, 11.7px)',
//     transformBox: 'fill-box',
//     transformOrigin: 'center',
// }}
