import { Page404 } from 'components/FullPage/pages/404'
import { DashboardLayout } from 'components/Layout'
import { useCurrentPath } from 'hooks'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router'
import { adminRoutes, adminRoutesPath } from './AdminRoutes'
import { anyRoute } from './LandingRoutes'
import { protectedRoutes } from './PrivateRoutes'
import { publicRoutes } from './PublicRoutes'

const HandleRoutes = () => {
    const user = useSelector(state => state.user)
    const { is_admin: isAdmin } = user || { is_admin: false }
    const { pathname } = useLocation()

    const currentPath = useCurrentPath([...protectedRoutes, ...publicRoutes, ...adminRoutes, ...anyRoute])
    const isProtectedRoute = protectedRoutes.map(r => r.path).includes(currentPath)
    const isPublicRoute = publicRoutes.map(r => r.path).includes(currentPath)
    const isAdminRoute = adminRoutes.map(r => r.path).includes(currentPath)

    if (user) {
        const { squad_not_onboarded: squadNotOnBoarded } = user
        // if user object shows they have an non on boarded squad
        // and they are not in one of the admin routes, only then redirect
        if (squadNotOnBoarded?.length > 0 && !adminRoutesPath.includes(pathname))
            return <Navigate replace to={`/dashboard/squads/${squadNotOnBoarded[0].squad_slug}`} />
    }

    if (!user && isProtectedRoute) return <Navigate replace to="/login" />
    if (!user && isAdminRoute) return <Navigate replace to="/login" />

    if (user && isPublicRoute) return <Navigate replace to="/dashboard" />
    if (user && !isAdmin && isAdminRoute) return <Navigate replace to="/dashboard" />

    if (isProtectedRoute || isPublicRoute || isAdminRoute) return null

    if (user)
        return (
            <DashboardLayout>
                <Page404 />
            </DashboardLayout>
        )

    return <Page404 />
}
export default HandleRoutes
