import { useSelector } from 'react-redux'

const LineUpPlayerRedCard = ({ player, x, y }) => {
    const { list: matchEvents } = useSelector(state => state.matchEvents)

    const playerHasRed = matchEvents?.find(event => event.event_type === 'red_card' && event.player.id === player.id)

    if (!playerHasRed) return null

    return (
        <rect
            id="red"
            stroke="#B0120A"
            fill="#E51C23"
            style={{
                transform: 'translate(3.5px, -6.3px) rotate(20deg) scale(0.65)',
                transformBox: 'fill-box',
                transformOrigin: 'center',
            }}
            x={x}
            y={y}
            width="5"
            height="7"
            rx="1"
            strokeWidth="0.5"
        />
    )
}

export default LineUpPlayerRedCard
