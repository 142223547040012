import { setSelectedTraining, updateSelectedDayEvents, updateTraining, updateUpcomingEvent } from 'components/store'
import moment from 'moment'
import { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Check, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { rsvpTraining } from 'services'

const now = moment.utc().format('YYYY-MM-DD HH:mm')
const inPast = data => now >= data.start_time

const RSVPTraining = ({ data, squad }) => {
    const dispatch = useDispatch()
    const { user: currentUser } = useSelector(state => state)
    const [submitting, setSubmitting] = useState(false)

    const setRSVPTraining = async decision => {
        if (!['yes', 'no'].includes(decision)) return
        setSubmitting(true)
        const training_session_id = data.id || data.parent_id
        const response = await rsvpTraining(squad.squad_slug, currentUser.id, decision, training_session_id, data.date)
        if (response && response.status === 202 && response.data && response.data.status === 'success') {
            const {
                data: { data: newData },
            } = response
            dispatch(updateTraining(newData, training_session_id))
            dispatch(setSelectedTraining(newData))
            const im_attending = decision === 'yes'
            const payload = {
                id: data.id || data.parent_id,
                date: data.date,
                type: 'trainings',
                data: {
                    im_attending,
                    id: newData.id,
                },
            }
            dispatch(updateUpcomingEvent(payload))
            dispatch(updateSelectedDayEvents(payload))
        }
        setSubmitting(false)
    }

    const amInLineUp = () => data.players.find(({ user }) => user?.id === currentUser?.id)

    if (!amInLineUp()) return null

    if (inPast(data)) return null

    return (
        <>
            <DropdownButton
                variant="info"
                id="dropdown-basic-button"
                onSelect={setRSVPTraining}
                disabled={submitting}
                title={
                    <div className="d-flex align-items-center">
                        {amInLineUp().player_attended === 'yes' ? (
                            <>
                                <div>
                                    <Check className="text-success" size={16} />
                                </div>
                                <div className="m-l-5">
                                    <span>Going</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <X size={16} color="red" />
                                <div className="m-l-5">
                                    <span>Not Going</span>
                                </div>
                            </>
                        )}
                    </div>
                }>
                {[
                    { value: 'yes', label: 'Going' },
                    { value: 'no', label: 'Not going' },
                ].map(option => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                        {option.label}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
            {/* <div className="rsvp-match-container">
                <Button
                    type="button"
                    disabled={submitting}
                    onClick={() => setRSVPTraining('yes')}
                    size="md"
                    className={`${amInLineUp().player_attended === 'yes' ? 'selected' : ''} going`}>
                    <Check
                        size={17}
                        // color="green"
                    />
                    &nbsp;I am attending
                </Button>
                <Button
                    type="button"
                    disabled={submitting}
                    onClick={() => setRSVPTraining('no')}
                    size="md"
                    className={`${amInLineUp().player_attended === 'no' ? 'selected' : ''} not-going`}>
                    <X
                        size={17}
                        // color="red"
                    />
                    &nbsp;Not attending
                </Button>
            </div> */}
        </>
    )
}

export default RSVPTraining
