import { SET_STAFF_DATA } from '../actions/actionTypes'

const staff = (state = [], action) => {
    switch (action.type) {
        case SET_STAFF_DATA: {
            const newState = [...state, ...action.payload]
            return newState
        }
        default:
            return state
    }
}

export default staff
