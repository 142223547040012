const validation = {
    nationality: values => {
        const errors = {}

        if (!values.nationality) {
            errors.nationality = 'Please enter your nationality'
        }

        return errors
    },
    profile: values => {
        const errors = {}

        if (!values.joining_as) {
            errors.joining_as = 'Required'
        }

        return errors
    },
    coachBio: values => {
        const errors = {}

        if (!values.coach_bio) {
            errors.coach_bio = 'Please fill out your bio'
        } else if (values.coach_bio.length < 200) {
            errors.coach_bio = 'Your bio should be at least 200 characters'
        }

        return errors
    },
    // positions: (values) => {
    //     const errors = {}

    //     if (!values.positions) {
    //         errors.positions = 'You need some positions'
    //     } else {
    //         if(values.positions.length === 0) {
    //             errors.positions = 'You need some positions'
    //         }
    //     }

    //     return errors
    // },
    footballingAttributes: values => {
        const errors = {}

        if (!values.height) {
            errors.height = 'Please provide us with your height'
        }

        if (!values.weight) {
            errors.weight = 'Please provide us with your weight'
        }

        if (!values.preferred_foot) {
            errors.preferred_foot = 'Please tell us your preferred foot'
        }

        if (!values.spoken_languages) {
            errors.spoken_languages = 'Select at least ONE language you speak'
        }

        return errors
    },
    biometrics: values => {
        const errors = {}

        if (!values.gender) {
            errors.gender = 'Please fill this out'
        }

        return errors
    },
    location: values => {
        const errors = {}

        if (!values.city || !values.address || !values.region || !values.country) {
            errors.city = 'Not a location'
        }

        return errors
    },
}

export default validation
