import { updateClubSquadDetail } from 'components/store/actions'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getSquad } from 'services'

export const EventSquadLayout = ({ event, children }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const squad = useSelector(state => state.clubSquadDetail)
    const [isLoading, setIsLoading] = useState(isEmpty(squad))
    const { squadSlug } = useSelector(state => state[event])

    const fetchSquad = async () => {
        // dispatch(setLoader(true))
        setIsLoading(true)
        const response = await getSquad(squadSlug)
        if (response && (response.status === 500 || response.status === 404)) {
            navigate('/dashboard')
        } else if (response && response.status === 200 && response.data && response.data.status === 'success') {
            dispatch(updateClubSquadDetail(response.data.data))
        }
        // dispatch(setLoader(false))
        setIsLoading(false)
    }

    useEffect(() => {
        if (squadSlug && squadSlug !== squad.squad_slug) fetchSquad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, squad, squadSlug])

    if (isLoading) return null
    if (isEmpty(squad)) return null
    return children
}
