import { createSelector } from '@reduxjs/toolkit'
import { MATCH_EVENT_TYPES } from 'constants'
import { isTechnical } from 'utils'

const SET_MATCH_EVENTS_DATA = 'SET_MATCH_EVENTS_DATA'
const SET_MATCH_EVENTS_LOADING = 'SET_MATCH_EVENTS_LOADING'
const RESET_MATCH_EVENTS_DATA = 'RESET_MATCH_EVENTS_DATA'

const initialState = {
    list: [],
    isLoading: false,
}

const matchEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCH_EVENTS_DATA:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case SET_MATCH_EVENTS_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_MATCH_EVENTS_DATA:
            return initialState
        default:
            return state
    }
}

export default matchEventsReducer

export const setMatchEventsData = payload => ({
    type: SET_MATCH_EVENTS_DATA,
    payload,
})

export const setMatchEventsLoading = () => ({
    type: SET_MATCH_EVENTS_LOADING,
})

export const resetMatchEventsData = () => ({
    type: RESET_MATCH_EVENTS_DATA,
})

// add match event using setMatchEventsData function
// only add if event is not already in the list
export const addMatchEvent = event => (dispatch, getState) => {
    const { list } = getState().matchEvents
    if (list.find(e => e.id === event.id)) return
    dispatch(setMatchEventsData([...list, event]))
}

// selectors
export const getMatchEvents = state => state.matchEvents.list

export const getSubstitutionEvents = createSelector(getMatchEvents, matchEvents =>
    matchEvents.filter(event => event.event_type === 'substitution')
)

export const getGoalScoredEvents = createSelector(getMatchEvents, matchEvents =>
    matchEvents.filter(event => event.event_type === 'goal_scored')
)

export const getNonTacticalEvents = createSelector(getMatchEvents, matchEvents =>
    matchEvents.filter(event => !isTechnical(event.event_type))
)

export const getTacticalEvents = createSelector(getMatchEvents, matchEvents =>
    matchEvents.filter(event => isTechnical(event.event_type))
)
