import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SVGFormation from '../SVGFormation'
// import Players from './Players'
import SubsBench from './SubsBench'

const FormationContainer = ({
    onChange,
    shirtPlayer,
    shirtGK,
    meta,
    shirts,
    titleClass,
    title = null,
    autoAssign = true,
}) => {
    const formationPicker = useSelector(store => store.formationPicker)

    useEffect(() => {
        onChange(formationPicker)
    }, [formationPicker, onChange])

    return (
        <div className="squad-create-form-formation-wrapper">
            <SubsBench />
            <div className="formation-lineup">
                <div className="formation-lineup-wrapper">
                    <SVGFormation
                        shirts={shirts}
                        shirtPlayer={shirtPlayer}
                        shirtGK={shirtGK}
                        meta={meta}
                        title={title}
                        autoAssign={autoAssign}
                        titleClass={titleClass}
                    />
                </div>
            </div>
            {/* <div className="players-position-picker">
                <Players />
            </div> */}
            {/* <Modal>
                <div className="players-position-picker">
                    <Players players={players} />
                </div>
            </Modal> */}
        </div>
    )
}

export default FormationContainer
