import { 
	HAS_NOTIFICATION_DATA
} from '../actions/actionTypes'

const initialState = {
    hasNotifications: false
}

const notificationBell = (state = initialState, action) => {
    switch (action.type) {
        case HAS_NOTIFICATION_DATA:
            return {
                ...state,
                hasNotifications: action.payload
            }
        default:
            return state
    }
}

export default notificationBell