import theme from './theme'
// cSpell:disable
const config = {
    API_URL: process.env.REACT_APP_API_URL,
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    FIREBASE_API_KEY: process.env.REACT_APP_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.REACT_APP_APP_ID,
    GA_TRACKING_ID: 'G-1NLGW9H53W', // OUR_TRACKING_ID
    TWITCH_CLIENT_ID: process.env.REACT_APP_TWITCH_CLIENT_ID,
    TWITCH_CLIENT_SECRET: process.env.REACT_APP_TWITCH_CLIENT_SECRET,
}

export default config

export { theme }
