const SET_POPUP = 'SET_POPUP'

const initialState = {
    show: false,
    type: '',
}

const popup = (state = initialState, action) => {
    switch (action.type) {
        case SET_POPUP:
            return {
                ...state,
                show: action.payload.show,
                type: action.payload.type,
            }
        default:
            return state
    }
}

export default popup

// action creators
// set popup
export const setPopup = props => ({
    type: SET_POPUP,
    payload: props,
})

// show popup
export const showPopup = (props = {}) => ({
    type: SET_POPUP,
    payload: {
        show: true,
        ...props,
    },
})

// hide popup
export const hidePopup = (props = {}) => ({
    type: SET_POPUP,
    payload: {
        show: false,
        ...props,
    },
})

// toggle popup
export const togglePopup =
    (props = {}) =>
    (dispatch, getState) => {
        const { popup } = getState()
        dispatch(setPopup({ show: !popup.show, ...props }))
    }
