import { deleteSelectedDayEvent, deleteUpcomingEvent, removeTraining } from 'components/store'
import moment from 'moment'
import { useState } from 'react'
import { Form as BsForm, Button, Modal } from 'react-bootstrap'
import { Trash2 } from 'react-feather'
import { Field, Form } from 'react-final-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTraining } from 'services'
import { getPermissions } from 'utils'

const TrainingDelete = ({ data, handleClose }) => {
    const dispatch = useDispatch()
    const squad = useSelector(state => state.clubSquadDetail)
    const user = useSelector(state => state.user)

    const { createTraining } = getPermissions(squad, user.id)
    const { is_repeat: isRepeat, has_repeat_parent: hasRepeatParent } = data
    const [show, setShow] = useState(false)

    const handleDeleteShow = () => setShow(true)
    const handleDeleteClose = () => setShow(false)

    const handleDelete = async values => {
        const trainingSessionId = data.id || data.parent_id
        const response = await deleteTraining(squad.squad_slug, trainingSessionId, values.deletion_type, data.date)

        if (response && response.status === 200 && response.data && response.data.status === 'success') {
            dispatch(removeTraining(trainingSessionId))
            const payload = {
                id: trainingSessionId,
                date: data.date,
                type: 'trainings',
                deletion_type: values.deletion_type,
                parent_id: data.id ? data.has_repeat_parent : data.parent_id,
            }
            dispatch(deleteUpcomingEvent(payload))
            dispatch(deleteSelectedDayEvent(payload))
            handleDeleteClose()
            handleClose()
            toast.success('Training session deleted successfully')
        }
    }
    const now = moment.utc().format('YYYY-MM-DD')

    const startDate = data.start_time
    const start = moment.utc(startDate).format('YYYY-MM-DD')
    const inFuture = date => moment(date).isAfter(now)
    const isBefore = inFuture(start)

    if (!createTraining || !isBefore) return null

    return (
        <>
            <Button onClick={handleDeleteShow} variant="danger" type="button" className="">
                {/* <Trash2 size={14} className="me-2" /> */}
                Cancel
            </Button>
            {show && (
                <Modal show={show} onHide={handleClose} centered className="z-1060 colorized-darkest">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>Cancel Training Session</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleDelete}>
                        {({ handleSubmit, submitting }) => (
                            <BsForm onSubmit={handleSubmit}>
                                <Modal.Body>
                                    {(isRepeat || hasRepeatParent) && (
                                        <div className="mt-3">
                                            <Field
                                                name="deletion_type"
                                                type="radio"
                                                value="this_session"
                                                initialValue="this_session">
                                                {({ input }) => (
                                                    <BsForm.Check
                                                        {...input}
                                                        type="radio"
                                                        id="this-session"
                                                        label="This session only"
                                                        defaultChecked
                                                    />
                                                )}
                                            </Field>
                                            <Field name="deletion_type" type="radio" value="this_and_future">
                                                {({ input }) => (
                                                    <BsForm.Check
                                                        {...input}
                                                        type="radio"
                                                        id="this-and-future-sessions"
                                                        label="This and future sessions"
                                                    />
                                                )}
                                            </Field>
                                            <Field name="deletion_type" type="radio" value="all_sessions">
                                                {({ input }) => (
                                                    <BsForm.Check
                                                        {...input}
                                                        type="radio"
                                                        id="all-sessions"
                                                        label="All sessions"
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose} disabled={submitting}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" type="submit" disabled={submitting}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </BsForm>
                        )}
                    </Form>
                </Modal>
            )}
        </>
    )
}
export default TrainingDelete
