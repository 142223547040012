import { useCallback, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const ReactSelectAdapter = ({ input, style, typing, isDisabled, maxLimit = null, ...rest }) => {
    const [focused, setFocused] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const selectRef = useCallback(e => {
        /* Your ref callback */
        if (e?.state.isFocused === true) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    })
    return (
        <Wrapper
            className={`react-select-wrapper${isOpen ? ' is-open' : ''} ${isDisabled ? ' disabled' : ''}`}
            style={style}>
            <Select
                ref={selectRef}
                focused={focused}
                menuPlacement="auto"
                isSearchable={typing ? true : false}
                value={input && input.value}
                isOptionDisabled={option => (maxLimit ? rest.value.length >= maxLimit : false)}
                onChange={value => {
                    input.onChange({ value })
                    setIsOpen(false)
                }}
                onInputFocus={({ focused }) => {
                    setFocused(focused)
                }}
                {...rest}
            />
        </Wrapper>
    )
}

export default ReactSelectAdapter

const Wrapper = styled.div`
    width: 100%;
`
