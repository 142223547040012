import { 
    SET_CLUB_DETAIL_DATA
} from '../actions/actionTypes'

const initialState = {}

const clubDetail = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLUB_DETAIL_DATA:
            return { 
                ...state, 
                ...action.payload 
            }
        default:
            return state
    }
}
export default clubDetail