import Avatar from 'components/shared/Avatar'
import { useSelector } from 'react-redux'
import BenchSubs from './BenchSubs'

const BenchLineup = () => {
    const { coaches } = useSelector(state => state.fixture.data)
    const manager = coaches.find(e => e.staff.name === 'manager')

    console.log(`🔥 | file: BenchLineup.jsx:10 | manager`, manager)

    return (
        <div className="fixture--bench-side">
            <h5>Substitutes</h5>
            <BenchSubs />
            {manager && (
                <>
                    <h5>Coach</h5>
                    <div className="bench--player">
                        <div className="d-flex align-items-center me-3">
                            <Avatar width={40} height={40} src={manager.user.photo_url_sm} alt={manager.user.name} />
                        </div>
                        <div className="player-name">
                            <p className="my-0 lh-1">
                                <span className="player text-white">{manager.user.name}</span>
                            </p>
                            <span className="hint-text">Manager</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default BenchLineup
