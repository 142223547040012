import { closeCalendar } from 'components/store'
import { BottomSideDrawer } from 'components/ui'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from './Calendar'
import { X } from 'react-feather'

const CalenderDrawer = () => {
    const dispatch = useDispatch()
    const { show: open } = useSelector(state => state.calender)

    const handleCalendarClose = () => dispatch(closeCalendar())

    return (
        <BottomSideDrawer open={open} onClose={handleCalendarClose} className="z-1010 h-100 h-md-unset">
            <Calendar />
            <Button variant="link" size="lg" onClick={handleCalendarClose} className="calender-drawer-close">
                <X />
            </Button>
        </BottomSideDrawer>
    )
}
export default CalenderDrawer
