import { getPlayerName } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildTitle = event => {
    const { player } = event
    return (
        <>
            <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}

const buildAssist = event => {
    const { player2 } = event
    if (!player2) return null
    return (
        <small className="italics">
            Assisted by <span className="">{player2.name}</span>
        </small>
    )
}

export const MatchEventGoalScored = ({ event }) => {
    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            title="GOAL!!!"
            secondary={buildTitle(event)}
            small={buildAssist(event)}
            image='<svg width="24" height="24" viewBox="0 0 24 24" fill="green"><title>Goal</title><path d="M4.925 4.927c3.901-3.903 10.249-3.903 14.15 0 3.9 3.9 3.9 10.248 0 14.148-3.901 3.9-10.249 3.9-14.15 0-3.9-3.9-3.9-10.249 0-14.148zm11.004-.585C12.692 2.684 8.62 3.207 5.912 5.913c-2.708 2.708-3.229 6.782-1.57 10.02l1.689-.703 2.738 2.74-.702 1.687c3.236 1.66 7.312 1.136 10.02-1.57 2.712-2.711 3.232-6.795 1.562-10.033l-1.674.695-2.74-2.74zm.704 7.586 1.299 3.297-2.707 2.707-3.261-1.262.836-3.868 3.833-.874zM8.621 6.11l3.216 1.359-.684 3.686-3.698.67-1.347-3.203L8.621 6.11z" fill-rule="evenodd"></path></svg>'
        />
    )
}
