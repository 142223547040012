import { Spinner } from 'react-bootstrap'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const ReactTableComponent = ({ columns, data, filterable, loading, ...props }) => {
    return (
        <div className="h-100">
            <ReactTable
                columns={columns || []}
                data={data || []}
                filterable={filterable || false}
                defaultPageSize={100}
                minRows={0}
                loadingText={loading &&
                    <h5>
                        <Spinner size="lg" variant={'info'} className="text-center" />
                    </h5>
                }
                className="-highlight"
                {...props}
                // LoadingComponent={({ loading }) => <LoadingTable loading={loading} columns={9} />}
            />
        </div>
    )
}

export default ReactTableComponent
