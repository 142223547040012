import { BsFilter } from 'react-icons/bs'
import { FiMoreHorizontal, FiX } from 'react-icons/fi'
import { GoTrophy } from 'react-icons/go'
import { IoFootballOutline } from 'react-icons/io5'
import { MdOutlineTimer } from 'react-icons/md'
import { PiDotsThreeCircleLight, PiRecordFill } from 'react-icons/pi'

export const ICONS = {
    filter: BsFilter,
    trophy: GoTrophy,
    football: IoFootballOutline,
    record: PiRecordFill,
    timer: MdOutlineTimer,
    x: FiX,
    menu: FiMoreHorizontal,
    dotsCircle: PiDotsThreeCircleLight,
}

export const ICON_NAMES = Object.keys(ICONS)

const Icon = ({ name, onClick, className, size = 24 }) => {
    if (!ICON_NAMES.includes(name)) return null

    const Component = ICONS[name]
    return <Component style={{ width: `${size}px`, height: `${size}px` }} className={className} onClick={onClick} />
}

export default Icon
