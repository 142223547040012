import { matchRoutes, useLocation } from 'react-router-dom'

const useCurrentPath = routes => {
    const location = useLocation()
    const routesMatched = matchRoutes(routes, location)
    if (!routesMatched) return null
    const [{ route }] = routesMatched
    return route.path
}

export default useCurrentPath
