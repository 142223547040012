import Avatar from 'components/shared/Avatar'
import { useSelector } from 'react-redux'

const AVATAR_SIZE = 100
const UserAvatar = () => {
    const { player: user } = useSelector(state => state.userProfile.data)

    return (
        <div className="flex-center py-3">
            <Avatar
                size={AVATAR_SIZE}
                src={user?.photo_url_md}
                alt={user?.username}
                style={{ border: '2px solid #fff' }}
            />
        </div>
    )
}
export default UserAvatar
