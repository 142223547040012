import { useSelector } from 'react-redux'

const BenchPlayerYellowCard = ({ player }) => {
    const { list: matchEvents } = useSelector(state => state.matchEvents)

    const playerHasYellow = matchEvents?.find(
        event => event.event_type === 'yellow_card' && event.player.id === player.id
    )
    const playerHasMultipleYellows =
        matchEvents?.filter(event => event.event_type === 'yellow_card' && event.player.id === player.id)?.length > 1

    if (playerHasMultipleYellows) {
        return <div className="double-yellow-card" />
    }

    if (!playerHasYellow) return null

    return <div className="yellow-card" />
}

export default BenchPlayerYellowCard
