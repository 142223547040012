import ReactTableComponent from 'components/shared/ReactTableComponent'
import { addSubstitution, selectPlayer } from 'components/store'
import { NUMBER_OF_SUBSTITUTES } from 'constants'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayer, getPlayerName, isSubPosition } from 'utils'
import columns from './columns'

const Players = () => {
    const dispatch = useDispatch()
    const { lineup, players, substitutions } = useSelector(store => store.formationPicker)
    const formattedLineUp = [...lineup].map((pos, node) => ({ ...pos, node }))

    const filteredLineup = lineup.filter(player => player.player)
    const lineupPlayers = filteredLineup.map(player => player.player)
    const subPlayers = substitutions.map(player => player.player)
    const notPickedPlayers = players.filter(player => {
        const isPlayerInLineup = lineupPlayers.find(p => p.id === player.id)
        const isPlayerInSubs = subPlayers.find(p => p.id === player.id)
        return !isPlayerInLineup && !isPlayerInSubs
    })

    const formatedPlayers = [
        ...notPickedPlayers.map(player => ({
            value: player,
            label: `${getPlayerName(player)} #${player.number} ${player.pos_group}`,
            inLineup: false,
        })),
        ...filteredLineup.map(({ player, position }) => ({
            value: player,
            label: `${getPlayerName(player)} #${player.number} ${player.pos_group}`,
            inLineup: position,
        })),
        ...substitutions.map(({ player, position }) => ({
            value: player,
            label: `${getPlayerName(player)} #${player.number} ${player.pos_group}`,
            inLineup: position,
        })),
    ]

    const handleChange = (player, position, node = 0) => {
        const payload = {
            player,
            position,
            node,
        }

        if (isSubPosition(position)) {
            dispatch(addSubstitution(payload))
        } else {
            dispatch(selectPlayer(payload))
        }
    }

    const data = [
        ...formattedLineUp.map(item => {
            const { player } = item
            if (!player) return item
            const newPlayer = getPlayer(item.player.id, players)
            return {
                ...item,
                player: newPlayer,
            }
        }),
        ...Array.from(Array(NUMBER_OF_SUBSTITUTES).keys()).map(i => {
            const position = `SUB-${i + 1}`
            const player = substitutions.find(item => item.position === position)?.player
            return {
                position,
                player: player || null,
            }
        }),
    ]

    return (
        <div className="players-list-container">
            <div className="table-responsive h-100">
                <ReactTableComponent
                    data={data}
                    columns={columns(formatedPlayers, handleChange)}
                    minRows={0}
                    // noDataText={<EmptySquad squad={squad} />}
                    showPagination={false}
                    className="-striped -highlight h-100"
                />
            </div>
        </div>
    )
}

export default Players
