import { useSelector } from 'react-redux'

const UserName = () => {
    const { player: user } = useSelector(state => state.userProfile.data)

    return (
        <div className="">
            <h3 className="w-100 my-0">
                {/* <em className="hint-text me-2">{user?.number}</em> */}
                {user.name}
            </h3>
            <p className="hint-text my-0">{`${user?.gender} / ${user.age} yrs`}</p>
        </div>
    )
}
export default UserName
