import React from 'react'
import { Link } from 'react-router-dom'

export const Page404 = () => {
    return (
        <div className="d-flex justify-content-center full-height full-width align-items-center">
            <div className="error-container text-center">
                <h1 className="error-number">404</h1>
                <h2 className="semi-bold">Sorry but we couldnt find this page</h2>
                <p className="p-b-10">This page you are looking for does not exsist <Link to='/'>Go Home</Link></p>
                {/* <div className="error-container-innner text-center">
                <form className="error-form">
                    <div className=" transparent text-left">
                    <div className="form-group form-group-default input-group">
                        <div className="form-input-group">
                        <label>Search</label>
                        <input className="form-control" placeholder="Try searching the missing page" type="email"/>
                        </div>
                        <div className="input-group-append">
                        <span className="input-group-text transparent">
                            <span className="pg-icon p-l-5">search</span>
                        </span>
                        </div>
                    </div>
                    </div>
                </form>
                </div> */}
            </div>
        </div>
    )
}