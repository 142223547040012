const validation = {
    bio: values => {
        const errors = {}

        if (!values.bio) {
            errors.bio = "Please write your squad's history."
        } else if (values.bio.length < 100 || values.bio.length > 500) {
            errors.bio = 'Your bio should be between 100 and 500 characters'
        }

        return errors
    },
    invite_player: values => {
        const errors = {}

        // if (!values.invite_player) {
        //     errors.invite_player = 'Please enter your invite to squad.'
        // }

        return errors
    },
    invite_staff: values => {
        const errors = {}

        // if (!values.invite_staff) {
        //     errors.invite_staff = 'Please enter your invite to squad.'
        // }

        return errors
    },
    logo: values => {
        const errors = {}
        const { logo } = values
        
        if (!logo) {
            errors.logo = 'Please upload your logo.'
        }

        return errors
    },
}

export default validation
