import { getRequest, postRequest } from './apiRequest'

// search api function
export const scouting = (squadSlug, prams = {}) => {
    const url = `/scouting/${squadSlug}/find`
    return getRequest(url, prams)
}

// approach a player
export const approach = (squadSlug, payload) => {
    const url = `/scouting/${squadSlug}/approach`
    return postRequest(url, payload)
}

// approach-cancel a player
export const approachCancel = (transferId, squadSlug, userId = null, email = null) => {
    const url = `/scouting/${squadSlug}/cancel-approach`
    const data = {
        values: {
            transfer_id: transferId,
            player_id: userId,
            email
        },
    }

    return postRequest(url, data)
}
