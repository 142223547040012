import MapContainer from 'components/shared/MapContainer'
import { MapPin } from 'react-feather'
import { useSelector } from 'react-redux'
import { formatAddress } from 'utils'

const FixtureMap = () => {
    const {
        isLoading,
        data: { location },
    } = useSelector(state => state.fixture)

    if (!location || isLoading) return null
    return (
        <>
            <p className="d-flex gap-1 lh-1 mt-0 mb-3">
                <MapPin size={18} /> {formatAddress(location.address)}
            </p>
            <MapContainer
                style={{
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                }}
                markerText={location.address}
                height={500}
                lat={location.lat}
                lng={location.lng}
                zoom={16}
            />
        </>
    )
}

export default FixtureMap
