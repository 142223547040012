import ShirtPicker from 'components/shared/ShirtPicker'
import React, { useEffect, useState } from 'react'
import { Form as BsForm } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { randomNumBetweenTwo } from 'utils'

const EditShirts = ({ value, onChange, squad }) => {
    const { shirts: jerseys } = useSelector(st => st.masterData)

    
    const [shirts, setShirts] = useState({
        home: value.home,
        away: value.away,
        alternate: value.alternate,
        keeper: value.keeper,
    })
    
    useEffect(() => {
        onChange(shirts)
    }, [shirts])

    const setShirt = (type, shirt) =>
        setShirts({
            ...shirts,
            [type]: shirt,
        })

    return (
        <Wrapper className="d-flex flex-row align-items-center justify-content-center">
            <div className="shirts-holder">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <BsForm.Group>
                        <ShirtPicker
                            shirtNumber={7}
                            onChange={shirt => setShirt('home', shirt)}
                            value={shirts.home}
                        />
                    </BsForm.Group>
                    <BsForm.Label className="w-100 text-center m-t-10">Home</BsForm.Label>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <BsForm.Group>
                        <ShirtPicker
                            shirtNumber={10}
                            onChange={shirt => setShirt('away', shirt)}
                            value={shirts.away}
                        />
                    </BsForm.Group>
                    <BsForm.Label className="w-100 text-center m-t-10">Away</BsForm.Label>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <BsForm.Group>
                        <ShirtPicker
                            shirtNumber={11}
                            onChange={shirt => setShirt('alternate', shirt)}
                            value={shirts.alternate}
                        />
                    </BsForm.Group>
                    <BsForm.Label className="w-100 text-center m-t-10">Alternate</BsForm.Label>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <BsForm.Group>
                        <ShirtPicker
                            isKeeper
                            shirtNumber={1}
                            onChange={shirt => setShirt('keeper', shirt)}
                            value={shirts.keeper}
                        />
                    </BsForm.Group>
                    <BsForm.Label className="w-100 text-center m-t-10">Keeper</BsForm.Label>
                </div>
            </div>
        </Wrapper>
    )
}

export default EditShirts

const Wrapper = styled.div`
    .shirts-holder {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        @media (min-width: 530px) {
            grid-template-columns: repeat(4, 1fr);
        }
        > div {
            padding: 1rem;
            border-radius: 0.5rem;
            background: #121f27;
        }
    }
`
