import MatchEventCorner from './MatchEventCorner'
import MatchEventFoul from './MatchEventFoul'
import MatchEventGoalConceded from './MatchEventGoalConceded'
import { MatchEventGoalScored } from './MatchEventGoalScored'
import { MatchEventRedCard } from './MatchEventRedCard'
import { MatchEventSubstitution } from './MatchEventSubstitution'
import MatchEventTechnical from './MatchEventTechnical'
import { MatchEventYellowCard } from './MatchEventYellowCard'

export const MatchEvent = ({ event }) => {
    switch (event.event_type) {
        case 'goal_scored':
            return <MatchEventGoalScored event={event} />
        case 'goal_conceded':
            return <MatchEventGoalConceded event={event} />
        case 'foul':
            return <MatchEventFoul event={event} />
        case 'yellow_card':
            return <MatchEventYellowCard event={event} />
        case 'red_card':
            return <MatchEventRedCard event={event} />
        case 'substitution':
            return <MatchEventSubstitution event={event} />
        case 'tackle':
            return <MatchEventTechnical event={event} />
        case 'shot':
            return <MatchEventTechnical event={event} />
        case 'interception':
            return <MatchEventTechnical event={event} />
        case 'header':
            return <MatchEventTechnical event={event} />
        case 'shot-saved':
            return <MatchEventTechnical event={event} />
        case 'key-pass':
            return <MatchEventTechnical event={event} />
        case 'successful-dribbles':
            return <MatchEventTechnical event={event} />
        case 'shot-blocked':
            return <MatchEventTechnical event={event} />
        case 'corner':
            return <MatchEventCorner event={event} />
        default:
            return null
    }
}
