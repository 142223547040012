import { cloneElement, useEffect, useState } from 'react'

function useDelayUnmount(isMounted, delayTime) {
    const [showDiv, setShowDiv] = useState(false)
    useEffect(() => {
        let timeoutId
        if (isMounted && !showDiv) {
            setShowDiv(true)
        } else if (!isMounted && showDiv) {
            timeoutId = setTimeout(() => setShowDiv(false), delayTime) //delay our unmount
        }
        return () => clearTimeout(timeoutId) // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
    }, [isMounted, delayTime, showDiv])
    return showDiv
}

const ShowWithAnimation = ({ children, isMounted }) => {
    const showDiv = useDelayUnmount(isMounted, 450)
    const mountedStyle = {
        position: 'relative',
        animation: 'FadeIn 100ms ease-in',
    }
    const unmountedStyle = {
        position: 'relative',
        animation: 'FadOut 100ms ease-out',
        animationFillMode: 'forwards',
    }

    if (!showDiv) return null

    return cloneElement(children, {
        style: { ...(children.props.style || {}), ...(isMounted ? mountedStyle : unmountedStyle) },
    })
}

export default ShowWithAnimation
