import { resetUserProfile } from 'components/store'
import { ChevronLeft } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getAllSearchParams } from 'utils'

const UserTop = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)

    const reset = () => {
        const { player, ...rest } = params
        setSearchParams(rest)
        dispatch(resetUserProfile())
    }

    return (
        <div className="py-2 px-5c">
            <div onClick={reset} className="hint-text d-flex align-items-center lh-1 gap-1">
                <ChevronLeft />
                Back
            </div>
        </div>
    )
}
export default UserTop
