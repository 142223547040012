const SET_FORM_STEP = 'SET_FORM_STEP'
const SET_FORM_STEP_NAME = 'SET_FORM_STEP_NAME'
const INITIATE_FORM = 'INITIATE_FORM'
const SET_FORM_DATA = 'SET_FORM_DATA'
const SET_FORM_IS_LOADING = 'SET_FORM_IS_LOADING'
const SET_FORM_IS_SUBMITTING = 'SET_FORM_IS_SUBMITTING'
const FORM_NEXT_STEP = 'FORM_NEXT_STEP'
const FORM_PREV_STEP = 'FORM_PREV_STEP'
const RESET_FORM_DATA = 'RESET_FORM_DATA'
const SET_FORM_VALIDATE = 'SET_FORM_VALIDATE'
const RESET_FORM_VALIDATE = 'RESET_FORM_VALIDATE'

const initialState = {
    step: 1,
    stepNames: [],
    stepBacks: [],
    total: 0,
    data: {},
    isLoading: false,
    validate: false,
}

const multiStepFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_STEP:
            return {
                ...state,
                step: action.payload,
            }

        case FORM_NEXT_STEP:
            return {
                ...state,
                step: state.step + 1,
                validate: false,
            }
        case FORM_PREV_STEP:
            delete state.data[`step${state.step}`]
            return {
                ...state,
                step: state.step - 1,
                validate: true,
            }
        case INITIATE_FORM:
            return {
                ...initialState,
                total: action.payload.total,
                stepNames: action.payload.stepNames,
                stepBacks: action.payload.stepBacks,
            }
        case SET_FORM_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            }
        case SET_FORM_VALIDATE:
            return {
                ...state,
                validate: true,
            }
        case RESET_FORM_VALIDATE:
            return {
                ...state,
                validate: false,
            }
        case RESET_FORM_DATA:
            return initialState
        default:
            return state
    }
}
export default multiStepFormReducer

// Action Creators
export const setFormStep = step => ({
    type: SET_FORM_STEP,
    payload: step,
})

export const formNextStep = () => ({
    type: FORM_NEXT_STEP,
})

export const formPrevStep = () => ({
    type: FORM_PREV_STEP,
})

export const initiateForm = (total, stepNames, stepBacks) => ({
    type: INITIATE_FORM,
    payload: {
        total,
        stepNames,
        stepBacks,
    },
})

export const setFormStepData = (step, data) => ({
    type: SET_FORM_DATA,
    payload: {
        [`step${step}`]: data,
    },
})

export const setFormValidate = () => ({
    type: SET_FORM_VALIDATE,
})

export const resetFormValidate = () => ({
    type: RESET_FORM_VALIDATE,
})
