import { LS_TOKEN_EXPIRES, LS_USER_DATA, LS_USER_TOKEN, LS_VERIFY_EMAIL_CANCELED } from 'constants'
import moment from 'moment'
import ls from '../../shared/LocalStorage'

const SET_USER_DATA = 'SET_USER_DATA'
const SET_USER_TOTAL_UNREAD_MESSAGES = 'SET_USER_TOTAL_UNREAD_MESSAGES'
export const CLEAR_USER_STORE = 'CLEAR_USER_STORE'
const SET_USER_EMAIL_UNVERIFIED = 'SET_USER_EMAIL_UNVERIFIED'
const UPDATE_SQUAD_NOT_ONBOARDED = 'UPDATE_SQUAD_NOT_ONBOARDED'
const REMOVE_USER_EMAIL_UNVERIFIED = 'REMOVE_USER_EMAIL_UNVERIFIED'

const userData = ls.loadState(LS_USER_DATA, true)
const tokenExpires = parseInt(localStorage.getItem(LS_TOKEN_EXPIRES))
const nowTimestamp = moment().unix()
const isTokenValid = tokenExpires > nowTimestamp

const initialState = isTokenValid ? userData || null : null

const user = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA: {
            const setUserDataState = {
                ...state,
                ...action.payload,
            }
            ls.saveState(LS_USER_DATA, setUserDataState, true)
            return setUserDataState
        }
        case UPDATE_SQUAD_NOT_ONBOARDED: {
            const setUserDataState2 = {
                ...state,
                squad_not_onboarded: [...state.squad_not_onboarded, action.payload],
            }
            ls.saveState(LS_USER_DATA, setUserDataState2, true)
            return setUserDataState2
        }
        case SET_USER_TOTAL_UNREAD_MESSAGES: {
            const setUserTotalUnreadMessagesState = {
                ...state,
                total_unread: action.payload,
            }
            ls.saveState(LS_USER_DATA, setUserTotalUnreadMessagesState, true)
            return setUserTotalUnreadMessagesState
        }
        case SET_USER_EMAIL_UNVERIFIED: {
            const setUserEmailUnverifiedState = {
                ...state,
                email_unverified: action.payload,
            }
            ls.saveState(LS_USER_DATA, setUserEmailUnverifiedState, true)
            return setUserEmailUnverifiedState
        }
        case REMOVE_USER_EMAIL_UNVERIFIED: {
            const { email_unverified, ...rest } = state
            ls.saveState(LS_USER_DATA, rest, true)
            return rest
        }
        case CLEAR_USER_STORE:
            // when user logs out, clear local storage data
            ls.clearState(LS_USER_DATA)
            ls.clearState(LS_USER_TOKEN)
            ls.clearState(LS_TOKEN_EXPIRES)
            ls.clearState(LS_VERIFY_EMAIL_CANCELED)
            return null
        default:
            return state
    }
}

export default user

export const setUser = user => ({
    type: SET_USER_DATA,
    payload: user,
})

export const setUserUnreadNotifications = total => ({
    type: SET_USER_TOTAL_UNREAD_MESSAGES,
    payload: total,
})

export const addNotOnBoardedSquadsToUser = data => ({
    type: UPDATE_SQUAD_NOT_ONBOARDED,
    payload: data,
})

export const setUserEmailUnverified = emailUnverified => ({
    type: SET_USER_EMAIL_UNVERIFIED,
    payload: emailUnverified,
})

export const removeUserEmailUnverified = () => ({
    type: REMOVE_USER_EMAIL_UNVERIFIED,
})

// selectors
export const getUserConversations = state => state.user.subscribed_squads

export const getUserConversation = (state, conversationId) => {
    const conversations = getUserConversations(state)
    return conversations.find(conversation => conversation.squad_id === conversationId)
}
