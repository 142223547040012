import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LineUpPlayerGoalScored from './LineUpPlayerGoalScored'
import LineUpPlayerRating from './LineUpPlayerRating'
import LineUpPlayerRedCard from './LineUpPlayerRedCard'
import LineUpPlayerSubstitution from './LineUpPlayerSubstitution'
import LineUpPlayerYellowCard from './LineUpPlayerYellowCard'

const Player = ({ player, rating, position, hidden = null, ground, fake, playerShirt, gkShirt }) => {
    const {
        masterData: { shirts },
    } = useSelector(state => state)

    return (
        <Wrapper className={`player ${!hidden ? position.pos : ''}`}>
            {!hidden && (
                <>
                    {position?.pos === 'GK' ? (
                        <img src={`/img/shirts/keeper_${gkShirt}.png`} alt="" className="player--shirt" />
                    ) : (
                        <img src={`/img/shirts/player_${playerShirt}.png`} alt="" className="player--shirt" />
                    )}
                    {player && (
                        <>
                            {position.pos === 'GK' ? (
                                <span
                                    className={`player--number ${gkShirt} ${
                                        shirts?.players['keeper_' + gkShirt]?.letter
                                    }`}>
                                    {player.number}
                                </span>
                            ) : (
                                <span
                                    className={`player--number ${playerShirt} ${
                                        shirts?.players['player_' + playerShirt]?.letter
                                    }`}>
                                    {player.number}
                                </span>
                            )}

                            {player.username && (
                                <span color="onSurface.nLv1" title={player.username} className="player--label">
                                    {player.username}
                                </span>
                            )}
                            <LineUpPlayerGoalScored player={player} />
                            <LineUpPlayerSubstitution player={player} />
                            <LineUpPlayerRating rating={rating} />
                            <LineUpPlayerYellowCard player={player} />
                            <LineUpPlayerRedCard player={player} />
                        </>
                    )}
                </>
            )}
        </Wrapper>
    )
}

export default Player

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out 0s;
    width: 56px;
    height: 72px;
    .player--shirt {
        width: 42px;
        object-fit: cover;
    }
    .player--number {
        width: 24px;
        position: absolute;
        top: 15px;
        left: calc(50% - 13px);
        color: rgb(0, 0, 0);
        text-align: center;
        font-family: 'scouty-semibold';
        letter-spacing: -2.2px;
        font-size: 1.5rem;
        &.light {
            color: #fff;
        }
    }
    .player--label {
        width: 64px;
        padding-top: 4px;
        color: var(--on-surface-nLv1);
        text-align: center;
        font-family: scouty-semibold;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.8);
    }
    
`
