import { useSelector } from 'react-redux'

const BenchPlayerRedCard = ({ player }) => {
    const { list: matchEvents } = useSelector(state => state.matchEvents)

    const playerHasRed = matchEvents?.find(event => event.event_type === 'red_card' && event.player.id === player.id)

    if (!playerHasRed) return null

    return <div className="red-card" />
}

export default BenchPlayerRedCard
