import classNames from 'classnames'
import { closePlayersPositionPicker } from 'components/store'
import { useWindowSize } from 'hooks'
import { ChevronLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import Players from '../formationPicker/Players'

const PlayersPositionPicker = () => {
    // const { width } = useWindowSize()
    const dispatch = useDispatch()
    const { playersPositionPicker } = useSelector(state => state.formationPicker)

    const handleClosePlayersPositionPicker = () => dispatch(closePlayersPositionPicker())

    // if (width > 768 && playersPositionPicker) {
    //     handleClosePlayersPositionPicker()
    // }

    return (
        <div
            className={classNames('dropdown-player to-right', {
                show: playersPositionPicker,
            })}>
            <div className="picker-header">
                <div className="d-flex align-items-center ms-12 gap-2">
                    <h5 className="my-0">Full Player List</h5>

                    {/* close button */}
                    <button type="button" onClick={handleClosePlayersPositionPicker} className="btn-close">
                        <ChevronLeft size={24} />
                    </button>
                </div>
            </div>
            <div className="scrollbar-hidden scrollbar-y flex-1">
                <Players />
            </div>
        </div>
    )
}
export default PlayersPositionPicker
