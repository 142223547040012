const loadState = (stateName, parse = false) => {
    try {
        const serializedState = localStorage.getItem(stateName)
        if (serializedState === null) {
            return undefined
        }
        if(parse) {
            return JSON.parse(serializedState)
        }
        return serializedState        
    } catch (err) {
        return undefined
    }
}
const saveState = (stateName, state, stringify = false) => {
    try {
        let serializedState = '';
        serializedState = state
        if(stringify === true) {
            // if the stringify is set to true we set this 
            // to a JSON stringified variable and pass it to the local storage
            serializedState = JSON.stringify(state)
        }
        localStorage.setItem(stateName, serializedState)
    } catch {
        // ignore write errors
    }
}

const clearState = stateName => {
    try {
        localStorage.removeItem(stateName)
    } catch {
        // ignore write errors
    }
}
export default {
    loadState,
    saveState,
    clearState,
}