import Toast from 'components/shared/Toast'
import { loadFixturePlayers, updateFixture, updateSelectedDayEvents, updateUpcomingEvent } from 'components/store'
import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { postRequest, rsvpMatch } from 'services'

const RSVPMatch = ({ playerInLineup, addToast }) => {
    const params = useParams()
    const dispatch = useDispatch()
    const [submitting, setSubmitting] = useState(false)
    const { user, clubSquadDetail: squad } = useSelector(state => state)
    const { data: fixture } = useSelector(state => state.fixture)

    const setRSVPMatch = async status => {
        if (!['yes', 'no'].includes(status)) return
        setSubmitting(true)
        const { data } = await rsvpMatch(squad.squad_slug, user.id, status, fixture.id)
        if (data.status === 'success') {
            const { match_players } = data.data
            dispatch(loadFixturePlayers(match_players))
            const im_attending = status === 'yes'
            const payload = {
                id: fixture.id,
                date: fixture.date,
                type: 'matches',
                data: {
                    im_attending,
                },
            }
            dispatch(updateUpcomingEvent(payload))
            dispatch(updateSelectedDayEvents(payload))
            const message = im_attending
                ? 'Thank you! You have notified your manager you WILL be attending!'
                : 'Thank you! You have notified your manager you WONT be attending!'
            toast.success(message)
        }
        setSubmitting(false)
    }

    return (
        <>
            <div className="rsvp-match-container">
                <Button
                    size="md"
                    disabled={playerInLineup.player_attended === 'yes' || submitting}
                    onClick={e => setRSVPMatch('yes')}
                    className={`${playerInLineup.player_attended === 'yes' ? 'selected' : ''} going `}>
                    <Check size={17} strokeWidth={3} color="#2ec52e" />
                    &nbsp;I Am Going
                </Button>
                <Button
                    size="md"
                    disabled={playerInLineup.player_attended === 'no' || submitting}
                    onClick={e => setRSVPMatch('no')}
                    className={`${playerInLineup.player_attended === 'no' ? 'selected' : ''} not-going `}>
                    <X size={17} strokeWidth={3} color="red" />
                    &nbsp;Not Going
                </Button>
            </div>
        </>
    )
}

export default Toast(RSVPMatch)
