import classNames from 'classnames'
import FixtureHeader from './FixtureHeader'
import FixturePlayerMenu from './FixturePlayerMenu'
import { MatchDetails } from './Tabs'

const FixtureLeft = ({ className, tactical = false, details = true, deleteable = false }) => {
    return (
        <div className={classNames('fixture-left', className)}>
            <FixtureHeader />
            {details && <FixturePlayerMenu />}
            <MatchDetails tactical={tactical} details={details} deleteable={deleteable} />
        </div>
    )
}

export default FixtureLeft
