export const LS_USER_TOKEN = 'SCOUTY_USER_TOKEN'

export const LS_USER_DATA = 'SCOUTY_USER_DATA'

export const LS_TOKEN_EXPIRES = 'SCOUTY_TOKEN_EXPIRES'

export const LS_VERIFY_EMAIL_CANCELED = 'SCOUTY_VERIFY_EMAIL_CANCELED'

export const NUMBER_OF_SUBSTITUTES = 10

export const MATCH_HALF_OPTIONS = [
    {
        value: '1st',
        label: '1st Half',
        labelShort: '1H',
        number: 1,
    },
    {
        value: '2nd',
        label: '2nd Half',
        labelShort: '2H',
        number: 2,
    },
    {
        value: 'et-1',
        label: 'ET 1st Half',
        labelShort: '1H ET',
        number: 3,
    },
    {
        value: 'et-2',
        label: 'ET 2nd Half',
        labelShort: '2H ET',
        number: 4,
    },
]

export const MATCH_EVENT_TYPES = [
    {
        label: 'Goal',
        id: 'goals',
        img: '027-goal.png',
        children: ['goal_scored', 'goal_conceded'],
    },
    {
        label: 'Foul committed',
        id: 'cards',
        children: ['yellow_card', 'foul', 'red_card'],
    },
    {
        label: 'Technical',
        id: 'technical',
        children: [
            'tackle',
            'shot',
            'interception',
            'header',
            'shot-saved',
            'key-pass',
            'successful-dribbles',
            'shot-blocked',
            'corner',
            'blunder'
        ],
    },
    {
        label: 'Substitution',
        id: 'substitution',
        children: ['substitution'],
    },
]

export const ZONES = [
    {
        name: 'Left Wing',
        position: ['LW', 'LS'],
        x1: 14,
        x2: 30,
        y1: 14,
        y2: 24,
    },
    {
        name: 'Striker Left',
        position: ['LS', 'SS', 'ST'],
        x1: 30,
        x2: 50,
        y1: 14,
        y2: 34,
    },
    {
        name: 'Striker',
        position: ['ST', 'FW'],
        x1: 50,
        x2: 70,
        y1: 14,
        y2: 34,
    },
    {
        name: 'Striker Right',
        position: ['RS', 'SS', 'ST'],
        x1: 70,
        x2: 90,
        y1: 14,
        y2: 34,
    },
    {
        name: 'Right Wing',
        position: ['RW', 'RS'],
        x1: 90,
        x2: 106,
        y1: 14,
        y2: 24,
    },
    // Attacking Midfielders
    {
        name: 'Left Wing Back',
        position: ['LW', 'AML'],
        x1: 14,
        x2: 30,
        y1: 24,
        y2: 64,
    },
    {
        name: 'Attacking Midfielder',
        position: ['AMC'],
        x1: 30,
        x2: 90,
        y1: 34,
        y2: 64,
    },
    {
        name: 'Right Wing Back',
        position: ['RW', 'AMR'],
        x1: 90,
        x2: 106,
        y1: 24,
        y2: 64,
    },
    // Midfielders
    {
        name: 'Left Midfielder',
        position: ['ML'],
        x1: 14,
        x2: 30,
        y1: 64,
        y2: 104,
    },
    {
        name: 'Midfielder',
        position: ['MC'],
        x1: 30,
        x2: 90,
        y1: 64,
        y2: 104,
    },
    {
        name: 'Right Midfielder',
        position: ['MR'],
        x1: 90,
        x2: 106,
        y1: 64,
        y2: 104,
    },
    // Defensive Midfielder
    {
        name: 'Left Wing Back',
        position: ['LWB'],
        x1: 14,
        x2: 30,
        y1: 104,
        y2: 134,
    },
    {
        name: 'Defensive Midfielder',
        position: ['DMC'],
        x1: 30,
        x2: 90,
        y1: 104,
        y2: 134,
    },
    {
        name: 'Right Wing Back',
        position: ['RWB'],
        x1: 90,
        x2: 106,
        y1: 104,
        y2: 134,
    },
    // Defenders
    {
        name: 'Left Defender',
        position: ['LWB', 'LB'],
        x1: 14,
        x2: 30,
        y1: 134,
        y2: 166,
    },
    {
        name: 'Defender',
        position: ['SW', 'DC'],
        x1: 30,
        x2: 90,
        y1: 134,
        y2: 150,
    },
    {
        name: 'Right Defender',
        position: ['RWB', 'RB'],
        x1: 90,
        x2: 106,
        y1: 134,
        y2: 166,
    },
    // Goalkeeper
    {
        name: 'Goalkeeper',
        position: ['GK'],
        x1: 30,
        x2: 90,
        y1: 150,
        y2: 166,
    },
]

export const SESSION_DURATION = [
    {
        label: '15 minutes',
        value: 15,
    },
    {
        label: '20 minutes',
        value: 20,
    },
    {
        label: '30 minutes',
        value: 30,
    },
    {
        label: '45 minutes',
        value: 45,
    },
    {
        label: '1 hour',
        value: 60,
    },
]

export const REPEATS = [
    { label: 'Never', value: 'never' },
    // { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    // { label: 'Monthly', value: 'monthly' },
]

export const POSITION_GROUP = [
    {
        label: 'Goalkeeper',
        value: 'GK',
    },
    {
        label: 'Defender',
        value: 'DEF',
    },
    {
        label: 'Midfielder',
        value: 'MID',
    },
    {
        label: 'Forward',
        value: 'FW',
    },
]

export * from './formationPicker'

export const PLAYER_LEVELS = [
    {
        label: 'Sunday League',
        value: 'sunday_league',
    },
    {
        label: 'Amateur',
        value: 'amateur',
    },
    {
        label: 'College',
        value: 'college',
    },
    {
        label: 'Semi-pro',
        value: 'semi_pro',
    },
]

export const GENDERS = [
    {
        label: 'Men',
        value: 'men',
    },
    {
        label: 'Women',
        value: 'women',
    },
    {
        label: 'Co-ed',
        value: 'co_ed',
    },
]
