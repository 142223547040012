import { 
    SET_SEARCH_PLAYERS_DATA
} from '../actions/actionTypes'

const initialState = {}

const searchPlayers = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_PLAYERS_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
export default searchPlayers