import { updateClubSquadDetail } from '../actions'
import { CLEAR_CLUB_SQUAD_DETAIL_DATA, SET_CLUB_SQUAD_DETAIL_DATA, SET_SQUAD_PLAYER_DATA } from '../actions/actionTypes'

const SUBTRACT_PENDING_TRANSFERS = 'SUBTRACT_PENDING_TRANSFERS'
const SET_SQUAD_SINGLE_PLAYER = 'SET_SQUAD_SINGLE_PLAYER'

const initialState = {}

const clubSquadDetail = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLUB_SQUAD_DETAIL_DATA:
            return {
                ...action.payload,
            }
        case SET_SQUAD_PLAYER_DATA:
            return {
                ...state,
                players: action.payload,
            }
        case SET_SQUAD_SINGLE_PLAYER:
            return {
                ...state,
                players: state.players.map(p =>
                    p.id === action.payload.playerId ? { ...p, ...action.payload.newData } : p
                ),
            }
        case CLEAR_CLUB_SQUAD_DETAIL_DATA:
            return {}
        case SUBTRACT_PENDING_TRANSFERS:
            return {
                ...state,
                pending_transfers: Math.max(0, state.pending_transfers - 1),
            }
        default:
            return state
    }
}
export default clubSquadDetail

// subtract pending transfers
export const subtractPendingTransfers = () => ({
    type: SUBTRACT_PENDING_TRANSFERS,
})

// update squad single player
export const updateSquadSinglePlayer = (playerId, newData) => ({
    type: SET_SQUAD_SINGLE_PLAYER,
    payload: {
        playerId,
        newData,
    },
})

// add opponent to squad
export const addOpponentToSquad = opponent => (dispatch, getState) => {
    const { clubSquadDetail } = getState()
    dispatch(
        updateClubSquadDetail({
            ...clubSquadDetail,
            opponents: [...clubSquadDetail.opponents, opponent],
        })
    )
}

// set players in squad
export const setPlayersInSquad = players => (dispatch, getState) => {
    const { clubSquadDetail } = getState()
    dispatch(
        updateClubSquadDetail({
            ...clubSquadDetail,
            players,
        })
    )
}

// Add a single player to the squad players array
export const addSinglePlayerToSquad = player => (dispatch, getState) => {
    const { clubSquadDetail } = getState()
    const { players } = clubSquadDetail
    const playerIndex = players.findIndex(p => p.id === player.id)
    const newPlayers = playerIndex === -1 ? [...players, player] : players.map(p => (p.id === player.id ? player : p))
    dispatch(
        updateClubSquadDetail({
            ...clubSquadDetail,
            players: newPlayers,
            total_players: newPlayers.length,
        })
    )
}

// Remove a single player from the squad players array
export const removePlayerFromSquad = player_id => (dispatch, getState) => {
    const { clubSquadDetail } = getState()
    const { players } = clubSquadDetail
    dispatch(
        updateClubSquadDetail({
            ...clubSquadDetail,
            players: players.filter(e => e.id !== player_id) || players,
            total_players: clubSquadDetail.total_players--,
        })
    )
}

// Add whatever data points you want to add here
export const updateSquadDetailData = data => (dispatch, getState) => {
    const { clubSquadDetail } = getState()
    let newData = clubSquadDetail

    Object.keys(data).forEach(e => (data[e] === 'delete' ? delete newData[e] : (newData[e] = data[e])))
    console.log(newData)
    dispatch(
        updateClubSquadDetail({
            ...newData,
        })
    )
}
