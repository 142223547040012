import {
    isMatchEnded,
    isMatchFifteenMinsLeft,
    isMatchFiveMinsAway,
    isMatchThreeHoursAgo,
    matchIsMoreThanThreeHoursAway,
} from 'components/shared/shared2'
import {
    openAddEvent,
    openChangeLineup,
    openEditMatch,
    showRatePlayersDoneModal,
    showRatePlayersModal,
} from 'components/store'
import { isEmpty } from 'lodash'
import Dropdown from 'components/shared/Dropdown'
import { Button, Nav, Tab, Tabs } from 'react-bootstrap'
import { Activity, ChevronLeft, MapPin, PlusCircle, Power, Star, Tool, XCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { isMatchOrTimerStarted } from 'utils'
import TimerButton from './TimerButton'
import { useState } from 'react'
import { soccerBall, subsBench } from 'components/shared/svg/svgs'
import { ConfirmModal } from 'components/shared'
import { openMatchEventsModal } from 'components/store'
import { cancelMatch } from 'services'
import { updateFixture } from 'components/store'
import {
    goal_conceded,
    goal_scored,
    yellow_card,
    red_card,
    corner_taken,
    foul,
    header,
    shot_attempted,
    dribbles,
    keeper_save,
    interception,
    tackle,
    shot_blocked,
    key_pass,
    subs,
    player_blunder,
} from 'components/shared/svg/svgs'

const eventsToSvg = {
    goal_conceded,
    goal_scored,
    yellow_card,
    red_card,
    foul,
    header,
    shot: shot_attempted,
    successful_dribbles: dribbles,
    shot_saved: keeper_save,
    corner: corner_taken,
    interception,
    tackle,
    shot_blocked,
    key_pass,
    substitution: subs,
    blunder: player_blunder,
}

const splitByBasic = jsonObj => {
    const basicEvents = {}
    const nonBasicEvents = {}

    for (const key in jsonObj) {
        if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
            const item = jsonObj[key]
            if (Object.prototype.hasOwnProperty.call(item, 'basic')) {
                if (item.basic === true) {
                    basicEvents[key] = item
                } else {
                    nonBasicEvents[key] = item
                }
            } else {
                nonBasicEvents[key] = item // If "basic" property is not present, consider it as non-basic
            }
        }
    }

    return { basicEvents, nonBasicEvents }
}

const EventBubbles = ({ events }) => {
    const dispatch = useDispatch()

    const handleShowAddEvent = event_type => dispatch(openAddEvent(event_type))
    return (
        <div className="event--trigger-container">
            {Object.keys(events).map(e => {
                const { id, label } = events[e]
                // const svg = id
                return (
                    <div className="event--trigger" onClick={e => handleShowAddEvent(id)}>
                        {eventsToSvg[id] && (
                            <div className="event-svg" dangerouslySetInnerHTML={{ __html: eventsToSvg[id] }} />
                        )}
                        <div className="trigger-label">{label}</div>
                    </div>
                )
            })}
        </div>
    )
}

const FixtureMenu = () => {
    const dispatch = useDispatch()
    const { isLoading, data: fixture } = useSelector(state => state.fixture)
    const { match_events } = useSelector(state => state.masterData)
    const [addEvent, setAddEvent] = useState(null)
    const [isCancelling, setIsCancelling] = useState(null)

    const handleRatePlayers = () => {
        if (fixture?.players.find(player => player?.rating?.rating)) {
            dispatch(showRatePlayersDoneModal())
        } else {
            dispatch(showRatePlayersModal())
        }
    }

    const openEventHistoryDrawer = () => dispatch(openMatchEventsModal())

    const handleEditMatch = () => dispatch(openEditMatch())

    const handleChangeLineup = () => dispatch(openChangeLineup())

    const handleCancelMatch = async () => {
        setIsCancelling(true)
        const {
            id: match_id,
            squad: { squad_slug },
        } = fixture
        const data = {
            match_id,
        }
        const response = await cancelMatch(squad_slug, data)
        dispatch(updateFixture(fixture.id, { is_cancelled: 1 }))
        setIsCancelling(false)
    }

    if (isEmpty(fixture) || isLoading) return null
    // const playersNotComing = playersRsvpNoFixture(fixture)

    const showRatePlayers = isMatchEnded(fixture) || isMatchThreeHoursAgo(fixture)

    const { basicEvents, nonBasicEvents } = splitByBasic(match_events)

    return (
        <div className={`fixture-menu ${addEvent ? 'big' : ''}`}>
            {isMatchFifteenMinsLeft(fixture) && (
                <>
                    {addEvent ? (
                        <div className="event-tabs">
                            <div className="go-back" onClick={e => setAddEvent(null)}>
                                <ChevronLeft size={20} />
                            </div>
                            <Tabs defaultActiveKey="key" id="uncontrolled-tab-example">
                                <Tab eventKey="key" title="Key Events">
                                    <EventBubbles events={nonBasicEvents} />
                                </Tab>
                                <Tab eventKey="basic" title="Basic Events">
                                    <EventBubbles events={basicEvents} />
                                </Tab>
                            </Tabs>
                        </div>
                    ) : (
                        <div className="fixture-menu-buttons">
                            <div className="btn--container">
                                <Button disabled={isLoading} size="lg" onClick={openEventHistoryDrawer}>
                                    <Activity size={22} strokeWidth={2.5} stroke="#fff" />
                                </Button>
                                <span className="btn--text">Edit Events</span>
                            </div>
                            <div className="btn--container">
                                <Button disabled={isLoading} size="lg" onClick={e => setAddEvent(true)}>
                                    <PlusCircle size={22} strokeWidth={2.5} stroke="#fff" />
                                </Button>
                                <span className="btn--text">Add Event</span>
                            </div>
                            {showRatePlayers && (
                                <div className="btn--container">
                                    <Button
                                        onClick={handleRatePlayers}
                                        className="cancel"
                                        disabled={isLoading}
                                        size="lg">
                                        <Power size={20} strokeWidth={3} stroke="#fff" />
                                    </Button>
                                    <span className="btn--text">End Match</span>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {isMatchFiveMinsAway(fixture) && (
                <div className={`fixture-menu-buttons`}>
                    {!isMatchOrTimerStarted(fixture) && (
                        <div className="btn--container">
                            <Button onClick={handleChangeLineup} size="lg">
                                <Tool size={22} strokeWidth={2.5} stroke="#fff" />
                            </Button>
                            <span className="btn--text">Lineup</span>
                        </div>
                    )}
                    {isMatchFiveMinsAway(fixture) && (
                        <>
                            <div className="btn--container">
                                <Button onClick={handleEditMatch} size="lg">
                                    <MapPin size={22} strokeWidth={2.5} stroke="#fff" />
                                </Button>
                                <span className="btn--text">Location</span>
                            </div>
                            <div className="btn--container">
                                <ConfirmModal
                                    disabled={!matchIsMoreThanThreeHoursAway(fixture)}
                                    title="Cancel Match"
                                    body={<p>Are you sure you want to Cancel this match?</p>}
                                    onConfirm={handleCancelMatch}
                                    isLoading={isCancelling}
                                    isLoadingConfirmText={'Cancelling'}
                                    confirmText="Yes Cancel"
                                    cancelText="Back"
                                    confirmVariant="danger">
                                    <Button
                                        size="lg"
                                        className="cancel"
                                        disabled={!matchIsMoreThanThreeHoursAway(fixture)}>
                                        <XCircle size={22} strokeWidth={2.5} stroke="#fff" />
                                    </Button>
                                </ConfirmModal>
                                <span className="btn--text">Cancel Match</span>
                            </div>
                        </>
                    )}
                </div>
            )}
            {/* <TimerButton /> */}
            {/* {(fixture.is_draft || fixture.is_draft) && <Button size="lg">Confirm Match</Button>} */}
            {/* </div> */}
        </div>
    )
}
export default FixtureMenu
