import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

class DebouncingValidatingField extends React.Component {
    static propTypes = {
        debounce: PropTypes.number,
    }
    static defaultProps = {
        debounce: 500,
    }
    validate = (...args) =>
        new Promise(resolve => {
            if (this.clearTimeout) this.clearTimeout()
            const timerId = setTimeout(() => {
                resolve(this.props.validate(...args))
            }, this.props.debounce)
            this.clearTimeout = () => {
                clearTimeout(timerId)
                resolve()
            }
        })
    render() {
        return <Field {...this.props} validate={this.validate} />
    }
}

export default DebouncingValidatingField
