import { getRequest, postRequest } from './apiRequest'

export const getAllUsers = params => {
    const url = `/admin/get-users`
    return getRequest(url, params)
}

export const getAllSquads = params => {
    const url = `/admin/get-squads`
    return getRequest(url, params)
}

// /admin/training/save-sub-module
export const approveSquad = data => {
    const url = `/admin/approve-squad`
    return postRequest(url, { values: data })
}

// /admin/training/save-sub-module
export const saveSubModule = data => {
    const url = `/admin/save-module-video`
    return postRequest(url, data)
}
