import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import HandleRoutes from './HandleRoutes'

const Home = lazy(() => import('components/FullPage'))
const CookiePolicy = lazy(() => import('components/FullPage/pages/Business/CookiePolicy'))
const PrivacyPolicy = lazy(() => import('components/FullPage/pages/Business/PrivacyPolicy'))
const ResetPassword = lazy(() => import('components/FullPage/pages/ResetPassword'))
const VerifyEmail = lazy(() => import('components/FullPage/pages/VerifyEmail'))
const RadialGraph = lazy(() => import('components/FullPage/pages/Graphs/RadialGraph'))

export const anyRoute = [
    {
        path: '/',
        element: Home,
    },
    {
        path: '/verify-email/:hash',
        element: VerifyEmail,
    },
    {
        path: '/reset-password/:hash',
        element: ResetPassword,
    },
    {
        path: '/cookie-policy',
        element: CookiePolicy,
    },
    {
        path: '/privacy',
        element: PrivacyPolicy,
    },
    {
        path: '/privacy-policy',
        element: PrivacyPolicy,
    },
    {
        path: '/terms-and-services',
        element: PrivacyPolicy,
    },
    {
        path: '/graph-test',
        element: RadialGraph,
    }
]

export const anyRoutePaths = anyRoute.map(route => route.path)

const LandingRoutes = () => {
    const routes = [
        ...anyRoute,
        {
            path: '*',
            element: HandleRoutes,
        },
    ]
    return (
        <Suspense fallback={<div />}>
            <Routes>
                {routes.map(({ path, element: Component }, index) => {
                    return <Route path={path} element={<Component />} key={index} />
                })}
            </Routes>
        </Suspense>
    )
}
export default LandingRoutes
