const defaultValue = [
    { value: 'friendly', label: 'Friendly'},
    { value: 'competitive', label: 'Competitive'}
];

const matchType = (state = defaultValue, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default matchType