import { isGoalConceded, isGoalScored, isHome } from 'utils'
import { loadFixture, updateFixtureDataAwayGoal, updateFixtureDataHomeGoal } from './fixtureReducer'

const SET_FIXTURES_DATA = 'SET_FIXTURES_DATA'
const SET_FIXTURES_PAGINATION = 'SET_FIXTURES_PAGINATION'
const SET_FIXTURES_LOADING = 'SET_FIXTURES_LOADING'
const RESET_FIXTURES_DATA = 'RESET_FIXTURES_DATA'
const SET_FIXTURES_MATCH_TYPE = 'SET_FIXTURES_MATCH_TYPE'
const RESET_FIXTURES_MATCH_TYPE = 'RESET_FIXTURES_MATCH_TYPE'

const matchType = localStorage.getItem('matchType') || 'upcoming'

const initialState = {
    list: [],
    pagination: {},
    isLoading: false,
    matchType,
}

const fixturesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIXTURES_DATA:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case SET_FIXTURES_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            }

        case SET_FIXTURES_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_FIXTURES_DATA:
            localStorage.removeItem('matchType')
            return initialState
        case SET_FIXTURES_MATCH_TYPE:
            localStorage.setItem('matchType', action.payload)
            return {
                ...state,
                matchType: action.payload,
            }
        case RESET_FIXTURES_MATCH_TYPE:
            localStorage.removeItem('matchType')
            return {
                ...state,
                matchType: initialState.matchType,
            }
        default:
            return state
    }
}

export default fixturesReducer

// Fixtures
export const setFixturesData = data => ({
    type: SET_FIXTURES_DATA,
    payload: data,
})

export const addMoreFixturesData = data => (dispatch, getState) => {
    const { fixtures } = getState()
    const updatedFixtures = [...fixtures.list, ...data]
    dispatch(setFixturesData(updatedFixtures))
}

export const setFixturesPagination = data => ({
    type: SET_FIXTURES_PAGINATION,
    payload: data,
})

export const loadingFixtures = () => ({
    type: SET_FIXTURES_LOADING,
})

export const resetFixtures = () => ({
    type: RESET_FIXTURES_DATA,
})

export const setFixturesMatchType = data => ({
    type: SET_FIXTURES_MATCH_TYPE,
    payload: data,
})

export const resetFixturesMatchType = () => ({
    type: RESET_FIXTURES_MATCH_TYPE,
})

// update a single fixture in the fixtures list
export const updateFixture = (match_id, data) => (dispatch, getState) => {
    const { fixtures, fixture } = getState()
    const updatedFixtures = fixtures.list.map(fixture =>
        fixture.id !== match_id
            ? fixture
            : {
                  ...fixture,
                  ...data,
              }
    )
    dispatch(setFixturesData(updatedFixtures))
    const selectedFixture = fixture.data
    if (selectedFixture.id === match_id) {
        dispatch(loadFixture({ ...selectedFixture, ...data }))
    }
}

// update a single fixture in the fixtures list when a home goal is scored
export const setFixtureGoalScored = match_id => (dispatch, getState) => {
    const { fixtures, fixture } = getState()
    const updatedFixtures = fixtures.list.map(fixture =>
        fixture.id !== match_id
            ? fixture
            : isHome(fixture.ground)
            ? {
                  ...fixture,
                  home_goals: fixture.home_goals + 1,
              }
            : {
                  ...fixture,
                  away_goals: fixture.away_goals + 1,
              }
    )
    dispatch(setFixturesData(updatedFixtures))
    const selectedFixture = fixture.data
    if (selectedFixture.id === match_id) {
        isHome(selectedFixture.ground)
            ? dispatch(updateFixtureDataHomeGoal(selectedFixture.home_goals + 1))
            : dispatch(updateFixtureDataAwayGoal(selectedFixture.away_goals + 1))
    }
}

// update a single fixture in the fixtures list when an away goal is scored
export const setFixtureGoalConceded = match_id => (dispatch, getState) => {
    const { fixtures, fixture } = getState()
    const updatedFixtures = fixtures.list.map(fixture =>
        fixture.id !== match_id
            ? fixture
            : isHome(fixture.ground)
            ? { ...fixture, away_goals: fixture.away_goals + 1 }
            : { ...fixture, home_goals: fixture.home_goals + 1 }
    )
    dispatch(setFixturesData(updatedFixtures))
    const selectedFixture = fixture.data
    if (selectedFixture.id === match_id) {
        isHome(selectedFixture.ground)
            ? dispatch(updateFixtureDataAwayGoal(selectedFixture.away_goals + 1))
            : dispatch(updateFixtureDataHomeGoal(selectedFixture.home_goals + 1))
    }
}

// update a single fixture in the fixtures list when a home goal is removed
export const removeFixtureGoalScored = match_id => (dispatch, getState) => {
    const { fixtures, fixture } = getState()
    const updatedFixtures = fixtures.list.map(fixture =>
        fixture.id !== match_id
            ? fixture
            : isHome(fixture.ground)
            ? { ...fixture, home_goals: fixture.home_goals - 1 }
            : { ...fixture, away_goals: fixture.away_goals - 1 }
    )
    dispatch(setFixturesData(updatedFixtures))
    const selectedFixture = fixture.data
    if (selectedFixture.id === match_id) {
        isHome(selectedFixture.ground)
            ? dispatch(updateFixtureDataHomeGoal(selectedFixture.home_goals - 1))
            : dispatch(updateFixtureDataAwayGoal(selectedFixture.away_goals - 1))
    }
}

// update a single fixture in the fixtures list when an away goal is removed
export const removeFixtureGoalConceded = match_id => (dispatch, getState) => {
    const { fixtures, fixture } = getState()
    const updatedFixtures = fixtures.list.map(fixture =>
        fixture.id !== match_id
            ? fixture
            : isHome(fixture.ground)
            ? { ...fixture, away_goals: fixture.away_goals - 1 }
            : { ...fixture, home_goals: fixture.home_goals - 1 }
    )
    dispatch(setFixturesData(updatedFixtures))
    const selectedFixture = fixture.data
    if (selectedFixture.id === match_id) {
        isHome(selectedFixture.ground)
            ? dispatch(updateFixtureDataAwayGoal(selectedFixture.away_goals - 1))
            : dispatch(updateFixtureDataHomeGoal(selectedFixture.home_goals - 1))
    }
}

export const handelGoal = (match_id, eventType) => dispatch => {
    if (isGoalScored(eventType)) dispatch(setFixtureGoalScored(match_id))
    if (isGoalConceded(eventType)) dispatch(setFixtureGoalConceded(match_id))
}

export const handelGoalRemove = (match_id, eventType) => dispatch => {
    if (isGoalScored(eventType)) dispatch(removeFixtureGoalScored(match_id))
    if (isGoalConceded(eventType)) dispatch(removeFixtureGoalConceded(match_id))
}

// Start Timer
export const startMatchFirstHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, first_half_start_time: time } }))
}

export const endMatchFirstHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, first_half_end_time: time } }))
}

export const startMatchSecondHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, second_half_start_time: time } }))
}

export const endMatchSecondHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, second_half_end_time: time } }))
}

export const startMatchETFirstHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, et_first_half_start_time: time } }))
}

export const endMatchETFirstHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, et_first_half_end_time: time } }))
}

export const startMatchETSecondHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, et_second_half_start_time: time } }))
}

export const endMatchETSecondHalf = time => (dispatch, getState) => {
    const {
        fixture: { data: fixture },
    } = getState()
    const { id: matchId, timer } = fixture
    dispatch(updateFixture(matchId, { timer: { ...timer, et_second_half_end_time: time } }))
}
// End Timer
