import UserAvatar from './UserAvatar'
import UserName from './UserName'
import UserTabs from './UserTabs'
import UserTop from './UserTop'

const User = () => {
    return (
        <div className="user-profile-page flex-col h-100">
            <UserTop />
            <div className="d-flex align-items-center gap-3 px-5c">
                <UserAvatar />
                <UserName />
            </div>
            <UserTabs />
        </div>
    )
}

export default User
