const defaultValue = [
    { value: 'cup', label: 'Cup'},
    { value: 'league', label: 'League'}
];

const competitionType = (state = defaultValue, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default competitionType