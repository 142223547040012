import { LS_VERIFY_EMAIL_CANCELED } from 'constants'

const VERIFY_EMAIL_CANCELED = 'VERIFY_EMAIL_CANCELED'
const VERIFY_EMAIL_CLEAR = 'VERIFY_EMAIL_CLEAR'

const canceled = localStorage.getItem(LS_VERIFY_EMAIL_CANCELED)

const initialState = {
    canceled: canceled ? parseInt(canceled) : null,
}

const verifyEmail = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_EMAIL_CANCELED:
            localStorage.setItem(LS_VERIFY_EMAIL_CANCELED, action.payload)
            return {
                ...state,
                canceled: action.payload,
            }
        case VERIFY_EMAIL_CLEAR:
            localStorage.removeItem(LS_VERIFY_EMAIL_CANCELED)
            return {
                ...state,
                canceled: null,
            }
        default:
            return state
    }
}

export default verifyEmail

// action creators
export const verifyEmailCanceled = canceled => ({
    type: VERIFY_EMAIL_CANCELED,
    payload: canceled,
})

export const verifyEmailClear = () => ({
    type: VERIFY_EMAIL_CLEAR,
})
