import cloneDeep from 'lodash/cloneDeep'

const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA'
const RESET_USER_PROFILE_DATA = 'RESET_USER_PROFILE_DATA'
const SET_USER_PROFILE_SQUAD = 'SET_USER_PROFILE_SQUAD'
const RESET_USER_PROFILE_SQUAD = 'RESET_USER_PROFILE_SQUAD'
const SET_USER_PROFILE_LOADING = 'SET_USER_PROFILE_LOADING'
const RESET_USER_PROFILE_LOADING = 'RESET_USER_PROFILE_LOADING'

const initialState = {
    isLoading: false,
    data: {},
    squadSlug: null,
}

const userProfileReducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case SET_USER_PROFILE_DATA:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }
        case RESET_USER_PROFILE_DATA:
            return cloneDeep(initialState)
        case SET_USER_PROFILE_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_USER_PROFILE_LOADING:
            return {
                ...state,
                isLoading: false,
            }

        case SET_USER_PROFILE_SQUAD:
            return {
                ...state,
                squadSlug: action.payload,
            }

        case RESET_USER_PROFILE_SQUAD:
            return {
                ...state,
                squadSlug: null,
            }

        default:
            return state
    }
}

export default userProfileReducer

export const setUserProfile = user => ({
    type: SET_USER_PROFILE_DATA,
    payload: user,
})

export const resetUserProfile = () => ({
    type: RESET_USER_PROFILE_DATA,
})

export const setUserProfileSquad = squadSlug => ({
    type: SET_USER_PROFILE_SQUAD,
    payload: squadSlug,
})

export const resetUserProfileSquad = () => ({
    type: RESET_USER_PROFILE_SQUAD,
})

export const setUserProfileLoading = () => ({
    type: SET_USER_PROFILE_LOADING,
})

export const resetUserProfileLoading = () => ({
    type: RESET_USER_PROFILE_LOADING,
})
