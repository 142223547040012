import classNames from 'classnames'
import GooglePlacesComponent from 'components/shared/GooglePlacesComponent'

export const LocationPicker = ({ value, onChange, className, selectProps, cityOnly = false, ...rest }) => {
    return (
        <div className="search-places">
            <div className="search-places--places-container">
                <GooglePlacesComponent
                    selectProps={{
                        isClearable: true,
                        className: classNames('react-select-min-width', className),
                        onChange,
                        defaultValue: value ? { label: value, value: value } : null,
                        value: value ? { label: value, value: value } : null,
                        autoFocus: true,
                        // placeholder: 'Search by city',
                        // menuIsOpen: true,
                        ...selectProps,
                    }}
                    autocompletionRequest={
                        cityOnly
                            ? {
                                  types: ['(cities)'],
                              }
                            : {}
                    }
                    {...rest}
                />
            </div>
        </div>
    )
}
