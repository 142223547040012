import config from 'config'

// export const fetchYoutubeChannel = async channelUsername => {
//     const response = await fetch(
//         `https://www.googleapis.com/youtube/v3/search?maxResults=1&type=channel&q=${channelUsername}&part=id,snippet&key=${config.FIREBASE_API_KEY}`
//     )
//     const data = await response.json()
//     if (data && data.items && data.items.length > 0) {
//         return data.items[0].id.channelId
//     }
//     return null
// }

export const fetchYoutubeLiveVideo = async channelId => {
    const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=id&channelId=${channelId}&type=video&eventType=live&key=${config.FIREBASE_API_KEY}`
    )
    const data = await response.json()
    if (data && data.items && data.items.length > 0) {
        return data.items[0].id.videoId
    }
    return null
}
