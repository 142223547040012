// import { UPDATE_SINGLE_NOTIFICATION } from '../actions/actionTypes'
const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA'
const SET_NOTIFICATION_PAGINATION = 'SET_NOTIFICATION_PAGINATION'
const SET_NOTIFICATION_LOADING = 'SET_NOTIFICATION_LOADING'
const RESET_NOTIFICATION_DATA = 'RESET_NOTIFICATION_DATA'
const SET_NOTIFICATION_FILTER_BY_UNREAD = 'SET_NOTIFICATION_FILTER_BY_UNREAD'
const RESET_NOTIFICATION_FILTER_BY_UNREAD = 'RESET_NOTIFICATION_FILTER_BY_UNREAD'
const UPDATE_NOTIFICATION_FILTER_BY_UNREAD = 'UPDATE_NOTIFICATION_FILTER_BY_UNREAD'

const initialState = {
    list: [],
    pagination: {},
    isLoading: false,
    filterByUnread: false,
    filteredList: [],
}

const notificationData = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATION_DATA:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case SET_NOTIFICATION_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            }
        case SET_NOTIFICATION_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case RESET_NOTIFICATION_DATA:
            return initialState
        case SET_NOTIFICATION_FILTER_BY_UNREAD:
            return {
                ...state,
                filterByUnread: true,
                filteredList: state.list
                    .filter(notification => notification.is_read === false)
                    .map(notification => notification.id),
            }
        case UPDATE_NOTIFICATION_FILTER_BY_UNREAD:
            return {
                ...state,
                filteredList: state.list
                    .filter(notification => notification.is_read === false)
                    .map(notification => notification.id),
            }
        case RESET_NOTIFICATION_FILTER_BY_UNREAD:
            return {
                ...state,
                filterByUnread: false,
                filteredList: [],
            }
        default:
            return state
    }
}

export default notificationData

// Notification
export const setNotificationData = data => ({
    type: SET_NOTIFICATION_DATA,
    payload: data,
})

export const setNotificationPagination = data => ({
    type: SET_NOTIFICATION_PAGINATION,
    payload: data,
})

export const loadingNotification = () => ({
    type: SET_NOTIFICATION_LOADING,
})

export const resetNotification = () => ({
    type: RESET_NOTIFICATION_DATA,
})

export const setNotificationFilterByUnread = () => ({
    type: SET_NOTIFICATION_FILTER_BY_UNREAD,
})

export const updateNotificationFilterByUnread = () => ({
    type: UPDATE_NOTIFICATION_FILTER_BY_UNREAD,
})

export const resetNotificationFilterByUnread = () => ({
    type: RESET_NOTIFICATION_FILTER_BY_UNREAD,
})

// update a single notification in the notification list
export const updateNotification = (notification_id, data) => (dispatch, getState) => {
    const { notificationData } = getState()
    const updatedNotification = notificationData.list.map(notification => {
        if (notification.id === notification_id) {
            return {
                ...notification,
                ...data,
            }
        }
        return notification
    })
    dispatch(setNotificationData(updatedNotification))
}
