import { createTheme } from '@mui/material'
import { Link } from 'react-router-dom'

const theme = createTheme({
    typography: {
        fontFamily: 'scouty-regular',
        fontWeightRegular: 400,
        fontWeightBold: 600,
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#161b22',
        },
        secondary: {
            main: '#0f212e',
        },
        complete: {
            main: '#1474e1',
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'none',
                as: Link,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#171A20',
                    backgroundImage: 'none',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#171A20',
                    backgroundImage: 'none',
                },
            },
        },
        // Tooltip
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#161b22',
                    color: '#fff',
                },
            },
        },
    },
})
export default theme
