const SET_ADMIN_SQUAD_DATA = 'SET_ADMIN_SQUAD_DATA'
const RESET_ADMIN_SQUAD_DATA = 'RESET_ADMIN_SQUAD_DATA'
const SET_ADMIN_SQUAD_LOADING = 'SET_ADMIN_SQUAD_LOADING'
const RESET_ADMIN_SQUAD_LOADING = 'RESET_ADMIN_SQUAD_LOADING'
const SET_ADMIN_SQUAD_PAGINATION = 'SET_ADMIN_SQUAD_PAGINATION'
const RESET_ADMIN_SQUAD_PAGINATION = 'RESET_ADMIN_SQUAD_PAGINATION'

const initialState = {
    list: [],
    isLoading: false,
    pagination: {},
}

const adminSquadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMIN_SQUAD_DATA:
            return {
                ...state,
                list: action.payload,
            }
        case RESET_ADMIN_SQUAD_DATA:
            localStorage.removeItem('currentPage')
            localStorage.removeItem('totalPages')
            localStorage.removeItem('isLoading')
            return {
                ...state,
                list: [],
            }

        case SET_ADMIN_SQUAD_LOADING:
            localStorage.setItem('isLoading', true)
            return {
                ...state,
                isLoading: true,
            }
        case RESET_ADMIN_SQUAD_LOADING:
            localStorage.setItem('isLoading', false)
            return {
                ...state,
                isLoading: false,
            }
        case SET_ADMIN_SQUAD_PAGINATION: {
            const { current_page: currentPage, total_pages: totalPages } = action.payload
            localStorage.setItem('currentPage', currentPage)
            localStorage.setItem('totalPages', totalPages)

            return {
                ...state,
                pagination: action.payload,
            }
        }
        case RESET_ADMIN_SQUAD_PAGINATION:
            return {
                ...state,
                pagination: {},
            }
        default:
            return state
    }
}

export default adminSquadReducer

export const loadAdminSquad = data => ({
    type: SET_ADMIN_SQUAD_DATA,
    payload: data,
})

export const loadMoreAdminSquad = data => (dispatch, getState) => {
    const { adminSquad } = getState()
    const updatedAdminSquad = [...adminSquad.list, ...data]
    dispatch(loadAdminSquad(updatedAdminSquad))
}

export const addAdminSquad = data => (dispatch, getState) => {
    const { adminSquad } = getState()
    const updatedAdminSquad = [...adminSquad.list, data]
    dispatch(loadAdminSquad(updatedAdminSquad))
}

export const resetAdminSquad = () => ({
    type: RESET_ADMIN_SQUAD_DATA,
})

export const loadingAdminSquad = () => ({
    type: SET_ADMIN_SQUAD_LOADING,
})

export const resetLoadingAdminSquad = () => ({
    type: RESET_ADMIN_SQUAD_LOADING,
})

export const setAdminSquadPagination = pagination => ({
    type: SET_ADMIN_SQUAD_PAGINATION,
    payload: pagination,
})

export const resetAdminSquadPagination = () => ({
    type: RESET_ADMIN_SQUAD_PAGINATION,
})

export const updateAdminSquad = adminSquad => (dispatch, getState) => {
    const { list } = getState().adminSquad
    console.log(adminSquad)
    // const newList = list.map(item => (item.id === adminSquad.id ? adminSquad : item))
    // dispatch(loadAdminSquad(newList))
}

export const deleteAdminSquad = adminSquad => (dispatch, getState) => {
    const { list } = getState().adminSquad
    const newList = list.filter(item => item.id !== adminSquad.id)
    dispatch(loadAdminSquad(newList))
}
