// import Avatar from 'components/shared/Avatar'
// import { selectConversation } from 'components/store'
import cloneDeep from 'lodash/cloneDeep'
import { useSelector } from 'react-redux'
import { useFirestoreDocuments } from 'services'
import Conversation from './Conversation'

const ChatChannels = () => {
    const { subscribed_squads: subscribedSquads } = useSelector(state => state.user)
    // const conversation = useSelector(state => state.conversation)
    // const { selectedConversation } = conversation

    const conversationIds = subscribedSquads?.map(conversation => conversation.squad_id.toString()) || []

    // const { data: conversations, loading } = useFirestoreDocuments('conversations', conversationIds)

    // if (!selectedConversation && conversations?.length > 0) {
    //     dispatch(selectConversation(conversations[0]))
    // }

    return (
        <div className="view channel-view">
            <div className="navbar navbar-default">
                <div className="navbar-inner">
                    {/* <a
                        className="action p-l-10 link text-color"
                        data-navigate="view"
                        data-view-port="#chat"
                        data-view-animation="push-parrallax">
                        <i className="pg-icon">add</i>
                    </a> */}
                    <h5 className="view-heading">
                        Chat List
                        {/* <div className="fs-11">Show All</div> */}
                    </h5>
                    {/* <a href="#" className="action p-r-10 pull-right link text-color">
                        <i className="pg-icon">more_horizontal</i>
                    </a> */}
                </div>
            </div>
            <div data-init-list-view="ioslist" className="list-view boreded no-top-border">
                <h2 className="list-view-fake-header">Subscribed Squads</h2>
                <div className="list-view-wrapper scrollbar-theme">
                    <div
                        className="list-view-wrapper scroll-content scroll-scrolly_visible"
                        data-ios="false"
                        style={{
                            height: 'auto',
                            marginBottom: 0,
                            marginRight: 0,
                            maxHeight: 902,
                        }}>
                        <div className="list-view-group-container">
                            <div className="list-view-group-header text-uppercase">Subscribed Squads</div>
                            {/* {loading ? (
                                <div className="d-flex justify-content-center my-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : ( */}
                            <ul>
                                {conversationIds.map(conversation => (
                                    <Conversation id={conversation} key={conversation} />
                                ))}
                            </ul>
                            {/* // )} */}
                        </div>
                    </div>
                    <div className="scroll-element scroll-x scroll-scrolly_visible">
                        <div className="scroll-element_outer">
                            <div className="scroll-element_size"></div>
                            <div className="scroll-element_track"></div>
                            <div className="scroll-bar" style={{ width: 89 }}></div>
                        </div>
                    </div>
                    <div className="scroll-element scroll-y scroll-scrolly_visible">
                        <div className="scroll-element_outer">
                            <div className="scroll-element_size"></div>
                            <div className="scroll-element_track"></div>
                            <div className="scroll-bar" style={{ height: 283, top: 0 }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatChannels
