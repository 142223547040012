import moment from 'moment-timezone'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { capitalizeFirst } from 'utils'

const Info = () => {
    const { isLoading, data: fixtureData } = useSelector(state => state.fixture)
    const { location, date, start_time, match_type, ground, arrive_before } = fixtureData
    const address = location ? location.address : ''

    if (!fixtureData || isLoading || !location) return <div className="p-3">Loading...</div>

    return (
        <>
            <div className="fixture--info">
                <Row className="mb-3">
                    <Col xs={6}>
                        <h6 className="fw-bold text-secondary mb-0">Match Date</h6>
                        <p className="no-margin fs-15">{moment.utc(date).format('Do, MMM Y')}</p>
                    </Col>
                    <Col>
                        <h6 className="fw-bold text-secondary mb-0">Start Time</h6>
                        <p className="no-margin fs-15">
                            {moment.utc(start_time).tz(location.timezone).format('h:mm A')}{' '}
                            {moment.tz(location.timezone).zoneAbbr()}
                            <span className="hint-text block">Arrive {arrive_before}min before</span>
                        </p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={6}>
                        <h6 className="fw-bold text-secondary mb-0">Venue</h6>
                        <p className="no-margin fs-15">{capitalizeFirst(ground)}</p>
                    </Col>
                    <Col>
                        <h6 className="fw-bold text-secondary mb-0">Match Type</h6>
                        <p className="no-margin fs-15">{capitalizeFirst(match_type)}</p>
                    </Col>
                </Row>
                <div className="mb-3">
                    <h6 className="fw-bold text-secondary mb-0">Location</h6>
                    <p className="no-margin fs-15" style={{ maxWidth: 270 }}>
                        {address}
                    </p>
                </div>
            </div>
            <hr className="mx-5 my-0 mb-2" />
        </>
    )
}
export default Info
