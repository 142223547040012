import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import Avatar from 'components/shared/Avatar'
import FinalFormError from 'components/shared/FinalFormError'
import { hideRatePlayersDoneModal, hideRatePlayersModal, loadFixture } from 'components/store'
import { updateClubSquadDetail } from 'components/store/actions'
import { fromPairs } from 'lodash'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { Star } from 'react-feather'
import { Field, Form } from 'react-final-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { saveMatchRating } from 'services'
import { getPlayerName, isSubPlayer } from 'utils'
import validation from './validation'

const RatePlayer = ({ nickname, rating, player, player_match_id, onChange, setMotM, isMotm, hasError }) => {
    return (
        <div>
            <div className="rate-player" key={player_match_id}>
                <div className={`motm ${isMotm ? 'chosen' : ''}`}>
                    <Tooltip placement="bottom" title={<h5 className="fs-20 lh-1 m-t-5 m-b-5">Man of the match</h5>}>
                        <Star onClick={() => setMotM(player_match_id)} size={40} />
                    </Tooltip>
                </div>
                <Avatar width={40} height={40} src={player.photo_url_sm} alt={nickname} />
                <div className="rate-player-name">{nickname}</div>
                <div className={`rating-badge rating-${rating}`} style={{ marginLeft: 'auto' }}>
                    {rating / 10}
                </div>
            </div>
            <div>
                <div className="rate-player-rating">
                    <Slider
                        // defaultValue={rating}
                        min={1}
                        max={100}
                        value={rating}
                        aria-label="Default"
                        // valueLabelDisplay="false"
                        className="text-complete"
                        onChange={({ target }) => onChange(target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

const RatePlayers = () => {
    const dispatch = useDispatch()
    const { squadSlug } = useSelector(state => state.fixture)
    const { showModal, showDoneModal } = useSelector(state => state.ratePlayers)
    const { data: fixture } = useSelector(state => state.fixture)
    const { list: matchEvents } = useSelector(state => state.matchEvents)

    const handleClose = () => dispatch(hideRatePlayersModal())

    const onSubmit = async values => {
        const response = await saveMatchRating(squadSlug, values, fixture.id)

        if (response.status === 202 && response.data && response.data.status === 'success') {
            const {
                data: { data: apiData },
            } = response
            const { match, squad } = apiData
            dispatch(updateClubSquadDetail(squad))
            dispatch(loadFixture(match))
            // dispatch(loadFixturePlayers(players))
            // dispatch(setPlayersInSquad(currentSquad))
            // dispatch(resetPlayerRating())
            toast.success('Player ratings saved successfully')
            handleClose()
        } else {
            toast.error('Error saving player ratings')
        }
    }

    if (!showModal && !showDoneModal) return null

    const subEvents = matchEvents?.filter(e => e.event_type === 'substitution')
    const subPlayersIds = subEvents?.map(e => e.player2.id)
    const subPlayers = fixture.players?.filter(isSubPlayer)
    const subPlayersPresent = subPlayers?.filter(p => subPlayersIds.includes(p.player.id))
    const playersWithoutSubs = fixture?.players?.filter(p => !isSubPlayer(p))

    return (
        <>
            <Modal show={showModal} onHide={handleClose} centered className="rate-players-modal">
                <Form
                    onSubmit={onSubmit}
                    validate={values => validation(values, playersWithoutSubs, subPlayersPresent)}
                    initialValues={fromPairs(
                        playersWithoutSubs.map(({ player_match_id }) => [`rate_player_${player_match_id}`, 50])
                    )}
                    mutators={{
                        setMotm: (args, state, utils) => {
                            utils.changeValue(state, 'player_motm', () => args[0])
                        },
                    }}
                    render={({ handleSubmit, form: { mutators, errors }, valid, submitting, values, form }) => (
                        <form onSubmit={handleSubmit} className="">
                            <Modal.Header closeButton>
                                <Modal.Title className="text-left">
                                    <h4>Rate players vs {fixture.opponent.name}</h4>
                                    <p className="m-t-5 mb-2">
                                        You can only do this once. Please be sure of your ratings.
                                    </p>
                                    {/* errors */}
                                    <Field name="error_field">
                                        {({ input, meta }) => <FinalFormError meta={meta} />}
                                    </Field>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="rate-players">
                                    <div className="rate-group">
                                        <hr />
                                        <h4>Starting 11</h4>
                                        <hr />
                                    </div>
                                    {console.log(playersWithoutSubs)}
                                    {playersWithoutSubs.map(({ player, nickname, player_match_id, rating }) => (
                                        <Field name={`rate_player_${player_match_id}`}>
                                            {({ input, meta }) => (
                                                <>
                                                    <RatePlayer
                                                        hasError={meta.error}
                                                        player={player}
                                                        player_match_id={player_match_id}
                                                        onChange={value => input.onChange(value)}
                                                        rating={input.value}
                                                        nickname={nickname}
                                                        isMotm={
                                                            values.player_motm && values.player_motm === player_match_id
                                                        }
                                                        setMotM={e => mutators.setMotm(player_match_id)}
                                                        key={player.id}
                                                    />
                                                </>
                                            )}
                                        </Field>
                                    ))}
                                    <div className="rate-group">
                                        <hr />
                                        <h4>Substitutes</h4>
                                        <hr />
                                    </div>
                                    {subPlayersPresent.length > 0 ? (
                                        <>
                                            {subPlayersPresent.map(
                                                ({ player, nickname, position, player_match_id, rating }) => (
                                                    <Field name={`rate_player_${player_match_id}`}>
                                                        {({ input, meta }) => (
                                                            <RatePlayer
                                                                player={player}
                                                                onChange={value => input.onChange(value)}
                                                                rating={input.value}
                                                                nickname={nickname}
                                                                position={position}
                                                                // subPlayer={
                                                                //     subEvents.find(sub => sub.player2.id === player.id)
                                                                //         .player
                                                                // }
                                                                isMotm={
                                                                    values.player_motm &&
                                                                    values.player_motm === player.id
                                                                }
                                                                setMotM={e => mutators.setMotm(e)}
                                                                key={player.id}
                                                            />
                                                        )}
                                                    </Field>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <span className="w-100 text-center stat hint-text">
                                            No subs if any participated
                                        </span>
                                    )}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    type="submit"
                                    disabled={!valid || submitting}
                                    variant="complete"
                                    size="lg"
                                    className="w-100 py-3">
                                    {submitting ? (
                                        <div className="d-flex gap-2">
                                            <Spinner animation="border" size="sm" role="status" />
                                            <div className="">Rating Players...</div>
                                        </div>
                                    ) : (
                                        <span className="fs-18">Save Player Ratings</span>
                                    )}
                                </Button>
                            </Modal.Footer>
                        </form>
                    )}
                />
            </Modal>
            <Modal show={showDoneModal} centered onHide={e => dispatch(hideRatePlayersDoneModal())}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-left">
                        <h4>Rate players vs {fixture.opponent.name}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You have already rated your players for this match. You can only do this once.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        variant="default"
                        size="xs"
                        onClick={e => dispatch(hideRatePlayersDoneModal())}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RatePlayers
