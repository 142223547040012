import { getPlayerName } from 'utils'
import { MatchEventTemplate } from './MatchEventTemplate'

const buildSubTitle = event => {
    const { player } = event
    return (
        <>
            Out: <span className="text-white semi-bolds">{getPlayerName(player)}</span>
        </>
    )
}

const buildTitle = event => {
    const { player2 } = event
    return (
        <>
            In: <span className="text-white semi-bolds">{getPlayerName(player2)}</span>
        </>
    )
}

export const MatchEventSubstitution = ({ event }) => {
    return (
        <MatchEventTemplate
            time={event?.minute}
            half={event?.half}
            // title={"GOAL!"}
            secondary={buildTitle(event)}
            small={buildSubTitle(event)}
            image='<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 7">
                            <path fill="#4fbe30" d="M4 2.16L2 0 0 2.16l.018.05H1.02V7h1.958V2.21H3.98" />
                            <path d="M8.02 4.837l-2 2.16-2-2.16.017-.05H5.04v-4.79h1.957v4.79H8" fill="#e43b3b" />
                        </svg>'
        />
    )
}
