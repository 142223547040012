export const LINES = {
    strikerLine: ['LS', 'ST', 'SS', 'RS', 'FW'],
    attackingMidLine: ['LW', 'AML', 'AMC', 'AMR', 'RW'],
    midfielderLine: ['ML', 'MC', 'MR'],
    defensiveLine: ['DMC'],
    backLine: ['LWB', 'LB', 'DC', 'SW', 'RB', 'RWB'],
}

export const FORMATIONS = {
    5: {
        '2-2': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DMC', x: 87, y: 125 },
                { position: 'DMC', x: 35, y: 126 },
                { position: 'AMC', x: 85, y: 55 },
                { position: 'AMC', x: 33, y: 55 },
            ],

        },
        '1-2-1 (Diamond)': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 60, y: 139 },
                { position: 'MC', x: 90, y: 96 },
                { position: 'MC', x: 31, y: 96 },
                { position: 'AMC', x: 60, y: 45 },
            ],
        },
        '2-1-1 (Tree)': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DMC', x: 35, y: 126 },
                { position: 'DMC', x: 84, y: 125 },
                { position: 'MC', x: 59, y: 89 },
                { position: 'ST', x: 59, y: 34 },
            ],
        },
        '1-2-1 (Attack)': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DMC', x: 57, y: 127 },
                { position: 'RW', x: 103, y: 44 },
                { position: 'LW', x: 17, y: 44 },
                { position: 'ST', x: 59, y: 30 },
            ],
        },
    },
    7: {
        '1-3-2': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 83, y: 137 },
                { position: 'DC', x: 40, y: 139 },
                { position: 'MR', x: 101, y: 83 },
                { position: 'MC', x: 61, y: 96 },
                { position: 'ML', x: 18, y: 86 },
                { position: 'ST', x: 62, y: 23 },
            ],
        },
        '2-1-2-1 (Defensive)': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 83, y: 137 },
                { position: 'DC', x: 40, y: 139 },
                { position: 'DMC', x: 61, y: 113 },
                { position: 'MR', x: 101, y: 83 },
                { position: 'ML', x: 18, y: 86 },
                { position: 'ST', x: 62, y: 23 },
            ],
        },
        '1-1-3-1': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 60, y: 138 },
                { position: 'MR', x: 101, y: 83 },
                { position: 'MC', x: 60, y: 103 },
                { position: 'ML', x: 18, y: 86 },
                { position: 'AMC', x: 60, y: 59 },
                { position: 'ST', x: 62, y: 23 },
            ],
        },
        '3-2-1 (Tree)': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 60, y: 138 },
                { position: 'RWB', x: 100, y: 131 },
                { position: 'LWB', x: 19, y: 133 },
                { position: 'MC', x: 39, y: 86 },
                { position: 'MC', x: 81, y: 85 },
                { position: 'FW', x: 59, y: 30 },
            ],
        },
        '2-2-2': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 81, y: 140 },
                { position: 'DC', x: 40, y: 140 },
                { position: 'MC', x: 81, y: 85 },
                { position: 'MC', x: 40, y: 85 },
                { position: 'RS', x: 81, y: 28 },
                { position: 'LS', x: 40, y: 28 },
            ],
        },
        '1-4-1': {
            lineUpV2: [
                { position: 'GK', x: 60, y: 166 },
                { position: 'DC', x: 60, y: 138 },
                { position: 'MR', x: 106, y: 85 },
                { position: 'MC', x: 44, y: 86 },
                { position: 'MC', x: 77, y: 86 },
                { position: 'ML', x: 14, y: 85 },
                { position: 'ST', x: 59, y: 27 },
            ],
        },
    },
    11: {
        '5-3-2 Defensive': {
            lineUpV2: [
                { position: 'DMC', x: 61, y: 109 },
                { position: 'LS', x: 43, y: 25 },
                { position: 'MC', x: 82, y: 84 },
                { position: 'MC', x: 36, y: 87 },
                { position: 'DC', x: 60, y: 146 },
                { position: 'RS', x: 75, y: 23 },
                { position: 'LWB', x: 14, y: 114 },
                { position: 'DC', x: 34, y: 145 },
                { position: 'DC', x: 86, y: 144 },
                { position: 'RWB', x: 106, y: 115 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-4-2': {
            lineUpV2: [
                { position: 'ST', x: 43, y: 25 },
                { position: 'ST', x: 76, y: 22 },
                { position: 'MC', x: 77, y: 89 },
                { position: 'ML', x: 16, y: 84 },
                { position: 'MC', x: 44, y: 91 },
                { position: 'MR', x: 106, y: 83 },
                { position: 'LB', x: 14, y: 144 },
                { position: 'DC', x: 76, y: 145 },
                { position: 'DC', x: 43, y: 145 },
                { position: 'RB', x: 104, y: 143 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-4-1-1': {
            lineUpV2: [
                { position: 'FW', x: 59, y: 21 },
                { position: 'AMC', x: 59, y: 55 },
                { position: 'ML', x: 16, y: 84 },
                { position: 'MC', x: 77, y: 89 },
                { position: 'MC', x: 44, y: 91 },
                { position: 'MR', x: 106, y: 83 },
                { position: 'LB', x: 14, y: 141 },
                { position: 'DC', x: 76, y: 145 },
                { position: 'DC', x: 43, y: 145 },
                { position: 'RB', x: 104, y: 138 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-1-4-1': {
            lineUpV2: [
                { position: 'FW', x: 59, y: 21 },
                { position: 'ML', x: 15, y: 79 },
                { position: 'MC', x: 76, y: 86 },
                { position: 'MC', x: 44, y: 87 },
                { position: 'MR', x: 105, y: 77 },
                { position: 'DMC', x: 60, y: 116 },
                { position: 'LB', x: 14, y: 141 },
                { position: 'DC', x: 76, y: 145 },
                { position: 'DC', x: 43, y: 145 },
                { position: 'RB', x: 104, y: 138 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-2-3-1 Attack': {
            lineUpV2: [
                { position: 'FW', x: 59, y: 21 },
                { position: 'AML', x: 24, y: 53 },
                { position: 'AMC', x: 59, y: 53 },
                { position: 'AMR', x: 96, y: 53 },
                { position: 'MC', x: 76, y: 86 },
                { position: 'MC', x: 44, y: 87 },
                { position: 'LB', x: 14, y: 136 },
                { position: 'DC', x: 76, y: 139 },
                { position: 'DC', x: 43, y: 140 },
                { position: 'RB', x: 105, y: 135 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-2-3-1 Defensive': {
            lineUpV2: [
                { position: 'ST', x: 59, y: 21 },
                { position: 'AMC', x: 60, y: 56 },
                { position: 'ML', x: 16, y: 77 },
                { position: 'MR', x: 103, y: 70 },
                { position: 'DMC', x: 77, y: 108 },
                { position: 'DMC', x: 45, y: 112 },
                { position: 'LB', x: 14, y: 144 },
                { position: 'DC', x: 76, y: 145 },
                { position: 'DC', x: 43, y: 145 },
                { position: 'RB', x: 104, y: 143 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-3-3 Counter': {
            lineUpV2: [
                { position: 'FW', x: 59, y: 21 },
                { position: 'LW', x: 16, y: 48 },
                { position: 'RW', x: 100, y: 46 },
                { position: 'MC', x: 76, y: 85 },
                { position: 'MC', x: 40, y: 85 },
                { position: 'DMC', x: 60, y: 113 },
                { position: 'LB', x: 14, y: 136 },
                { position: 'DC', x: 76, y: 139 },
                { position: 'DC', x: 43, y: 140 },
                { position: 'RB', x: 105, y: 135 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-3-3 Flat': {
            lineUpV2: [
                { position: 'FW', x: 59, y: 21 },
                { position: 'LW', x: 16, y: 48 },
                { position: 'RW', x: 100, y: 46 },
                { position: 'MC', x: 60, y: 93 },
                { position: 'MC', x: 87, y: 82 },
                { position: 'MC', x: 33, y: 82 },
                { position: 'LB', x: 14, y: 136 },
                { position: 'DC', x: 76, y: 139 },
                { position: 'DC', x: 43, y: 140 },
                { position: 'RB', x: 105, y: 135 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '4-3-3 Attack': {
            lineUpV2: [
                { position: 'LS', x: 32, y: 28 },
                { position: 'ST', x: 59, y: 16 },
                { position: 'RS', x: 88, y: 28 },
                { position: 'MC', x: 60, y: 93 },
                { position: 'MC', x: 87, y: 82 },
                { position: 'MC', x: 33, y: 82 },
                { position: 'LB', x: 14, y: 136 },
                { position: 'DC', x: 43, y: 140 },
                { position: 'DC', x: 76, y: 139 },
                { position: 'RB', x: 105, y: 135 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '3-1-4-2': {
            lineUpV2: [
                { position: 'ST', x: 73, y: 21 },
                { position: 'ST', x: 43, y: 22 },
                { position: 'ML', x: 14, y: 81 },
                { position: 'MC', x: 79, y: 82 },
                { position: 'MC', x: 42, y: 82 },
                { position: 'MR', x: 106, y: 81 },
                { position: 'DMC', x: 60, y: 112 },
                { position: 'DC', x: 61, y: 144 },
                { position: 'DC', x: 32, y: 141 },
                { position: 'DC', x: 90, y: 141 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '3-4-1-2': {
            lineUpV2: [
                { position: 'ST', x: 73, y: 21 },
                { position: 'ST', x: 43, y: 22 },
                { position: 'AMC', x: 59, y: 53 },
                { position: 'ML', x: 14, y: 81 },
                { position: 'MC', x: 79, y: 82 },
                { position: 'MC', x: 42, y: 82 },
                { position: 'MR', x: 106, y: 81 },
                { position: 'DC', x: 61, y: 144 },
                { position: 'DC', x: 32, y: 141 },
                { position: 'DC', x: 90, y: 141 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '3-5-2 Narrow': {
            lineUpV2: [
                { position: 'DMC', x: 61, y: 109 },
                { position: 'LS', x: 43, y: 25 },
                { position: 'DC', x: 86, y: 144 },
                { position: 'MC', x: 82, y: 84 },
                { position: 'MC', x: 36, y: 87 },
                { position: 'DC', x: 60, y: 146 },
                { position: 'RS', x: 75, y: 23 },
                { position: 'LWB', x: 14, y: 114 },
                { position: 'DC', x: 34, y: 145 },
                { position: 'RWB', x: 106, y: 115 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
        '3-5-2 Attack': {
            lineUpV2: [
                { position: 'DMC', x: 61, y: 109 },
                { position: 'LS', x: 43, y: 25 },
                { position: 'DC', x: 88, y: 139 },
                { position: 'MC', x: 82, y: 84 },
                { position: 'MC', x: 36, y: 87 },
                { position: 'DC', x: 60, y: 144 },
                { position: 'RS', x: 75, y: 23 },
                { position: 'AML', x: 17, y: 51 },
                { position: 'DC', x: 32, y: 140 },
                { position: 'AMR', x: 103, y: 49 },
                { position: 'GK', x: 60, y: 166 },
            ],
        },
    },
}
