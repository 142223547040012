import { useRef } from 'react'
import { Form as BsForm, Button } from 'react-bootstrap'
import { Send } from 'react-feather'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { addMessageToChat, updateLastMessage } from 'services'

const ChatInput = ({ selectedConversation }) => {
    const { id: currentUserId } = useSelector(state => state.user)
    const inputRef = useRef(null)

    const handleSubmit = ({ message }, form) => {
        const refId = selectedConversation.squadId.toString()
        addMessageToChat(refId, message, currentUserId)
        updateLastMessage(refId, message, currentUserId)
        form.reset()
        inputRef.current.style.height = '2.5rem'
    }

    const setDynamicHeight = e => {
        const el = e.target
        el.style.height = '2.5rem'
        el.style.height = `${el.scrollHeight + 2}px`
    }

    const handleKeyDown = (e, form) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            e.stopPropagation()
            form.submit()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {({ handleSubmit, submitting, values, form }) => (
                <BsForm onSubmit={handleSubmit} className="d-flex align-items-end gap-2 mx-4 mb-4 mt-1">
                    <Field name="message">
                        {({ input, meta }) => (
                            <BsForm.Control
                                {...input}
                                ref={inputRef}
                                as="textarea"
                                className="form-control rounded-3 scrollbar-hidden"
                                placeholder="Message"
                                style={{
                                    minHeight: '40px',
                                    maxHeight: '250px',
                                }}
                                onInput={e => {
                                    setDynamicHeight(e)
                                    input.onChange(e)
                                }}
                                onKeyDown={e => handleKeyDown(e, form)}
                            />
                        )}
                    </Field>
                    <Button type="submit" className="btn btn-primary h-10">
                        <Send className="w-6 h-6" />
                    </Button>
                </BsForm>
            )}
        </Form>
    )
}

export default ChatInput
