import classNames from 'classnames'
import moment from 'moment'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const MonthPicker = ({ showDate, setShowDate, calendarRef }) => {
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)

    const handleClose = () => setShowDate(false)
    const handleShow = () => setShowDate(true)

    const selectYear = year => () => setSelectedYear(year)
    const selectMonth = month => () => setSelectedMonth(month)

    const handleDateChange = () => {
        const calendarApi = calendarRef.current.getApi()
        calendarApi.gotoDate(moment([selectedYear, selectedMonth, 1]).format('YYYY-MM-DD'))
        setSelectedYear(null)
        setSelectedMonth(null)
        handleClose()
    }

    if (!showDate) return null
    return (
        <Modal show={showDate} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Calendar {!selectedYear ? 'Year' : 'Month'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className={classNames('d-grid gap-2', {
                        'grid-cols-4 grid-cols-md-7': !selectedYear,
                        'grid-cols-3 grid-cols-md-6': selectedYear,
                    })}>
                    {!selectedYear
                        ? Array.from(Array(20).keys())
                              .map(n => n + 2011)
                              .map(year => (
                                  <Button
                                      onClick={selectYear(year)}
                                      size="md"
                                      key={year}
                                      className={classNames(
                                          'rounded-pill fw-normal',
                                          year === moment().year()
                                              ? 'btn-complete'
                                              : year === selectedYear
                                              ? 'btn-success'
                                              : 'btn-clear'
                                      )}>
                                      {year}
                                  </Button>
                              ))
                        : [...Array(12)].map((_, index) => {
                              const month = moment().month(index)
                              return (
                                  <Button
                                      onClick={selectMonth(month.month())}
                                      size="md"
                                      key={month.format('MMM')}
                                      className={classNames(
                                          'rounded-pill fw-normal',
                                          month.month() === moment().month()
                                              ? 'btn-complete'
                                              : month.month() === selectedMonth
                                              ? 'btn-success'
                                              : 'btn-clear'
                                      )}>
                                      {month.format('MMM')}
                                  </Button>
                              )
                          })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={handleDateChange}
                    disabled={!selectedYear || !selectedMonth}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default MonthPicker
