import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Component, createRef } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('./../../img/marker-icon-2x.png'),
    iconUrl: require('./../../img/marker-icon.png'),
    shadowUrl: require('./../../img/marker-shadow.png'),
})

class MapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lat: this.props.lat || 51.505,
            lng: this.props.lng || -0.09,
            zoom: this.props.zoom || 13,
            zoomControl: this.props.zoomControl || true,
            height: this.props.height || 400,
            showMarker: this.props.showMarker || true,
            markerText: this.props.markerText || null,
            style: this.props.style || null,
            key: this.props.key || null,
        }
        this.mapRef = createRef()
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lat != this.props.lat && prevProps.lng != this.props.lng) {
            this.setState({
                lat: this.props.lat,
                lng: this.props.lng,
            })
        }
    }

    handleClick(location) {
        if (this.props.onChange) {
            this.props.onChange(location)
            this.setState({
                lat: location.latlng.lat,
                lng: location.latlng.lng,
            })
        }
    }

    render() {
        const position = [this.state.lat, this.state.lng]
        const { style, zoom, zoomControl, width, height, showMarker, markerText } = this.state
        return (
            <>
                <div className="map-container px-0" zoomControl={zoomControl} style={style || null}>
                    <Map
                        ref={this.mapRef}
                        center={position}
                        zoom={zoom}
                        attributionControl={false}
                        scrollWheelZoom={false}
                        style={{ width, height, zIndex: 0 }}
                        onClick={this.handleClick}>
                        <TileLayer
                            url="https://{s}.google.com/vt/x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        />
                        {/* <TileLayer
                            url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&poi=off"
                            // maxZoom={20}
                            subdomains={['mt0','mt1','mt2','mt3']}
                        /> */}
                        {/* <TileLayer
                            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.{ext}"
                            subdomains="abcd"
                            tileLayerOptions
                            minZoom={0}
                            maxZoom={20}
                            ext="png"
                            attribution="Map tiles by <a href='http://stamen.com'>Stamen Design</a>, under <a href='http://creativecommons.org/licenses/by/3.0'>CC BY 3.0</a>. Data by <a href='http://openstreetmap.org'>OpenStreetMap</a>, under <a href='http://creativecommons.org/licenses/by-sa/3.0'>CC BY SA</a>."
                        /> */}
                        {/* <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            // maxZoom={20}
                        /> */}
                        <Marker position={position}>{markerText && <Popup>{markerText}</Popup>}</Marker>
                    </Map>
                </div>
                {this.props.onChange && (
                    <small className="help p-r-0 w-100 block text-right hint-text text-warning semi-bold">
                        * Click anywhere to choose the exact location
                    </small>
                )}
            </>
        )
    }
}

export default MapContainer
