const SET_TRANSFER_DATA = 'SET_TRANSFER_DATA'
const RESET_TRANSFER_DATA = 'RESET_TRANSFER_DATA'
const SET_TRANSFER_LOADING = 'SET_TRANSFER_LOADING'
const RESET_TRANSFER_LOADING = 'RESET_TRANSFER_LOADING'
const SET_TRANSFER_PAGINATION = 'SET_TRANSFER_PAGINATION'
const RESET_TRANSFER_PAGINATION = 'RESET_TRANSFER_PAGINATION'
const SET_TRANSFER_TYPE = 'SET_TRANSFER_TYPE'
const RESET_TRANSFER_TYPE = 'RESET_TRANSFER_TYPE'
const SET_TRANSFER_OPEN_STATE = 'SET_TRANSFER_OPEN_STATE'
const RESET_TRANSFER_OPEN_STATE = 'RESET_TRANSFER_OPEN_STATE'

const initialState = {
    list: [],
    typeState: '',
    openState: 'open',
    isLoading: false,
    pagination: {},
}

const transferReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFER_DATA:
            return {
                ...state,
                list: action.payload,
            }
        case RESET_TRANSFER_DATA:
            localStorage.removeItem('currentPage')
            localStorage.removeItem('totalPages')
            localStorage.removeItem('isLoading')
            return {
                ...state,
                list: [],
            }

        case SET_TRANSFER_LOADING:
            localStorage.setItem('isLoading', true)
            return {
                ...state,
                isLoading: true,
            }
        case RESET_TRANSFER_LOADING:
            localStorage.setItem('isLoading', false)
            return {
                ...state,
                isLoading: false,
            }
        case SET_TRANSFER_PAGINATION: {
            const { current_page: currentPage, total_pages: totalPages } = action.payload
            localStorage.setItem('currentPage', currentPage)
            localStorage.setItem('totalPages', totalPages)

            return {
                ...state,
                pagination: action.payload,
            }
        }
        case RESET_TRANSFER_PAGINATION:
            localStorage.removeItem('currentPage')
            localStorage.removeItem('totalPages')
            return {
                ...state,
                pagination: {},
            }
        case SET_TRANSFER_TYPE:
            return {
                ...state,
                typeState: action.payload,
            }
        case RESET_TRANSFER_TYPE:
            return {
                ...state,
                typeState: '',
            }

        case SET_TRANSFER_OPEN_STATE:
            return {
                ...state,
                openState: action.payload,
            }
        case RESET_TRANSFER_OPEN_STATE:
            return {
                ...state,
                openState: '',
            }
        default:
            return state
    }
}

export default transferReducer

export const loadTransfers = data => ({
    type: SET_TRANSFER_DATA,
    payload: data,
})

export const loadMoreTransfers = data => (dispatch, getState) => {
    const { transfer } = getState()
    const updatedTransfers = [...transfer.list, ...data]
    dispatch(loadTransfers(updatedTransfers))
}

export const addTransfer = data => (dispatch, getState) => {
    const { transfer } = getState()
    const updatedTransfers = [...transfer.list, data]
    dispatch(loadTransfers(updatedTransfers))
}

export const resetTransfers = () => ({
    type: RESET_TRANSFER_DATA,
})

export const loadingTransfers = () => ({
    type: SET_TRANSFER_LOADING,
})

export const resetLoadingTransfers = () => ({
    type: RESET_TRANSFER_LOADING,
})

export const setTransferPagination = pagination => ({
    type: SET_TRANSFER_PAGINATION,
    payload: pagination,
})

export const resetTransferPagination = () => ({
    type: RESET_TRANSFER_PAGINATION,
})

export const setTransferType = type => ({
    type: SET_TRANSFER_TYPE,
    payload: type,
})

export const resetTransferType = () => ({
    type: RESET_TRANSFER_TYPE,
})

export const setTransferOpenState = state => ({
    type: SET_TRANSFER_OPEN_STATE,
    payload: state,
})

export const resetTransferOpenState = () => ({
    type: RESET_TRANSFER_OPEN_STATE,
})

export const updateTransfer = transfer => (dispatch, getState) => {
    const { list } = getState().transfer
    const newList = list.map(item => (item.id === transfer.id ? transfer : item))
    dispatch(loadTransfers(newList))
}

export const deleteTransfer = transfer => (dispatch, getState) => {
    const { list } = getState().transfer
    const newList = list.filter(item => item.id !== transfer.id)
    dispatch(loadTransfers(newList))
}
