import { IconButton, Tooltip } from '@mui/material'
import Avatar from 'components/shared/Avatar'
import { clearConversation, getUserConversation } from 'components/store'
import { ChevronLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'

const ConversationHeader = ({ selectedConversation }) => {
    const dispatch = useDispatch()
    const { squadId } = selectedConversation
    const squad = useSelector(state => getUserConversation(state, squadId))

    const backToConversations = () => dispatch(clearConversation())

    return (
        <div className="conversation-header">
            <Tooltip title="Back">
                <IconButton onClick={backToConversations} size="small" aria-label="Back" className="btn-icon">
                    <ChevronLeft size={16} />
                </IconButton>
            </Tooltip>
            <div className="d-flex">
                <div className="">
                    <Avatar width={30} height={30} boxed src={squad?.photo_url_xs} alt={squad?.name} />
                </div>
                <div className="d-flex flex-column justify-content-center ms-3">
                    <h6 className="fw-bold my-0">{squad?.name}</h6>
                </div>
            </div>
            <div style={{ width: 26 }} />
        </div>
    )
}
export default ConversationHeader
