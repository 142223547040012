import React from 'react'

const IndoorField = () => {
    return (
        <g transform="translate(0, 10)">
            <path
                d="M -30 30 L -30 150 L 150 150 L 150 30 L -30 30 Z"
                fill="rgb(189 40 40)"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -1, 0, -0.000012, 0.000016)"></path>
            <path
                d="M -26.744 34.167 L -26.744 145.129 L 146.506 145.129 L 146.506 34.167 L -26.744 34.167 Z"
                fill="rgb(9 110 143)"
                stroke="#c5c3c3"
                stroke-width="1"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -1, 0, 0.000006, -0.000004)"></path>
            <path
                d="M -27.777 64.611 L -24.66 64.611 M -27.777 118.993 L -24.66 118.993 M 148.589 64.611 L 152.189 64.601 M 148.589 118.993 L 152.222 119.008 M -24.66 50.054 C -11.366 50.054 -0.357 64.611 -0.357 82.19 L -0.357 99.493 C -0.357 117.07 -11.366 131.628 -24.66 131.628 M 148.589 50.054 C 135.295 50.054 124.286 64.611 124.286 82.19 L 124.286 99.493 C 124.286 117.07 135.295 131.628 148.589 131.628 M 20.418 32.201 L 20.418 40.44 M 41.191 32.201 L 41.191 40.44 M 82.738 32.201 L 82.738 40.44 M 103.511 32.201 L 103.511 40.44 M 61.965 36.32 L 61.965 147.283"
                stroke="#c5c3c3"
                stroke-width="1"
                fill="none"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -1, 0, -0.257511, 0.257502)"></path>
            {/* <path
        d="M -24.818 82.5 L -28.807 82.5 L -28.807 99.3 L -24.818 99.3 M -28.807 83.3 L -24.818 83.3 M -24.818 84.1 L -28.807 84.1 M -28.807 84.9 L -24.818 84.9 M -24.818 85.7 L -28.807 85.7 M -28.807 86.5 L -24.818 86.5 M -24.818 87.3 L -28.807 87.3 M -28.807 88.1 L -24.818 88.1 M -24.818 88.9 L -28.807 88.9 M -28.807 89.7 L -24.818 89.7 M -24.818 90.5 L -28.807 90.5 M -28.807 91.3 L -24.818 91.3 M -24.818 92.1 L -28.807 92.1 M -28.807 92.9 L -24.818 92.9 M -24.818 93.7 L -28.807 93.7 M -28.807 94.5 L -24.818 94.5 M -24.818 95.3 L -28.807 95.3 M -28.807 96.1 L -24.818 96.1 M -24.818 96.9 L -28.807 96.9 M -28.807 97.7 L -24.818 97.7 M -24.818 98.5 L -28.807 98.5 M -28.193 82.5 L -28.193 99.3 M -27.579 99.3 L -27.579 82.5 M -26.966 82.5 L -26.966 99.3 M -26.352 99.3 L -26.352 82.5 M -25.739 82.5 L -25.739 99.3 M -25.125 99.3 L -25.125 82.5 M 147 82.5 L 150.989 82.5 L 150.989 99.3 L 147 99.3 M 147 83.3 L 150.989 83.3 M 150.989 84.1 L 147 84.1 M 147 84.9 L 150.989 84.9 M 150.989 85.7 L 147 85.7 M 147 86.5 L 150.989 86.5 M 150.989 87.3 L 147 87.3 M 147 88.1 L 150.989 88.1 M 150.989 88.9 L 147 88.9 M 147 89.7 L 150.989 89.7 M 150.989 90.5 L 147 90.5 M 147 91.3 L 150.989 91.3 M 150.989 92.1 L 147 92.1 M 147 92.9 L 150.989 92.9 M 150.989 93.7 L 147 93.7 M 147 94.5 L 150.989 94.5 M 150.989 95.3 L 147 95.3 M 147 96.1 L 150.989 96.1 M 150.989 96.9 L 147 96.9 M 147 97.7 L 150.989 97.7 M 150.989 98.5 L 147 98.5 M 147.307 82.5 L 147.307 99.3 M 147.921 99.3 L 147.921 82.5 M 148.534 82.5 L 148.534 99.3 M 149.148 99.3 L 149.148 82.5 M 149.761 82.5 L 149.761 99.3 M 150.375 99.3 L 150.375 82.5"
        stroke="#000"
        stroke-width="0.3"
        fill="none"
        style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
        transform="matrix(0, 1, -1, 0, -0.000007, -0.000047)"></path> */}
            {/* <path
        d="M -24.716 82.5 L -23.693 82.5 L -23.693 99.3 L -24.716 99.3 L -24.716 82.5 Z M 145.875 82.5 L 146.898 82.5 L 146.898 99.3 L 145.875 99.3 L 145.875 82.5 Z"
        fill="none"
        stroke="#777"
        style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
        transform="matrix(0, 1, -1, 0, -0.000011, -0.000043)"></path> */}
            {/* <path
        d="M -24.511 82.767 L -23.898 82.767 L -23.898 99.033 L -24.511 99.033 L -24.511 82.767 Z M 146.08 82.767 L 146.693 82.767 L 146.693 99.033 L 146.08 99.033 L 146.08 82.767 Z"
        stroke="#aaa"
        fill="#ccc"
        style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
        transform="matrix(0, 1, -1, 0, 0, -0.000039)"></path> */}
            {/* <path
        d="M -24.818 82.5 L -23.591 82.5 M -23.591 99.3 L -24.818 99.3 M 145.773 82.5 L 147 82.5 M 147 99.3 L 145.773 99.3 L 147 99.3 Z"
        fill="none"
        stroke="#333"
        style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
        transform="matrix(0, 1, -1, 0, -0.000007, -0.000047)"></path>
    <path
        d="M -24.227 34.167 C -24.227 35.98 -25.349 37.462 -26.72 37.462 M 144.037 34.167 C 144.037 35.98 145.159 37.462 146.53 37.462 M -24.227 145.129 C -24.227 143.316 -25.349 141.833 -26.72 141.833 M 144.037 145.129 C 144.037 143.316 145.159 141.833 146.53 141.833"
        stroke="#c5c3c3"
        stroke-width="1"
        fill="none"
        style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
        transform="matrix(0, 1, -1, 0, -0.000004, 0.000007)"></path> */}
            <circle
                cx="-208.142"
                cy="186.764"
                r="1.246"
                fill="#c5c3c3"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -1.002885, 0, 269.401123, -159.362823)"></circle>
            <circle
                cx="91.916"
                cy="174.426"
                r="1.246"
                fill="#c5c3c3"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -1.002885, 0, -30.783857, -84.714821)"></circle>
            <circle
                cx="391.916"
                cy="174.426"
                r="6"
                fill="#c5c3c3"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 0.204545, -0.208333, 0, -331.870514, -22.39229)"></circle>
            <circle
                cx="91.916"
                cy="174.426"
                r="16.5"
                fill="none"
                stroke="#c5c3c3"
                stroke-width="1"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 1, -0.998757, 0, -30.693834, -84.503647)"></circle>
            <circle
                cx="301.916"
                cy="174.426"
                r="3"
                fill="#c5c3c3"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 0.204545, -0.266667, 0, -502.112427, -63.884953)"></circle>
            <circle
                cx="301.916"
                cy="274.426"
                r="2"
                fill="#c5c3c3"
                style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
                transform="matrix(0, 0.204545, -0.266667, 0, -602.112427, -163.884949)"></circle>
        </g>
    )
}

export default IndoorField
