import React from 'react'
import Avatar from "components/shared/Avatar"
import { AlertCircle, Check, Info, XCircle } from 'react-feather'

const CustomToast = ({ appearance, status, title, photo, children, link }) => (
    <div className="pgn push-on-sidebar-open pgn-flip">
        <div className={`alert alert-${appearance}`}>
            <div className="status-container">
                {!photo ? (
                    <>
                        {status === 'success' && (
                            <div className="text-success">
                                <Check size={30} />
                            </div>
                        )}
                        {status === 'danger' && (
                            <div className="text-danger">
                                <XCircle size={30} />
                            </div>
                        )}
                        {status === 'info' && (
                            <div className="text-info">
                                <Info size={30} />
                            </div>
                        )}
                        {status === 'warning' && (
                            <div className="text-warning">
                                <AlertCircle size={30} />
                            </div>
                        )}
                    </>
                ) : (
                    <Avatar width={55} height={55} className="margin-auto">
                        <div dangerouslySetInnerHTML={{ __html: photo }} />
                    </Avatar>
                )}
            </div>
            {title && <p className="bold">{title}</p>}
            <p>{children}</p>
        </div>
    </div>
)

export default CustomToast
