import BenchLineup from './BenchLineup'

const Bench = () => {
    return (
        <div className="fixture--bench">
            <BenchLineup />
        </div>
    )
}

export default Bench
