import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const ConfirmModal = ({
    children,
    onConfirm,
    onCancel,
    title,
    body,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    confirmVariant = 'success',
    cancelVariant = 'secondary',
    isLoading = false,
    isLoadingConfirmText = null,
    confirmProps = {},
    cancelProps = {},
    thirdButtonText = false,
    thirdButtonVariant = 'complete',
    thirdButtonProps = {},
    onThirdButtonClick = () => {},
    disabled = false,
}) => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const handleCancel = onCancel
        ? () => {
              handleClose()
              onCancel()
          }
        : handleClose

    const handleConfirm = onConfirm
        ? async () => {
              await onConfirm()
              handleClose()
          }
        : handleClose

    return (
        <>
            {disabled ? children : <div onClick={handleShow}>{children}</div>}
            {show && (
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    animation={false}
                    className="confirm--modal z-1070"
                    backdropClassName="z-1065">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h4>{title}</h4>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>{body}</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={cancelVariant}
                            type="button"
                            disabled={isLoading}
                            className="bg-transparent"
                            onClick={handleCancel}
                            {...cancelProps}>
                            {cancelText}
                        </Button>
                        {thirdButtonText && (
                            <Button
                                variant={thirdButtonVariant}
                                type="button"
                                onClick={onThirdButtonClick}
                                {...thirdButtonProps}>
                                {thirdButtonText}
                            </Button>
                        )}
                        <Button
                            variant={confirmVariant}
                            disabled={isLoading}
                            type="button"
                            onClick={handleConfirm}
                            {...confirmProps}>
                            {isLoading ? isLoadingConfirmText : confirmText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}
