import classNames from 'classnames'
import Avatar from 'components/shared/Avatar'
import { LoadingCircle } from 'components/shared/Skeleton'
import { setUserData, updateClubSquadDetail } from 'components/store/actions'
import { useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import toast from 'react-hot-toast'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch } from 'react-redux'
import { uploadSquadLogo } from 'services'

const LogoCropper = ({ squadSlug, photo, onSuccess, label = false, centered }) => {
    const dispatch = useDispatch()
    const imgRef = useRef(null)
    const inputRef = useRef(null)
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [completedCrop, setCompletedCrop] = useState()
    const [crop, setCrop] = useState()
    const [imgSrc, setImgSrc] = useState('')

    const handleClose = () => {
        if (inputRef?.current && inputRef?.current?.value) inputRef.current.value = ''
        setIsLoading(false)
        setShow(false)
        setImgSrc('')
        setCrop(undefined)
        setCompletedCrop(undefined)
    }
    const handleShow = () => setShow(true)

    const handleFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
            reader.readAsDataURL(e.target.files[0])
            handleShow()
        }
    }

    const centerPixelCrop = (width, height) => {
        const size = Math.min(width, height)
        return {
            x: (width - size) / 2,
            y: (height - size) / 2,
            width: size,
            height: size,
            unit: 'px',
        }
    }

    const onImageLoad = e => {
        const { width, height } = e.currentTarget
        const crop = centerPixelCrop(width, height)
        setCrop(crop)
    }

    const makeClientCrop = async crop => {
        if (imgRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imgRef.current, crop)
            setCompletedCrop(croppedImageUrl)
        }
    }

    const getCroppedImg = async (image, crop) => {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return canvas.toDataURL('image/png')
    }

    const handelSubmit = async () => {
        setIsLoading(true)
        // const responseCrop = await fetch(completedCrop)
        // const blob = await responseCrop.blob()
        // Get the base64 representation of the cropped image
        const response = await uploadSquadLogo(squadSlug, completedCrop)

        if (response.status === 200 && response.data && response.data.status === 'success') {
            const {
                data: { user, squad },
            } = response
            await dispatch(setUserData(user))
            await dispatch(updateClubSquadDetail(squad))
            const { photo_url_md: photo } = squad
            onSuccess && onSuccess(photo)
        } else {
            toast.error('Sorry there was an error, please contact the administrator')
        }
        handleClose()
    }

    const onCropChange = crop => setCrop(crop)

    const size = 200
    const style = { width: size, height: size }

    return (
        <Row>
            {label && <label className="form-label fw-bold">Logo</label>}
            <Col className="d-flex flex-column">
                <div className={classNames('upload-wrapper', { 'mx-auto': centered })} style={style}>
                    <label className="label-upload" htmlFor="formFile">
                        Upload photo
                    </label>
                    <input
                        className="d-none"
                        type="file"
                        accept="image/*"
                        id="formFile"
                        onChange={e => handleFile(e)}
                        ref={inputRef}
                    />
                    <Avatar width={200} height={200} src={photo} alt="Logo" boxed />
                </div>
                {show && (
                    <Modal className="fill-in colorized" backdrop="static" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h4>Upload logo</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center p-t-20">
                            {!imgSrc ? (
                                <p>Loading...</p>
                            ) : (
                                <ReactCrop
                                    crop={crop}
                                    onChange={onCropChange}
                                    onComplete={crop => makeClientCrop(crop)}
                                    aspect={1}>
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(1) rotate(0deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="complete" onClick={handelSubmit} disabled={isLoading}>
                                {isLoading && <LoadingCircle className="me-2" />}
                                Done
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Col>
        </Row>
    )
}
export default LogoCropper
