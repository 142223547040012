const validation = (values, playersWithoutSubs, subPlayersPresent) => {
    const errors = {}
    const allPlayers = [...playersWithoutSubs, ...subPlayersPresent]
    const allPlayersIds = allPlayers.map(e => e.player_match_id)
    const valueArray = Object.keys(values).filter(key => key.includes('rate_player'))
    const isAllPlayersRated = allPlayersIds.length === valueArray.length

    const allPlayerMatchIds = playersWithoutSubs.map(e => `rate_player_${e.player_match_id}`)

    allPlayerMatchIds.forEach(element => {
        if (!values[element]) {
            errors[element] = true
        }
    })

    if (!isAllPlayersRated) {
        errors.error_field = `Please rate all players. `
    }

    if (!values.player_motm) {
        errors.error_field = errors.error_field
            ? `${errors.error_field} And pick the player of match`
            : 'Please pick the player of the match'
    }

    return errors
}

export default validation
